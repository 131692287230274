import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en.json';

TimeAgo.addDefaultLocale(en);

export const DEBOUNCE_INTERVAL = 350;
export const HOVER_INFO_DELAY = 300;
export const MAX_READABLE_WIDTH = 800;
export const timeAgoEn = new TimeAgo('en-US');
export const defaultSideBarWidth = 225;
export const BUILDER_AREA_HIGHLIGHT_TIME = 5; // in seconds
export const PROVIDER_REVIEW_NOTES_MAX_LENGTH = 160;
