import { FC, ChangeEvent, useState } from 'react';

import MaskedInput, { conformToMask } from '@weave/text-mask';
import {
  FieldLayoutWithAction,
  digitsOnly,
  getMaskConfigProps,
} from '@weave/design-system';

interface SocialSecurityNumberInputProps {
  [key: string]: any;
}

// Inspired by the design-system.
const SocialSecurityNumberInput: FC<SocialSecurityNumberInputProps> = ({ ...rest }) => {
  const [value, setValue] = useState('');
  // always keep the value digits-only, keeping formatting only in the ui
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setValue(digitsOnly(event.target.value));
  };

  // @ts-ignore
  const { maskConfigProps, nonConfigProps } = getMaskConfigProps(rest);
  const ssnMask = [/\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];

  return (
    <MaskedInput
      {...nonConfigProps}
      onChange={handleChange}
      type="text"
      pattern="[0-9]*"
      mask={ssnMask}
      value={
        value.trim()
          ? conformToMask(value, ssnMask, maskConfigProps).conformedValue
          : value
      }
    />
  );
};

export const BuilderSSNField = () => (
  // Inspired by the design-system.
  // @ts-ignore
  <FieldLayoutWithAction field={SocialSecurityNumberInput} />
);
