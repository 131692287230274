import { css } from '@emotion/core';
import { theme } from '@weave/theme';

export const tabStyle = css`
  flex: 1;

  .tab-content {
    display: flex;
    align-items: center;
    gap: ${theme.spacing(1)};

    .count {
      padding: ${theme.spacing(0.5, 0.7)};
      margin: ${theme.spacing(0, 1, 0, 0)};
      border-radius: ${theme.borderRadius.small};
      background-color: ${theme.colors.neutral10};
      color: ${theme.colors.text.subdued};
      line-height: 0.75rem;
      font-size: ${theme.fontSize(12)};
      font-weight: 400;
    }
  }

  &[aria-selected='true'] {
    color: ${theme.colors.primary50};

    .tab-content {
      color: inherit;
    }
  }
`;
