import { FC, useEffect, useState } from 'react';

import { CheckIconSmall, Text, Chip } from '@weave/design-system';

import { useWritebackCapabilitiesContext } from 'context';
import { SyncAppTypes } from 'shared/types';
import { formatPMSName } from 'shared/helpers/utils';

import {
  selectedWritebackDestinationContainerStyles,
  writebackDestinationTextStyle,
  chipStyle,
  multiMessageTextStyle,
} from './selected-writeback-destination.styles';

interface SelectedWritebackDestinationProps {
  sourceTenantId: string;
}

export const SelectedWritebackDestination: FC<SelectedWritebackDestinationProps> = ({
  sourceTenantId,
}) => {
  const [sourceTenant, setSourceTenant] =
    useState<SyncAppTypes.FlattenedSourceTenant | null>(null);
  const { getSourceTenantDetails, hasMultiTenantSource } =
    useWritebackCapabilitiesContext();

  useEffect(() => {
    setSourceTenant(getSourceTenantDetails.bySourceTenantId(sourceTenantId, true));
  }, [sourceTenantId]);

  if (!sourceTenant) {
    return null;
  }

  const pmsName = formatPMSName(sourceTenant.pmsName);

  function getText() {
    if (!sourceTenant) {
      return null;
    }

    if (sourceTenant.parentSourceName) {
      return (
        <>
          {sourceTenant.parentSourceName} | <span className="pms-name">{pmsName}</span> |{' '}
          {sourceTenant.sourceName}
          <span className="pms-version">{sourceTenant.pmVersion}</span>
        </>
      );
    }

    return (
      <>
        {sourceTenant.sourceName} | <span className="pms-name">{pmsName}</span>{' '}
        <span className="pms-version">{sourceTenant.pmVersion}</span>
      </>
    );
  }

  return (
    <div>
      {hasMultiTenantSource && (
        <Text css={multiMessageTextStyle}>
          Given your multi-location office setup with a shared database, you can choose
          the PMS / EHR for new patient record writebacks.
        </Text>
      )}

      <div css={selectedWritebackDestinationContainerStyles}>
        <CheckIconSmall color="primary" />
        <Text css={writebackDestinationTextStyle}>{getText()}</Text>
        <Chip variant="outline" css={chipStyle}>
          selected
        </Chip>
      </div>
    </div>
  );
};
