import { useQuery } from 'react-query';

import { CustomAxios } from 'shared/helpers/axios';
import { getCurrentLocationId } from 'shared/helpers/utils';
import { MedicalCondition, MedicalConditionType } from 'shared/types';
import { queryKeys } from 'shared/constants';
import { useEffect, useState } from 'react';

interface UseMedicalConditionsProps {
  sourceTenantId: string;
  hasMedicalConditons: boolean;
}

export function useMedicalConditions({
  sourceTenantId,
  hasMedicalConditons = false,
}: UseMedicalConditionsProps) {
  const { isLoading, data, isError } = useQuery(
    queryKeys.medicalConditions,
    () => getMedicalConditions(sourceTenantId),
    {
      staleTime: 5 * 60 * 1000,
      refetchOnMount: true,
      enabled: hasMedicalConditons,
    }
  );

  const [allergies, setAllergies] = useState<MedicalCondition>();
  const [medications, setMedications] = useState<MedicalCondition>();
  const [disease, setDisease] = useState<MedicalCondition>();

  useEffect(() => {
    if (!isLoading && data) {
      (data.medicalConditions || []).forEach((condition) => {
        if (condition.name === MedicalConditionType.allergies) {
          setAllergies(condition);
        } else if (condition.name === MedicalConditionType.medications) {
          setMedications(condition);
        } else if (condition.name === MedicalConditionType.disease) {
          setDisease(condition);
        }
      });
    }
  }, [isLoading, data]);

  return {
    data: data?.medicalConditions || [],
    allergies,
    medications,
    disease,
    isLoading,
    isEmpty: data?.isEmpty || false,
    isError,
  };
}

interface MedicalConditionsResponse {
  medicalConditions?: MedicalCondition[];
  isEmpty?: boolean;
}

async function getMedicalConditions(sourceTenantId: string) {
  const respone = await CustomAxios.get<MedicalConditionsResponse>(
    `/medical-conditions`,
    {
      params: {
        companyId: getCurrentLocationId(),
        source_tenant_id: sourceTenantId,
      },
    }
  );
  return respone.data;
}
