export const queryKeys = {
  legalTerms: 'legal-terms',
  formTemplates: 'form-templates',
  simplifiedFormsList: 'simplified-forms-list',
  formPackets: 'form-packets',
  companyLogo: 'company-logo',
  writebackSettings: 'writeback-settings',
  writebackAuth: 'writeback-auth',
  writebackCapabilitiesV3: 'writeback-capabilities-v3',
  medicalConditions: 'medical-conditions',
  formSubmission: 'form-submission',
  formSubmissions: 'form-submissions',
  formAttachment: 'form-attachment',
  submissionPDF: 'submission-pdf',
  submissionCategoryCount: 'submission-category-count',
  clonerLocations: 'cloner-locations',
  validateLocation: 'validate-location',
  featureFlag: 'feature-flag',
  formsUpdateIsReadFlag: 'forms-update-isread-flag-for-submitted-form',
  submissionsUnreadCount: 'forms-submitted-forms-unread-count',
  nonEditableForms: 'non-editable-forms',
  sourceTenants: 'source-tenants',
  preferredSourceTenant: 'preferred-source-tenant',
  builderComponents: 'builder-components',
  locationChildren: 'location-children',
  weaveTemplates: 'weave-templates',
  weaveTemplateTermsAndConditions: 'weave-template-t-and-c',
  uploadForm: 'upload-form',
  digitization: 'digitization',
  customAccessUser: 'custom-access-user',
  providerMapping: {
    list: 'providers-list',
    mapping: 'provider-mapping',
  },
  providerReview: {
    signature: 'provider-signature',
  },
  appointments: {
    appointment: 'appointment',
    list: 'appointments-list',
    count: 'appointments-count',
  },
  patrons: 'patrons',
  eagleSoft: {
    multiImport: 'eaglesoft-multi-import',
  },
  customizationFlags: 'customization-flags',
  kioskListing: {
    forms: 'kiosk-form-order',
    packets: 'kiosk-packet-order',
  },
};
