import { useAuth } from 'auth/auth-provider';
import { FORMS_ACLs } from 'shared/types';

export const useUserACL = () => {
  const { hasACL } = useAuth();

  const hasProviderReviewManageACL = hasACL(FORMS_ACLs.FORMS_PROVIDER_REVIEW_MANAGE);
  const hasProviderReviewACL = hasACL(FORMS_ACLs.FORMS_PROVIDER_REVIEW);

  const hasProviderReviewManagePermission = hasProviderReviewManageACL;
  const hasProviderReviewPermission = hasProviderReviewManageACL
    ? false
    : hasProviderReviewACL;

  const hasNotProviderReviewPermission =
    !hasProviderReviewManagePermission && !hasProviderReviewPermission;

  return {
    hasProviderReviewPermission,
    hasProviderReviewManagePermission,
    hasNotProviderReviewPermission,
  };
};
