import { useQuery, useMutation, useQueryClient, UseMutateFunction } from 'react-query';

import { useWritebackCapabilitiesContext, useLocationValidityContext } from 'context';
import { queryKeys } from 'shared/constants';
import { getCurrentLocationId } from 'shared/helpers/utils';
import { CustomAxios, getResponseData } from 'shared/helpers/axios';
import { WritebackSettingResponse, UpdateWritebackSettingPayload } from 'shared/types';
import { useAlert } from '@weave/design-system';
import { AxiosResponse } from 'axios';

export const useWritebackSettings = () => {
  const queryClient = useQueryClient();

  const { isValidLocation, isValidating } = useLocationValidityContext();
  const { hasMultipleSupportedSourceTenants } = useWritebackCapabilitiesContext();

  // Hook for fetching writeback settings list.
  const { data, ...otherUseQueryProps } = useQuery(
    queryKeys.writebackSettings,
    () =>
      fetchWritebackSettings({
        hasMultipleSourceTenants: hasMultipleSupportedSourceTenants(),
      }),
    {
      refetchOnMount: true,
      enabled: !isValidating && isValidLocation,
    }
  );

  // Hook for updating the writeback setting.
  const {
    mutateAsync: updateWritebackSetting,
    isLoading: isUpdating,
    isSuccess: isUpdateSuccess,
    isError: isUpdateError,
  } = useMutation(updateWbSetting, {
    onSuccess: () => {
      queryClient.invalidateQueries(queryKeys.writebackSettings);
    },
  });

  const writebackSettingsData = data || [];

  function hasAtLeastOneActiveWritebackSetting() {
    for (const setting of writebackSettingsData) {
      if (setting.settingValue !== 'Off') {
        return true;
      }
    }

    return false;
  }

  return {
    data: writebackSettingsData,
    ...otherUseQueryProps,
    updateWritebackSetting,
    isUpdating,
    isUpdateSuccess,
    isUpdateError,
    hasAtLeastOneActiveWritebackSetting,
  };
};

interface FetchWritebackSettingsPayload {
  hasMultipleSourceTenants?: boolean;
}

interface WritebackSettingsResponse {
  message: string;
  data: WritebackSettingResponse[];
}

async function fetchWritebackSettings({
  hasMultipleSourceTenants,
}: FetchWritebackSettingsPayload = {}) {
  const companyId = getCurrentLocationId();

  if (!companyId) {
    return Promise.reject('Location ID not provided!');
  }

  const response = await CustomAxios.get<WritebackSettingsResponse>(
    '/writeback/settings',
    {
      params: { company_id: companyId },
    }
  );

  const data: WritebackSettingResponse[] = getResponseData(response);

  return data.map((setting) => {
    let customName = '';
    let customDescription = '';
    let customNote = '';

    switch (setting.settingName) {
      case 'Create Person':
        customName = 'New Patient Creation';
        customDescription = hasMultipleSourceTenants
          ? 'Weave sends new patient information to your selected system of record (PMS or EHR) to create a new patient record. Use this setting to choose whether new patient record creation happens automatically, or not at all.'
          : 'Weave sends new patient information to your system of record (PMS or EHR) to create a new patient record. Use this setting to choose whether new patient record creation happens automatically, or not at all.';
        break;

      case 'Update Person':
        customName = 'Patient Record Updates';
        customDescription =
          'Weave Forms can recognize a returning patient and update their patient record with new information. Use this setting to choose whether patient record updates happen automatically, or not at all.';
        customNote =
          'Weave Forms recognizes a returning patient based on four primary fields of information: First Name, Last Name, Date of Birth, and Phone. If these fields match an existing patient record, Weave will overwrite and update that patient record. If any field is different, Weave will create a new patient record if New Patient Creation is set to Automatic.';
        break;

      case 'Upload Document':
        customName = 'PDF Document Writebacks';
        customDescription =
          "Weave generates full PDF files of completed forms and saves them to your system of record's document center. Use this setting to choose whether completed form PDF uploads to the system of record happen automatically, or not at all.";
        break;
    }

    return { ...setting, customName, customDescription, customNote };
  });
}

async function updateWbSetting({
  settingId,
  settingValue,
  settingName,
}: UpdateWritebackSettingPayload) {
  const companyId = getCurrentLocationId();

  if (!companyId) {
    return Promise.reject('Location ID not provided!');
  }

  return await CustomAxios.put<WritebackSettingsResponse>(
    `/writeback/settings/${settingId}`,
    {
      company_id: companyId,
      setting_value: settingValue,
    }
  );
}

interface UseRefreshSourceTenantResults {
  handleRefreshSourceTenant: UseMutateFunction<
    AxiosResponse<any, any>,
    unknown,
    void,
    unknown
  >;
  isLoadingRefreshTenant: boolean;
}

export const useRefreshSourceTenant = (): UseRefreshSourceTenantResults => {
  const alert = useAlert();
  const queryClient = useQueryClient();
  const queryKeyToInvalidate = queryKeys.sourceTenants;

  const { mutate, isLoading } = useMutation(refreshSourceTenants, {
    onSuccess: async () => {
      await queryClient.invalidateQueries(queryKeyToInvalidate);
      alert.success('Successfully refreshed the source tenants!');
    },
    onError: async () => {
      alert.error('Failed to refresh the source tenants!');
    },
  });

  return {
    handleRefreshSourceTenant: mutate,
    isLoadingRefreshTenant: isLoading,
  };
};

function refreshSourceTenants() {
  const locationId = getCurrentLocationId();
  return CustomAxios.post(`/refresh-source-tenants`, {
    company_ids: [locationId],
  });
}
