import { FC } from 'react';

import { SpinningLoader, SpinningLoaderProps } from '@weave/design-system';

import { getContainerStyle } from './centered-spinning-loader.style';

interface CenteredSpinningLoaderProps extends SpinningLoaderProps {
  removePadding?: boolean;
}

export const CenteredSpinningLoader: FC<CenteredSpinningLoaderProps> = ({
  removePadding,
  ...otherProps
}) => (
  <div css={getContainerStyle({ removePadding })}>
    <SpinningLoader {...otherProps} />
  </div>
);
