import { PrimaryFieldsIndexMapper } from 'context/form-components/reducers';
import { BuilderFields } from './fields-order.types';
import { IFormBuilder as FB } from './form-builder.types';
import { Dispatch, SetStateAction } from 'react';

export namespace BuilderContext {
  export interface IUsedTemplates {
    allergies: boolean;
    covid: boolean;
    femaleMedicalConditions: boolean;
    medicalConditions: boolean;
    patient: boolean;
    primaryInsurance: boolean;
    secondaryInsurance: boolean;
  }

  export interface OrderedFields {
    id: string;
    name: string;
  }

  export enum FormUpdateType {
    ADD_SECTION = 'add-section',
    COPY_SECTION = 'copy-section',
    COPY_FIELD = 'copy-field',
    DELETE_SECTION = 'delete-section',
    UPDATE_SECTION = 'update-section',
    ADD_FIELD = 'add-field',
    DELETE_FIELD = 'delete-field',
    UPDATE_FIELD = 'update-field',
    MOVE_FIELD_WITHIN_SECTIONS = 'move-field-within-sections',
    SET_FORM = 'set-form',
    REORDER_SECTIONS = 'reorder-sections',
    UPDATE_FORM_NAME = 'update-form-name',
    ADD_SECTION_TEMPLATE = 'add-section-template',
    SET_FORM_ID = 'set-form-id',
    ADD_CONDITION = 'add-condition',
    UPDATE_CONDITION = 'update-condition',
    UPDATE_CONDITIONS_ACTION = 'update-conditions-action',
    DELETE_CONDITION = 'delete-condition',
  }

  export interface IAddNewField {
    type: FormUpdateType.ADD_FIELD;
    data: FB.Field;
    sectionId: string;
    targetIndex: number;
  }
  export interface IUpdateSection {
    type: FormUpdateType.UPDATE_SECTION;
    data: FB.Section;
    sectionId: string;
  }

  export interface IDeleteField {
    type: FormUpdateType.DELETE_FIELD;
    data: {
      fieldId: string;
      sectionId: string;
      targetIndex: number;
      targetToFieldMapper: Record<string, string[]>;
      conditionsMapper: Record<string, FB.FormCondition>;
    };
  }

  export interface IAddSection {
    type: FormUpdateType.ADD_SECTION;
    data: string;
  }

  export interface IMoveFieldWithinSection {
    type: FormUpdateType.MOVE_FIELD_WITHIN_SECTIONS;
    data: {
      sourceSectionId: string;
      sourceSection: FB.Section;
      targetSectionId: string;
      targetSection: FB.Section;
    };
  }

  export interface IUpdateField {
    type: FormUpdateType.UPDATE_FIELD;
    data: FB.Field;
  }

  export interface ISetForm {
    type: FormUpdateType.SET_FORM;
    data: FB.FormStructure;
  }

  export interface IReorderSections {
    type: FormUpdateType.REORDER_SECTIONS;
    data: {
      sections: string[];
    };
  }
  export interface IUpdateFormName {
    type: FormUpdateType.UPDATE_FORM_NAME;
    data: {
      name: string;
    };
  }
  export interface IAddSectionTemplate {
    type: FormUpdateType.ADD_SECTION_TEMPLATE;
    data: {
      name: string;
      fields: Record<string, FB.Field>;
      fieldsOrder: string[];
      sectionTemplate: keyof IUsedTemplates;
      condition?: FB.FormCondition;
    };
  }

  export interface IDeleteSection {
    type: FormUpdateType.DELETE_SECTION;
    data: {
      sectionId: string;
      sectionIndex: number;
    };
  }
  export interface ISetFormId {
    type: FormUpdateType.SET_FORM_ID;
    data: { formId: string };
  }

  export interface ICopySection {
    type: FormUpdateType.COPY_SECTION;
    data: {
      newSectionId: string;
      section: FB.Section;
      newFields: Record<string, FB.Field>;
    };
  }

  export interface ICopyField {
    type: FormUpdateType.COPY_FIELD;
    data: {
      sectionId: string;
      insertIndex: number;
      fieldId: string;
      field: FB.Field;
    };
  }

  export interface IaddNewCondition {
    type: FormUpdateType.ADD_CONDITION;
    data: AddNewConditionArgs;
  }

  export interface IUpdateCondition {
    type: FormUpdateType.UPDATE_CONDITION;
    data: {
      conditionId: string;
      index: number;
      term: FB.FormTerm;
      action: FB.FormAction[];
    };
  }

  export interface IUpdateConditionsAction {
    type: FormUpdateType.UPDATE_CONDITIONS_ACTION;
    data: {
      index: number;
      newAction: FB.FormAction;
      isNewTarget: boolean;
      selectedTarget: string;
    };
  }

  export interface IDeleteCondition {
    type: FormUpdateType.DELETE_CONDITION;
    data: {
      conditionId: string;
      fieldId: string;
    };
  }

  export type FormStateReducerAction =
    | IAddNewField
    | IUpdateSection
    | IDeleteField
    | IAddSection
    | IMoveFieldWithinSection
    | IUpdateField
    | ISetForm
    | IReorderSections
    | IUpdateFormName
    | IAddSectionTemplate
    | IDeleteSection
    | ISetFormId
    | ICopySection
    | ICopyField
    | IaddNewCondition
    | IUpdateCondition
    | IUpdateConditionsAction
    | IDeleteCondition;

  // primary fields type

  export enum PrimaryFieldsUpdateType {
    ENABLE = 'enable',
    DISABLE = 'disable',
    SET_USED_FIELDS = 'set-used-fields',
    BATCH_ENABLE = 'batch-enable',
  }

  export interface ITogglePrimaryFieldStatus {
    type: PrimaryFieldsUpdateType.ENABLE | PrimaryFieldsUpdateType.DISABLE;
    fieldName: string;
  }

  export interface ISetUsedPrimaryFields {
    type: PrimaryFieldsUpdateType.SET_USED_FIELDS;
    data: {
      fields: string[];
    };
  }

  export interface IEnableBatchPrimaryFields {
    type: PrimaryFieldsUpdateType.BATCH_ENABLE;
    data: {
      fields: string[];
    };
  }

  export type PrimaryFieldsReducerAction =
    | ITogglePrimaryFieldStatus
    | ISetUsedPrimaryFields
    | IEnableBatchPrimaryFields;

  export interface AddNewConditionArgs {
    fieldId: string;
    targetFieldId?: string;
  }

  export interface IFormComponentsContext {
    isFormBuilderReady: boolean;
    primaryFieldsOrder: BuilderFields[];
    primaryFieldsIndexMapper: PrimaryFieldsIndexMapper;
    sectionTemplates: FB.ISectionTemplates | undefined;
    usedTemplates: IUsedTemplates;
    formState: FB.FormStructure;
    highlightedArea: string | undefined;
    recentlyAddedFieldId: string | undefined;
    orderedFields: OrderedFields[];
    conditionsMapper: Record<string, FB.FormCondition>;
    targetToFieldMapper: Record<string, string[]>;
    invalidConditions: Record<string, boolean>;
    setInvalidConditions: Dispatch<SetStateAction<Record<string, boolean>>>;
    addNewCondition: (args: AddNewConditionArgs) => void;
    setHighlightedArea: (targetFieldId: string | undefined) => void;
    addSectionTemplate: (templateName: keyof FB.ISectionTemplates) => void;
    updateFormState: (action: FormStateReducerAction) => void;
    updatePrimaryFields: (data: PrimaryFieldsReducerAction) => void;
    updateUsedTemplates: (templateName: keyof IUsedTemplates, isUsed: boolean) => void;
  }
}
