import { UseMutateFunction, useMutation, useQueryClient } from 'react-query';

import { useAlert } from '@weave/design-system';

import { queryKeys } from 'shared/constants';
import { CustomAxios } from 'shared/helpers/axios';
import { getCurrentLocationId } from 'shared/helpers/utils';
import { IFormToClone } from 'shared/types';

interface NewFormTitles {
  [key: string]: string;
}

interface CloneFormsPayload {
  forms: IFormToClone[];
  locationIds: string[];
  new_form_titles?: NewFormTitles;

  isSameLocationCopy?: boolean; // for non-unify offices
}

interface UseFormClonerResults {
  cloneForms: UseMutateFunction<any, unknown, CloneFormsPayload, unknown>;
  isCloning: boolean;
}

export const useFormCloner = (): UseFormClonerResults => {
  const alert = useAlert();
  const queryClient = useQueryClient();

  const { mutate, isLoading } = useMutation(cloneForms, {
    onSuccess: (_, payload) => {
      return queryClient.invalidateQueries(queryKeys.formTemplates).then(() => {
        alert.success(getSuccessMessage(payload));
      });
    },
    onError: (_, { isSameLocationCopy = false }) => {
      alert.error(isSameLocationCopy ? 'Failed to copy form' : 'Cloning failed!');
    },
  });

  return {
    cloneForms: mutate,
    isCloning: isLoading,
  };
};

async function cloneForms({ forms, locationIds, new_form_titles }: CloneFormsPayload) {
  const sourceLocation = getCurrentLocationId();

  return CustomAxios.post('/copy-forms', {
    form_ids: forms.map((form) => form.formId),
    source_location: sourceLocation,
    target_locations: locationIds,
    ...(new_form_titles && { new_form_titles }),
  });
}

function getSuccessMessage({
  locationIds,
  forms,
  isSameLocationCopy = false,
}: CloneFormsPayload) {
  if (isSameLocationCopy) return 'Successfully copied form';

  const part1 = forms.length > 1 ? `${forms.length} forms` : 'a form';
  const part2 =
    locationIds.length > 1 ? `${locationIds.length} different locations` : 'the location';

  return `Successfully cloned ${part1} to ${part2}!`;
}
