import { Suspense } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { Route, Switch } from 'react-router';
import { Global } from '@emotion/core';
import { OktaAuth } from '@okta/okta-auth-js';

import { AlertsProvider, ThemeProvider } from '@weave/design-system';

import { oktaAuthConfig } from 'auth/okta-config';
import { AuthProvider } from 'auth/auth-provider';
import { store } from 'shared/redux/store';
import { globalStyle } from 'shared/styles';
import { CenteredSpinningLoader } from 'shared/components';
import { WebSocketProvider } from 'shared/providers/websocket.provider';

import { publicRoutes } from 'pages/routes';

import AppContent from './app-content/app-content.component';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 600000, // 10 minutes
      cacheTime: 900000, // 15 minutes
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    },
  },
});

const oktaAuth = new OktaAuth({ ...oktaAuthConfig });

export const App = () => {
  return (
    <Provider store={store}>
      <ThemeProvider includeEmotionTheme>
        <AlertsProvider>
          <Global styles={globalStyle} />
          <QueryClientProvider client={queryClient}>
            <BrowserRouter>
              <AuthProvider oktaAuth={oktaAuth}>
                <WebSocketProvider>
                  <Suspense fallback={<CenteredSpinningLoader />}>
                    <Switch>
                      {publicRoutes.map((route) => (
                        <Route key={route.path as string} {...route} />
                      ))}
                      <AppContent />
                    </Switch>
                  </Suspense>
                </WebSocketProvider>
              </AuthProvider>
            </BrowserRouter>
            <ReactQueryDevtools />
          </QueryClientProvider>
        </AlertsProvider>
      </ThemeProvider>
    </Provider>
  );
};
