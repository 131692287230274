import { FC } from 'react';
import { format, differenceInYears } from 'date-fns';
import { SerializedStyles, css } from '@emotion/core';

import { useHoverLabel, Placement } from '@weave/design-system';

import { timeAgoEn } from 'shared/constants';
import { convertUTCtoISO, isDefaultUTCDate } from 'shared/helpers/utils';
import { defaultHoverFontStyle } from 'shared/styles';

import { labelContainerStyle, labelInnerContainerStyle } from './relative-date.styles';

interface RelativeDateProps {
  date: string | undefined;
  labelPrefix?: JSX.Element | string;
  labelSuffix?: JSX.Element | string;
  hoverLabelPrefix?: JSX.Element | string;
  hoverLabelSuffix?: JSX.Element | string;
  hideHoverLabel?: boolean;
  hoverLabelPlacement?: Placement;
  hoverLabelStyleOverride?: SerializedStyles;
  showDescriptiveLabel?: boolean;
  dateFormat?: string;
  dateFormatWithYear?: string;
  showShortDate?: boolean;
}

const DATE_FORMAT = 'EEEE, MMM dd, hh:mm aaa';
const DATE_FORMAT_WITH_YEAR = 'dd MMM yyyy, hh:mm aaa';

export const RelativeDate: FC<RelativeDateProps> = ({
  date,
  labelPrefix,
  labelSuffix,
  hoverLabelPrefix,
  hoverLabelSuffix,
  hideHoverLabel,
  hoverLabelPlacement,
  showDescriptiveLabel = false,
  children,
  dateFormat = DATE_FORMAT,
  dateFormatWithYear = DATE_FORMAT_WITH_YEAR,
  showShortDate = true,
  hoverLabelStyleOverride = css``,
  ...otherProps
}) => {
  const { HoverLabel, labelProps, triggerProps } = useHoverLabel({
    placement: hoverLabelPlacement || 'right',
  });

  const hoverStyles = [defaultHoverFontStyle, hoverLabelStyleOverride];
  const shouldIgnoreDate = date ? isDefaultUTCDate(date) : true;

  function getLabelContent() {
    if (!date) {
      return '';
    }

    if (!showShortDate || shouldIgnoreDate) {
      return (
        <span css={labelInnerContainerStyle}>
          {labelPrefix} {labelSuffix}
        </span>
      );
    }

    const dateObj = new Date(convertUTCtoISO(date));
    const formattedDate = showDescriptiveLabel
      ? timeAgoEn.format(dateObj)
      : timeAgoEn.format(dateObj, 'mini-now');

    return (
      <span css={labelInnerContainerStyle}>
        {labelPrefix} {formattedDate} {labelSuffix}
      </span>
    );
  }

  function getHoverLabelContent() {
    if (!date || shouldIgnoreDate) {
      return '';
    }

    const dateObj = new Date(convertUTCtoISO(date));
    const moreThanAnYearOld = differenceInYears(Date.now(), dateObj) >= 1;
    const formattedDate = format(
      dateObj,
      moreThanAnYearOld ? dateFormatWithYear : dateFormat
    );

    return (
      <>
        {hoverLabelPrefix} <b>{formattedDate}</b> {hoverLabelSuffix}
      </>
    );
  }

  return (
    <>
      <span css={labelContainerStyle} {...triggerProps} {...otherProps}>
        {getLabelContent()}
      </span>

      {!hideHoverLabel && !shouldIgnoreDate && (
        <HoverLabel {...labelProps} css={hoverStyles}>
          {getHoverLabelContent()}
        </HoverLabel>
      )}
    </>
  );
};
