export interface OptionSetValue {
  label: string;
  value: string;
  disabled?: boolean;
}

interface OptionSet {
  isEmpty?: boolean;
  options?: OptionSetValue[];
}

export enum MedicalConditionType {
  'allergies' = 'ALLERGY',
  'medications' = 'MEDICATION',
  'disease' = 'DISEASE',
}

export type MedicalConditionName = 'ALLERGY' | 'DISEASE' | 'MEDICATION';

export interface MedicalCondition {
  id: string;
  lastUpdated: string;
  name: MedicalConditionName;
  optionSet: OptionSet;
  activeOptionSetCount?: number;
  totalOptionSetCount?: number;
}
