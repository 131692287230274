import { css } from '@emotion/core';
import { theme } from '@weave/theme';

export const containerStyle = css`
  flex-grow: 1;
  overflow-x: auto;
  height: 100%;
  display: flex;

  > *:not(:last-of-type) {
    margin-right: ${theme.spacing(4)};
  }
`;

export const navLinkStyle = css`
  display: flex;
  align-items: center;
  text-decoration: none;
  color: ${theme.colors.text.subdued};
  margin-top: ${theme.spacing(3)};
  padding: ${theme.spacing(0, 5, 1.5)};
  border-bottom: 3px solid transparent;

  &.active,
  :hover {
    border-color: ${theme.colors.primary60};
    font-weight: ${theme.font.weight.bold};
    color: inherit;
  }
`;
