import { FC } from 'react';

import { SwitchField, Text, UploadIcon, PayIcon } from '@weave/design-system';
import { BaseFieldProps } from '@weave/design-system';

import {
  containerStyle,
  infoTextStyle,
  switchContainerStyle,
  switchFieldLabelStyle,
  switchFieldStyle,
} from './form-digitization-setting.styles';

interface FormDigitizationSettingProps {
  switchUploadProps: BaseFieldProps<boolean, ''>;
  switchDigitizationFieldProps: BaseFieldProps<boolean, ''>;
}

const FormDigitizationSetting: FC<FormDigitizationSettingProps> = ({ switchUploadProps, switchDigitizationFieldProps }) => (
  <div css={containerStyle}>
    <Text css={infoTextStyle}>For Forms Digitization team only</Text>

    <div css={switchContainerStyle}>
      <UploadIcon />
      <Text css={switchFieldLabelStyle}>Forms Upload</Text>
      <SwitchField {...switchUploadProps} label="" name="" css={switchFieldStyle} />
    </div>
    <div css={switchContainerStyle}>
      <PayIcon />
      <Text css={switchFieldLabelStyle}>Digitization fee check</Text>
      <SwitchField disabled={!switchUploadProps.value} {...switchDigitizationFieldProps} label="" name="" css={switchFieldStyle} />
    </div>
  </div>
);

export default FormDigitizationSetting;
