import { css } from '@emotion/core';
import { theme } from '@weave/theme';

export const buttonStyle = css`
  background: ${theme.colors.secondary.seaweed60} !important;
  border-color: ${theme.colors.secondary.seaweed60} !important;
  gap: ${theme.spacing(1)};
  height: 44px !important;
  line-height: 1 !important;
`;

export const modalStyle = css`
  padding-top: ${theme.spacing(1)} !important;
`;

export const headerStyle = css`
  h2 {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    margin-bottom: ${theme.spacing(1)};
  }
`;

export const titleStyle = css`
  font-size: ${theme.fontSize(14)} !important;
  font-weight: 400 !important;
  margin: 0;

  .date {
    font-size: ${theme.fontSize(16)};
    font-weight: 700;
  }
`;

export const closeModalButtonStyle = css`
  margin-left: auto;
  right: -16px;
`;

export const messageContainerStyle = css`
  background: ${theme.colors.primary5};
  padding: ${theme.spacing(2)};
  padding-bottom: ${theme.spacing(3)};
  margin-top: ${theme.spacing(1)};

  p {
    font-size: ${theme.fontSize(14)};
    margin-top: 0;
  }

  .links {
    display: flex;

    a {
      margin-left: auto;
      font-weight: 700;
    }
  }
`;

export const subscriptionContainerStyle = css`
  display: flex;
  align-items: center;
  margin-top: ${theme.spacing(3)};

  .text {
    font-size: ${theme.fontSize(12)};
  }
`;

export const manageSubscriptionButtonStyle = css`
  white-space: nowrap;
  height: 44px !important;
`;
