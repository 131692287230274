import axios from 'axios';
import { OktaAuth, Tokens } from '@okta/okta-auth-js';

import { CustomAxios, getResponseData } from 'shared/helpers/axios';
import { APP_BASE_URL } from 'shared/constants';
import { isWeaveUser } from 'shared/helpers/utils';

const OKTA_URL = 'https://auth.weaveconnect.com/api/v1/sessions/me';
const GET_WEAVE_JWT_URL = '/auth/oktaexchange';
const REFRESH_WEAVE_TOKEN = '/portal/token/refresh';
const LOCATION_SEARCH_URL = '/portal/locations/';
const LOCATION_DETAILS_URL = '/portal/v1/locations/?';
const contentType = { 'Content-Type': 'application/json' };

interface CustomUserParams {
  email: string;
  locationID: string;
  featureName?: string;
}

export const authApi = {
  POST: {
    getWeaveToken: (token: string) =>
      CustomAxios.post(
        GET_WEAVE_JWT_URL,
        {
          idToken: token,
          exp: '240',
          source: 'forms',
        },
        { baseURL: APP_BASE_URL }
      )
        .then<{ token: string }>(getResponseData)
        .then((res) => res.token),

    refreshWeaveToken: () =>
      CustomAxios.post(
        REFRESH_WEAVE_TOKEN,
        { Expiration: '240' },
        { baseURL: APP_BASE_URL }
      )
        .then(getResponseData)
        .then((token: string) => token),

    isCustomAccessUser: async ({
      email,
      locationID,
      featureName,
    }: CustomUserParams): Promise<boolean> => {
      if (!email) {
        return false;
      }

      if (!isWeaveUser()) {
        return false;
      }

      const payload: { user_email: string; feature_name?: string } = {
        user_email: email,
      };
      if (featureName) {
        payload.feature_name = featureName;
      } else {
        return false;
      }

      try {
        const response = await CustomAxios.post<{ success: boolean }>(
          '/search-custom-user',
          payload,
          { headers: { 'Location-Id': locationID } }
        );

        return getResponseData(response).success || false;
      } catch {
        return false;
      }
    },
  },

  GET: {
    session: () =>
      axios
        .get(OKTA_URL, {
          withCredentials: true,
          headers: contentType,
        })
        .then((res) => (res.data.status === 'ACTIVE' ? true : false)),

    oktaToken: (oktaAuth: OktaAuth) =>
      oktaAuth.token.getWithoutPrompt().then<Tokens | undefined>(({ tokens }) => tokens),

    oktaTokenAlternative: (oktaAuth: OktaAuth) =>
      oktaAuth.token.parseFromUrl().then<Tokens | undefined>(({ tokens }) => tokens),

    locationQuery: (query: string) =>
      CustomAxios.get(`${LOCATION_SEARCH_URL}${query}`, {
        baseURL: APP_BASE_URL,
      }).then(getResponseData),

    locationDetails: (locationUrlParams: string) =>
      CustomAxios.get(`${LOCATION_DETAILS_URL}${locationUrlParams}`, {
        baseURL: APP_BASE_URL,
      }).then(getResponseData),
  },

  DELETE: {
    session: () =>
      axios
        .delete(OKTA_URL, {
          withCredentials: true,
          headers: contentType,
        })
        .then((res) => res),
  },
};
