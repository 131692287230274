import { FC } from 'react';

import { Modal, ModalControlModalProps, PrimaryButton, Text } from '@weave/design-system';

import AutoReminderWarning from './auto-reminder-warning/auto-reminder-warning.component';
import { AutoReminderCheckResult, ReminderType } from '../auto-reminder.types';

import { buttonContainerStyle } from './auto-reminder-warning-modal.styles';

interface AutoReminderWarningModalProps {
  modalProps: ModalControlModalProps;
  entityType: 'form' | 'packet';
  autoReminders: AutoReminderCheckResult;
  onClose: () => void;
}

const AutoReminderWarningModal: FC<AutoReminderWarningModalProps> = ({
  modalProps,
  entityType,
  autoReminders,
  onClose,
}) => {
  return (
    <Modal {...modalProps} minWidth={600}>
      <Modal.Header>Unable to delete this form!</Modal.Header>
      <Modal.Body>
        <Text>
          The {entityType} you are trying to delete is currently in use by one or more
          auto-reminders.
        </Text>

        <Text>
          Delete the following auto-reminder(s) from your Desktop Client and try again:
        </Text>

        <div>
          <AutoReminderWarning
            reminderType={ReminderType.AppointmentReminder}
            activeReminders={autoReminders.appointmentReminders}
          />

          <AutoReminderWarning
            reminderType={ReminderType.AppointmentFollowup}
            activeReminders={autoReminders.appointmentFollowup}
          />

          <AutoReminderWarning
            reminderType={ReminderType.BirthdayReminder}
            activeReminders={autoReminders.birthdays}
          />

          <AutoReminderWarning
            reminderType={ReminderType.RecallReminder}
            activeReminders={autoReminders.recall}
          />

          <AutoReminderWarning
            reminderType={ReminderType.ReviewReminder}
            activeReminders={autoReminders.reviews}
          />

          <AutoReminderWarning
            reminderType={ReminderType.SaveTheDateReminder}
            activeReminders={autoReminders.saveTheDates}
          />

          <AutoReminderWarning
            reminderType={ReminderType.MissedCall}
            activeReminders={autoReminders.missedCalls}
          />

          <AutoReminderWarning
            reminderType={ReminderType.MissedCall}
            activeReminders={autoReminders.missedCalls}
          />
          <AutoReminderWarning
            reminderType={ReminderType.Unspecified}
            activeReminders={autoReminders.unspecified}
          />
        </div>
      </Modal.Body>

      <div css={buttonContainerStyle}>
        <PrimaryButton onClick={onClose} size="tiny">
          Okay
        </PrimaryButton>
      </div>
    </Modal>
  );
};

export default AutoReminderWarningModal;
