import React, { useEffect, useMemo, useRef, useState } from 'react';
import { noop } from 'lodash';

import { useWebSocketURL } from 'shared/hooks/use-web-socket-url.hook';
import { WebSocketEventType } from './websocket.types';

export type WebSocketListener = (message: MessageEvent) => void;

// Each message type can have an array of listeners
export type WebSocketListenerMap = {
  [key in WebSocketEventType]?: WebSocketListener[];
};

export type WebSocketClient = {
  addListener: (type: WebSocketEventType, fn: WebSocketListener) => void;
  connected: boolean;
  removeListener: (type: WebSocketEventType, fn: WebSocketListener) => void;
  send: (data: string | Blob | ArrayBuffer) => void;
};

export const WebSocketContext = React.createContext<WebSocketClient>({
  addListener: noop,
  connected: false,
  removeListener: noop,
  send: noop,
});
const reconnectWaitMax = 128;

export const WebSocketProvider: React.FC = ({ children }) => {
  const wsUrl = useWebSocketURL();
  const socket = useRef<WebSocket>();
  const [connected, setConnected] = useState(false);
  const [disconnectWait, setDisconnectWait] = useState(1);
  const listeners = useRef<WebSocketListenerMap>({});
  const connectionTimeout = useRef<number>();

  function clearTimeout() {
    window.clearTimeout(connectionTimeout.current);
    connectionTimeout.current = undefined;
  }

  function connect(url: string) {
    const ws = new WebSocket(url);

    ws.onmessage = (message: MessageEvent) => {
      const type: WebSocketEventType = JSON.parse(message?.data ?? '')?.method;
      const currentListeners: WebSocketListener[] = listeners.current[type] ?? [];
      console.debug('\nonMessage:', message, currentListeners);
      currentListeners.forEach((fn) => {
        return fn(message);
      });
    };

    ws.onopen = () => {
      console.debug('websocket open');
      clearTimeout();
      setDisconnectWait(1);
      socket.current = ws;
      setConnected(true);
    };

    ws.onclose = () => {
      console.debug('closing websocket connection');
      listeners.current = {};
      setConnected(false);

      setDisconnectWait((wait) => {
        let newWait = wait * 2;
        if (wait > reconnectWaitMax) {
          newWait = reconnectWaitMax;
        }
        return newWait;
      });
    };
  }

  useEffect(() => {
    if (wsUrl) connect(wsUrl);

    return () => {
      if (
        socket.current?.readyState === WebSocket.OPEN ||
        socket.current?.readyState === WebSocket.CONNECTING
      ) {
        socket.current.close();
      }
    };
  }, [wsUrl]);

  useEffect(() => {
    if (
      wsUrl &&
      (socket.current?.readyState === WebSocket.CLOSED ||
        socket.current?.readyState === WebSocket.CLOSING)
    ) {
      connectionTimeout.current = window.setTimeout(
        () => connect(wsUrl),
        disconnectWait * 1000
      );
    }
    return clearTimeout;
  }, [disconnectWait, wsUrl]);

  const client: WebSocketClient = useMemo(
    () => ({
      addListener: (type: WebSocketEventType, fn: WebSocketListener) => {
        const currentListeners = listeners.current[type] ?? [];
        listeners.current[type] = [...currentListeners, fn];
        console.debug('addListener', type, listeners.current[type]);
      },
      connected: socket.current?.readyState === WebSocket.OPEN,
      removeListener: (type: WebSocketEventType, fn: WebSocketListener) => {
        const currentListeners = listeners.current[type] ?? [];
        listeners.current[type] = currentListeners.filter((listener) => listener !== fn);
        console.debug('removeListener', type);
      },
      send: socket.current?.send ?? noop,
    }),
    [connected]
  );

  return <WebSocketContext.Provider value={client}>{children}</WebSocketContext.Provider>;
};
