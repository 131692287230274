import { WritebackSettingName, WritebackStatusCode } from 'shared/types';

interface WritebackSettingState {
  settingId: string;
  value: boolean;
  shouldShowSwitch: boolean;
  shouldDisableSwitch: boolean;
  failed: boolean;
  notApplicable: boolean;
  autoMode: boolean;
  manualMode: boolean;
  statusCode: WritebackStatusCode | 'Not Applicable';
}

export type WritebackSettingsState = {
  [key in WritebackSettingName]: WritebackSettingState;
};

interface ToggleCreatePersonSettingAction {
  type: 'TOGGLE_CREATE_PERSON';
}

interface ToggleUpdatePersonSettingAction {
  type: 'TOGGLE_UPDATE_PERSON';
}

interface ToggleDocumentUploadSettingAction {
  type: 'TOGGLE_UPLOAD_DOCUMENT';
}

interface ResetWritebackSettingsAction {
  type: 'RESET_STATE';
}

interface ModifyWritebackSettingsStateAction {
  type: 'MODIFY_STATE';
  payload: WritebackSettingsState;
}

interface CheckUploadDocumentSettingAction {
  type: 'CHECK_UPLOAD_DOCUMENT';
}

export type WritebackSettingsToggleActions =
  | ToggleCreatePersonSettingAction
  | ToggleUpdatePersonSettingAction
  | ToggleDocumentUploadSettingAction;

export type WritebackSettingsActions =
  | WritebackSettingsToggleActions
  | ResetWritebackSettingsAction
  | ModifyWritebackSettingsStateAction
  | CheckUploadDocumentSettingAction;

export const INITIAL_WB_SETTINGS_STATE: WritebackSettingsState = {
  'Create Person': {
    settingId: '',
    value: false,
    shouldShowSwitch: false,
    shouldDisableSwitch: false,
    failed: false,
    notApplicable: false,
    autoMode: false,
    manualMode: false,
    statusCode: 'Not Applicable',
  },
  'Update Person': {
    settingId: '',
    value: false,
    shouldShowSwitch: false,
    shouldDisableSwitch: false,
    failed: false,
    notApplicable: false,
    autoMode: false,
    manualMode: false,
    statusCode: 'Not Applicable',
  },
  'Upload Document': {
    settingId: '',
    value: false,
    shouldShowSwitch: false,
    shouldDisableSwitch: false,
    failed: false,
    notApplicable: false,
    autoMode: false,
    manualMode: false,
    statusCode: 'Not Applicable',
  },
};

export const writebackSettingReducer = (
  state: WritebackSettingsState,
  action: WritebackSettingsActions
): WritebackSettingsState => {
  switch (action.type) {
    case 'TOGGLE_CREATE_PERSON':
      return {
        ...state,
        'Create Person': {
          ...state['Create Person'],
          value: !state['Create Person'].value,
        },
      };

    case 'TOGGLE_UPDATE_PERSON':
      return {
        ...state,
        'Update Person': {
          ...state['Update Person'],
          value: !state['Update Person'].value,
        },
      };

    case 'TOGGLE_UPLOAD_DOCUMENT':
      return {
        ...state,
        'Upload Document': {
          ...state['Upload Document'],
          value: !state['Upload Document'].value,
        },
      };

    case 'CHECK_UPLOAD_DOCUMENT': {
      const createPersonSetting = state['Create Person'];
      const updatePersonSetting = state['Update Person'];

      const createPersonAvailable =
        !createPersonSetting.notApplicable && !createPersonSetting.value;

      const createAndUpdatePersonAvailable =
        !createPersonSetting.notApplicable &&
        !updatePersonSetting.notApplicable &&
        !createPersonSetting.value &&
        !updatePersonSetting.value;

      /**
       * Unselect the "Upload Document" setting and disable it if:
       *
       * 1. A failed "Create Person" setting is available and not selected.
       * 2. The "Create Person" and "Update Person" settings are available and not selected
       */
      if (createPersonAvailable || createAndUpdatePersonAvailable) {
        return {
          ...state,
          'Upload Document': {
            ...state['Upload Document'],
            value: false,
            shouldDisableSwitch: true,
          },
        };
      }

      return {
        ...state,
        'Upload Document': {
          ...state['Upload Document'],
          shouldDisableSwitch: false,
        },
      };
    }

    case 'RESET_STATE':
      return {
        ...INITIAL_WB_SETTINGS_STATE,
      };

    case 'MODIFY_STATE':
      return {
        ...state,
        ...action.payload,
      };

    default:
      return state;
  }
};
