import { css } from '@emotion/core';
import { theme } from '@weave/theme';

export const containerStyle = css`
  width: 100%;
  margin: 0;
`;

export const inputStyle = css`
  width: 100%;
  height: 100%;
  cursor: default;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
`;

export const valueContainerStyle = css`
  display: flex;
  gap: ${theme.spacing(1)};
  flex-wrap: wrap;
  max-width: calc(100% - 32px);
`;
