import { css } from '@emotion/core';
import { theme } from '@weave/theme';

export const cardContainerStyle = css`
  border: 1px solid ${theme.colors.neutral5};
  background-color: ${theme.colors.neutral70};
  padding: ${theme.spacing(3, 1)};
`;

export const clickableCardStyle = css`
  cursor: pointer;
`;

export const cardContentContainerStyle = css`
  position: relative;
  display: flex;
  width: 100%;
  color: ${theme.colors.white};
`;
