import { useRef, useMemo, useEffect, useReducer } from 'react';

import { useAlert } from '@weave/design-system';

import { ReducerState, reducer } from './selection.reducer';
import { SelectedItemIds, LoadedItemIds } from './selection.types';
import { areAllItemsSelected } from './selection.utils';

interface UseSelectionProps {
  loadedItemIds: LoadedItemIds;
  selectionLimitAlertMessage: string;
  selectionLimitOverride?: number;
}

interface UseSelectionResults {
  areAllSelected: boolean;
  selectionCount: number;
  selectedItems: string[];
  toggleSelection: (itemId: string) => void;
  toggleSelectAll: () => void;
  selectAll: () => void;
  getSelection: () => string[];
  clearSelection: () => void;
  isItemSelected: (itemId: string) => boolean;
  backupSelection: () => void;
  getSelectionBackup: () => string[];
  clearSelectionBackup: () => void;
}

const SHOULD_CONSIDER_LIMIT = true;

const initialState: ReducerState = {
  selectedItems: {},
  masterSelect: false,
};

export const useSelection = ({
  loadedItemIds,
  selectionLimitAlertMessage,
  selectionLimitOverride,
}: UseSelectionProps): UseSelectionResults => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const cachedSelection = useRef<SelectedItemIds>({});
  const alert = useAlert();

  const selectionMetadata = useMemo<{ count: number; items: string[] }>(() => {
    let count = 0;
    const items: string[] = [];

    for (const itemId in state.selectedItems) {
      items.push(itemId);
      count += 1;
    }

    return {
      count,
      items,
    };
  }, [state.selectedItems]);

  useEffect(() => {
    if (
      state.masterSelect &&
      !areAllItemsSelected({
        loadedItemIds: loadedItemIds,
        selectedItems: state.selectedItems,
      })
    ) {
      dispatch({ type: 'reset-master-select' });
    }
  }, [loadedItemIds]);

  function toggleSelection(itemId: string) {
    dispatch({
      type: 'toggle-select',
      payload: {
        itemId,
        loadedItemIds,
        alertRef: alert,
        alertMessage: selectionLimitAlertMessage,
        selectionLimitOverride: selectionLimitOverride,
      },
    });
  }

  function selectAll() {
    dispatch({ type: 'select-all', payload: loadedItemIds });
  }

  function clearSelection() {
    dispatch({ type: 'clear-all' });
  }

  function toggleSelectAll() {
    if (state.masterSelect) {
      clearSelection();
    } else {
      selectAll();
    }
  }

  function getSelection() {
    return Object.keys(state.selectedItems);
  }

  function isItemSelected(itemId: string) {
    return state.selectedItems[itemId] || false;
  }

  function backupSelection() {
    cachedSelection.current = state.selectedItems;
  }

  function clearSelectionBackup() {
    cachedSelection.current = {};
  }

  function getSelectionBackup() {
    return Object.keys(cachedSelection.current);
  }

  return {
    areAllSelected: state.masterSelect,
    selectionCount: selectionMetadata.count,
    selectedItems: selectionMetadata.items,
    toggleSelection,
    toggleSelectAll,
    selectAll,
    getSelection,
    clearSelection,
    isItemSelected,
    backupSelection,
    clearSelectionBackup,
    getSelectionBackup,
  };
};
