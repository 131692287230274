import { useState, useEffect } from 'react';
import { useQuery } from 'react-query';

import {
  useControlledField,
  useModalControl,
  ModalControlResponse,
  BaseFieldProps,
} from '@weave/design-system';

import { useLocationValidityContext } from 'context';
import { queryKeys } from 'shared/constants';
import { CustomAxios } from 'shared/helpers/axios';
import { getCurrentLocationId } from 'shared/helpers/utils';
import { useUpdateDigitizationFeeCheck } from 'shared/hooks';

export interface UseDigitizationFeeResults {
  modalControls: ModalControlResponse;
  toggleDigitizationFeeSwitchProps: BaseFieldProps<boolean, ''>;
  checkDigitizationFee: boolean | undefined;
  isLoading: boolean;
}

export const useDigitizationFee = (isAllowedUploadForm: boolean): UseDigitizationFeeResults => {
  const { isValidating, isValidLocation } = useLocationValidityContext();

  const [checkDigitizationFee, setCheckDigitizationFee] = useState<boolean>(false);
  const { updateDigitizationFeeCheck } = useUpdateDigitizationFeeCheck();
  const modalControls = useModalControl();

  const toggleDigitizationFeeSwitchProps = useControlledField({
    type: 'switch',
    value: checkDigitizationFee,
    onChange: (value: boolean) => {
      if (!value) {
        modalControls.openModal();
        return;
      }
      setCheckDigitizationFee(value);
      updateDigitizationFeeCheck(value);
    },
  });

  const { data, isLoading, isFetching } = useQuery(
    [queryKeys.digitization, getCurrentLocationId()],
    fetchDigitizationFee,
    {
      enabled: !isValidating && isValidLocation && isAllowedUploadForm,
    }
  );

  useEffect(() => {
    if (!isLoading && data && !isFetching) {
      const validateDigitizationFee = !!data.validateOfficeCredit
      setCheckDigitizationFee(validateDigitizationFee);
    }
  }, [data, isLoading, isFetching]);


  useEffect(() => {
    // If allowed upload form is toggled off, then digitization fee should be false
    if (!isAllowedUploadForm) {
      setCheckDigitizationFee(false);
    }
  }, [isAllowedUploadForm]);

  return {
    modalControls,
    toggleDigitizationFeeSwitchProps,
    checkDigitizationFee,
    isLoading,
  };
};

interface GetFormDigitizationFeeResponse {
  validateOfficeCredit: boolean | undefined;
}

async function fetchDigitizationFee() {
  const { data } = await CustomAxios.get<GetFormDigitizationFeeResponse>(
    `/digitization/fees-check`,
    {
      params: {
        companyId: getCurrentLocationId(),
      },
    }
  );
  return data;
}
