import { css } from '@emotion/core';
import { theme } from '@weave/theme';

export const selectedWritebackDestinationContainerStyles = css`
  display: flex;
  align-items: center;
  gap: ${theme.spacing(1)};
`;

export const writebackDestinationTextStyle = css`
  color: ${theme.colors.neutral50} !important;
  font-size: ${theme.fontSize(14)} !important;

  .pms-version {
    font-size: ${theme.fontSize(12)} !important;
  }
`;

export const chipStyle = css`
  .chip-text {
    font-size: ${theme.fontSize(14)};
  }
`;

export const multiMessageTextStyle = css`
  margin-bottom: 0;
  font-size: ${theme.fontSize(14)} !important;
  text-align: left;
`;
