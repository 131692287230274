interface GetItemNumberParams {
  pageIndex: number;
  itemIndex: number;
  perPageCount: number;
}

export function getItemNumber({
  pageIndex,
  itemIndex,
  perPageCount,
}: GetItemNumberParams) {
  const itemNumber = pageIndex * perPageCount + itemIndex + 1;
  return itemNumber < 10 ? `0${itemNumber}` : itemNumber;
}
