import { FC } from 'react';
import { noop, isFunction } from 'lodash';
import { SerializedStyles } from '@emotion/core';

import {
  cardContainerStyle,
  clickableCardStyle,
  cardContentContainerStyle,
} from './minified-card.style';

interface MinifiedCardProps {
  onClick?: () => void;
  isSelected?: boolean;
  activeStyle?: SerializedStyles;
}

export const MinifiedCard: FC<MinifiedCardProps> = ({
  onClick = noop,
  children,
  isSelected,
  activeStyle,
  ...otherProps
}) => (
  <div
    css={[
      cardContainerStyle,
      isFunction(onClick) && clickableCardStyle,
      isSelected && activeStyle,
    ]}
    onClick={onClick}
    {...otherProps}
  >
    <div css={cardContentContainerStyle}>{children}</div>
  </div>
);
