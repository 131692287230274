import { css } from '@emotion/core';
import { theme } from '@weave/theme';

export const mobileNavbarStripIconsStyle = css`
  display: flex;
  margin: ${theme.spacing(1, 2)};
`;

export const hamburgerIconStyle = css`
  margin-top: ${theme.spacing(1)};
`;

export const plusIconStyle = css`
  margin-left: auto;
`;

export const childrenStyle = css`
  width: 100%;
`;
