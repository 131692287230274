import { useMutation, UseMutateAsyncFunction, useQueryClient } from 'react-query';

import { useAlert } from '@weave/design-system';

import { getCurrentLocationId } from 'shared/helpers/utils';
import { RejectSubmissionPayload } from 'shared/types';
import { queryKeys } from 'shared/constants';
import { User } from 'auth/auth.types';

import { submitFormSubmissionReview } from 'pages/form-submissions/form-submissions.api';

interface UseRejectSubmissionResults {
  rejectSubmission: UseMutateAsyncFunction<any, unknown, IPayload, unknown>;
  isMarkingAsRejected: boolean;
}

const ERROR_MESSAGE = 'Failed to reject the submission!';

export const useRejectSubmission = (): UseRejectSubmissionResults => {
  const alert = useAlert();
  const queryClient = useQueryClient();

  const { mutateAsync, isLoading } = useMutation(rejectSubmission, {
    onSuccess: (res, payload) => {
      refetchSubmission(payload);

      if (!res.success) {
        alert.error(ERROR_MESSAGE);
        return;
      }

      alert.success('Submission rejected successfully!');
    },
    onError: (_, payload) => {
      refetchSubmission(payload);
      alert.error(ERROR_MESSAGE);
    },
  });

  function refetchSubmission(payload: IPayload) {
    return Promise.all([
      queryClient.invalidateQueries(queryKeys.formSubmissions),
      queryClient.invalidateQueries([queryKeys.formSubmission, payload.submissionId]),
    ]);
  }

  return {
    rejectSubmission: mutateAsync,
    isMarkingAsRejected: isLoading,
  };
};

interface IPayload {
  submissionId: string;
  note: string;
  user: User;
}

async function rejectSubmission({ submissionId, note, user }: IPayload) {
  const payload: RejectSubmissionPayload = {
    company_id: getCurrentLocationId(),
    submission_id: submissionId,
    note,
    review_status: 2,
    provider_details: {
      ...user,
    },
  };

  return submitFormSubmissionReview(payload);
}
