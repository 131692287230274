import { useState } from 'react';
import { useHistory } from 'react-router';

import { ContentLoader } from '@weave/design-system';

import {
  SideNavContext,
  WritebackCapabilitiesProvider,
  WritebackSetupProvider,
  LocationValidityProvider,
} from 'context';
import { useAuth } from 'auth/auth-provider';

import { GlobalNav } from './global-nav/global-nav.component';
import { MainContent } from './private/main-content/main-content.component';
import { SideNav } from './private/side-nav/side-nav.component';

import { containerStyle, innerContainer } from './app-content.style';

const AppContent = () => {
  const history = useHistory();
  const { user } = useAuth();
  const [showMobileSideNav, setShowSideNav] = useState(false);

  function toggleSideNav() {
    setShowSideNav(!showMobileSideNav);
  }

  return (
    <div css={containerStyle}>
      {history.location.pathname !== '/location' && !user ? (
        <ContentLoader show={true} />
      ) : (
        <SideNavContext.Provider value={{ showMobileSideNav, toggleSideNav }}>
          <LocationValidityProvider>
            <GlobalNav />

            <WritebackCapabilitiesProvider>
              <WritebackSetupProvider>
                <div css={innerContainer}>
                  <SideNav />
                  <MainContent />
                </div>
              </WritebackSetupProvider>
            </WritebackCapabilitiesProvider>
          </LocationValidityProvider>
        </SideNavContext.Provider>
      )}
    </div>
  );
};

export default AppContent;
