import { FC, useRef } from 'react';
import { BuilderContext, IFormBuilder } from 'shared/types';
import ConditionItem from './condition-item.component';
import NewConditionButton from './new-condition-button';
import { conditionsWrapperStyles } from './condition-panel.style';
import { ConfirmationModal, useModalControl } from '@weave/design-system';
import { useFormComponentsContext } from 'context';

interface ConditionPanelProps {
  field: IFormBuilder.Field;
  conditions: string[];
}

const ConditionPanel: FC<ConditionPanelProps> = ({ field, conditions }) => {
  const { modalProps, openModal } = useModalControl();
  const conditionToBeDeleted = useRef<string>();
  const { updateFormState } = useFormComponentsContext();
  if (conditions.length < 1) {
    return null;
  }

  const onDelete = () => {
    if (conditionToBeDeleted.current) {
      updateFormState({
        type: BuilderContext.FormUpdateType.DELETE_CONDITION,
        data: {
          conditionId: conditionToBeDeleted.current,
          fieldId: field.id,
        },
      });
    }
  };

  return (
    <section css={conditionsWrapperStyles}>
      {conditions.map((conditionId, index) => {
        return (
          <ConditionItem
            key={conditionId}
            id={conditionId}
            conditionNumber={index + 1}
            field={field}
            onDelete={(id) => {
              conditionToBeDeleted.current = id;
              openModal();
            }}
          />
        );
      })}
      <div className="new-condition">
        <NewConditionButton fieldId={field.id} />
      </div>
      <ConfirmationModal
        {...modalProps}
        title="Delete Condition"
        message="Are you sure you want to delete this condition?"
        onConfirm={onDelete}
        confirmLabel="Delete"
        destructive
      />
    </section>
  );
};

export default ConditionPanel;
