import { FC, useEffect } from 'react';

import { Text, useFormField, TextField } from '@weave/design-system';

import { containerStyle, textStyle } from './form-cloner-captcha.styles';

interface FormClonerCaptchaProps {
  locationCount: number;
  selectedItemCount: number;
  onValidation: (isValid: boolean) => void;
}

const FormClonerCaptcha: FC<FormClonerCaptchaProps> = ({
  locationCount,
  selectedItemCount,
  onValidation,
}) => {
  const textFieldProps = useFormField({ type: 'text' });
  const captchaText = selectedItemCount > 1 ? 'clone forms' : 'clone form';

  useEffect(() => {
    if (textFieldProps.value === captchaText) {
      onValidation(true);
      return;
    }

    onValidation(false);
  }, [textFieldProps.value, captchaText]);

  if (locationCount === 0) {
    return null;
  }

  return (
    <section css={containerStyle}>
      <Text css={textStyle}>
        Please type in <b>{captchaText}</b> to confirm.
      </Text>

      <TextField {...textFieldProps} name="captcha-field" label="" />
    </section>
  );
};

export default FormClonerCaptcha;
