import axios, { AxiosRequestConfig, AxiosResponse, AxiosInstance } from 'axios';
import jwtDecode from 'jwt-decode';

import { AuthStorage, DecodedAccessToken, Location, WeaveToken } from 'auth/auth.types';
import { API_URL, headerKeys } from 'shared/constants';
import { handleLocalStorage } from 'auth/auth.helpers';

type CustomAxiosInstance = AxiosInstance & {
  __wvaTokens?: {
    weave_token: WeaveToken;
    decoded_weave_token: string;
  };
};

const location = handleLocalStorage.get(AuthStorage.active_location) as Location;

export const axiosInstance: CustomAxiosInstance = axios.create({
  baseURL: `${API_URL}/forms-digital/v1`,
});

if (location) {
  axiosInstance.defaults.headers.common[headerKeys.locationId] = location.locationID;
}

axiosInstance.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.response?.status === 401) {
    }

    return Promise.reject({
      ...error.response.data,
      status: error.response.status,
    });
  }
);

export class CustomAxios {
  static setHeader = (key: string, value: string) => {
    axiosInstance.defaults.headers.common[key] = value;
  };
  static setBaseUrl = (newBaseUrl: string) => {
    axiosInstance.defaults.baseURL = newBaseUrl;
  };
  static headerExists = (key: string) => {
    return !!axiosInstance.defaults.headers.common[key];
  };

  static deleteHeader = (key: string) => {
    delete axiosInstance.defaults.headers.common[key];
  };

  static setLocationHeader = (locationId: string) => {
    axiosInstance.defaults.headers.common[headerKeys.locationId] = locationId;
  };

  static removeLocationHeader = () => {
    delete axiosInstance.defaults.headers.common[headerKeys.locationId];
  };

  static setUpAuth = (weaveToken: string, weaveDecodedToken?: WeaveToken) => {
    axiosInstance.__wvaTokens = {
      weave_token: weaveDecodedToken || jwtDecode(weaveToken),
      decoded_weave_token: weaveToken,
    };

    CustomAxios.setHeader(headerKeys.auth, `Bearer ${weaveToken}`);
    window.localStorage.setItem(AuthStorage.weave_token, weaveToken);
  };

  /* If screen was reloaded want to setup auth again */
  static resetAuth = (weaveToken: string) => {
    axiosInstance.__wvaTokens = {
      weave_token: jwtDecode(weaveToken),
      decoded_weave_token: weaveToken,
    };
    CustomAxios.setHeader(headerKeys.auth, `Bearer ${weaveToken}`);
  };

  static clearAuth = () => {
    delete axiosInstance.__wvaTokens;
    CustomAxios.deleteHeader(headerKeys.auth);
    CustomAxios.deleteHeader(headerKeys.locationId);
    CustomAxios.deleteHeader(headerKeys.ipAddress);
  };

  static request = <Res = any>(config: AxiosRequestConfig) => {
    return axiosInstance.request<Res>(config);
  };
  static get = <Res = any>(url: string, config?: AxiosRequestConfig) => {
    return axiosInstance.get<Res>(url, config);
  };
  static delete = <Res = any>(url: string, config?: AxiosRequestConfig) => {
    return axiosInstance.delete<Res>(url, config);
  };
  static head = (url: string, config?: AxiosRequestConfig) => {
    return axiosInstance.head(url, config);
  };
  static post = <Res = any, ReqBody = any>(
    url: string,
    data?: ReqBody,
    config?: AxiosRequestConfig
  ) => {
    return axiosInstance.post<Res>(url, data, config);
  };
  static put = <Res = any, ReqBody = any>(
    url: string,
    data?: ReqBody,
    config?: AxiosRequestConfig
  ) => {
    return axiosInstance.put<Res>(url, data, config);
  };
  static patch = <Res = any, ReqBody = any>(
    url: string,
    data?: ReqBody,
    config?: AxiosRequestConfig
  ) => {
    return axiosInstance.patch<Res>(url, data, config);
  };

  static instance = () => {
    return axiosInstance;
  };
}

export const getResponseData = ({ data }: AxiosResponse) =>
  data?.hasOwnProperty('data') ? data.data : data;
