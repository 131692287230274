import { css } from '@emotion/core';
import { theme } from '@weave/theme';

export const iconMenuContainer = css`
  position: relative;
`;

export const iconMenuStyles = css`
  z-index: 100;
  border-radius: ${theme.borderRadius.medium};
  background-color: ${theme.colors.neutral80};
  width: 200px;
  right: 0;
  margin-top: ${theme.spacing(1)};
  > li {
    color: ${theme.colors.white};
    padding: ${theme.spacing(1,6, 1, 2)}
  }
  >li:hover {
    background-color: ${theme.colors.neutral70};
  }
  > li:first-child:hover {
    background-color: ${theme.colors.neutral70};
    border-top-left-radius: ${theme.borderRadius.medium};
    border-top-right-radius: ${theme.borderRadius.medium};
  }
  > li:first-child:focus {
    background-color: ${theme.colors.neutral60};
    border-top-left-radius: ${theme.borderRadius.medium};
    border-top-right-radius: ${theme.borderRadius.medium};
  }
  > li:last-child:hover {
    background-color: ${theme.colors.neutral70};
    border-bottom-left-radius: ${theme.borderRadius.medium};
    border-bottom-right-radius: ${theme.borderRadius.medium};
  }
  > li:last-child:focus {
    background-color: ${theme.colors.neutral60};
    border-bottom-left-radius: ${theme.borderRadius.medium};
    border-bottom-right-radius: ${theme.borderRadius.medium};
  }
`;