import { css } from '@emotion/core';
import { theme } from '@weave/theme';

export const containerStyle = css`
  display: flex;
  align-items: center;
  padding: ${theme.spacing(0, 3)};
`;

export const withContentStyle = css`
  margin-bottom: ${theme.spacing(2)};
`;

export const childContainerStyle = css`
  margin-left: ${theme.spacing(2)};
`;
