import { FC } from 'react';
import {
  boxSkeletonStyle,
  buttonSkeletonStyle,
  iconSkeletonStyle,
  inputSkeletonStyle,
  skeletonStyles,
  textSkeletonStyle,
} from './skeleton-loader.style';
import { SkeletonType } from './skeleton-loader.type';

const ButtonSkeleton: FC<SkeletonType.ButtonSkeletonProps> = ({ width }) => {
  return <div css={buttonSkeletonStyle(width)}></div>;
};

const TextSkeleton: FC<SkeletonType.TextSkeletonProps> = ({
  width,
  type = 'small',
  className,
}) => {
  return (
    <div css={textSkeletonStyle(width, type)} {...(className && { className })}></div>
  );
};

const IconSkeleton: FC<SkeletonType.IconSkeletonProps> = ({ size }) => {
  return <div css={iconSkeletonStyle(size)}></div>;
};

const InputSkeleton: FC<SkeletonType.InputSkeletonProps> = ({ width, hasIcon }) => {
  return (
    <div css={inputSkeletonStyle(width)}>
      {hasIcon && <IconSkeleton size="small" />}
      <TextSkeleton width="40%" type="medium" />
    </div>
  );
};

const BoxSkeleton: FC<SkeletonType.BoxSkeletonProps> = ({ height, width, children }) => {
  return <div css={boxSkeletonStyle(height, width)}>{children}</div>;
};

export const SkeletonLoader = () => {
  return (
    <div css={skeletonStyles}>
      <div className="skeleton-fields-area">
        <div className="skeleton-tabs">
          <ButtonSkeleton width="150px" />
          <ButtonSkeleton width="150px" />
        </div>
        <InputSkeleton width="100%" hasIcon={true} />
        <div className="skeleton-fields-category">
          <TextSkeleton width="50%" type="medium" />
          {Array.from({ length: 3 }, (_) => (
            <div className="skeleton-field">
              <IconSkeleton size="medium" />
              <div className="skeleton-field-details">
                <TextSkeleton width="50%" type="medium" />
                <TextSkeleton width="100%" />
                <TextSkeleton width="100%" />
                <TextSkeleton width="30%" />
              </div>
            </div>
          ))}
        </div>
        {Array.from({ length: 3 }, () => (
          <div className="skeleton-fields-category">
            <TextSkeleton width="50%" type="medium" />
            {Array.from({ length: 4 }, () => (
              <div className="skeleton-field">
                <IconSkeleton size="medium" />
                <div className="skeleton-field-details">
                  <TextSkeleton width="50%" type="medium" />
                </div>
              </div>
            ))}
          </div>
        ))}
      </div>
      <div className="skeleton-form-area">
        <div className="form-area-heading">
          <TextSkeleton width="50%" type="large" />
          <div className="form-heading-buttons">
            <ButtonSkeleton width="100px" />
            <ButtonSkeleton width="100px" />
            <ButtonSkeleton width="100px" />
          </div>
        </div>
        <TextSkeleton width="15%" type="medium" className="skeleton-section-name" />
        <BoxSkeleton height="auto" width="100%">
          <div className="skeleton-section">
            <div className="skeleton-section-field">
              <TextSkeleton width="20%" type="medium" />
              <TextSkeleton width="80%" type="medium" className="skeleton-section-text" />
              <TextSkeleton width="80%" type="medium" className="skeleton-section-text" />
              <TextSkeleton width="80%" type="medium" className="skeleton-section-text" />
              <TextSkeleton width="80%" type="medium" className="skeleton-section-text" />
            </div>
            <div className="skeleton-section-field">
              <TextSkeleton width="20%" type="medium" />
              <TextSkeleton width="80%" type="medium" className="skeleton-section-text" />
              <TextSkeleton width="80%" type="medium" className="skeleton-section-text" />
              <TextSkeleton width="80%" type="medium" className="skeleton-section-text" />
              <TextSkeleton width="80%" type="medium" className="skeleton-section-text" />
            </div>
          </div>
        </BoxSkeleton>
        <TextSkeleton width="15%" type="medium" className="skeleton-section-name" />
        <BoxSkeleton height="auto" width="100%">
          <div className="skeleton-section">
            <div className="skeleton-section-field">
              <TextSkeleton width="20%" type="medium" />
              <TextSkeleton width="80%" type="medium" className="skeleton-section-text" />
              <TextSkeleton width="80%" type="medium" className="skeleton-section-text" />
              <TextSkeleton width="80%" type="medium" className="skeleton-section-text" />
              <TextSkeleton width="80%" type="medium" className="skeleton-section-text" />
            </div>
          </div>
        </BoxSkeleton>
        <div className="skeleton-section-buttons">
          <ButtonSkeleton width="49%" />
          <ButtonSkeleton width="49%" />
        </div>
      </div>
    </div>
  );
};
