import { css } from '@emotion/core';
import {
  ConfirmationModal,
  KeyNames,
  PrimaryButton,
  ReorderIcon,
  Text,
  useModalControl,
  XIcon,
} from '@weave/design-system';
import { theme } from '@weave/theme';
import { useFormComponentsContext } from 'context';
import { FC, FocusEvent, KeyboardEvent, useCallback, useRef, useState } from 'react';
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd';
import { BuilderContext, IFormBuilder as FB } from 'shared/types';

interface BuilderInlineListEditorProps {
  field: FB.BaseOptionsField;
}

const primaryFieldNoteStyle = css`
  display: flex;
  align-items: center;
`;

const inputStyle = css`
  border: none;
  height: 40px;
  padding: ${theme.spacing(0, 1)};
  outline: none;

  :focus {
    border-bottom: 2px solid ${theme.colors.primary50};
  }

  :hover:not(:focus) {
    border-bottom: 1px solid ${theme.colors.neutral40};
  }
`;

const listStyle = css`
  list-style: none;
  max-height: 400px;
  overflow-y: auto;
  padding: ${theme.spacing(1)} 0 0 0;
`;

const listItemStyle = (isDragging: boolean) => css`
  display: flex;
  align-items: center;
  margin-bottom: ${theme.spacing(2)};
  justify-content: space-between;
  height: 40px;

  ${isDragging && `box-shadow: ${theme.shadows.floating}`};

  .delete-item-icon {
    visibility: hidden;
    margin-left: auto;
    cursor: pointer;
  }

  .draggable-icon {
    visibility: hidden;
    cursor: grab;
  }

  .edit-item-input {
    flex: 1;
  }

  :hover {
    .delete-item-icon,
    .draggable-icon {
      visibility: visible;
    }
  }
`;

const addOptionInputStyle = (textLength: number) => css`
  width: 400px;
  margin-left: ${theme.spacing(2)};
  ${textLength > 0 && `border-bottom: 1px solid ${theme.colors.neutral40};`}
`;

const addOptionButtonStyle = css`
  padding: ${theme.spacing(1)};
  margin-left: ${theme.spacing(1)};
`;

export const BuilderInlineListEditor: FC<BuilderInlineListEditorProps> = ({ field }) => {
  const [newOption, setNewOptionValue] = useState('');
  const { modalProps, openModal } = useModalControl();
  const optionToBeDeleted = useRef<number | null>(null);

  const { updateFormState } = useFormComponentsContext();

  const addNewOption = () => {
    const newOptions = [...field.options, { label: newOption, value: newOption }];
    updateFormState({
      type: BuilderContext.FormUpdateType.UPDATE_FIELD,
      data: {
        ...field,
        options: newOptions,
      },
    });
    setNewOptionValue('');
    scrollToNewlyAddedOption(newOptions.length - 1);
  };

  const onKeyDetect = (e: KeyboardEvent<HTMLInputElement>): void => {
    if (e.key === KeyNames.Enter && newOption.trim() !== '') {
      addNewOption();
    }
  };

  const changeInputFocus = (fieldId: string, index: number) => {
    setTimeout(() => {
      const nextfield = document.querySelector(
        `input[name='${fieldId}-${index}']`
      ) as HTMLInputElement;

      // If found, focus the next field
      if (nextfield !== null) {
        nextfield.focus();
      }
    }, 0);
  };

  const scrollToNewlyAddedOption = (index: number) => {
    // scroll only after newly added element is available in DOM
    setTimeout(() => {
      const listView = document.getElementById(field.id);
      const target = document.getElementById(`${field.id}-${index}`);
      if (listView && target) {
        listView.scrollTo({
          top: target.offsetTop,
          behavior: 'smooth',
        });
      }
    }, 0);
  };

  const deleteOption = (index: number) => {
    const options = [...field.options];
    options.splice(index, 1);
    updateFormState({
      type: BuilderContext.FormUpdateType.UPDATE_FIELD,
      data: {
        ...field,
        options: options,
      },
    });
  };

  const showDeleteConfirmationModal = (index: number) => {
    optionToBeDeleted.current = index;
    openModal();
  };

  const onListItemInputKeyDown = useCallback(
    (e: KeyboardEvent<HTMLInputElement>) => {
      const options = [...field.options];
      const { value } = e.target as HTMLInputElement;
      const index = Number((e.target as HTMLInputElement).dataset.index);

      if (e.key === KeyNames.Enter && value.trim() !== '') {
        options.splice(index + 1, 0, { label: '', value: '' });

        updateFormState({
          type: BuilderContext.FormUpdateType.UPDATE_FIELD,
          data: {
            ...field,
            options: options,
          },
        });

        // focus on newly added item after it's added to the DOM
        changeInputFocus(field.id, index + 1);
      } else if (e.key === KeyNames.Backspace && value === '') {
        // delete option and move focus to previous option
        options.splice(index, 1);
        updateFormState({
          type: BuilderContext.FormUpdateType.UPDATE_FIELD,
          data: {
            ...field,
            options: options,
          },
        });

        if (options.length > 0) {
          if (index === 0) {
            changeInputFocus(field.id, 0);
          } else {
            changeInputFocus(field.id, index - 1);
          }
        }
      }
    },
    [field]
  );

  const onListItemInputFocusOut = useCallback(
    (e: FocusEvent<HTMLInputElement>) => {
      const { value } = e.target;
      const index = Number(e.target.dataset.index);
      const options = [...field.options];

      if (value.trim() === '') {
        options.splice(index, 1);
      } else {
        options[index] = {
          label: value,
          value,
        };
      }

      updateFormState({
        type: BuilderContext.FormUpdateType.UPDATE_FIELD,
        data: {
          ...field,
          options: options,
        },
      });
    },
    [field]
  );

  const clearTextSelection = () => {
    // clears selection on tab press and puts cursot at the end of the text
    window.getSelection()?.collapseToEnd();
  };

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) return;

    const { index: destinationIndex } = result.destination;
    const { index: sourceIndex } = result.source;

    const options = [...field.options];

    [options[destinationIndex], options[sourceIndex]] = [
      options[sourceIndex],
      options[destinationIndex],
    ];

    updateFormState({
      type: BuilderContext.FormUpdateType.UPDATE_FIELD,
      data: {
        ...field,
        options: options,
      },
    });
  };

  if (field.meta.optionSet || typeof field.options === 'string') {
    return (
      <div css={primaryFieldNoteStyle}>
        <Text> Preview this form to see the answer options</Text>
      </div>
    );
  }

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId={field.id}>
        {(dropProvided) => (
          <ul
            css={listStyle}
            {...dropProvided.droppableProps}
            ref={dropProvided.innerRef}
            id={field.id}
          >
            {field.options.map((item, index) => (
              <Draggable
                draggableId={item.value}
                index={index}
                key={`${field.id}-${item.value}-${index}`}
              >
                {(dragProvided, dragSnapshot) => (
                  <li
                    css={listItemStyle(dragSnapshot.isDragging)}
                    {...dragProvided.draggableProps}
                    ref={dragProvided.innerRef}
                    id={`${field.id}-${index}`}
                  >
                    <span {...dragProvided.dragHandleProps}>
                      <ReorderIcon className="draggable-icon" size={18} color="light" />
                    </span>
                    <input
                      name={`${field.id}-${index}`}
                      data-index={index}
                      css={inputStyle}
                      className="edit-item-input"
                      defaultValue={item.label}
                      onBlur={onListItemInputFocusOut}
                      onKeyDown={onListItemInputKeyDown}
                      onFocus={clearTextSelection}
                    />
                    <XIcon
                      className="delete-item-icon"
                      size={18}
                      color={'light'}
                      onClick={() => showDeleteConfirmationModal(index)}
                    />
                  </li>
                )}
              </Draggable>
            ))}
          </ul>
        )}
      </Droppable>
      <input
        css={[inputStyle, addOptionInputStyle(newOption.trim().length)]}
        placeholder="Add option"
        value={newOption}
        onChange={(e) => setNewOptionValue(e.target.value)}
        onKeyDown={onKeyDetect}
      />
      {newOption.trim() !== '' && (
        <PrimaryButton css={addOptionButtonStyle} onClick={addNewOption} size="tiny">
          Add
        </PrimaryButton>
      )}
      <ConfirmationModal
        {...modalProps}
        title="Delete Option"
        message="Are you sure you want to delete this option? This can't be undone."
        onConfirm={() => {
          deleteOption(optionToBeDeleted.current as number);
          optionToBeDeleted.current = null;
        }}
        onCancel={() => {
          optionToBeDeleted.current = null;
        }}
        destructive={true}
      />
    </DragDropContext>
  );
};
