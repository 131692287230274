import { LocationValidationActions } from './locations-validation.actions';

export interface ReducerState {
  invalidLocations: string[];
  isValidating: boolean;
}

export function reducer(
  state: ReducerState,
  action: LocationValidationActions
): ReducerState {
  switch (action.type) {
    case 'start-validation':
      return {
        ...state,
        isValidating: true,
      };

    case 'finish-validation':
      return {
        ...state,
        isValidating: false,
      };

    case 'set-invalid-locations':
      return {
        ...state,
        invalidLocations: [...action.payload],
      };

    case 'reset-invalid-locations':
      return {
        ...state,
        invalidLocations: [],
      };

    default:
      throw new Error('Invalid action!!');
  }
}
