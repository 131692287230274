import { createContext } from 'react';

interface ISideNavContext {
  showMobileSideNav: boolean;
  toggleSideNav: () => void;
}

export const SideNavContext = createContext<ISideNavContext>({
  showMobileSideNav: false,
  toggleSideNav: () => {},
});
