import { css } from '@emotion/core';
import { theme } from '@weave/theme';

export const defaultHoverFontStyle = css`
  font-size: ${theme.fontSize(14)} !important;
  font-weight: 400 !important;

  > * {
    color: ${theme.colors.white} !important;
  }
`;
