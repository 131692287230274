import { useState, Dispatch, SetStateAction } from 'react';

import { useModalControl, ModalControlResponse } from '@weave/design-system';

import WritebackDestinations from './writeback-destinations/writeback-destinations.component';
import SourceTenantSettingConfirmationModal from './source-tenant-setting-confirmation-modal/source-tenant-setting-confirmation-modal.component';

interface UseSourceTenantSettingsProps {
  defaultSourceTenantId?: string;
}

interface UseSourceTenantSettingsResults extends ModalControlResponse {
  WritebackDestinations: typeof WritebackDestinations;
  selectedSourceTenantId: string;
  sourceTenantIdBackup: string;
  setSelectedSourceTenantId: Dispatch<SetStateAction<string>>;
  setSourceTenantIdBackup: Dispatch<SetStateAction<string>>;
  SourceTenantSettingConfirmationModal: typeof SourceTenantSettingConfirmationModal;
}

export const useSourceTenantSettings = ({
  defaultSourceTenantId = '',
}: UseSourceTenantSettingsProps = {}): UseSourceTenantSettingsResults => {
  const [selectedSourceTenantId, setSelectedSourceTenantId] =
    useState(defaultSourceTenantId);
  const [sourceTenantIdBackup, setSourceTenantIdBackup] = useState(defaultSourceTenantId);

  const modalControls = useModalControl();

  return {
    ...modalControls,
    WritebackDestinations,
    selectedSourceTenantId,
    sourceTenantIdBackup,
    setSelectedSourceTenantId,
    setSourceTenantIdBackup,
    SourceTenantSettingConfirmationModal,
  };
};
