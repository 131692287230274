import { useMediaQuery } from 'react-responsive';

import {
  DESKTOP_MODE_MIN_WIDTH,
  BIG_SCREEN_MODE_MIN_WIDTH,
  TABLET_MODE_MAX_WIDTH,
  PHONE_MODE_MAX_WIDTH,
} from 'shared/constants';

export const useDesktopOrLaptopMode = () =>
  useMediaQuery({ minWidth: DESKTOP_MODE_MIN_WIDTH });

export const useBigScreenMode = () =>
  useMediaQuery({ minWidth: BIG_SCREEN_MODE_MIN_WIDTH });

export const useTabletMode = () => useMediaQuery({ maxWidth: TABLET_MODE_MAX_WIDTH });

export const usePhoneMode = () => useMediaQuery({ maxWidth: PHONE_MODE_MAX_WIDTH });
