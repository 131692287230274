import { css } from '@emotion/core';
import { theme } from '@weave/theme';

export const blackButtonHoverStyle = css`
  transition: all 0.5s ease;
  border: ${theme.spacing(0.25)} solid ${theme.colors.neutral70} !important;

  :hover {
    background-color: ${theme.colors.white} !important;
    color: ${theme.colors.neutral70}!important;
  }
`;
