import { useCallback, useEffect, useState } from 'react';
import { useQuery } from 'react-query';

import { useAlert } from '@weave/design-system';

import { useLocationValidityContext } from 'context';
import { queryKeys } from 'shared/constants';
import {
  fetchAvailableSourceTenants,
  fetchPreferredSourceTenant,
} from 'shared/helpers/axios/apis';
import { getCurrentLocationId } from 'shared/helpers/utils';
import { SyncAppTypes, WritebackCapabilitiesV3 } from 'shared/types';

type GetSourceTenantDetailsBySourceTenantIdOverload = {
  (
    sourceTenantId: string,
    shouldFlattenResponse?: false | undefined
  ): SyncAppTypes.ModifiedSourceTenant | null;
  (
    sourceTenantId: string,
    shouldFlattenResponse: true
  ): SyncAppTypes.FlattenedSourceTenant | null;
};

type GetPreferredSourceTenantDetailsOverload = {
  (shouldFlattenResponse?: false | undefined): SyncAppTypes.ModifiedSourceTenant | null;
  (shouldFlattenResponse: true): SyncAppTypes.FlattenedSourceTenant | null;
};

interface FilterSourceTenantsForWritebackPayload {
  sourceTenantsList: SyncAppTypes.ModifiedSourceTenant[];
  applicableSourceTenantIds: string[];
  areSupportedSources: boolean;
}

interface UseSourceTenantsProps {
  enabled: boolean;
  writebackCapabilities: WritebackCapabilitiesV3.WritebackSource[];
}

export interface UseSourceTenantsResults {
  supportedSourceTenants: SyncAppTypes.ModifiedSourceTenant[];
  unsupportedSourceTenants: SyncAppTypes.ModifiedSourceTenant[];
  hasMultiTenantSource: boolean;
  isLoadingSourceTenants: boolean;
  isFetchedSourceTenants: boolean;
  getSourceTenantDetails: {
    bySourceTenantId: GetSourceTenantDetailsBySourceTenantIdOverload;
    byId: (id: string) => SyncAppTypes.ModifiedSourceTenant | null;
  };
  hasMultipleSourceTenants: () => boolean;
  hasMultipleSupportedSourceTenants: () => boolean;
  hasSupportedSourceTenants: () => boolean;
  preferredSourceTenantId: string;
  isLoadingPreferredSourceTenant: boolean;
  isFetchedPreferredSourceTenant: boolean;
  getPreferredSourceTenantDetails: GetPreferredSourceTenantDetailsOverload;
  getApplicableSourceTenantsForWriteback: (
    applicableSourceTenantIds: string[]
  ) => SyncAppTypes.ModifiedSourceTenant[];
  getSourceTenantValidity: (
    sourceTenantId: string,
    ignoreRootSourceTenantId?: boolean
  ) => SyncAppTypes.SourceTenantValidity;
  getSourceId: (sourceTenantId: string) => string;
  checkForMultiTenantSource: (sources: SyncAppTypes.ModifiedSourceTenant[]) => boolean;
}

export const useSourceTenants = ({
  enabled,
  writebackCapabilities,
}: UseSourceTenantsProps): UseSourceTenantsResults => {
  const [supportedSourceTenants, setSupportedSourceTenants] = useState<
    SyncAppTypes.ModifiedSourceTenant[]
  >([]);
  const [unsupportedSourceTenants, setUnsupportedSourceTenants] = useState<
    SyncAppTypes.ModifiedSourceTenant[]
  >([]);
  const [hasMultiTenantSource, setHasMultiTenantSource] = useState(false);
  const { isValidating, isValidLocation } = useLocationValidityContext();
  const alert = useAlert();

  const {
    data: sourceTenantsResponse,
    isLoading: isLoadingSourceTenants,
    isFetched: isFetchedSourceTenants,
  } = useQuery(
    [queryKeys.sourceTenants, getCurrentLocationId()],
    fetchAvailableSourceTenants,
    {
      onError: () => {
        alert.error('Failed to fetch the list of source tenants!');
      },
      select: (data): SyncAppTypes.SourceTenantsFormattedResponse | undefined => {
        const supportedSources: SyncAppTypes.ModifiedSourceTenant[] = [];
        const unsupportedSources: SyncAppTypes.ModifiedSourceTenant[] = [];

        for (const source of data) {
          if (
            writebackCapabilities.find((capability) => capability.sourceId === source.id)
          ) {
            supportedSources.push({ ...source, isSupported: true });
          } else {
            unsupportedSources.push({ ...source, isSupported: false });
          }
        }

        return data
          ? {
              supportedSources,
              unsupportedSources,
              totalSources: data.length,
              supportedSourcesCount: supportedSources.length,
            }
          : undefined;
      },
      enabled: !isValidating && isValidLocation && enabled,
    }
  );

  const {
    data: preferredSourceTenantRes,
    isLoading: isLoadingPreferredSourceTenant,
    isFetched: isFetchedPreferredSourceTenant,
  } = useQuery(
    [queryKeys.preferredSourceTenant, getCurrentLocationId()],
    fetchPreferredSourceTenant,
    {
      onError: () => {
        alert.error('Failed to get the preferred source tenant!');
      },
      enabled: !isValidating && isValidLocation,
    }
  );

  const preferredSourceTenantId = preferredSourceTenantRes?.sourceTenantId ?? '';

  const hasMultipleSourceTenants = useCallback(() => {
    if (
      isLoadingSourceTenants ||
      !sourceTenantsResponse ||
      !sourceTenantsResponse.totalSources
    ) {
      return false;
    }

    return sourceTenantsResponse.totalSources > 1;
  }, [isLoadingSourceTenants, sourceTenantsResponse]);

  const hasMultipleSupportedSourceTenants = useCallback(() => {
    if (
      isLoadingSourceTenants ||
      !sourceTenantsResponse ||
      !sourceTenantsResponse.supportedSourcesCount
    ) {
      return false;
    }

    return sourceTenantsResponse.supportedSourcesCount > 1;
  }, [isLoadingSourceTenants, sourceTenantsResponse]);

  const checkForMultiTenantSource = useCallback(
    (sources: SyncAppTypes.ModifiedSourceTenant[]) => {
      return sources.some((source) => source.sites && source.sites.length > 0);
    },
    []
  );

  useEffect(() => {
    const _supportedSources = sourceTenantsResponse?.supportedSources ?? [];
    setSupportedSourceTenants(_supportedSources);
    setUnsupportedSourceTenants(sourceTenantsResponse?.unsupportedSources ?? []);

    const hasMultiTenantSource = checkForMultiTenantSource(_supportedSources);
    setHasMultiTenantSource(hasMultiTenantSource);
  }, [sourceTenantsResponse]);

  function hasSupportedSourceTenants() {
    if (
      isLoadingSourceTenants ||
      !sourceTenantsResponse ||
      !sourceTenantsResponse.supportedSourcesCount
    ) {
      return false;
    }

    return sourceTenantsResponse.supportedSourcesCount > 0;
  }

  /**
   * Returns the source tenant details for the given sourceTenantId.
   * If `shouldFlattenResponse` is true, the response will be flattened
   * to have the active site details merged with the sourceTenant details.
   */
  function getSourceTenantDetails(
    sourceTenantId: string,
    shouldFlattenResponse?: false | undefined
  ): SyncAppTypes.ModifiedSourceTenant | null;
  function getSourceTenantDetails(
    sourceTenantId: string,
    shouldFlattenResponse: true
  ): SyncAppTypes.FlattenedSourceTenant | null;
  function getSourceTenantDetails(sourceTenantId: string, shouldFlattenResponse = false) {
    const sourceTenant = supportedSourceTenants.find((source) => {
      // Check if the sourceTenantId is a source Id (for backward compatibility)
      if (source.id === sourceTenantId) {
        return true;
      }

      // Check if multi tenant source site has the sourceTenantId
      if (source.sites && source.sites.length > 0) {
        return source.sites.some((site) => site.sourceTenantId === sourceTenantId);
      }

      // Check if single tenant source
      return source.sourceTenantId === sourceTenantId;
    });

    if (!sourceTenant) {
      return null;
    }

    // Return the sourceTenant if it is a single tenant source, or if the response flattening is not required
    if (
      !shouldFlattenResponse ||
      !sourceTenant.sites ||
      sourceTenant.sites.length === 0
    ) {
      return sourceTenant;
    }

    const matchingSite = sourceTenant.sites.find(
      (site) => site.sourceTenantId === sourceTenantId
    );

    if (!matchingSite) {
      return null;
    }

    // Flatenned response will have the active site details merged with the sourceTenant details
    const flattenedResponse: SyncAppTypes.FlattenedSourceTenant = {
      ...sourceTenant,
      sourceTenantId: matchingSite.sourceTenantId,
      sourceName: matchingSite.name,
      isSiteId: true,
      parentSourceName: sourceTenant.sourceName,
      parentSourceTenantId: sourceTenant.sourceTenantId,
    };

    return flattenedResponse;
  }

  /**
   * Returns the preferred source tenant details.
   * If `shouldFlattenResponse` is true, the response will be flattened
   * to have the active site details merged with the sourceTenant details.
   */
  function getPreferredSourceTenantDetails(
    shouldFlattenResponse?: false | undefined
  ): SyncAppTypes.ModifiedSourceTenant | null;
  function getPreferredSourceTenantDetails(
    shouldFlattenResponse: true
  ): SyncAppTypes.FlattenedSourceTenant | null;
  function getPreferredSourceTenantDetails(shouldFlattenResponse = false) {
    if (shouldFlattenResponse) {
      return getSourceTenantDetails(preferredSourceTenantId, true);
    }

    return getSourceTenantDetails(preferredSourceTenantId);
  }

  function filterSourceTenantsForWriteback({
    sourceTenantsList,
    applicableSourceTenantIds,
    areSupportedSources,
  }: FilterSourceTenantsForWritebackPayload) {
    const filteredSourceTenants: SyncAppTypes.ModifiedSourceTenant[] = [];

    for (const sourceTenant of sourceTenantsList) {
      // Check against the source Ids for backward compatibility with older submissions.
      if (applicableSourceTenantIds.includes(sourceTenant.id)) {
        filteredSourceTenants.push({ ...sourceTenant, isSupported: areSupportedSources });
        continue;
      }

      // Not all sites might be available for writeback for a given submission.
      if (sourceTenant.sites && sourceTenant.sites.length > 0) {
        const applicableSites = sourceTenant.sites.filter((site) =>
          applicableSourceTenantIds.includes(site.sourceTenantId)
        );

        if (applicableSites.length > 0) {
          filteredSourceTenants.push({
            ...sourceTenant,
            sites: applicableSites,
            isSupported: areSupportedSources,
          });
        }
        continue;
      }

      if (
        sourceTenant.sourceTenantId &&
        applicableSourceTenantIds.includes(sourceTenant.sourceTenantId)
      ) {
        filteredSourceTenants.push({ ...sourceTenant, isSupported: areSupportedSources });
      }
    }

    return filteredSourceTenants;
  }

  /**
   * Returns the applicable source tenants for the given sourceTenantIds.
   */
  function getApplicableSourceTenantsForWriteback(
    applicableSourceTenantIds: string[]
  ): SyncAppTypes.ModifiedSourceTenant[] {
    const supportedSources = filterSourceTenantsForWriteback({
      sourceTenantsList: supportedSourceTenants,
      applicableSourceTenantIds,
      areSupportedSources: true,
    });

    const unsupportedSources = filterSourceTenantsForWriteback({
      sourceTenantsList: unsupportedSourceTenants,
      applicableSourceTenantIds,
      areSupportedSources: false,
    });

    return [...supportedSources, ...unsupportedSources];
  }

  /**
   * Returns the source tenant Id validity
   */
  function getSourceTenantValidity(
    sourceTenantId: string,
    ignoreMultiTenantRootSourceTenantId = false // This is used to ignore the root source tenant ID for multi-tenant source
  ): SyncAppTypes.SourceTenantValidity {
    const sourceTenant = supportedSourceTenants.find((source) => {
      // Check if the sourceTenantId is a source Id (for backward compatibility)
      if (source.id === sourceTenantId) {
        return true;
      }

      // Check if the sourceTenantId is the root sourceTenantId for a multi tenant source, and should be ignored
      const shouldIgnoreSourceTenantId =
        ignoreMultiTenantRootSourceTenantId && source.sites && source.sites.length > 0;

      /**
       * This is to handle the case where the sourceTenantId is the
       * root sourceTenantId for a multi tenant source, as well as the case of
       * a single tenant source.
       */
      if (source.sourceTenantId === sourceTenantId && !shouldIgnoreSourceTenantId) {
        return true;
      }

      // Check if multi tenant source site has the sourceTenantId
      if (source.sites && source.sites.length > 0) {
        return source.sites.some((site) => site.sourceTenantId === sourceTenantId);
      }

      return false;
    });

    if (!sourceTenant) {
      return {
        isValidSource: false,
        isSiteId: false,
      };
    }

    return {
      isValidSource: true,
      sourceId: sourceTenant.id,
      isSiteId: !!sourceTenant.sourceTenantId,
    };
  }

  /**
   * Returns the source Id for the given sourceTenantId.
   * If the passed in Id is itself a source Id, returns the same Id.
   * If the sourceTenantId is not valid, an empty string is returned.
   */
  function getSourceId(sourceTenantId: string): string {
    const sourceTenant = supportedSourceTenants.find((source) => {
      // Check if the sourceTenantId is a source Id (for backward compatibility)
      if (source.id === sourceTenantId) {
        return true;
      }

      // Check if multi tenant source site has the sourceTenantId
      if (source.sites && source.sites.length > 0) {
        const foundInSites = source.sites.some(
          (site) => site.sourceTenantId === sourceTenantId
        );

        if (foundInSites) {
          return true;
        }
      }

      // Check if single tenant source
      return source.sourceTenantId === sourceTenantId;
    });

    if (!sourceTenant) {
      return '';
    }

    return sourceTenant.id;
  }

  return {
    supportedSourceTenants,
    unsupportedSourceTenants,
    hasMultiTenantSource,
    isLoadingSourceTenants,
    isFetchedSourceTenants,
    getSourceTenantDetails: {
      bySourceTenantId: getSourceTenantDetails,
      byId: (id) => supportedSourceTenants.find((source) => source.id === id) ?? null,
    },
    hasMultipleSourceTenants,
    hasMultipleSupportedSourceTenants,
    hasSupportedSourceTenants,
    preferredSourceTenantId,
    isLoadingPreferredSourceTenant,
    isFetchedPreferredSourceTenant,
    getPreferredSourceTenantDetails,
    getApplicableSourceTenantsForWriteback,
    getSourceTenantValidity,
    getSourceId,
    checkForMultiTenantSource,
  };
};
