import { FC, useState, useEffect } from 'react';

import {
  Modal,
  ModalControlModalProps,
  useControlledField,
  MultiselectField,
  IconButton,
  ContentLoader,
  PrimaryButton,
  SecondaryButton,
  XIcon,
} from '@weave/design-system';

import { CustomMultiSelect } from 'shared/components';

import { useClonerLocations } from '../../useClonerLocations';
import FormClonerSummary from './form-cloner-summary/form-cloner-summary.component';
import FormClonerCaptcha from './form-cloner-captcha/form-cloner-captcha.component';
import FormClonerErrors from './form-cloner-errors/form-cloner-errors.component';

import {
  containerStyle,
  headerStyle,
  getBodyStyle,
  getInnerContainerStyle,
  buttonContainerStyle,
} from './form-cloner-modal.styles';
import { useCustomAccessUser, useUnifyCloner } from 'shared/hooks';
import { CUSTOM_USER_ACCESS } from 'shared/constants';

interface FormClonerModalProps {
  modalProps: ModalControlModalProps;
  selectedItemCount: number;
  invalidLocations: string[];
  disableAllButtons?: boolean;
  disablePrimaryButton?: boolean;
  onClose: () => void;
  onConfirmClone: (locationIds: string[]) => void;
}

const DEFAULT_SEARCH_TERM = 'a';

const FormClonerModal: FC<FormClonerModalProps> = ({
  modalProps,
  selectedItemCount,
  disablePrimaryButton,
  onClose,
  onConfirmClone,
  invalidLocations,
  disableAllButtons,
}) => {
  const [isCaptchaValid, setIsCaptchaValid] = useState(false);
  const [locationSearchTerm, setLocationSearchTerm] = useState('');
  const [loadedLocations, setLoadedLocations] = useState<Record<string, string>>({});
  const [selectedLocations, setSelectedLocations] = useState<string[]>([]);
  const [isDropdownActive, setIsDropdownActive] = useState(false);
  const [parentLocation, setParentLocation] = useState<{
    Name: string;
    LocationID: string;
  } | null>(null);

  const {
    isLoading: isLoadingUnifyLocations,
    hasAccessToMultipleLocations,
    locations: unifyLocations,
  } = useUnifyCloner(locationSearchTerm);

  const { isCustomAccessUser, isLoading: isCheckingCustomUserAccess } =
    useCustomAccessUser(CUSTOM_USER_ACCESS.formCloner);

  const { locations, isLoading } = useClonerLocations({
    searchTerm: locationSearchTerm || DEFAULT_SEARCH_TERM,
    enableFetch: isCustomAccessUser ? true : !hasAccessToMultipleLocations,
  });

  const multiSelectProps = useControlledField({
    type: 'selectlist',
    value: selectedLocations,
    onChange: (value: string[]) => {
      setSelectedLocations(value);
    },
  });

  useEffect(() => {
    if (isLoading || isLoadingUnifyLocations || isCheckingCustomUserAccess) {
      return;
    }

    if (hasAccessToMultipleLocations && !isCustomAccessUser) {
      const locations = {};
      unifyLocations.forEach((child) => {
        locations[child.locationID] = child.name;
      });

      setLoadedLocations(locations);
      return;
    }

    setLoadedLocations((currentValue) => {
      const newState = { ...currentValue };

      for (const location of locations) {
        if (!newState[location.locationID]) {
          newState[location.locationID] = location.name;
        }
      }

      return newState;
    });
  }, [
    locations,
    hasAccessToMultipleLocations,
    isLoading,
    isLoadingUnifyLocations,
    isCheckingCustomUserAccess,
  ]);

  const locationCount = selectedLocations.length;
  const heading =
    selectedItemCount > 1
      ? `Clone ${selectedItemCount} selected forms to location(s)`
      : 'Clone selected form to location(s)';

  function searchTermHandler(searchTerm: string) {
    setLocationSearchTerm(searchTerm);
  }

  function modalCloseHandler() {
    onClose();
    setSelectedLocations([]);
  }

  function captchaValidationHandler(isValid: boolean) {
    if (isValid !== isCaptchaValid) {
      setIsCaptchaValid(isValid);
    }
  }

  function shouldDisablePrimaryButton() {
    return (
      !isCaptchaValid || locationCount === 0 || disablePrimaryButton || disableAllButtons
    );
  }

  function cloneHandler() {
    onConfirmClone(selectedLocations);
  }

  function dropdownActivationHandler(isActive: boolean) {
    setIsDropdownActive(isActive);
  }

  return (
    <Modal
      {...modalProps}
      disableCloseOnEscape={true}
      disableCloseOnOverlayClick={true}
      css={containerStyle}
      minWidth={600}
    >
      <Modal.Header css={headerStyle}>
        {heading}
        <IconButton label="" onClick={modalCloseHandler} disabled={disableAllButtons}>
          <XIcon />
        </IconButton>
      </Modal.Header>

      <Modal.Body
        css={getBodyStyle({
          isDropdownActive,
        })}
      >
        <CustomMultiSelect
          {...multiSelectProps}
          label="Select Locations"
          name="select-locations"
          onSearch={searchTermHandler}
          loadedItems={loadedLocations}
          maxAllowed={10}
          searchFieldPlaceholder="Search locations..."
          isLoadingItems={isLoading}
          onActive={dropdownActivationHandler}
          invalidItems={invalidLocations}
        >
          {isCustomAccessUser ? (
            <>
              {locations.map(({ locationID, name }) => (
                <MultiselectField.Option value={locationID} key={locationID}>
                  {name}
                </MultiselectField.Option>
              ))}
            </>
          ) : (
            <>
              {parentLocation &&
                parentLocation.Name.toLowerCase().includes(
                  locationSearchTerm.toLowerCase()
                ) && (
                  <MultiselectField.Option
                    value={parentLocation.LocationID}
                    key={parentLocation.LocationID}
                  >
                    {parentLocation.Name}
                  </MultiselectField.Option>
                )}
              {unifyLocations.map(({ locationID, name }) => (
                <MultiselectField.Option value={locationID} key={locationID}>
                  {name}
                </MultiselectField.Option>
              ))}
            </>
          )}
        </CustomMultiSelect>

        <div css={getInnerContainerStyle({ isDropdownActive })}>
          <FormClonerErrors
            invalidLocations={invalidLocations}
            loadedLocations={loadedLocations}
          />

          <FormClonerCaptcha
            locationCount={locationCount}
            selectedItemCount={selectedItemCount}
            onValidation={captchaValidationHandler}
          />

          <FormClonerSummary
            itemCount={selectedItemCount}
            locationCount={locationCount}
          />
        </div>

        <ContentLoader show={disableAllButtons} />
      </Modal.Body>

      <div css={buttonContainerStyle}>
        <PrimaryButton
          disabled={shouldDisablePrimaryButton()}
          onClick={cloneHandler}
          size="tiny"
        >
          Clone
        </PrimaryButton>

        <SecondaryButton
          onClick={modalCloseHandler}
          disabled={disableAllButtons}
          size="tiny"
        >
          Cancel
        </SecondaryButton>
      </div>
    </Modal>
  );
};

export default FormClonerModal;
