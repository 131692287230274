import { css } from '@emotion/core';
import { theme } from '@weave/theme';

export const NewStatusBadge = ({ ...rest }) => {
  return (
    <div css={newBadgeStyles} {...rest}>
      New
    </div>
  );
};

const newBadgeStyles = css`
  padding: ${theme.spacing(0.25)};
  border-radius: 2px;
  background-color: ${theme.colors.primary40};
  text-align: center;
  color: white;
  border: 1px solid ${theme.colors.primary50};
  text-transform: uppercase;
  font-size: ${theme.fontSize(8)};
  font-weight: 600;
  padding: 2px 3.5px 1.75px;
  height: 14px;
`;
