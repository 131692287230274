import { useEffect, useState } from 'react';
import { join } from 'lodash';

import { getCurrentLocationId, getCurrentLocationName } from 'shared/helpers/utils';
import { getWeaveToken } from 'shared/helpers/utils/token';
import { REACT_APP_NOTIFICATION_SOCKET_URL } from 'shared/constants';

export function useWebSocketURL() {
  const locationId = getCurrentLocationId();
  const location = getCurrentLocationName();
  const token = getWeaveToken();
  const [url, setUrl] = useState('');

  useEffect(() => {
    if (location && token) {
      const baseUrl = REACT_APP_NOTIFICATION_SOCKET_URL;

      let params = join(
        [
          `practice=api`,
          `location=${encodeURI(location)}`,
          `location_id=${encodeURI(locationId)}`,
          `token=${encodeURI(token)}`,
        ],
        '&'
      );

      setUrl(`${baseUrl}?${params}`);
    } else {
      setUrl('');
    }
  }, [location, token]);

  return url;
}
