import { useState, useEffect } from 'react';
import { useQuery } from 'react-query';

import {
  useControlledField,
  ModalControlResponse,
  useModalControl,
  BaseFieldProps,
} from '@weave/design-system';

import { useLocationValidityContext } from 'context';
import { useUpdateFormUploadStatus } from 'shared/hooks';
import { getCurrentLocationId } from 'shared/helpers/utils';
import { queryKeys } from 'shared/constants';
import { CustomAxios } from 'shared/helpers/axios';

export interface UseFormUploadResults extends ModalControlResponse {
  toggleFormsUploadSwitchProps: BaseFieldProps<boolean, ''>;
  canUploadForms: boolean;
  isLoading: boolean;
}

export const useFormsUpload = (): UseFormUploadResults => {
  const { isValidating, isValidLocation } = useLocationValidityContext();

  const [canUploadForms, setCanUploadForms] = useState(false);
  const { updateFormUploadStatus } = useUpdateFormUploadStatus();
  const modalControls = useModalControl();

  const toggleFormsUploadSwitchProps = useControlledField({
    type: 'switch',
    value: canUploadForms,
    onChange: (value: boolean) => {
      if (value) {
        modalControls.openModal();
        return;
      }

      setCanUploadForms(value);
      updateFormUploadStatus(!value);
    },
  });

  const { data, isLoading } = useQuery(
    [queryKeys.uploadForm, getCurrentLocationId()],
    fetchFormUploadState,
    {
      enabled: !isValidating && isValidLocation,
    }
  );

  useEffect(() => {
    if (!isLoading && data) {
      const flagSubmitted = !!data?.flagSubmitted;
      setCanUploadForms(!flagSubmitted);
    }
  }, [data, isLoading]);

  return {
    ...modalControls,
    toggleFormsUploadSwitchProps,
    canUploadForms,
    isLoading,
  };
};

interface GetFormUploadStateResponse {
  flagSubmitted?: boolean;
  message: string;
}

async function fetchFormUploadState() {
  const { data } = await CustomAxios.get<GetFormUploadStateResponse>(
    `/companies/forms-submitted/${getCurrentLocationId()}`
  );

  return data;
}
