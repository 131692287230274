import { FC, useRef } from 'react';

import {
  useModalControl,
  Heading,
  Text,
  IconButton,
  XIcon,
  TableInstance,
} from '@weave/design-system';

import { useWritebackSetupContext, useWritebackCapabilitiesContext } from 'context';
import { useSourceTenantSettings } from 'shared/hooks';
import { CustomModalButtons } from 'shared/components';
import { pendoTags } from 'shared/constants';
import { SyncAppTypes } from 'shared/types';

import SkipWritebackSetupModal from '../../skip-modal/skip-modal.component';

import { headingContainerStyle } from '../../writeback-setup-modal.styles';
import {
  headerStyle,
  textStyle,
  getSyncAppStyleOverride,
} from './writeback-destination.styles';

const WritebackDestinationScreen: FC = () => {
  const shouldPreventTableRowSelectionRef = useRef(false);

  const {
    modalProps,
    openModal: openSkipModal,
    closeModal: closeSkipModal,
  } = useModalControl();

  const {
    supportedSourceTenants,
    hasMultipleSupportedSourceTenants,
    hasMultiTenantSource,
    getSourceTenantDetails,
  } = useWritebackCapabilitiesContext();

  const {
    selectedSourceTenantId,
    onChangeSource,
    goToNextStep,
    hasCustomUserAccess,
    closeModal: onCloseModal,
  } = useWritebackSetupContext();

  const { WritebackDestinations } = useSourceTenantSettings({
    defaultSourceTenantId: selectedSourceTenantId,
  });

  const isMulti = hasMultipleSupportedSourceTenants() || hasMultiTenantSource;

  function preventTableRowSelection() {
    shouldPreventTableRowSelectionRef.current = true;
  }

  function restoreTableRowSelection() {
    setTimeout(() => {
      shouldPreventTableRowSelectionRef.current = false;
    }, 500);
  }

  function changeSourceTenantHandler(
    sourceTenantId: string,
    tableInstance: TableInstance<SyncAppTypes.ModifiedSourceTenant>
  ) {
    if (shouldPreventTableRowSelectionRef.current) {
      return;
    }

    const sourceTenant = getSourceTenantDetails.bySourceTenantId(sourceTenantId, true);

    onChangeSource(sourceTenantId);

    if (sourceTenant?.isSiteId) {
      if (tableInstance) {
        preventTableRowSelection();
        tableInstance.toggleAllRowsSelected(false);
        restoreTableRowSelection();
      }
    }
  }

  return (
    <>
      <div css={headingContainerStyle}>
        <Heading level={2} css={headerStyle}>
          Select default writeback destination
        </Heading>

        {hasCustomUserAccess && (
          <IconButton onClick={onCloseModal} label="">
            <XIcon color="default" />
          </IconButton>
        )}
      </div>

      <Text css={textStyle}>
        Since your office has access to two or more compatible systems of record, select
        one of them as your default where all form submissions will be saved. You may
        override this default setting whenever you sync manually.
      </Text>

      <div css={getSyncAppStyleOverride({ isMultiPMS: isMulti })}>
        <WritebackDestinations
          sourceTenants={supportedSourceTenants}
          onSelectSourceTenant={changeSourceTenantHandler}
          preferredSourceTenantId={selectedSourceTenantId}
          warningMessage={
            <span>
              By clicking <b>Save</b>, you authorize Weave to create a new patient record
              if it doesn't exist in the selected system of record. You agree to take full
              responsibility for this action.
            </span>
          }
          hasMultiTenantSource={hasMultiTenantSource}
          pendoTrackingId={pendoTags.writebackAuthSetup.selectAnotherSource}
        />
      </div>

      <CustomModalButtons
        primaryButtonText="Save"
        onClickPrimaryButton={() => {
          goToNextStep();
        }}
        primaryButtonTrackingId={pendoTags.writebackAuthSetup.reviewSourceSelection}
        secondaryButtonText="Cancel, I'm Not Sure"
        onClickSecondaryButton={openSkipModal}
        secondaryButtonTrackingId={pendoTags.writebackAuthSetup.cancelSourceSelection}
      />

      <SkipWritebackSetupModal modalProps={modalProps} onClose={closeSkipModal} />
    </>
  );
};

export default WritebackDestinationScreen;
