import { FC } from 'react';

import { Text, Heading, IconButton, XIcon } from '@weave/design-system';

import { useWritebackSetupContext, useWritebackCapabilitiesContext } from 'context';
import { useAuth } from 'auth/auth-provider';
import { useSourceTenantSettings } from 'shared/hooks';
import { CustomModalButtons } from 'shared/components';
import { pendoTags } from 'shared/constants';

import { headingStyle, containerStyle, textStyle } from './multi-pms-notification.styles';
import { headerStyle } from '../../../writeback-setup/setup-screens/writeback-destination/writeback-destination.styles';

interface MultiPMSNotificationProps {
  onCloseModal: () => void;
}

const MultiPMSNotification: FC<MultiPMSNotificationProps> = ({ onCloseModal }) => {
  const { selectedSourceTenantId, WritebackDestinations, setSelectedSourceTenantId } =
    useSourceTenantSettings();

  const { supportedSourceTenants, hasMultiTenantSource, updatePreferredSourceTenant } =
    useWritebackCapabilitiesContext();
  const { signOut } = useAuth();

  const { hasCustomUserAccess } = useWritebackSetupContext();

  async function savePreferredSourceTenant() {
    if (!selectedSourceTenantId) {
      return;
    }

    await updatePreferredSourceTenant({ sourceTenantId: selectedSourceTenantId });
    onCloseModal();
  }

  return (
    <>
      <div css={headingStyle}>
        <Heading level={2} css={headerStyle}>
          Select default writeback destination
        </Heading>
        {hasCustomUserAccess && (
          <IconButton onClick={onCloseModal} label="">
            <XIcon color="default" />
          </IconButton>
        )}
      </div>

      <div css={containerStyle}>
        <Text css={textStyle}>
          We noticed that your system of record was changed recently. Since your office
          has access to two or more compatible systems of record, select one of them as
          your default where all form submissions will be saved. You may override this
          default setting whenever you sync manually.
        </Text>

        <WritebackDestinations
          onSelectSourceTenant={(sourceTenantId) => {
            setSelectedSourceTenantId(sourceTenantId);
          }}
          preferredSourceTenantId={selectedSourceTenantId}
          sourceTenants={supportedSourceTenants}
          hasMultiTenantSource={hasMultiTenantSource}
          warningMessage={
            <>
              By clicking <b>Save</b>, you authorize Weave to create a new patient record
              if it doesn't exist in the selected system of record. You agree to take full
              responsibility for this action.
            </>
          }
        />
      </div>

      <CustomModalButtons
        primaryButtonText="Save"
        onClickPrimaryButton={savePreferredSourceTenant}
        disablePrimaryButton={!selectedSourceTenantId}
        primaryButtonTrackingId={
          pendoTags.preferredSourceCorrection.updatePreferredMultiPMS
        }
        secondaryButtonText="Cancel, I'm not sure"
        onClickSecondaryButton={signOut}
        secondaryButtonTrackingId={pendoTags.preferredSourceCorrection.cancelNotSure}
      />
    </>
  );
};

export default MultiPMSNotification;
