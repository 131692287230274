import { css } from '@emotion/core';
import { theme } from '@weave/theme';

import {
  TABLET_MODE_MAX_WIDTH,
  PHONE_MODE_MAX_WIDTH,
  defaultSideBarWidth,
} from 'shared/constants';

export const sideNavContainerStyle = css`
  display: flex;
  background-color: ${theme.colors.white};
  position: relative;
  border-right: 1px solid ${theme.colors.neutral10};
  z-index: 0;

  @media (max-width: ${PHONE_MODE_MAX_WIDTH}px) {
    z-index: ${theme.zIndex.highest};
  }
`;

export const mainNavMenuStyle = css`
  width: ${defaultSideBarWidth}px;
  z-index: 1;
  background-color: ${theme.colors.white};
  overflow: auto;

  @media (max-width: ${TABLET_MODE_MAX_WIDTH}px) {
    width: 110px;
  }

  @media (max-width: ${PHONE_MODE_MAX_WIDTH}px) {
    position: absolute;
    height: 100%;
    left: -100vw;
    transition: 0.5s;
    width: 90vw;
    box-shadow: ${theme.shadows.floating};
  }
`;

export const showPhoneSideNavStyle = css`
  @media (max-width: ${PHONE_MODE_MAX_WIDTH}px) {
    left: 0px;
  }
`;

export const crossIconContainerStyle = css`
  padding: ${theme.spacing(0, 1)};
`;

export const crossIconStyle = css`
  display: flex;
  margin-left: auto;
`;
