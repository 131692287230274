import { css } from '@emotion/core';
import { theme } from '@weave/theme';

export const containerStyle = css`
  margin-top: ${theme.spacing(1.5)};
  margin-bottom: ${theme.spacing(2)};
`;

export const textStyle = css`
  margin: 0;
  margin-bottom: ${theme.spacing(1)};
`;
