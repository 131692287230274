import { FormDataItem } from 'shared/types';
import { getCurrentLocationId } from 'shared/helpers/utils';

import { getResponseData } from '../';
import { CustomAxios } from '../axios-helpers';

interface FormBuilderElements {
  elements: string;
  fields: string;
  primaryFields: string;
  sectionTemplates: string;
}

type FormTemplateResponse = {
  template: FormDataItem;
  logoUrl: string;
};
export const getFormTemplate = (formId: string) =>
  CustomAxios.get('', {
    params: {
      form_id: formId,
    },
  }).then<FormTemplateResponse>((res: any) => {
    if (res.data?.hasOwnProperty('data')) {
      return {
        template: JSON.parse(res.data.data)[0],
        logoUrl: res.data.logoUrl,
      };
    } else {
      return {
        template: undefined,
        logoUrl: '',
      };
    }
  });

export const getBuilderElements = () =>
  CustomAxios.get(
    `/form-elements?companyId=${getCurrentLocationId()}`
  ).then<FormBuilderElements>(getResponseData);

interface SaveFormResponse {
  formId: string;
  message: string;
  success: boolean;
}

export async function saveFormTemplate(formTemplate) {
  return CustomAxios.post('/forms', formTemplate).then<SaveFormResponse>((res) => {
    return getResponseData(res);
  });
}
