import { FC } from 'react';
import { css } from '@emotion/core';

import { theme } from '@weave/theme';

type DotProps = {
  color?: string;
  innerRef?: React.Ref<HTMLDivElement>;
  className?: string;
};

const dotStyle = css`
  width: 12px;
  height: 12px;
  border: 1.5px solid ${theme.colors.white};
  border-radius: 50%;
  right: calc(-1.5px + ${theme.spacing(1)});
`;

export const Dot: FC<DotProps> = ({
  color = theme.colors.primary40,
  innerRef,
  ...rest
}) => (
  <div
    css={[
      dotStyle,
      css`
        background: ${color};
      `,
    ]}
    ref={innerRef}
    {...rest}
  />
);
