import {
  createContext,
  Dispatch,
  FC,
  MutableRefObject,
  SetStateAction,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';

import { useAlert } from '@weave/design-system';

import { uploadMedia } from 'shared/helpers/axios/apis';
import { genUID } from 'shared/helpers/utils';
import {
  ProviderSignature,
  MediaManagerResponse,
  RawProviderSignature,
  WritebackSettingName,
  ProviderSignaturePayload,
} from 'shared/types';
import {
  useWritebackRetry,
  RetryWritebackPayload,
  useRejectSubmission,
  useApproveSubmission,
  useUserACL,
} from 'shared/hooks';
import { useAuth } from 'auth/auth-provider';

import { saveDefaultSignature } from 'pages/form-submissions/form-submissions.api';

import useWritebackConfig, {
  UseWritebackConfigResults,
} from './review-submission/writeback-config/useWritebackConfig';
import { NormalizedSubmissionDetailResponse } from 'pages/form-submissions/form-submissions.types';

export type { WritebackSettingsToggleActions } from './review-submission/writeback-config/writeback-config.reducer';

interface ReviewSubmissionProviderProps {
  submission: NormalizedSubmissionDetailResponse;
  submissionId: string;
  hasPMSIntegration: boolean;
  isWritebackCapableSubmission: boolean;
}

type ApproveAndSyncResult = 'failed' | 'success' | 'partial';

interface ReviewSubmissionProviderContext
  extends UseWritebackConfigResults,
    ReviewSubmissionProviderProps {
  previousSubmissionId: string;
  rejectionNote: string;
  acceptanceNote: string;
  isRetryingWriteback: boolean;
  isMarkingAsRejected: boolean;
  isMarkingAsApproved: boolean;
  isSignatureDefault: MutableRefObject<boolean>;
  setSignature: Dispatch<SetStateAction<ProviderSignaturePayload | null>>;
  storeSignature: (signature: RawProviderSignature, isDefault: boolean) => void;
  setRejectionNote: Dispatch<SetStateAction<string>>;
  setAcceptanceNote: Dispatch<SetStateAction<string>>;
  rejectSubmission: () => Promise<any>;
  triggerWritebacks: (retrySearch?: boolean) => Promise<any>;
  approveAndSync: (userName?: string) => Promise<ApproveAndSyncResult>;
  hasAtLeastOneWritebackSettingSelected: () => boolean;
  isSyncingSubmission: (submissionId: string) => boolean;
}

const ReviewSubmissionContext = createContext<
  ReviewSubmissionProviderContext | undefined
>(undefined);

export const ReviewSubmissionProvider: FC<ReviewSubmissionProviderProps> = ({
  children,
  ...otherProps
}) => {
  const { submissionId, hasPMSIntegration, isWritebackCapableSubmission } = otherProps;

  const { user } = useAuth();
  const alert = useAlert();
  const [rejectionNote, setRejectionNote] = useState<string>('');
  const [acceptanceNote, setAcceptanceNote] = useState<string>('');
  const [signature, setSignature] = useState<ProviderSignaturePayload | null>(null);
  const isSignatureDefault = useRef(false);
  const [previousSubmissionId, setPreviousSubmissionId] = useState('');
  const [submissionsUnderSync, setSubmissionsUnderSync] = useState<string[]>([]);

  const writebackConfigProps = useWritebackConfig();
  const { writebackSyncMode, writebackSettingsConfig, sourceTenantId } =
    writebackConfigProps;

  const { hasProviderReviewManagePermission } = useUserACL();
  const { rejectSubmission, isMarkingAsRejected } = useRejectSubmission();
  const { approveSubmission, isMarkingAsApproved } = useApproveSubmission();
  const { isRetryingWriteback, retryWriteback } = useWritebackRetry();

  useEffect(() => {
    // reset the context
    setRejectionNote('');
    setAcceptanceNote('');

    if (!isSignatureDefault.current) {
      setSignature(null);
    }

    if (previousSubmissionId !== submissionId) {
      setPreviousSubmissionId(submissionId);
    }
  }, [submissionId]);

  const uploadSignature = async (signature: File): Promise<MediaManagerResponse> => {
    return await uploadMedia(signature);
  };

  const getSignatureField = async (
    practitionerSignature: RawProviderSignature
  ): Promise<ProviderSignaturePayload> => {
    let signatureImageId;
    if (practitionerSignature && practitionerSignature.type === 'image') {
      const uploadedImage = await uploadSignature(practitionerSignature.data as File);
      if (uploadedImage.success) {
        signatureImageId = uploadedImage.fileId;
      } else {
        throw new Error();
      }
    }

    const signature: ProviderSignature = {
      data: signatureImageId || (practitionerSignature.data as string),
      timestamp: practitionerSignature.timestamp,
      type: practitionerSignature.type,
      font_type: practitionerSignature.font_type,
    };

    const fieldId = genUID();

    return {
      provider_esign: {
        [fieldId]: {
          id: fieldId,
          label: 'E-Signature (Provider)',
          meta: {
            displayName: 'Provider E-Signature',
            type: 'eSign',
          },
          required: true,
          value: JSON.stringify(signature),
        },
      },
    };
  };

  const storeSignature = async (signature: RawProviderSignature, isDefault: boolean) => {
    const signatureField = await getSignatureField(signature);
    setSignature(signatureField);
    if (isDefault) {
      try {
        await saveDefaultSignature({
          ...signatureField,
          provider_email: user?.email as string,
        });
        alert.success('Successfully saved signature as default');
      } catch (error) {
        alert.error('Failed to save default signature');
      }
    }
  };

  async function approveThisSubmission(userName: string | undefined) {
    if (!signature || !user) {
      return;
    }

    return approveSubmission({
      submissionId,
      note: acceptanceNote,
      signature,
      user: {
        ...user,
        ...(userName && { name: userName }),
      },
    });
  }

  async function rejectThisSubmission() {
    if (!user) {
      return;
    }

    return rejectSubmission({
      submissionId,
      note: rejectionNote,
      user,
    });
  }

  function isSyncingSubmission(id: string) {
    return submissionsUnderSync.includes(id);
  }

  function markSubmissionAsUnderSync(id: string) {
    if (!isSyncingSubmission(id)) {
      setSubmissionsUnderSync((currentValue) => [...currentValue, id]);
    }
  }

  function unmarkSubmissionAsUnderSync(id: string) {
    setSubmissionsUnderSync((currentValue) => {
      const copyOfCurrentValue = [...currentValue];
      const index = copyOfCurrentValue.indexOf(id);

      if (index === -1) {
        return copyOfCurrentValue;
      }

      copyOfCurrentValue.splice(index, 1);
      return copyOfCurrentValue;
    });
  }

  async function triggerWritebacks(retrySearch = false) {
    const settingIdsToRetry: string[] = [];
    const submissionIdToSync = submissionId;

    for (const key in writebackSettingsConfig) {
      const wbSetting = writebackSettingsConfig[key as WritebackSettingName];
      if (wbSetting.value) {
        settingIdsToRetry.push(wbSetting.settingId);
      }
    }

    const payload: RetryWritebackPayload = {
      settingIds: settingIdsToRetry,
      submissionId: submissionIdToSync,
      mode: writebackSyncMode,
      sourceTenantId,
    };

    if (retrySearch) {
      delete payload.sourceTenantId;
    }

    markSubmissionAsUnderSync(submissionIdToSync);
    const response = await retryWriteback(payload);
    unmarkSubmissionAsUnderSync(submissionIdToSync);

    return response;
  }

  function shouldAutoWriteback() {
    if (
      !hasPMSIntegration ||
      !hasAtLeastOneWritebackSettingSelected() ||
      !isWritebackCapableSubmission
    ) {
      return false;
    }

    const createPersonConfig = writebackSettingsConfig['Create Person'];
    const updatePersonConfig = writebackSettingsConfig['Update Person'];
    const uploadDocumentConfig = writebackSettingsConfig['Upload Document'];

    const isInManualMode =
      createPersonConfig.manualMode ||
      updatePersonConfig.manualMode ||
      uploadDocumentConfig.manualMode;

    const isCreatePersonInAutoMode =
      (createPersonConfig.statusCode === 'Pending' && createPersonConfig.autoMode) ||
      createPersonConfig.statusCode === 'Not Applicable';

    const isUpdatePersonInAutoMode =
      (updatePersonConfig.statusCode === 'Pending' && updatePersonConfig.autoMode) ||
      updatePersonConfig.statusCode === 'Not Applicable';

    const isUploadDocumentInAutoMode =
      (uploadDocumentConfig.statusCode === 'Pending' && uploadDocumentConfig.autoMode) ||
      uploadDocumentConfig.statusCode === 'Not Applicable';

    return (
      (isCreatePersonInAutoMode &&
        isUpdatePersonInAutoMode &&
        isUploadDocumentInAutoMode) ||
      (hasProviderReviewManagePermission && isInManualMode)
    );
  }

  async function approveAndSync(userName?: string): Promise<ApproveAndSyncResult> {
    const isApproved = await approveThisSubmission(userName);

    if (shouldAutoWriteback()) {
      const writebackResponse = await triggerWritebacks();
      return writebackResponse.success ? 'success' : 'partial';
    }
    return isApproved.success ? 'success' : 'failed';
  }

  function hasAtLeastOneWritebackSettingSelected() {
    return (
      writebackSettingsConfig['Create Person'].value ||
      writebackSettingsConfig['Update Person'].value ||
      writebackSettingsConfig['Upload Document'].value
    );
  }

  const contextValue: ReviewSubmissionProviderContext = {
    ...otherProps,
    ...writebackConfigProps,
    previousSubmissionId,
    rejectionNote,
    acceptanceNote,
    isRetryingWriteback,
    isMarkingAsRejected,
    isMarkingAsApproved,
    isSignatureDefault,
    setSignature,
    storeSignature,
    setRejectionNote,
    setAcceptanceNote,
    rejectSubmission: rejectThisSubmission,
    triggerWritebacks,
    approveAndSync,
    hasAtLeastOneWritebackSettingSelected,
    isSyncingSubmission,
  };

  return (
    <ReviewSubmissionContext.Provider value={contextValue}>
      {children}
    </ReviewSubmissionContext.Provider>
  );
};

export const useReviewSubmissionContext = (): ReviewSubmissionProviderContext => {
  const context = useContext(ReviewSubmissionContext);

  if (context === undefined) {
    throw new Error(
      'useReviewSubmissionContext must be used within a <ReviewSubmissionProvider />.'
    );
  }

  return context;
};
