import { FC } from 'react';

import {
  BasicFormFieldProps,
  ExtraFieldProps,
  ListboxChildren,
} from '@weave/design-system';

import { DropdownProvider } from 'shared/providers';

import MultiSelectBaseField from './multi-select-base-field/multi-select-base-field.component';

type CustomMultiSelectProps = BasicFormFieldProps<'multiselect'> &
  Pick<ExtraFieldProps, 'containerCss'> & {
    children: ListboxChildren;
    searchFieldPlaceholder?: string;
    onSearch?: (searchTerm: string) => void;
    loadedItems?: Record<string, string>;
    isLoadingItems?: boolean;
    onActive?: (isActive: boolean) => void;
    invalidItems?: string[];
  };

export const CustomMultiSelect: FC<CustomMultiSelectProps> = ({
  children,
  autoClose = false,
  onSearch,
  loadedItems,
  isLoadingItems,
  onActive,
  invalidItems,
  ...rest
}) => (
  <DropdownProvider
    id={rest.id}
    onBlur={rest.onBlur}
    onFocus={rest.onFocus}
    onChange={rest.onChange}
    onSearch={onSearch}
    value={rest.value}
    name={rest.name}
    loadedItems={loadedItems}
    isLoadingItems={isLoadingItems}
    onActive={onActive}
    invalidItems={invalidItems}
  >
    <MultiSelectBaseField {...rest} autoClose={autoClose}>
      {children}
    </MultiSelectBaseField>
  </DropdownProvider>
);
