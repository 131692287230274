import {
  FORM_SUBMISSION_ROOT,
  FORM_BUILDER,
  KIOSK_MODE,
  SETTINGS,
  TRAINING_VIDEOS,
  WEAVE_TEMPLATES,
} from 'shared/constants/paths';
import { pendoTags } from 'shared/constants';

import DashboardIconSmall from 'assets/icons/small/forms-dashboard.svg';
import FormsBuilderIconSmall from 'assets/icons/small/forms-builder.svg';
import FormsTrainingIconSmall from 'assets/icons/small/forms-training.svg';
import FormsKioskIconSmall from 'assets/icons/small/forms-kiosk.svg';
import FormsSettingsIconSmall from 'assets/icons/small/forms-settings.svg';
import FormsTemplateIconSmall from 'assets/icons/small/forms-template.svg';

import DashboardIconMedium from 'assets/icons/medium/forms-dashboard.svg';
import FormsBuilderIconMedium from 'assets/icons/medium/forms-builder.svg';
import FormsTrainingIconMedium from 'assets/icons/medium/forms-training.svg';
import FormsKioskIconMedium from 'assets/icons/medium/forms-kiosk.svg';
import FormsSettingsIconMedium from 'assets/icons/medium/forms-settings.svg';
import FormsTemplateIconMedium from 'assets/icons/medium/forms-template.svg';

import DashboardIconLarge from 'assets/icons/large/forms-dashboard.svg';
import FormsBuilderIconLarge from 'assets/icons/large/forms-builder.svg';
import FormsTrainingIconLarge from 'assets/icons/large/forms-training.svg';
import FormsKioskIconLarge from 'assets/icons/large/forms-kiosk.svg';
import FormsSettingsIconLarge from 'assets/icons/large/forms-settings.svg';
import FormsTemplateIconLarge from 'assets/icons/large/forms-template.svg';

import { SideNavLink } from '../side-nav-link.type';

const DASHBOARD_LINK: SideNavLink = {
  to: FORM_SUBMISSION_ROOT,
  smallIcon: <DashboardIconSmall />,
  mediumIcon: <DashboardIconMedium />,
  largeIcon: <DashboardIconLarge />,
  text: 'Dashboard',
  pendoTag: pendoTags.activitiesPage,
};

const SETTINGS_LINK: SideNavLink = {
  to: SETTINGS.root,
  smallIcon: <FormsSettingsIconSmall />,
  mediumIcon: <FormsSettingsIconMedium />,
  largeIcon: <FormsSettingsIconLarge />,
  text: 'Settings',
  pendoTag: pendoTags.settingsPage,
};

const WEAVE_TEMPLATE_LINK: SideNavLink = {
  to: WEAVE_TEMPLATES.root,
  smallIcon: <FormsTemplateIconSmall />,
  mediumIcon: <FormsTemplateIconMedium />,
  largeIcon: <FormsTemplateIconLarge />,
  text: 'Form Templates',
  pendoTag: pendoTags.templateGallery.navLink,
  hoverLabel: (
    <>
      Get a headstart with ready-to-use form templates. Quickly <b>make a copy</b> and
      send those forms to your patients.
    </>
  ),
};

const FORM_BUILDER_LINK: SideNavLink = {
  to: FORM_BUILDER.root,
  smallIcon: <FormsBuilderIconSmall />,
  mediumIcon: <FormsBuilderIconMedium />,
  largeIcon: <FormsBuilderIconLarge />,
  text: 'Form Builder',
  pendoTag: pendoTags.builderPage,
};

const TRAINING_VIDEO_LINK: SideNavLink = {
  to: TRAINING_VIDEOS.root,
  smallIcon: <FormsTrainingIconSmall />,
  mediumIcon: <FormsTrainingIconMedium />,
  largeIcon: <FormsTrainingIconLarge />,
  text: 'Training',
  pendoTag: pendoTags.trainingPage,
};

const KIOSK_LINK: SideNavLink = {
  to: KIOSK_MODE.root,
  smallIcon: <FormsKioskIconSmall />,
  mediumIcon: <FormsKioskIconMedium />,
  largeIcon: <FormsKioskIconLarge />,
  text: 'Kiosk Mode',
  pendoTag: pendoTags.kioskPage._,
};

interface LinkConfig {
  showRipple?: boolean;
}

function getKioskLink({ showRipple = false }: LinkConfig = {}): SideNavLink {
  return {
    ...KIOSK_LINK,
    showRipple,
  };
}

interface ISideNavLinksConfig {
  isUnifyParentLocation?: boolean;
  hasProviderReviewPermission?: boolean;
}

export function getSideNavLinks({
  isUnifyParentLocation,
  hasProviderReviewPermission,
}: ISideNavLinksConfig = {}): SideNavLink[] {
  if (hasProviderReviewPermission) {
    return [DASHBOARD_LINK, getKioskLink({ showRipple: true })];
  }

  const links: SideNavLink[] = [
    DASHBOARD_LINK,
    WEAVE_TEMPLATE_LINK,
    FORM_BUILDER_LINK,
    TRAINING_VIDEO_LINK,
  ];

  if (!isUnifyParentLocation) {
    links.push(getKioskLink({ showRipple: true }));
  }

  links.push(SETTINGS_LINK);

  return links;
}
