import { FC, useEffect } from 'react';

import { FieldLayout, useFade, MultiselectFieldProps } from '@weave/design-system';

import { useDropdownContext } from 'shared/providers';

import { DropdownIcon } from '../../custom-icons/dropdown-icon/dropdown-icon.component';
import MultiSelectInput from '../multi-select-input/multi-select-input.component';
import MultiSelectMenu from '../multi-select-menu/multi-select-menu.component';

import { containerStyle } from './multi-select-base-field.styles';

type MultiSelectBaseFieldProps = MultiselectFieldProps & {
  searchFieldPlaceholder?: string;
};

const MultiSelectBaseField: FC<MultiSelectBaseFieldProps> = ({
  children,
  maxAllowed,
  minRequired,
  placeholder = 'Select one',
  direction = 'down',
  autoClose,
  ...rest
}) => {
  const { active, setActive, onActive } = useDropdownContext();
  const menuStyle = useFade(active);

  useEffect(() => {
    if (autoClose && maxAllowed === rest.value.length) {
      // note: the onBlur function is different between useForm and useFormField.
      // useForm requires an event to be passed in, but useFormField does not.
      // this component is used by both hooks.
      rest.onBlur({
        target: { name: rest.name },
      } as React.FocusEvent<HTMLInputElement>);
      setActive(false);

      if (onActive) {
        onActive(false);
      }
    }
  }, [rest.value.length]);

  return (
    <FieldLayout
      // @ts-ignore
      field={MultiSelectInput}
      fieldComponentProps={{
        options: children,
      }}
      endAdornment={<DropdownIcon active={active} />}
      // css={active && activeDropdownStyle}
      placeholder={placeholder}
      {...rest}
      css={containerStyle}
    >
      <MultiSelectMenu
        active={active}
        direction={direction}
        style={menuStyle}
        name={rest.name}
        placeholder={rest.searchFieldPlaceholder || 'Search...'}
      >
        {children}
      </MultiSelectMenu>
    </FieldLayout>
  );
};

export default MultiSelectBaseField;
