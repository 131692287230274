import { css } from '@emotion/core';
import { theme } from '@weave/theme';

export const quickSectionsStyle = css`
  display: flex;
  justify-content: space-between;
  margin: ${theme.spacing(1, 1, 0, 1)};
`;

export const inplaceActionButtonWrapperStyle = css`
  width: 49%;
`;

export const newSectionInputWrapperStyle = css`
  display: flex;
  width: 100%;
  button {
    padding: ${theme.spacing(1)};
    margin-left: ${theme.spacing(0.5)};
  }
`;

export const newSectionInputStyle = css`
  flex: 1;
  background-color: ${theme.colors.white};
`;

export const newSectionTemplateStyle = css`
  border-color: ${theme.colors.neutral30} !important;
`;

export const sectionTemplateWrapperStyle = css`
  width: 100%;
`;

export const sectionTemplateDropdownStyle = css`
  background-color: ${theme.colors.white};
`;

export const sectionTemplateDropdownOptionStyle = css`
  .section-template-item {
    display: flex;
    align-items: center;
    gap: ${theme.spacing(1)};
  }
`;

export const syncIconStyle = (syncable: boolean) => {
  return css`
    color: ${syncable ? theme.colors.primary50 : theme.colors.neutral30};
    margin-right: ${theme.spacing(1)};
  `;
};
