import { useAlert } from '@weave/design-system';

import { BULK_SELECTION_LIMIT } from 'shared/constants';

import { areAllItemsSelected } from './selection.utils';
import { SelectedItemIds, LoadedItemIds } from './selection.types';

export interface ReducerState {
  selectedItems: SelectedItemIds;
  masterSelect: boolean;
}

interface SelectAllAction {
  type: 'select-all';
  payload: SelectedItemIds;
}

interface ToggleSelectAction {
  type: 'toggle-select';
  payload: {
    itemId: string;
    loadedItemIds: LoadedItemIds;
    alertRef: ReturnType<typeof useAlert>;
    alertMessage: string;
    selectionLimitOverride?: number;
  };
}

interface ClearSelectionAction {
  type: 'clear-all';
}

interface ResetMasterSelectAction {
  type: 'reset-master-select';
}

type FormSelectionAction =
  | SelectAllAction
  | ClearSelectionAction
  | ToggleSelectAction
  | ResetMasterSelectAction;

export function reducer(state: ReducerState, action: FormSelectionAction): ReducerState {
  switch (action.type) {
    case 'select-all':
      return {
        selectedItems: { ...action.payload },
        masterSelect: true,
      };

    case 'clear-all':
      return { selectedItems: {}, masterSelect: false };

    case 'toggle-select':
      const { itemId, loadedItemIds, alertRef, alertMessage, selectionLimitOverride } =
        action.payload;

      let newState: ReducerState = {
        ...state,
        selectedItems: { ...state.selectedItems },
      };

      if (state.selectedItems[itemId]) {
        delete newState.selectedItems[itemId];
        newState = { ...newState, masterSelect: false };
      } else {
        const selectionLimit = selectionLimitOverride ?? BULK_SELECTION_LIMIT;

        if (Object.keys(state.selectedItems).length >= selectionLimit) {
          if (alertMessage) {
            alertRef.info('You can only select 100 forms at a time!');
          }

          return newState;
        }

        const newSelectedItems = { ...newState.selectedItems, [itemId]: true };
        newState = {
          ...newState,
          selectedItems: newSelectedItems,
          masterSelect: areAllItemsSelected({
            loadedItemIds: loadedItemIds,
            selectedItems: newSelectedItems,
          }),
        };
      }

      return newState;

    case 'reset-master-select':
      return {
        ...state,
        masterSelect: false,
      };

    default:
      throw new Error('Invalid action!!');
  }
}
