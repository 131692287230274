import { useMutation, UseMutateAsyncFunction, useQueryClient } from 'react-query';

import { useAlert } from '@weave/design-system';

import { getCurrentLocationId } from 'shared/helpers/utils';
import { queryKeys } from 'shared/constants';
import { ApproveSubmissionPayload, ProviderSignaturePayload } from 'shared/types';
import { User } from 'auth/auth.types';

import { submitFormSubmissionReview } from 'pages/form-submissions/form-submissions.api';

interface UseApproveSubmissionResults {
  approveSubmission: UseMutateAsyncFunction<any, unknown, IPayload, unknown>;
  isMarkingAsApproved: boolean;
}

const ERROR_MESSAGE = 'Failed to approve the submission!';

export const useApproveSubmission = (): UseApproveSubmissionResults => {
  const alert = useAlert();
  const queryClient = useQueryClient();

  const { mutateAsync, isLoading } = useMutation(approveSubmission, {
    onSuccess: (res, payload) => {
      refetchSubmission(payload);

      if (!res.success) {
        alert.error(ERROR_MESSAGE);
        return;
      }

      alert.success('Submission approved successfully!');
    },
    onError: (_, payload) => {
      refetchSubmission(payload);
      alert.error(ERROR_MESSAGE);
    },
  });

  function refetchSubmission(payload: IPayload) {
    return Promise.all([
      queryClient.invalidateQueries(queryKeys.formSubmissions),
      queryClient.invalidateQueries([queryKeys.formSubmission, payload.submissionId]),
    ]);
  }

  return {
    approveSubmission: mutateAsync,
    isMarkingAsApproved: isLoading,
  };
};

interface IPayload {
  submissionId: string;
  note: string;
  signature: ProviderSignaturePayload;
  user: User;
}

async function approveSubmission({ submissionId, note, signature, user }: IPayload) {
  const payload: ApproveSubmissionPayload = {
    company_id: getCurrentLocationId(),
    submission_id: submissionId,
    note,
    review_status: 1,
    ...signature,
    provider_details: {
      ...user,
    },
  };

  return submitFormSubmissionReview(payload);
}
