import { useReducer, Dispatch, useState, SetStateAction } from 'react';

import { RetryWritebackMode } from 'shared/hooks';
import { WritebackStatus, WritebackStatusCode } from 'shared/types';

import {
  writebackSettingReducer,
  INITIAL_WB_SETTINGS_STATE,
  WritebackSettingsActions,
  WritebackSettingsState,
} from './writeback-config.reducer';

export * from './writeback-config.reducer';

interface IInitSettingsConfig {
  writebackStatusCode?: WritebackStatusCode;
  isArchived?: boolean;
  writebackStatus: WritebackStatus[];
  isSolicited: boolean;
  canUploadDocument: boolean;
}

export interface UseWritebackConfigResults {
  writebackSyncMode: RetryWritebackMode;
  writebackSettingsConfig: WritebackSettingsState;
  dispatchWritebackSettingsChange: Dispatch<WritebackSettingsActions>;
  updateWritebackSettingsConfig: (config: IInitSettingsConfig) => void;
  isWritebackConfigInitialised: boolean;
  setIsWritebackConfigInitialised: Dispatch<SetStateAction<boolean>>;
  sourceTenantId: string;
  isSourceTenantIdInitialised: boolean;
  changeSourceTenantId: (sourceTenantId: string) => void;
  resetWritebackSource: () => void;
}

const useWritebackConfig = (): UseWritebackConfigResults => {
  const [isWritebackConfigInitialised, setIsWritebackConfigInitialised] = useState(false);
  const [sourceTenantId, setSourceTenantId] = useState('');
  const [isSourceTenantIdInitialised, setIsSourceTenantIdInitialised] = useState(false);
  const [writebackSyncMode, setWritebackSyncMode] =
    useState<RetryWritebackMode>('Manual');
  const [writebackSettingsState, dispatchWritebackSettingsChange] = useReducer(
    writebackSettingReducer,
    INITIAL_WB_SETTINGS_STATE
  );

  function updateWritebackSettingsConfig({
    writebackStatusCode,
    isArchived,
    writebackStatus,
    isSolicited,
    canUploadDocument,
  }: IInitSettingsConfig) {
    let isCreatePersonFulfilled = false;
    let isUpdatePersonFulfilled = false;
    const initialState: WritebackSettingsState = {
      ...INITIAL_WB_SETTINGS_STATE,
    };

    setWritebackSyncMode(writebackStatusCode === 'Failed' ? 'Retry' : 'Manual');

    if (writebackStatusCode === 'Fulfilled' || isArchived) {
      dispatchWritebackSettingsChange({
        type: 'MODIFY_STATE',
        payload: INITIAL_WB_SETTINGS_STATE,
      });
      setIsWritebackConfigInitialised(true);
      return;
    }

    for (const setting of writebackStatus) {
      const shouldIgnoreSetting =
        setting.settingStatus === 'Fulfilled' ||
        setting.settingStatus === 'Not Applicable';

      switch (setting.settingName) {
        case 'Create Person':
          isCreatePersonFulfilled = setting.settingStatus === 'Fulfilled';

          initialState['Create Person'] = {
            ...initialState['Create Person'],
            settingId: setting.settingId,
            failed: setting.settingStatus === 'Failed',
            notApplicable: setting.settingStatus === 'Not Applicable',
            autoMode: setting.settingValue === 'Auto',
            manualMode: setting.settingValue === 'Manual',
            statusCode: setting.settingStatus,
          };

          if (!isSolicited && !shouldIgnoreSetting) {
            initialState['Create Person'] = {
              ...initialState['Create Person'],
              value: true,
              shouldShowSwitch: true,
            };
          }
          break;

        case 'Update Person':
          isUpdatePersonFulfilled = setting.settingStatus === 'Fulfilled';

          initialState['Update Person'] = {
            ...initialState['Update Person'],
            settingId: setting.settingId,
            failed: setting.settingStatus === 'Failed',
            notApplicable: setting.settingStatus === 'Not Applicable',
            autoMode: setting.settingValue === 'Auto',
            manualMode: setting.settingValue === 'Manual',
            statusCode: setting.settingStatus,
          };

          if (!shouldIgnoreSetting) {
            initialState['Update Person'] = {
              ...initialState['Update Person'],
              value: true,
              shouldShowSwitch: true,
            };
          }
          break;

        case 'Upload Document':
          initialState['Upload Document'] = {
            ...initialState['Upload Document'],
            settingId: setting.settingId,
            failed: setting.settingStatus === 'Failed',
            notApplicable: setting.settingStatus === 'Not Applicable',
            autoMode: setting.settingValue === 'Auto',
            manualMode: setting.settingValue === 'Manual',
            statusCode: setting.settingStatus,
          };

          if (!shouldIgnoreSetting && canUploadDocument) {
            initialState['Upload Document'] = {
              ...initialState['Upload Document'],
              value: true,
              shouldShowSwitch: true,
            };
          }
          break;
      }
    }

    if (writebackStatusCode === 'Failed') {
      if (initialState['Create Person'].value || isCreatePersonFulfilled) {
        initialState['Update Person'] = {
          ...initialState['Update Person'],
          value: false,
          shouldShowSwitch: false,
          notApplicable: true,
        };
      }
    }

    if (isUpdatePersonFulfilled) {
      initialState['Create Person'] = {
        ...initialState['Create Person'],
        value: false,
        shouldShowSwitch: false,
        notApplicable: true,
      };
    }

    dispatchWritebackSettingsChange({
      type: 'MODIFY_STATE',
      payload: initialState,
    });
    setIsWritebackConfigInitialised(true);
  }

  function changeSourceTenantId(newSourceTenantId: string) {
    setSourceTenantId(newSourceTenantId);
    setIsSourceTenantIdInitialised(true);
  }

  function resetWritebackSource() {
    setSourceTenantId('');
    setIsSourceTenantIdInitialised(false);
  }

  return {
    writebackSyncMode,
    writebackSettingsConfig: writebackSettingsState,
    dispatchWritebackSettingsChange,
    updateWritebackSettingsConfig,
    isWritebackConfigInitialised,
    setIsWritebackConfigInitialised,
    sourceTenantId,
    isSourceTenantIdInitialised,
    changeSourceTenantId,
    resetWritebackSource,
  };
};

export default useWritebackConfig;
