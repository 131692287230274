import { css } from '@emotion/core';
import { theme } from '@weave/theme';

export const subNavMenuStyle = css`
  display: flex;
  flex-flow: column;
  min-width: 250px;
  height: 100%;
  padding: ${theme.spacing(2)};
  border-right: 1px solid ${theme.colors.neutral10};

  &.nested-nav {
    padding: 0;
    min-width: unset;
    transition: height 1s ease-in-out;

    .line-divider {
      margin-right: 0;
    }

    .sub-nav-icon {
      gap: ${theme.spacing(1)};
    }

    a {
      padding-left: ${theme.spacing(2)};
    }
  }

  a {
    display: flex;
    align-items: center;
    background-color: ${theme.colors.neutral5};
    color: ${theme.colors.neutral70};
    text-decoration: none;
    border-radius: ${theme.borderRadius.small};
    padding: ${theme.spacing(1)};
    margin-bottom: ${theme.spacing(1)};

    .unread-submission-dot,
    .unread-submission-badge {
      margin-left: auto;
    }

    .unread-submission-badge {
      display: none;
    }

    .sub-nav-icon {
      display: flex;
      align-items: center;
      gap: ${theme.spacing(0.5)};
      margin-right: ${theme.spacing(1)};
      color: ${theme.colors.neutral60};
      svg {
        fill: ${theme.colors.neutral60};

        path {
          fill: ${theme.colors.neutral60};
        }
      }
    }

    .sub-nav-count {
      &--zero {
        background-color: ${theme.colors.neutral20};
        color: ${theme.colors.white};
      }
    }

    &:hover {
      background-color: ${theme.colors.neutral20};

      .sub-nav-icon {
        color: ${theme.colors.neutral90};
        svg {
          fill: ${theme.colors.neutral90};

          path {
            fill: ${theme.colors.neutral90};
          }
        }
      }

      .unread-submission-dot {
        display: none;
      }

      .unread-submission-badge {
        display: block;
      }
    }

    &.active {
      background-color: ${theme.colors.primary50};

      .unread-submission-dot {
        display: none;
      }

      .unread-submission-badge {
        display: block;
      }

      .sub-nav-text {
        color: ${theme.colors.white};
      }

      .sub-nav-icon {
        color: ${theme.colors.white};
        svg {
          path {
            fill: ${theme.colors.white};
          }
        }
      }

      .line-divider {
        path {
          stroke: ${theme.colors.primary20};
        }
      }

      .sub-nav-count {
        background-color: ${theme.colors.white};
        color: ${theme.colors.primary50};
        min-width: 25px;

        &--zero {
          background-color: ${theme.colors.primary50};
          border: 1px solid ${theme.colors.white};
        }
      }
    }
  }
`;

export const subNavLinkCountStyle = css`
  padding: ${theme.spacing(0.5, 0.5)};
  margin: 0;
  margin-left: auto;
  border-radius: ${theme.borderRadius.small};
  background-color: ${theme.colors.primary50};
  line-height: 0.75rem;
  min-width: 25px;
  text-align: center;
`;

export const subNavTitleStyle = css`
  margin-top: ${theme.spacing(0)};
  margin-bottom: ${theme.spacing(0)};
`;

export const newBadgeStyleOverride = css`
  margin-left: ${theme.spacing(1)};
`;
