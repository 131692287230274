import { css } from '@emotion/core';
import { theme } from '@weave/theme';
import { PersonImageSize } from './person-image.component';

const personStyle = css`
  border-radius: 50%;
  background-color: ${theme.colors.primary10};
  color: ${theme.colors.primary60};
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
`;

const personXSmallStyle = [
  personStyle,
  css`
    width: 32px;
    height: 32px;
    font-size: ${theme.fontSize(12)};
  `,
];

const personSmallStyle = [
  personStyle,
  css`
    width: 48px;
    height: 48px;
    font-size: ${theme.fontSize(14)};
  `,
];
const personMediumStyle = [
  personStyle,
  css`
    width: 56px;
    height: 56px;
    font-size: ${theme.fontSize(20)};
    font-weight: ${theme.font.weight.semibold};
  `,
];
const personLargeStyle = [
  personStyle,
  css`
    width: 64px;
    height: 64px;
    font-size: ${theme.fontSize(28)};
  `,
];

export const getPersonStyle = (size: PersonImageSize) => {
  switch (size) {
    case 'x-small':
      return personXSmallStyle;
    case 'small':
      return personSmallStyle;
    case 'medium':
      return personMediumStyle;
    case 'large':
      return personLargeStyle;
  }
};
