import { css } from '@emotion/core';
import { theme } from '@weave/theme';

export const formFieldsRootStyles = css`
  display: flex;
  flex-direction: column;
  width: 27%;
  max-width: 450px;
  height: 100%;
  padding: ${theme.spacing(2, 3)};
`;

export const tabStyles = css`
  height: 100%;
`;

export const tabsContentStyle = css`
  padding-top: ${theme.spacing(3)};
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const fieldItemsStyle = css`
  overflow: auto;
  flex: 1;
  padding-left: 0;
  margin-top: 0;
  list-style-type: none;
  margin-top: ${theme.spacing(2)};
`;

export const categoryNameStyle = css`
  font-size: ${theme.fontSize(18)};
  margin-bottom: ${theme.spacing(1)};

  &:first-of-type {
    margin-top: 0;
  }
`;

export const tabBarStyle = css`
  #primary-fields,
  #elements {
    &[aria-selected='true'] {
      color: ${theme.colors.primary50};
    }
    flex: 1;
  }

  ::after {
    background-color: ${theme.colors.primary50};
  }
`;

export const panelDescriptionStyle = (descPanelCollapsed: boolean) => css`
  ${!descPanelCollapsed &&
  `
    border: 1px solid ${theme.colors.neutral20};
    border-radius: ${theme.borderRadius.medium};
    padding: ${theme.spacing(2)};
  `}
  margin-top: ${theme.spacing(3)};
  margin-bottom: ${theme.spacing(1)};
  position: relative;

  .close-icon {
    position: absolute;
    top: ${theme.spacing(2)};
    right: ${theme.spacing(2)};
    cursor: pointer;
  }

  &.expanded-view {
    transition: all 0.1s linear;
  }
`;

export const panelDescriptionTextStyle = css`
  margin: 0;
  font-size: ${theme.fontSize(13)};

  &.element-panel-desc {
    display: flex;
    align-items: center;

    .alert-icon {
      margin-right: ${theme.spacing(0.5)};
    }
  }

  &.collapsed-desc {
    cursor: pointer;
  }
`;

export const panelDescSyncIconStyle = css`
  position: relative;
  top: ${theme.spacing(0.6)};
  margin-right: ${theme.spacing(0.5)};

  &.writeable-icon {
    color: ${theme.colors.primary50};
  }
`;
