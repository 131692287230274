import { css } from '@emotion/core';
import { theme } from '@weave/theme';

import { TABLET_MODE_MAX_WIDTH } from 'shared/constants';

export const flexCenterStyle = css`
  align-items: center;
  display: flex;
  flex: 1;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  .header-title {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const headerStyle = css`
  align-items: center;
  background-color: ${theme.colors.neutral70};
  display: flex;
  height: ${theme.spacing(10)};
  justify-content: space-between;
  padding: ${theme.spacing(0, 3)};
  gap: ${theme.spacing(1)};

  h2 {
    color: ${theme.colors.white};
  }

  @media only screen and (min-width: ${`${TABLET_MODE_MAX_WIDTH}px`}) {
    background-color: ${theme.colors.white};
    border-bottom: 1px solid ${theme.colors.neutral10};

    h2 {
      color: ${theme.colors.neutral90};
    }
  }

  .sync-button {
    width: auto;
  }

  .syncing {
    @keyframes spin {
      from {
        transform: rotate(0deg);
      }
      to {
        transform: rotate(360deg);
      }
    }

    svg {
      animation: spin 2s infinite linear;
    }
  }
`;

export const moreButtonStyle = css`
  color: ${theme.colors.white};

  @media only screen and (min-width: ${`${TABLET_MODE_MAX_WIDTH}px`}) {
    color: ${theme.colors.neutral70};
  }
`;

export const backLinkStyle = css`
  align-items: center;
  color: ${theme.colors.white};
  display: flex;
  text-decoration: none;

  @media only screen and (min-width: ${`${TABLET_MODE_MAX_WIDTH}px`}) {
    display: none;
  }

  > svg {
    margin-right: ${theme.spacing(1)};
  }
`;

export const flyoutMenuItemStyles = css`
  background-color: ${theme.colors.neutral10};
  box-shadow: ${theme.shadows.heavy};
  right: 1.5rem;
  top: 7.5rem;
  width: ${theme.spacing(25)};
  z-index: ${theme.zIndex.highest};
`;

export const editableTitleStyle = css`
  background-color: transparent;
  border: 1px solid transparent;
  color: ${theme.colors.white};
  font-size: ${theme.fontSize(20)};
  font-weight: 800;
  padding: ${theme.spacing(1)};
  width: 100%;

  @media only screen and (min-width: ${`${TABLET_MODE_MAX_WIDTH}px`}) {
    color: ${theme.colors.neutral90};
  }
`;

export const iconButtonContainerStyle = css`
  display: flex;
  align-items: center;
`;

export const sendButtonStyle = css`
  padding: ${theme.spacing(1, 2)};
`;

export const needsApprovalToggleStyle = css`
  margin-right: ${theme.spacing(-1)};
  position: relative;
`;

export const reviewIconStyle = (isReviewRequired: boolean) => css`
  path {
    fill: ${isReviewRequired ? theme.colors.primary50 : theme.colors.neutral90};
  }
`;

export const needReviewFlyoutStyle = css`
  position: absolute;
  padding: ${theme.spacing(1, 2)};
  top: ${theme.spacing(6)};
  left: ${theme.spacing(-8)};
  width: max-content;
`;

export const reviewConfirmationModalStyle = css`
  padding: ${theme.spacing(3, 5)};

  ul {
    li {
      line-height: 1.5;
      :not(:last-child) {
        margin-bottom: ${theme.spacing(1)};
      }
    }
  }

  h2 {
    margin: ${theme.spacing(0, 0)};
  }

  .action-btn {
    display: flex;
    justify-content: flex-end;
    gap: ${theme.spacing(1)};
    padding: ${theme.spacing(3, 0, 1, 0)};
    border-top: 1px solid ${theme.colors.neutral10};

    button {
      padding: ${theme.spacing(1, 2)};
    }
  }
`;
