import { css } from '@emotion/core';
import { theme } from '@weave/theme';

export const navCardContainerStyle = css`
  width: 100%;
  box-shadow: 0px 1px 0px #e2e7f1, 0px -1px 0px #e2e7f1;
  padding: ${theme.spacing(2)};
  position: relative;
  display: flex;
  width: 100%;
  cursor: pointer;
  align-items: center;
  .card-primary-text {
    color: ${theme.colors.neutral90};
  }
  .card-secondary-text {
    color: ${theme.colors.neutral50};
  }
  &.active {
    background-color: ${theme.colors.neutral70};
    .card-primary-text {
      color: ${theme.colors.white};
    }
    .card-secondary-text {
      color: ${theme.colors.neutral20};
    }
  }
`;

export const navCardTextStyle = css`
  align-items: center;
`;

export const navCardHeadingStyle = css`
  font-weight: ${theme.font.weight.semibold};
  margin: 0;
`;

export const navCardSecondaryInfoStyle = css`
  margin: ${theme.spacing(0.5, 0, 0, 0)};
  color: ${theme.colors.neutral50};
  display: inline-block;
`;

export const iconsWrapperStyle = css`
  margin-left: auto;
  display: flex;
  align-items: center;
  margin-top: -0.35rem;
`;

export const alertIconStyles = css`
  fill: #ff6666;
`;
