import { css } from '@emotion/core';
import { theme } from '@weave/theme';

export const globalStyle = css`
  html,
  body {
    height: 100vh;
    padding: 0;
    margin: 0;
    color: ${theme.colors.neutral90};
  }

  #root {
    height: 100%;
    overflow: hidden;

  }
`;
