import { useContext, useEffect } from 'react';

import { WebSocketContext } from 'shared/providers/websocket.provider';
import { WebSocketEventType } from 'shared/providers/websocket.types';

interface UseWebSocketResponse {
  sendMessage: (message: string | Blob | ArrayBuffer) => void;
}
interface Props {
  deps?: any[];
  onMessage: (message: MessageEvent) => void;
  type: WebSocketEventType;
}

export const useWebSocket = ({
  deps = [],
  onMessage,
  type,
}: Props): UseWebSocketResponse => {
  const { addListener, connected, removeListener, send } = useContext(WebSocketContext);

  useEffect(() => {
    if (connected) {
      addListener(type, onMessage);
      return () => {
        removeListener(type, onMessage);
      };
    }
    return;
  }, [connected, ...deps]);

  return { sendMessage: send };
};
