import { CustomAxios, getResponseData } from 'shared/helpers/axios';
import { getCurrentLocationId } from 'shared/helpers/utils';

interface GeneratePDFResponse {
  file: string;
  message: string;
}

export const generatePDF = (formId: string): Promise<GeneratePDFResponse> => {
  const locationId = getCurrentLocationId();
  return CustomAxios.get(
    `/pdf-auth?form_id=${formId}&company_id=${locationId}`
  ).then<GeneratePDFResponse>(getResponseData);
};
