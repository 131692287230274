import { useQuery } from 'react-query';

import { validateLocation } from 'shared/helpers/axios/apis';
import {
  processLocationValidationStatus,
  getCurrentLocationId,
} from 'shared/helpers/utils';
import { queryKeys } from 'shared/constants';

interface UseValidateLocationProps {
  disabled?: boolean;
}

export interface UseValidateLocationResults
  extends ReturnType<typeof processLocationValidationStatus> {
  isValidating: boolean;
  checkOnboardingStatus: (options?: any) => Promise<any>;
}

export const useValidateLocation = ({
  disabled = false,
}: UseValidateLocationProps): UseValidateLocationResults => {
  const { data, isLoading, refetch } = useQuery(
    [queryKeys.validateLocation, getCurrentLocationId()],
    () => validateLocation(),
    { enabled: !disabled }
  );

  return {
    checkOnboardingStatus: refetch,
    ...processLocationValidationStatus(data),
    isValidating: isLoading,
  };
};
