import { useModalControl, ModalControlResponse } from '@weave/design-system';

import { getCurrentLocationId } from 'shared/helpers/utils';

import SendFormInfoModal from './send-form-info-modal.component';

interface UseSendFormResults extends ModalControlResponse {
  SendFormInfoModal: typeof SendFormInfoModal;
  sendForm: (formId: string) => void;
}

export const useSendForm = (): UseSendFormResults => {
  const modalControls = useModalControl();

  function sendForm(formId: string) {
    const locationId = getCurrentLocationId();
    window.open(`weave://forms?formId=${formId}&locationId=${locationId}`, '_parent');
  }

  return {
    ...modalControls,
    SendFormInfoModal,
    sendForm,
  };
};
