import { FC, FocusEvent, KeyboardEvent, useEffect, useState } from 'react';
import { css } from '@emotion/core';
import { Draggable } from 'react-beautiful-dnd';

import {
  CheckboxField,
  ConfirmationModal,
  CopyIcon,
  DataSyncIcon,
  EditSimpleIcon,
  KeyNames,
  Modal,
  ReorderIcon,
  Text,
  TextField,
  TrashIcon,
  useAlert,
  useControlledField,
  useFormField,
  useHoverLabel,
  useModalControl,
} from '@weave/design-system';

import { useFormComponentsContext } from 'context';
import { CustomSwitchField } from 'shared/components';
import { BuilderContext, IFormBuilder as FB } from 'shared/types';
import {
  BUILDER_AREA_HIGHLIGHT_TIME,
  LocalStorageKeys,
  pendoTags,
  primaryFieldsOrder,
} from 'shared/constants';
import { genUID } from 'shared/helpers/utils';
import { defaultHoverFontStyle } from 'shared/styles';
import { handleLocalStorage } from 'auth/auth.helpers';

import { FieldRenderer } from './field-renderer.component';
import ConditionPanel from '../condition-panel/condition-panel.component';
import NewConditionButton from '../condition-panel/new-condition-button';

import {
  fieldActionsStyle,
  fieldRendererWrapper,
  fieldStyle,
  headerStyles,
  syncIconStyles,
} from './field.style';

interface FormFieldProps {
  field: FB.Field;
  index: number;
  sectionId: string;
  totalFields: number;
}

const fieldsWithoutLabel = ['richText', 'checkbox'];
const fieldWithoutRequireField = ['richText'];

export const FormField: FC<FormFieldProps> = ({
  field,
  index,
  sectionId,
  totalFields,
}) => {
  const {
    primaryFieldsIndexMapper,
    highlightedArea,
    conditionsMapper,
    targetToFieldMapper,
    setHighlightedArea,
    updateFormState,
    updatePrimaryFields,
  } = useFormComponentsContext();
  const dontAskAgainCopyConfirmation = useFormField({ type: 'checkbox', value: false });
  const [editMode, setEditMode] = useState(false);
  const {
    modalProps: duplicateFieldModalProps,
    triggerProps: duplicateFieldModalTriggerProps,
  } = useModalControl();

  const fieldNameProps = useFormField({
    type: 'text',
    value: field.label,
    required: true,
  });

  const {
    HoverLabel,
    labelProps: hoverLabelProps,
    triggerProps: hoverLabelTriggerProps,
  } = useHoverLabel({
    placement: 'left',
  });

  const { modalProps, triggerProps } = useModalControl();
  const alert = useAlert();

  useEffect(() => {
    if (highlightedArea === field.id) {
      setTimeout(() => {
        setHighlightedArea(undefined);
      }, BUILDER_AREA_HIGHLIGHT_TIME * 1000);
    }
  }, [highlightedArea]);

  const requiredFieldProps = useControlledField({
    type: 'switch',
    value: field.required,
    onChange: (value: boolean) => {
      updateFormState({
        type: BuilderContext.FormUpdateType.UPDATE_FIELD,
        data: {
          ...field,
          required: value,
        },
      });
    },
  });

  const onDeleteField = () => {
    updateFormState({
      type: BuilderContext.FormUpdateType.DELETE_FIELD,
      data: {
        fieldId: field.id,
        sectionId,
        targetIndex: index,
        targetToFieldMapper,
        conditionsMapper,
      },
    });

    if (field.meta.dataKey) {
      updatePrimaryFields({
        fieldName: field.meta.dataKey,
        type: BuilderContext.PrimaryFieldsUpdateType.ENABLE,
      });
    }
  };

  const onUpdateFieldLabel = () => {
    updateFormState({
      type: BuilderContext.FormUpdateType.UPDATE_FIELD,
      data: {
        ...field,
        label: fieldNameProps.value,
      },
    });
    setEditMode(false);
  };

  const detectEnterPress = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === KeyNames.Enter) {
      onUpdateFieldLabel();
    }
  };

  const labelInputOnBlur = (e?: FocusEvent<Element, Element>) => {
    if (e) {
      // hacky: check if actionText button is clicked
      // this isn't handled in the design system
      if (e.relatedTarget && e.relatedTarget.innerHTML === 'Update') {
        return;
      } else {
        setEditMode(false);
      }
    } else {
      setEditMode(false);
    }
  };

  const showLabel = !fieldsWithoutLabel.includes(field.meta.type);
  const showRequireField = !fieldWithoutRequireField.includes(field.meta.type);

  const getSyncStatus = (fieldName: string): boolean => {
    const field = primaryFieldsIndexMapper[fieldName];
    const fieldsOrderCopy =
      primaryFieldsOrder[field.categoryIndex].order[field.orderIndex].syncable;
    return fieldsOrderCopy ?? false;
  };

  const onTriggerDuplicateField = () => {
    const noCopyConfirmation = handleLocalStorage.get(
      LocalStorageKeys.noCopyConfirmation
    );
    if (noCopyConfirmation) {
      onCopyField();
    } else {
      duplicateFieldModalTriggerProps.onClick();
    }
  };

  const onCopyField = () => {
    if (dontAskAgainCopyConfirmation.value) {
      handleLocalStorage.create(LocalStorageKeys.noCopyConfirmation, 'true');
    }

    const newField = { ...field };
    // conditions should not be copied. If we decide to copy them in future, we need to create new conditions and assign a new condition id here
    newField.condition_ids = [];
    const newFieldId = genUID();
    newField.id = newFieldId;
    newField.label = `${newField.label} (copy)`;
    updateFormState({
      type: BuilderContext.FormUpdateType.COPY_FIELD,
      data: {
        insertIndex: index,
        field: newField,
        sectionId,
        fieldId: newFieldId,
      },
    });
    alert.success('Field cloned successfully');

    if (duplicateFieldModalProps.show) {
      duplicateFieldModalProps.onClose();
    }
  };

  return (
    <>
      <Draggable draggableId={`${field.id}__field`} index={index}>
        {(provided) => {
          return (
            <>
              <section
                css={fieldStyle}
                {...(field.id === highlightedArea && { className: 'highlighted' })}
                {...provided.draggableProps}
                ref={provided.innerRef}
              >
                <header css={headerStyles(editMode)}>
                  {totalFields > 1 && (
                    <span
                      className="reorder-icon"
                      {...provided.dragHandleProps}
                      data-trackingid={pendoTags.newFormEditor.reorderField}
                    >
                      <ReorderIcon size={18} color="light" />
                    </span>
                  )}
                  {field.meta.dataKey && (
                    <DataSyncIcon
                      css={syncIconStyles(getSyncStatus(field.meta.dataKey))}
                    />
                  )}
                  {showLabel && (
                    <>
                      {!editMode ? (
                        <div
                          className="field-name-text"
                          onClick={() => setEditMode(true)}
                        >
                          <Text
                            className="field-name"
                            as="h3"
                            weight="bold"
                            color="light"
                          >
                            {field.label}
                          </Text>
                          <EditSimpleIcon
                            className="edit-field-icon"
                            size={18}
                            color="light"
                          />
                        </div>
                      ) : (
                        <div className="field-name-edit">
                          <TextField
                            autoFocus
                            {...fieldNameProps}
                            name={`${field.id}__edit`}
                            label=""
                            actionText="Update"
                            onActionClick={onUpdateFieldLabel}
                            onBlur={labelInputOnBlur}
                            onKeyDown={detectEnterPress}
                            data-trackingid={pendoTags.newFormEditor.fieldLabelUpdate}
                          />
                        </div>
                      )}
                    </>
                  )}

                  <div className="field-item-actions">
                    {/* don't show for primary fields */}
                    {!field.meta.dataKey && (
                      <span {...hoverLabelTriggerProps}>
                        <CopyIcon
                          size={20}
                          color="light"
                          onClick={onTriggerDuplicateField}
                        ></CopyIcon>
                        <HoverLabel css={defaultHoverFontStyle} {...hoverLabelProps}>
                          Clone Form Field
                        </HoverLabel>
                      </span>
                    )}

                    <TrashIcon
                      className="delete-field"
                      color="light"
                      size={20}
                      {...triggerProps}
                    />
                  </div>
                </header>
                <div css={fieldRendererWrapper(field.meta.type)}>
                  <FieldRenderer field={field} />
                </div>
                <div css={fieldActionsStyle}>
                  {showRequireField && (
                    <CustomSwitchField
                      name="required"
                      label={'Required'}
                      {...requiredFieldProps}
                    />
                  )}
                  {field.meta.type !== 'richText' &&
                    field.meta.type !== 'cardCapture' &&
                    (field.condition_ids || []).length === 0 && (
                      <NewConditionButton fieldId={field.id} />
                    )}
                </div>
              </section>
              <ConditionPanel field={field} conditions={field.condition_ids || []} />
            </>
          );
        }}
      </Draggable>
      <ConfirmationModal
        {...modalProps}
        title="Delete Field"
        message="Are you sure you want to delete this field? This can't be undone. This will also delete any conditions associated with this field."
        onConfirm={onDeleteField}
        confirmLabel="Delete"
        destructive={true}
      />

      <Modal {...duplicateFieldModalProps}>
        <Modal.Header textAlign="left">Clone Field</Modal.Header>
        <Modal.Body>
          <Text
            css={css`
              margin-top: 0;
            `}
          >
            Are you sure you want to clone this field?
          </Text>
          <CheckboxField
            {...dontAskAgainCopyConfirmation}
            name="dontAskAgain"
            label="Don't ask me again"
          />
        </Modal.Body>
        <Modal.Actions
          onSecondaryClick={duplicateFieldModalProps.onClose}
          onPrimaryClick={onCopyField}
          primaryLabel={'Duplicate'}
          secondaryLabel={'Cancel'}
        />
      </Modal>
    </>
  );
};
