import { useLocation } from 'react-router';
import { useQuery } from 'react-query';

import { useAuth } from 'auth/auth-provider';
import { useWritebackCapabilitiesContext, useLocationValidityContext } from 'context';
import { queryKeys } from 'shared/constants';
import { fetchCategorySubmissionStats } from 'shared/helpers/axios/apis';
import { getCurrentLocationId, getPastUnixTime } from 'shared/helpers/utils';
import { SubmissionCount } from 'shared/types';
import { FORM_SUBMISSION_CATEGORIES } from 'shared/constants/paths';

import { useUserACL } from '../use-user-ACL.hook';

type DashboardLabelType = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | 13 | 14 | 15;

const labelMapping: Record<string, DashboardLabelType> = {
  [FORM_SUBMISSION_CATEGORIES.allSubmitted]: 1, // Inbox
  [FORM_SUBMISSION_CATEGORIES.recentlySubmitted]: 2, // Recently Submitted
  [FORM_SUBMISSION_CATEGORIES.needsReview]: 3, // Needs Review
  [FORM_SUBMISSION_CATEGORIES.needApproval.root]: 4, // Sync Manually, returns root, manual, and not synced
  [FORM_SUBMISSION_CATEGORIES.needApproval.manual]: 4, // Sync Manually. Will be 5 if individual count is required
  [FORM_SUBMISSION_CATEGORIES.needApproval.notSynced]: 4, // Sync Manually. Will be 6 if individual count is required
  [FORM_SUBMISSION_CATEGORIES.failedSync]: 7, // Failed Sync
  [FORM_SUBMISSION_CATEGORIES.reviewed.root]: 8, // Reviewed
  [FORM_SUBMISSION_CATEGORIES.reviewed.approved]: 9, // Approved
  [FORM_SUBMISSION_CATEGORIES.reviewed.rejected]: 10, // Rejected
  [FORM_SUBMISSION_CATEGORIES.completed.root]: 11, // Completed, returns root, with Writebacks, and without Writebacks
  [FORM_SUBMISSION_CATEGORIES.completed.withWritebacks]: 11, // Completed with Writebacks. Will be 12 if individual count is required
  [FORM_SUBMISSION_CATEGORIES.completed.withoutWritebacks]: 11, // Completed without Writebacks. Will be 13 if individual count is required
  [FORM_SUBMISSION_CATEGORIES.notSubmitted]: 14, // Sent
  [FORM_SUBMISSION_CATEGORIES.archived]: 15, // Archived
};

const DEFAULT_DATE = 946684800000; // 2000-01-01
const ALLOWED_PRACTITIONER_LABELS = [3, 8, 9, 10, 14];
const DISALLOWED_NON_INTEGRATED_LOCATION_LABELS = [4, 5, 6, 7, 11, 12, 13];

interface UseSubmissionStatsProps {
  dashboardLabel?: DashboardLabelType;
  disabled?: boolean;
}

const defaultProps: UseSubmissionStatsProps = {
  disabled: false,
  dashboardLabel: undefined,
};

export const useSubmissionStats = ({ dashboardLabel, disabled } = defaultProps) => {
  const { pathname } = useLocation();
  const submittedFrom = getPastUnixTime({ weeks: 1 });
  const { hasProviderReviewPermission } = useUserACL();
  const { user } = useAuth();
  const { isValidLocation, isValidating } = useLocationValidityContext();
  const {
    hasPMSIntegration,
    isLoadingSourceTenants,
    isFetchingWritebackSourcesCapabilities,
  } = useWritebackCapabilitiesContext();

  const locationId = getCurrentLocationId();

  let dashboard_label = dashboardLabel || labelMapping[pathname];
  let enabledForPractitioner = true;
  let enabledForNonIntegratedLocation = true;

  if (hasProviderReviewPermission) {
    // Practitioner can only access Needs Review hence dashboard_label is set to it
    if (dashboard_label === 1) {
      dashboard_label = labelMapping[FORM_SUBMISSION_CATEGORIES.needsReview];
    } else if (!ALLOWED_PRACTITIONER_LABELS.includes(dashboard_label)) {
      enabledForPractitioner = false;
    }
  }

  if (
    !hasPMSIntegration &&
    DISALLOWED_NON_INTEGRATED_LOCATION_LABELS.includes(dashboard_label)
  ) {
    enabledForNonIntegratedLocation = false;
  }

  const { data, isLoading } = useQuery(
    [locationId, queryKeys.submissionCategoryCount, dashboard_label],
    () =>
      fetchCategorySubmissionStats({
        dashboard_label,
        submitted_from: dashboard_label === 2 ? submittedFrom : DEFAULT_DATE,
        is_location_writeback_capable: hasPMSIntegration,
        show_unread_count: false,
        ...(hasProviderReviewPermission && { provider_email: user?.email as string }),
      }),
    {
      enabled:
        !isFetchingWritebackSourcesCapabilities &&
        !isLoadingSourceTenants &&
        !!dashboard_label &&
        !disabled &&
        !isValidating &&
        isValidLocation &&
        enabledForPractitioner &&
        enabledForNonIntegratedLocation,

      staleTime: 1000 * 60 * 3, // 3 minutes
    }
  );

  let subCategories: SubmissionCount[] = [];

  if (data?.result?.length ?? 0 > 1) {
    subCategories = data?.result.slice(1) ?? [];
  }

  return { count: data?.result?.[0]?.count ?? 0, subCategories, isLoading };
};
