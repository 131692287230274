import { css } from '@emotion/core';
import { theme } from '@weave/theme';

export const modalStyle = css`
  padding-top: ${theme.spacing(2)};
`;

export const headerStyle = css`
  h2 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: ${theme.spacing(0, 0.5)};
  }
`;

export const bodyStyle = css`
  padding: ${theme.spacing(0, 4)} !important;
`;

export const footerStyle = css`
  display: flex;
  flex-direction: row-reverse;
  gap: ${theme.spacing(2)};
  padding: ${theme.spacing(3, 4, 0)};
  border-top: 1px solid ${theme.colors.neutral10};
  margin-top: ${theme.spacing(2)};
`;
