import { TextareaField, useFormField } from '@weave/design-system';
import { FC } from 'react';

interface BuilderTextareaFieldProps {
  name: string;
}

export const BuilderTextareaField: FC<BuilderTextareaFieldProps> = ({ name }) => {
  const fieldProps = useFormField({
    type: 'text',
  });
  return <TextareaField name={name} label={''} {...fieldProps} />;
};
