import { getCurrentLocationId } from 'shared/helpers/utils';
import { API_URL, headerKeys } from 'shared/constants';
import { CustomAxios } from '../axios-helpers';

export interface ILocationChildren {
  Active: boolean;
  LocationID: string;
  Name: string;
  ParentID: string;
  PhoneTenantID: string | null;
  Slug: string;
  Type: number;
  Vertical: number;
  VerticalID: number;
  VerticalTextID: number;
}

interface IFetchLocationChildrenResponse {
  data: ILocationChildren[];
}

export async function fetchLocationChildren(location?: string) {
  const locationId = getCurrentLocationId();
  const { data } = await CustomAxios.get<IFetchLocationChildrenResponse>(
    `/portal/v1/location/${location || locationId}/children`,
    {
      baseURL: API_URL,
      ...(location && {
        headers: {
          [headerKeys.locationId]: location,
        },
      }),
    }
  );

  return data;
}
