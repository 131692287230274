import { css } from '@emotion/core';
import { theme } from '@weave/theme';

export const containerStyle = css`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing(2)};
`;

export const infoTextStyle = css`
  font-size: ${theme.fontSize(12)};
  color: ${theme.colors.text.subdued};
  margin: 0;
`;

export const switchContainerStyle = css`
  display: flex;
  align-items: center;
  gap: ${theme.spacing(1)};
`;

export const switchFieldLabelStyle = css`
  margin: 0;
`;

export const switchFieldStyle = css`
  margin-left: auto;
`;
