import { FC } from 'react';

import { Text } from '@weave/design-system';

import { ActiveReminder, ReminderType } from '../../auto-reminder.types';

import { titleStyle } from './auto-reminder-warning.styles';
interface AutoReminderWarningProps {
  reminderType: ReminderType;
  activeReminders: ActiveReminder[];
}

const AutoReminderWarning: FC<AutoReminderWarningProps> = ({
  activeReminders,
  reminderType,
}) => {
  function getTitle() {
    switch (reminderType) {
      case ReminderType.AppointmentReminder:
        return 'Appointment Reminders';

      case ReminderType.AppointmentFollowup:
        return 'Appointment Followup';

      case ReminderType.BirthdayReminder:
        return 'Birthday Message';

      case ReminderType.RecallReminder:
        return 'Recall Reminders';

      case ReminderType.ReviewReminder:
        return 'Review Reminders';

      case ReminderType.SaveTheDateReminder:
        return 'Save the date';

      case ReminderType.MissedCall:
        return 'Missed Call Reminders';
    }
    return 'Unspecified Reminders';
  }

  if (activeReminders.length === 0) {
    return null;
  }

  return (
    <section>
      <Text as="h3" css={titleStyle}>
        {getTitle()}:
      </Text>

      <ol>
        {activeReminders.map(({ name, id }) => (
          <li key={id}>{name}</li>
        ))}
      </ol>
    </section>
  );
};

export default AutoReminderWarning;
