import { FC } from 'react';
import { getPersonStyle } from './person-image.style';

function getInitials(firstName: string, lastName: string) {
  return `${firstName?.[0] || ''}${lastName?.[0] || ''}`;
}

export type PersonImageSize = 'x-small' | 'small' | 'medium' | 'large';

interface PersonImageProps {
  firstName: string;
  lastName: string;
  size: PersonImageSize;
}

export const PersonImage: FC<PersonImageProps> = ({ firstName, lastName, size }) => {
  const initials = getInitials(firstName, lastName);

  return <div css={getPersonStyle(size)}>{initials}</div>;
};
