import { useMutation, useQueryClient, UseMutateAsyncFunction } from 'react-query';

import { useAlert } from '@weave/design-system';

import { CustomAxios } from 'shared/helpers/axios';
import { getCurrentLocationId } from 'shared/helpers/utils';
import { queryKeys } from 'shared/constants';

interface UseUpdateFormUploadStatusResults {
  updateFormUploadStatus: UseMutateAsyncFunction<any, unknown, boolean, unknown>;
  isUpdating: boolean;
}

export const useUpdateFormUploadStatus = (): UseUpdateFormUploadStatusResults => {
  const queryClient = useQueryClient();
  const alert = useAlert();

  const { mutateAsync, isLoading } = useMutation(updateFormUploadStatus, {
    onSuccess: (_, payload) => {
      if (payload) {
        alert.success('Form uploads for digitization are now enabled!');
      } else {
        alert.success('Form uploads for digitization are now disabled!');
      }
      queryClient.invalidateQueries([queryKeys.digitization, getCurrentLocationId()]);
      return queryClient.invalidateQueries(queryKeys.uploadForm);
    },
  });

  return {
    updateFormUploadStatus: mutateAsync,
    isUpdating: isLoading,
  };
};

interface UseUpdateDigitizationFeeCheckResults {
  updateDigitizationFeeCheck: UseMutateAsyncFunction<any, unknown, boolean, unknown>;
  isUpdating: boolean;
}

export const useUpdateDigitizationFeeCheck = (): UseUpdateDigitizationFeeCheckResults => {
  const queryClient = useQueryClient();
  const alert = useAlert();

  const { mutateAsync, isLoading } = useMutation(updateDigitizationFeeCheck, {
    onSuccess: (_, payload) => {
      if (payload) {
        alert.success('Digitization fee check is now applied!');
      } else {
        alert.success('Digitization fees check is now relaxed!');
      }
      return queryClient.invalidateQueries(queryKeys.digitization);
    },
  });
  return {
    updateDigitizationFeeCheck: mutateAsync,
    isUpdating: isLoading,
  }
}

async function updateFormUploadStatus(newStatus: boolean) {
  return CustomAxios.patch('/companies/forms-submitted', {
    company_id: getCurrentLocationId(),
    upload_forms: newStatus,
  });
}

async function updateDigitizationFeeCheck(isValidateFee: boolean) {
  return CustomAxios.patch('/digitization/fees-check', {
    company_id: getCurrentLocationId(),
    relax_digitization_fees: isValidateFee,
  });
}
