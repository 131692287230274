import { useMutation, useQueryClient, UseMutateAsyncFunction } from 'react-query';

import { useAlert } from '@weave/design-system';

import {
  updatePreferredSourceTenant,
  UpdatePreferredSourceTenantPayload,
} from 'shared/helpers/axios/apis';
import { queryKeys } from 'shared/constants';

export interface UseUpdatePreferredSourceTenantResult {
  updatePreferredSourceTenant: UseMutateAsyncFunction<
    any,
    unknown,
    UpdatePreferredSourceTenantPayload,
    unknown
  >;
  isUpdatingPreferredSyncApp: boolean;
}

export const useUpdatePreferredSourceTenant =
  (): UseUpdatePreferredSourceTenantResult => {
    const alert = useAlert();
    const queryClient = useQueryClient();
    const queryKeyToInvalidate = queryKeys.preferredSourceTenant;

    const { mutateAsync, isLoading } = useMutation(updatePreferredSourceTenant, {
      onSuccess: async () => {
        await queryClient.invalidateQueries(queryKeyToInvalidate);
        alert.success('Successfully updated the preferred source tenant!');
      },
      onError: async () => {
        await queryClient.invalidateQueries(queryKeyToInvalidate);
        alert.error('Failed to update the preferred source tenant!');
      },
    });

    return {
      updatePreferredSourceTenant: mutateAsync,
      isUpdatingPreferredSyncApp: isLoading,
    };
  };
