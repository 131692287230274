import { css } from '@emotion/core';
import { theme } from '@weave/theme';
import { SkeletonType } from './skeleton-loader.type';

const commonStyles = css`
  @keyframes skeleton-loading {
    0% {
      background-color: ${theme.colors.neutral20};
    }
    100% {
      background-color: ${theme.colors.neutral10};
    }
  }

  border-radius: ${theme.borderRadius.small};
  animation: skeleton-loading 1s linear infinite alternate;
`;

export const buttonSkeletonStyle = (width: string) => css`
  ${commonStyles}
  height: 40px;
  width: ${width};
  margin: ${theme.spacing(1)};
`;

export const textSkeletonStyle = (width: string, type: SkeletonType.TextType) => css`
  ${commonStyles}
  width: ${width};
  margin: ${theme.spacing(0.5, 0)};

  ${type === 'small' && 'height: 6px;'}

  ${type === 'medium' && 'height: 10px;'}

  ${type === 'large' && 'height: 16px;'}
`;

export const iconSkeletonStyle = (size: SkeletonType.IconSize) => {
  return css`
    ${commonStyles}
    margin-right: ${theme.spacing(2)};

    ${size === 'small' &&
    `
      width: 20px;
      height: 20px;
    `}

    ${size === 'medium' &&
    `
      width: 25px;
      height: 25px;
    `}


    ${size === 'large' &&
    `
      width: 30px;
      height: 30px;
    `}
  `;
};

export const inputSkeletonStyle = (width: string) => css`
  display: flex;
  width: ${width};
  height: 40px;
  border: 1px solid ${theme.colors.neutral10};
  align-items: center;
  padding: ${theme.spacing(1)};
`;

export const boxSkeletonStyle = (height: string, width: string) => css`
  border: 2px solid ${theme.colors.neutral10};
  height: ${height};
  width: ${width};
`;

export const skeletonStyles = css`
  width: 100%;
  display: flex;

  .skeleton-fields-category {
    margin: ${theme.spacing(2, 0)};
  }

  .skeleton-field {
    display: flex;
    align-items: center;
  }

  .skeleton-field-details {
    flex: 1;
    margin: ${theme.spacing(2, 0)};
  }

  .skeleton-fields-area {
    width: 27%;
    max-width: 450px;
    height: 100%;
    padding: ${theme.spacing(2, 3)};
    border-right: 1px solid ${theme.colors.neutral20};
  }

  .skeleton-tabs {
    width: 100;
    display: flex;
    justify-content: space-between;
    margin-bottom: ${theme.spacing(2)};
  }

  .skeleton-form-area {
    flex: 1;
    height: 100%;
    padding: ${theme.spacing(3)};
  }

  .form-area-heading {
    display: flex;
    width: 100%;
    align-items: center;

    .form-heading-buttons {
      display: flex;
      margin-left: auto;
    }
  }

  .skeleton-section-name {
    margin: ${theme.spacing(4, 0, 2, 0)};
  }

  .skeleton-section {
    padding: ${theme.spacing(2)};
  }

  .skeleton-section-field {
    padding: ${theme.spacing(2)};
  }

  .skeleton-section-text {
    margin: ${theme.spacing(3, 0)};
  }

  .skeleton-section-buttons {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }
`;
