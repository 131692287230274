import { css } from '@emotion/core';

export const headingContainerStyle = css`
  display: flex;
  align-self: center;
  justify-content: space-between;
`;

export const modalBodyStyle = css`
  position: relative;
`;
