import { FC } from 'react';

import { Text } from '@weave/design-system';

import { containerStyle, textStyle, listStyle } from './form-cloner-errors.styles';

interface FormClonerErrorsProps {
  loadedLocations: Record<string, string>;
  invalidLocations: string[];
}

const FormClonerErrors: FC<FormClonerErrorsProps> = ({
  loadedLocations,
  invalidLocations,
}) => {
  if (invalidLocations.length === 0) {
    return null;
  }

  return (
    <section css={containerStyle}>
      <Text css={textStyle}>
        The following locations do not have <b>Forms</b> enabled:
      </Text>

      <ul css={listStyle}>
        {invalidLocations.map((invalidLocationId) => (
          <li key={invalidLocationId}>{loadedLocations[invalidLocationId]}</li>
        ))}
      </ul>
    </section>
  );
};

export default FormClonerErrors;
