import { css } from '@emotion/core';

export const containerStyle = css`
  display: flex;
  flex-direction: column;
  height: 100%;

  a {
    text-decoration: none !important;
  }
`;

export const contentContainerStyle = css`
  flex-grow: 1;
  overflow: auto;
`;

export const innerContainer = css`
  display: flex;
  flex-grow: 1;
  overflow: auto;
`;
