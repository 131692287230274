import { FC, Fragment } from 'react';
import { NavLink } from 'react-router-dom';

import { Text } from '@weave/design-system';

import { Dot, NewBadge } from 'shared/components';
import { NestedSubNav } from 'shared/types';

import LineDividerIcon from 'assets/icons/small/line-divider-icon.svg';

import {
  subNavMenuStyle,
  subNavLinkCountStyle,
  subNavTitleStyle,
  newBadgeStyleOverride,
} from '../sub-nav-menu/sub-nav-menu.style';
import { NewStatusBadge } from 'pages/form-submissions/components/new-status-badge/new-status-badge.component';
import { theme } from '@weave/theme';

interface NestedNavMenuProps {
  nestedLinks: NestedSubNav[];
  onChange: (index: number) => void;
  onHover: (navId: string | undefined) => void;
  onHoverOut: () => void;
}

export const NestedNavMenu: FC<NestedNavMenuProps> = ({
  nestedLinks,
  onChange,
  onHover,
  onHoverOut,
  ...otherProps
}) => {
  return (
    <div css={subNavMenuStyle} {...otherProps} className="nested-nav">
      {nestedLinks.map(
        (
          {
            to,
            title,
            count,
            pendoTag,
            hidden,
            abbreviatedCount,
            id,
            newFeatureHelper,
            icon,
            showCount,
            boldOnCount,
            showUnreadIndicator,
            unreadCount,
          },
          index
        ) => (
          <Fragment key={to}>
            {!hidden ? (
              <>
                <NavLink
                  to={to}
                  onClick={() => onChange(index)}
                  data-trackingid={pendoTag}
                  onMouseOver={() => onHover(id)}
                  onMouseOut={onHoverOut}
                >
                  {icon && (
                    <span className="sub-nav-icon">
                      <LineDividerIcon className="line-divider" />
                      {icon}
                    </span>
                  )}
                  <Text
                    size="large"
                    weight={!!(count && boldOnCount) ? 'semibold' : 'regular'}
                    textAlign="left"
                    css={subNavTitleStyle}
                    className="sub-nav-text"
                  >
                    {title}
                  </Text>

                  {!!(count && boldOnCount) && (
                    <>
                      <Dot
                        color={theme.colors.primary40}
                        className="unread-submission-dot"
                      />
                      <NewStatusBadge className="unread-submission-badge" />
                    </>
                  )}

                  {newFeatureHelper && (
                    <NewBadge placement="right" css={newBadgeStyleOverride}>
                      {newFeatureHelper}
                    </NewBadge>
                  )}

                  {count !== undefined && !!showCount && (
                    <Text
                      size="medium"
                      color="white"
                      weight={'regular'}
                      css={subNavLinkCountStyle}
                      className={count === 0 ? 'sub-nav-count--zero' : 'sub-nav-count'}
                    >
                      {abbreviatedCount || count}
                    </Text>
                  )}

                  {showUnreadIndicator &&
                    unreadCount !== 0 &&
                    unreadCount !== undefined && <Dot css={{ marginLeft: 'auto' }} />}
                </NavLink>
              </>
            ) : null}
          </Fragment>
        )
      )}
    </div>
  );
};
