import { useState } from 'react';
import { useHistory, useLocation } from 'react-router';

import {
  CaretDownIconSmall,
  useFlyoutMenu,
  useSlideFadeTransition,
  FlyoutMenuAction,
  LogOutIcon,
  Text,
  CaretUpIconSmall,
  LocationIcon,
  Heading,
  ConfirmationModal,
} from '@weave/design-system';

import {
  useTabletMode,
  useFormsDigitization,
  useUpdateFormUploadStatus,
  useUpdateDigitizationFeeCheck,
  useCustomAccessUser,
  useUserACL,
} from 'shared/hooks';
import { pendoTags, CUSTOM_USER_ACCESS } from 'shared/constants';
import { FORM_SUBMISSION_CATEGORIES } from 'shared/constants/paths';
import { CustomFlyoutMenuAction } from 'shared/types';
import WeaveLogoWithText from 'assets/weave-logo-with-text.svg';
import WeaveLogoWithoutText from 'assets/weave-logo-without-text.svg';

import { useAuth } from '../../../auth/auth-provider';
import { handleLocalStorage } from '../../../auth/auth.helpers';
import { AuthStorage, Location } from '../../../auth/auth.types';

import {
  containerStyle,
  brandContainerStyle,
  userStyle,
  nameWrap,
  userProfileStyles,
  flyoutMenuItemStyles,
  nameContainer,
  brandContainerNonLocationStyle,
  brandContainerLocationStyle,
  weaveLogoWithTextStyle,
  productName,
  productVersionStyle,
  personAndNotificationBadgeContainer,
} from './global-nav.style';

export const GlobalNav = () => {
  const isTabletMode = useTabletMode();
  const { pathname } = useLocation();
  const history = useHistory();
  const activeLocation = handleLocalStorage.get(AuthStorage.active_location) as Location;
  const { hasProviderReviewPermission } = useUserACL();

  const hasSelectedLocation = activeLocation !== null;
  const isSelectLocation = pathname === '/location';
  const brandStyles =
    isSelectLocation || hasProviderReviewPermission
      ? [brandContainerStyle, brandContainerLocationStyle]
      : [brandContainerStyle, brandContainerNonLocationStyle];

  function goToDashboard() {
    if (!hasSelectedLocation) {
      return;
    }

    history.push(FORM_SUBMISSION_CATEGORIES.allSubmitted);
  }

  function getWeaveLogo() {
    return (
      <div
        css={weaveLogoWithTextStyle}
        style={{ cursor: hasSelectedLocation ? 'pointer' : 'default' }}
        onClick={goToDashboard}
      >
        {isTabletMode ? (
          <WeaveLogoWithoutText className="logo" />
        ) : (
          <WeaveLogoWithText className="logo" />
        )}
      </div>
    );
  }

  return (
    <nav css={containerStyle}>
      <div css={brandStyles}>{getWeaveLogo()}</div>
      <User />
    </nav>
  );
};

const User = () => {
  const [isMouseOverProductText, toggleIsMouseOverProductText] = useState(false);
  const { isCustomAccessUser } = useCustomAccessUser(
    CUSTOM_USER_ACCESS.formsUploadSetting
  );

  const locations = handleLocalStorage.get(AuthStorage.locations) as Location[];
  const location = handleLocalStorage.get(AuthStorage.active_location) as Location;
  const isWeaveUser = handleLocalStorage.get(AuthStorage.is_weave_user);
  const history = useHistory();
  const { signOut, user } = useAuth();
  const {
    FormDigitizationSetting,
    digitizationFeeProps: {
      toggleDigitizationFeeSwitchProps,
      modalControls: { modalProps: digitizationModalProps },
    },
    formsUploadProps: { toggleFormsUploadSwitchProps, modalProps },
  } = useFormsDigitization();
  const { updateFormUploadStatus } = useUpdateFormUploadStatus();
  const { updateDigitizationFeeCheck } = useUpdateDigitizationFeeCheck();

  const { active, FlyoutMenu, flyoutProps, triggerProps } = useFlyoutMenu();
  const transitions = useSlideFadeTransition({ active, direction: 'up' });

  const onNotificationIconClickHandler = () => {
    history.push(FORM_SUBMISSION_CATEGORIES.recentlySubmitted);
  };

  function getMenuActions() {
    const menuActions: CustomFlyoutMenuAction[] = [];
    const logoutItem: CustomFlyoutMenuAction = {
      Icon: LogOutIcon,
      label: 'Sign Out',
      onClick: () => signOut(),
    };

    if ((locations && locations.length > 1) || isWeaveUser) {
      menuActions.push({
        Icon: LocationIcon,
        label: 'Change Company',
        onClick: () => {
          history.push('/location');
        },
        trackingId: pendoTags.companyChangeButton,
      });

      if (isCustomAccessUser) {
        menuActions.push({
          label: (
            <FormDigitizationSetting
              key="form-upload-setting"
              switchUploadProps={toggleFormsUploadSwitchProps}
              switchDigitizationFieldProps={toggleDigitizationFeeSwitchProps}
            />
          ),
          onClick: () => {},
        });
      }
    }

    menuActions.push(logoutItem);
    return menuActions as FlyoutMenuAction[];
  }

  return (
    <>
      {user && (
        <div css={userStyle}>
          <Heading
            level={3}
            css={productName}
            onMouseOver={() => {
              toggleIsMouseOverProductText(true);
            }}
            onMouseOut={() => {
              toggleIsMouseOverProductText(false);
            }}
          >
            {isMouseOverProductText ? 'Digital Forms' : 'FORMS'}{' '}
            {isMouseOverProductText && (
              <Text as="span" css={productVersionStyle} color="light" weight="regular">
                v2.0
              </Text>
            )}
          </Heading>

          <div css={personAndNotificationBadgeContainer}>
            <span {...triggerProps} css={userProfileStyles}>
              <div css={nameContainer}>
                <div css={nameWrap} data-trackingid={pendoTags.profileButton}>
                  <Text>{user.name}</Text>
                  {location && <h6>{location.name}</h6>}
                </div>
                {active ? <CaretUpIconSmall /> : <CaretDownIconSmall />}
              </div>
              {transitions.map(
                ({ item, key, props }) =>
                  item && (
                    <FlyoutMenu
                      key={key}
                      css={flyoutMenuItemStyles}
                      {...flyoutProps}
                      actions={getMenuActions()}
                      style={props}
                    />
                  )
              )}
            </span>
          </div>
        </div>
      )}

      <ConfirmationModal
        {...modalProps}
        onConfirm={() => updateFormUploadStatus(false)}
        title="Are you sure you want to enable this feature?"
        message="This would allow the customer to upload forms through the Admin Portal."
      />
      <ConfirmationModal
        {...digitizationModalProps}
        onConfirm={() => updateDigitizationFeeCheck(false)}
        title="Are you sure you want to relax digitization fee?"
        message="This would allow the customer to upload forms through the Admin Portal without paying digitization fee."
      />
    </>
  );
};
