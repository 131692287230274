import { FC } from 'react';

import { useHoverLabel, UseHintProps } from '@weave/design-system';

import { Badge } from 'shared/components';

import {
  newBadgeContainerStyle,
  newBadgeHoverLabelStyle,
  badgeStyleOverride,
} from './new-badge.style';

interface NewBadgeProps extends UseHintProps {}

export const NewBadge: FC<NewBadgeProps> = ({
  children,
  buffer,
  hoverDelay,
  offset,
  placement,
  trigger,
  ...otherProps
}) => {
  const useHoverLabelProps = { buffer, hoverDelay, offset, placement, trigger };
  const { triggerProps, labelProps, HoverLabel } = useHoverLabel(useHoverLabelProps);

  return (
    <>
      <div {...triggerProps} css={newBadgeContainerStyle} {...otherProps}>
        <Badge type="info" css={badgeStyleOverride}>
          New
        </Badge>
      </div>

      <HoverLabel {...labelProps} css={newBadgeHoverLabelStyle}>
        {children}
      </HoverLabel>
    </>
  );
};
