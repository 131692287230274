import { FC } from 'react';
import { css } from '@emotion/core';

import { CaretDownIconSmall, DropdownFieldProps } from '@weave/design-system';

type DropdownIconProps = Pick<DropdownFieldProps, 'active' | 'icon'>;

export const DropdownIcon: FC<DropdownIconProps> = ({ active, icon }) => {
  const Icon = icon ?? CaretDownIconSmall;

  return (
    <Icon
      css={[
        css`
          cursor: default;
          pointer-events: none;
          position: absolute;
          top: 50%;
          right: 16px;
          transform: translateY(-50%);
          transition: transform 250ms ease-out;
        `,
        !icon &&
          active &&
          css`
            transform: translateY(-50%) rotate(180deg);
          `,
      ]}
    />
  );
};
