import { css } from '@emotion/core';
import { theme } from '@weave/theme';

const uploadButtonStyle = css`
  background-color: white;
  padding: ${theme.spacing(1.5, 2)};
  border-radius: ${theme.borderRadius.full};
  border: 2px solid ${theme.colors.neutral30};
  color: ${theme.colors.neutral30};
  font-weight: ${theme.font.weight.bold};
  margin-right: ${theme.spacing(2)};
`;

export const BuilderCardCapture = () => {
  return (
    <>
      <button css={uploadButtonStyle}>Picture of front</button>
      <button css={uploadButtonStyle}>Picture of back</button>
    </>
  );
};
