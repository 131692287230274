import { createContext, FC, useContext } from 'react';

import { useSourceTenants, UseSourceTenantsResults } from './useSourceTenants';
import {
  useUpdatePreferredSourceTenant,
  UseUpdatePreferredSourceTenantResult,
} from './useUpdatePreferredSourceTenant';
import {
  useUpdateWritebackAuth,
  UseUpdateWritebackAuthResults,
} from './useUpdateWritebackAuth';
import { useWritebackAuth, UseWritebackAuthResults } from './useWritebackAuth';
import {
  UseWritebackCapabilitiesResults,
  useWritebackCapabilitiesV3,
} from './useWritebackCapabilitiesV3';

interface WritebackCapabilitiesProviderProps {}

interface WritebackCapabilitiesProviderContext
  extends UseWritebackCapabilitiesResults,
    UseUpdatePreferredSourceTenantResult,
    UseWritebackAuthResults,
    UseUpdateWritebackAuthResults,
    UseSourceTenantsResults {
  showWritebackSettingsSection: boolean;
  hasPMSIntegration: boolean;
}

const WritebackCapabilitiesContext = createContext<
  WritebackCapabilitiesProviderContext | undefined
>(undefined);

export const WritebackCapabilitiesProvider: FC<WritebackCapabilitiesProviderProps> = ({
  children,
}) => {
  const writebackAuthProps = useWritebackAuth();
  const wbSourceCapabilitiesProps = useWritebackCapabilitiesV3();
  const sourceTenantsProps = useSourceTenants({
    enabled: wbSourceCapabilitiesProps.isFetchedWritebackSourcesCapabilities,
    writebackCapabilities: wbSourceCapabilitiesProps.writebackSourcesCapabilities,
  });
  const updatePreferredSourceTenantProps = useUpdatePreferredSourceTenant();
  const updateWritebackAuthProps = useUpdateWritebackAuth();

  function showWritebackSettingsSection() {
    const preferredSourceTenant = sourceTenantsProps.getPreferredSourceTenantDetails();

    if (!preferredSourceTenant) {
      return false;
    }

    const {
      showWritebackSettings: { createPerson, updatePerson, uploadDocument },
    } = wbSourceCapabilitiesProps.getSourceCapabilities(preferredSourceTenant.id);

    return createPerson || updatePerson || uploadDocument;
  }

  function hasPMSIntegration() {
    return (
      sourceTenantsProps.hasSupportedSourceTenants() &&
      wbSourceCapabilitiesProps.hasWritebackCapableSources
    );
  }

  const contextValue: WritebackCapabilitiesProviderContext = {
    ...sourceTenantsProps,
    ...wbSourceCapabilitiesProps,
    ...updatePreferredSourceTenantProps,
    ...writebackAuthProps,
    ...updateWritebackAuthProps,
    showWritebackSettingsSection: showWritebackSettingsSection(),
    hasPMSIntegration: hasPMSIntegration(),
  };

  return (
    <WritebackCapabilitiesContext.Provider value={contextValue}>
      {children}
    </WritebackCapabilitiesContext.Provider>
  );
};

export const useWritebackCapabilitiesContext =
  (): WritebackCapabilitiesProviderContext => {
    const context = useContext(WritebackCapabilitiesContext);

    if (context === undefined) {
      throw new Error(
        'useWritebackCapabilitiesContext must be used within a <WritebackCapabilitiesProvider />.'
      );
    }

    return context;
  };
