import { createContext, FC, useContext, useState, useEffect } from 'react';

import { useWritebackCapabilitiesContext } from 'context';
import { WritebackSetupStep } from 'shared/types';
import { WRITEBACK_SETUP_STEPS, CUSTOM_USER_ACCESS } from 'shared/constants';
import {
  useWritebackSettings,
  useCustomAccessUser,
  useHasAnySubmission,
} from 'shared/hooks';

import { useWritebackSetup, UseWritebackSetupResults } from './useWritebackSetup';

interface WritebackSetupProviderProps {}

interface WritebackSetupProviderContext extends UseWritebackSetupResults {
  hasCustomUserAccess: boolean;
  isStepInitialised: boolean;
  currentStep: WritebackSetupStep;
  goToNextStep: () => void;
  selectedSourceTenantId: string;
  onChangeSource: (sourceId: string) => void;
  onFinishWritebackSetup: () => Promise<void>;
  isWritebackSetupInProgress: boolean;
}

const WritebackSetupContext = createContext<WritebackSetupProviderContext | undefined>(
  undefined
);

export const WritebackSetupProvider: FC<WritebackSetupProviderProps> = ({ children }) => {
  const [currentStep, setCurrentStep] = useState<WritebackSetupStep>(
    WritebackSetupStep.WELCOME
  );
  const [selectedSourceTenantId, setSelectedSourceTenantId] = useState('');
  const [isStepInitialised, setIsStepInitialised] = useState(false);
  const [isWritebackSetupInProgress, setIsWritebackSetupInProgress] = useState(false);

  const writebackSetupProps = useWritebackSetup();

  const hasAtLeastOneSubmission = useHasAnySubmission();

  const {
    updateWritebackAuth,
    hasPMSIntegration,
    hasMultipleSupportedSourceTenants,
    hasMultiTenantSource,
    updatePreferredSourceTenant,
    supportedSourceTenants,
    isLoadingSourceTenants,
  } = useWritebackCapabilitiesContext();

  const { isCustomAccessUser } = useCustomAccessUser(CUSTOM_USER_ACCESS.writebackSetup);
  const { data: writebackSettings, updateWritebackSetting } = useWritebackSettings();

  const isMulti = hasMultipleSupportedSourceTenants() || hasMultiTenantSource;

  // Select first source as default
  useEffect(() => {
    if (isLoadingSourceTenants || supportedSourceTenants.length === 0) {
      return;
    }

    const firstAvailableSourceTenant = supportedSourceTenants[0];

    if (firstAvailableSourceTenant.sourceTenantId) {
      setSelectedSourceTenantId(firstAvailableSourceTenant.sourceTenantId);
      return;
    }

    if (
      !firstAvailableSourceTenant.sites ||
      firstAvailableSourceTenant.sites.length === 0
    ) {
      return;
    }

    setSelectedSourceTenantId(firstAvailableSourceTenant.sites[0].sourceTenantId);
  }, [isLoadingSourceTenants, supportedSourceTenants]);

  // Should skip the welcome screen?
  useEffect(() => {
    if (!hasPMSIntegration || isStepInitialised || isLoadingSourceTenants) {
      return;
    }

    if (hasAtLeastOneSubmission) {
      if (!isMulti) {
        setCurrentStep(WritebackSetupStep.REVIEW);
      } else {
        setCurrentStep(WritebackSetupStep.SELECT_DESTINATION);
      }
    }

    setIsStepInitialised(true);
  }, [
    hasPMSIntegration,
    isStepInitialised,
    isLoadingSourceTenants,
    hasAtLeastOneSubmission,
    isMulti,
  ]);

  function goToNextStep() {
    const availableSteps = !isMulti
      ? WRITEBACK_SETUP_STEPS.singlePMSLocation
      : WRITEBACK_SETUP_STEPS.multiPMSLocation;

    if (currentStep < availableSteps.length) {
      setCurrentStep(availableSteps[currentStep + 1]);
    }
  }

  function onChangeSource(sourceTenantId: string) {
    setSelectedSourceTenantId(sourceTenantId);
  }

  async function onFinishWritebackSetup() {
    setIsWritebackSetupInProgress(true);

    await updateWritebackAuth({
      previousStatus: 'Invalid',
      status: 'Authorised',
    });

    if (selectedSourceTenantId) {
      await updatePreferredSourceTenant({ sourceTenantId: selectedSourceTenantId });
    }

    const wbSettingAPIs: Array<Promise<any>> = [];

    for (const { settingId, settingName } of writebackSettings) {
      wbSettingAPIs.push(
        updateWritebackSetting({
          settingId,
          settingName,
          settingValue: 'Auto',
        })
      );
    }

    await Promise.all(wbSettingAPIs);
    setIsWritebackSetupInProgress(false);
  }

  const contextValue: WritebackSetupProviderContext = {
    ...writebackSetupProps,
    hasCustomUserAccess: isCustomAccessUser,
    isStepInitialised,
    currentStep,
    goToNextStep,
    selectedSourceTenantId,
    onChangeSource,
    onFinishWritebackSetup,
    isWritebackSetupInProgress,
  };

  return (
    <WritebackSetupContext.Provider value={contextValue}>
      {children}
    </WritebackSetupContext.Provider>
  );
};

export const useWritebackSetupContext = (): WritebackSetupProviderContext => {
  const context = useContext(WritebackSetupContext);

  if (context === undefined) {
    throw new Error(
      'useWritebackSetupContext must be used within a <WritebackSetupProvider />'
    );
  }

  return context;
};
