import { css } from '@emotion/core';
import { theme } from '@weave/theme';

export const headingStyle = css`
  line-height: 32px;
  margin: 0;
  margin-bottom: ${theme.spacing(2)};
`;

export const listStyle = css`
  font-size: ${theme.fontSize(16)};
  line-height: 24px;
  padding-left: ${theme.spacing(4)};
`;
