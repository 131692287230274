import { css } from '@emotion/core';
import { theme } from '@weave/theme';

export const buttonsContainerStyle = css`
  display: flex;
  flex-direction: row-reverse;
  gap: ${theme.spacing(2)};
`;

export const buttonStyle = css`
  padding-left: ${theme.spacing(2)};
  padding-right: ${theme.spacing(2)};
`;
