import { IFormBuilder } from 'shared/types';

export interface ICondition {
  label: string;
  value: IFormBuilder.FormConditionType;
  fieldValue?: string;
}

export const EQUALS: ICondition = {
  label: 'Is equal to',
  value: 'equals',
};
export const NOT_EQUALS: ICondition = { label: 'Is not equal to', value: 'notEquals' };
export const IS_ANSWERED: ICondition = { label: 'Is answered', value: 'answered' };
export const GREATER_THAN: ICondition = {
  label: 'Is greater than',
  value: 'greaterThan',
};
export const LESS_THAN: ICondition = { label: 'Is less than', value: 'lessThan' };
export const GREATER_THAN_OR_EQUAL = {
  label: 'Is greater than or equal to',
  value: 'greaterThanOrEqual',
};
export const LESS_THAN_OR_EQUAL = {
  label: 'Is less than or equal to',
  value: 'lessThanOrEqual',
};
export const CONTAINS: ICondition = { label: 'Contains', value: 'contains' };
export const NOT_CONTAINS: ICondition = {
  label: 'Does not contain',
  value: 'notContains',
};
