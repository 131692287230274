import { css } from '@emotion/core';
import { theme } from '@weave/theme';

export const containerStyle = css`
  position: relative;

  input {
    cursor: pointer;
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

export const fileInputStyle = css`
  z-index: ${theme.zIndex.background};
`;
