import { useState, useEffect, useRef } from 'react';
import { useQuery } from 'react-query';

import { ProviderMapping } from 'shared/helpers/axios/apis';
import { queryKeys } from 'shared/constants';

import { useProviderMappingMutations } from './useProviderMappingMutations';

interface UseProviderMappingProps {
  enabled?: boolean;
}

export interface UseProviderMappingResults {
  providers: ProviderMapping.Types.ProviderMapping[];
  mappedProviders: ProviderMapping.Types.MappedProvider[];
  isLoadingProviderMapping: boolean;
  hasErrorLoadingProviderMapping: boolean;
  getProviderDetailsByName: (
    providerName: string
  ) => ProviderMapping.Types.MappedProvider | undefined;
  updateProviderMapping: (
    provider: ProviderMapping.Types.ProviderMapping,
    user: ProviderMapping.Types.User
  ) => void;
  removeProviderMapping: (user: ProviderMapping.Types.ProviderMapping) => void;
  hasProviderMapping: (user: ProviderMapping.Types.User) => boolean;
  saveMapping: () => void;
  isBulkSavingMapping: boolean;
}

export const useProviderMapping = (
  props: UseProviderMappingProps = { enabled: true }
): UseProviderMappingResults => {
  const [mappedProviders, setMappedProviders] = useState<
    ProviderMapping.Types.MappedProvider[]
  >([]);

  const [isLoadingMappedProviders, setIsLoadingMappedProviders] = useState(true);
  const [providers, setProviders] = useState<ProviderMapping.Types.ProviderMapping[]>([]);
  const providersBackup = useRef<ProviderMapping.Types.ProviderMapping[]>([]);
  const mappingsToRemove = useRef<ProviderMapping.Types.ProviderMapping[]>([]);

  const [providerMappingLookup, setProviderMappingLookup] = useState<
    Record<string, number>
  >({});

  const providerMappingMutationsProps = useProviderMappingMutations();

  const { data, isLoading, isError } = useQuery(
    queryKeys.providerMapping.mapping,
    ProviderMapping.API.fetchProviderMapping,
    {
      enabled: props.enabled,
      retry: 1,
    }
  );

  // Map the provider data to a lookup object and an array of mapped providers
  useEffect(() => {
    if (isLoading || isError) {
      return;
    }

    const mappingResponse = data ?? [];
    const mappingLookup: Record<string, number> = {};
    const mappedProviders: ProviderMapping.Types.MappedProvider[] = [];

    mappingResponse.forEach((provider) => {
      if (provider.email && provider.userId) {
        mappedProviders.push({
          ...provider,
          email: provider.email,
          userId: provider.userId,
        });
        mappingLookup[provider.name] = mappedProviders.length - 1;
      }
    });

    setProviders(mappingResponse);
    providersBackup.current = mappingResponse.map((p) => ({ ...p })); // Create a backup of the original data

    setProviderMappingLookup(mappingLookup);
    setMappedProviders(mappedProviders);
    setIsLoadingMappedProviders(false);
  }, [data, isLoading, isError]);

  function getProviderDetailsByName(providerName: string) {
    const providerDetails = mappedProviders[providerMappingLookup[providerName]];

    return providerDetails ?? undefined;
  }

  function hasProviderMapping(user: ProviderMapping.Types.User) {
    return providers.some(({ userId }) => userId === user.UserID);
  }

  function addToMappingsToRemove(provider: ProviderMapping.Types.ProviderMapping) {
    // No need to remove if the provider is not yet saved
    if (!provider.id) {
      return;
    }

    const providerExists = mappingsToRemove.current.some(
      (mapping) => mapping.userId === provider.userId
    );

    if (providerExists) {
      return;
    }

    mappingsToRemove.current = [...mappingsToRemove.current, provider];
  }

  function updateProviderMapping(
    provider: ProviderMapping.Types.ProviderMapping,
    user: ProviderMapping.Types.User
  ) {
    setProviders((previousList) =>
      previousList.map((p) =>
        p.name === provider.name
          ? {
              ...provider,
              email: user.Username,
              userId: user.UserID,
            }
          : p
      )
    );
  }

  function removeProviderMapping(provider: ProviderMapping.Types.ProviderMapping) {
    addToMappingsToRemove(provider);

    setProviders((previousList) =>
      previousList.map((p) =>
        p.name === provider.name
          ? {
              ...provider,
              email: '',
              userId: '',
            }
          : p
      )
    );
  }

  async function saveMapping() {
    const mappingToUpdate: ProviderMapping.Types.ProviderMapping[] = [];
    const mappingToDelete = mappingsToRemove.current;

    for (const provider of providers) {
      if (!provider.email || !provider.userId) {
        continue;
      }

      // Find those mapping that changed
      const index = providersBackup.current.findIndex(
        (p) =>
          p.name === provider.name &&
          p.email === provider.email &&
          p.userId === provider.userId
      );

      if (index === -1) {
        mappingToUpdate.push(provider);
      }
    }

    await providerMappingMutationsProps.bulkSaveProviderMapping({
      mappingToUpdate,
      mappingToDelete,
    });

    // Reset the mappings to remove
    mappingsToRemove.current = [];
  }

  return {
    providers,
    mappedProviders,
    isLoadingProviderMapping: isLoading || isLoadingMappedProviders,
    hasErrorLoadingProviderMapping: isError,
    getProviderDetailsByName,
    updateProviderMapping,
    hasProviderMapping,
    removeProviderMapping,
    saveMapping,
    isBulkSavingMapping: providerMappingMutationsProps.isBulkSavingMapping,
  };
};
