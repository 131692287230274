import { FC, useEffect, useRef } from 'react';
import { conditionsPanelItemStyles } from './condition-panel.style';
import {
  CustomChangeEvent,
  DropdownField,
  FormRow,
  Heading,
  IconButton,
  MultiselectField,
  TrashIcon,
  useFormField,
} from '@weave/design-system';
import {
  BuilderContext,
  IFormBuilder,
  MedicalConditionType,
  OptionSetValue,
} from 'shared/types';
import { EQUALS, IS_ANSWERED } from './conditions';
import { useFormComponentsContext, useWritebackCapabilitiesContext } from 'context';
import { pendoTags } from 'shared/constants';
import { useMedicalConditions } from 'shared/hooks';

const ConditionActions: {
  label: string;
  value: IFormBuilder.ConditionOperation;
  trackingId: string;
}[] = [
  {
    label: 'Show',
    value: 'show',
    trackingId: pendoTags.newFormEditor.conditionalLogic.showField,
  },
  {
    label: 'Hide',
    value: 'hide',
    trackingId: pendoTags.newFormEditor.conditionalLogic.hideField,
  },
];

interface ConditionItemProps {
  id: string;
  conditionNumber: number;
  field: IFormBuilder.Field;
  onDelete: (id: string) => void;
}

const ConditionItem: FC<ConditionItemProps> = ({
  id,
  conditionNumber,
  field,
  onDelete,
}) => {
  const {
    orderedFields,
    conditionsMapper,
    formState: { conditions },
    invalidConditions,
    setInvalidConditions,
    updateFormState,
  } = useFormComponentsContext();

  const { preferredSourceTenantId, getSourceCapabilities, getSourceId } =
    useWritebackCapabilitiesContext();

  const preferredSourceId = getSourceId(preferredSourceTenantId);

  const { allergies, disease, medications } = useMedicalConditions({
    sourceTenantId: preferredSourceTenantId,
    hasMedicalConditons:
      getSourceCapabilities(preferredSourceId).showMedicalConditionsTab,
  });

  const condition = conditionsMapper[id];

  const previousTargetedFields = useRef(
    (condition.actions || []).map((action) => action.target)
  );
  const selectedTargetValue = useRef<string>();

  const targetedFields = useFormField({
    type: 'multiselect',
    required: true,
    value: previousTargetedFields.current,
    minRequired: 1,
  });

  const conditionDropdownProps = useFormField(
    {
      type: 'dropdown',
      required: true,
      value: condition.terms[0].check,
    },
    [condition.terms[0].check]
  );

  const specificValue = useFormField(
    {
      type: 'text',
      required: true,
      value: condition.terms[0].value,
    },
    [condition.terms[0].value]
  );

  const actionsDropdownProps = useFormField(
    {
      type: 'dropdown',
      required: true,
      value: (condition.actions || [])[0]?.operation ?? '',
    },
    [(condition.actions || [])[0]?.operation]
  );

  // MultiSelect: gets triggered when the targeted fields are changed
  // the multiselect onchange references to the initial context value in the updateCondition function
  // so we need to update the multi-select value using useEffect
  useEffect(() => {
    const areTargetedFieldsChanged =
      targetedFields.value.length !== previousTargetedFields.current.length ||
      !targetedFields.value.every(
        (target, index) => previousTargetedFields.current[index] === target
      );
    if (!areTargetedFieldsChanged) return;

    const conditionIndex = conditions?.findIndex((c) => c.id === id);

    if (conditions && conditionIndex !== undefined && conditionIndex > -1) {
      const updatedCondition = {
        conditionId: id,
        index: conditionIndex,
        action: [...(condition.actions || [])],
        term: {
          ...condition.terms[0],
        },
      };
      const selectedValue = selectedTargetValue.current;

      if (!selectedValue) return; // some old conditions don't have target field

      const isNewTarget = !previousTargetedFields.current.includes(selectedValue);

      // it's possible to remove all the fields from multi-select
      // so we need to check if the there exists a condition action without target
      // if it does then we add the target to that action
      const conditionActionWithoutTargetIndex = updatedCondition.action.findIndex(
        (action) => !action.target
      );

      // new target only
      if (isNewTarget && conditionActionWithoutTargetIndex === -1) {
        updatedCondition.action.push({
          target: selectedValue,
          type: 'fields',
          operation: actionsDropdownProps.value as IFormBuilder.ConditionOperation,
        });
      } else if (conditionActionWithoutTargetIndex > -1) {
        // new target but there is an action without target
        updatedCondition.action[conditionActionWithoutTargetIndex] = {
          ...updatedCondition.action[conditionActionWithoutTargetIndex],
          target: selectedValue,
        };
      } else {
        // existing target, remove the action
        updatedCondition.action = conditions[conditionIndex].actions.filter(
          (action) => action.target !== selectedValue
        );
      }

      updateFormState({
        type: BuilderContext.FormUpdateType.UPDATE_CONDITION,
        data: updatedCondition,
      });
      setInvalidConditions((prev) => {
        return {
          ...prev,
          [id]: false,
        };
      });
    }
    previousTargetedFields.current = targetedFields.value;
  }, [targetedFields.value, conditionsMapper[id], conditions]);

  const updateCondition = (e: CustomChangeEvent) => {
    const conditionIndex = conditions?.findIndex((c) => c.id === id);
    if (conditionIndex !== undefined && conditionIndex > -1) {
      // if the condition doesn't have any actions then we need to add a default action
      const actions: IFormBuilder.FormAction[] =
        (condition.actions || []).length === 0
          ? [
              {
                operation: actionsDropdownProps.value as IFormBuilder.ConditionOperation,
                target: '',
                type: 'fields',
              },
            ]
          : [...(condition.actions || [])];

      const updatedCondition = {
        conditionId: id,
        index: conditionIndex,
        action: actions,
        term: {
          ...condition.terms[0],
        },
      };

      if (e.name === 'check') {
        updatedCondition.term.check = e.value;
      }

      if (e.name === 'value') {
        updatedCondition.term.value = e.value;
      }

      if (e.name === 'term') {
        updatedCondition.action.forEach((action, index) => {
          updatedCondition.action[index] = {
            ...action,
            operation: e.value,
          };
        });
      }
      updateFormState({
        type: BuilderContext.FormUpdateType.UPDATE_CONDITION,
        data: updatedCondition,
      });
      setInvalidConditions((prev) => {
        return {
          ...prev,
          [id]: false,
        };
      });
    }
  };

  const getSepecificValue = (): OptionSetValue[] => {
    if (MedicalConditionType[field.meta.optionSet ?? '']) {
      switch (MedicalConditionType[field.meta.optionSet ?? '']) {
        case MedicalConditionType.allergies:
          return allergies?.optionSet?.options ?? [];
        case MedicalConditionType.disease:
          return disease?.optionSet?.options ?? [];
        case MedicalConditionType.medications:
          return medications?.optionSet?.options ?? [];
      }
    }

    return (field as IFormBuilder.BaseOptionsField).options || [];
  };

  return (
    <section css={conditionsPanelItemStyles(invalidConditions[id])}>
      <header>
        <Heading className="condition-name" level={3}>
          Condition {conditionNumber}
        </Heading>
        <IconButton label="" className="delete-condition" onClick={() => onDelete(id)}>
          <TrashIcon />
        </IconButton>
      </header>
      <section>
        <FormRow>
          <DropdownField
            {...conditionDropdownProps}
            label="If the answer is"
            name="check"
            onChange={updateCondition}
          >
            {getConditionValues(field).map((condition) => (
              <DropdownField.Option value={condition.value} key={condition.value}>
                {condition.label}
              </DropdownField.Option>
            ))}
          </DropdownField>
        </FormRow>
        <FormRow>
          {['equals', 'notEquals'].includes(conditionDropdownProps.value) && (
            <DropdownField
              {...specificValue}
              label="Value"
              name="value"
              onChange={updateCondition}
            >
              {getSepecificValue().map((option) => {
                // don't show if a medical condition is disabled in the settings
                if (option.disabled) return null;

                return (
                  <DropdownField.Option value={option.value} key={option.value}>
                    {option.label}
                  </DropdownField.Option>
                );
              })}
            </DropdownField>
          )}
        </FormRow>
        <FormRow>
          <DropdownField
            {...actionsDropdownProps}
            label="Then"
            name="term"
            onChange={updateCondition}
          >
            {ConditionActions.map((action) => (
              <DropdownField.Option
                value={action.value}
                key={action.value}
                trackingId={action.trackingId}
              >
                {action.label}
              </DropdownField.Option>
            ))}
          </DropdownField>
          <MultiselectField
            {...targetedFields}
            label="Field"
            name="field"
            onChange={(e) => {
              targetedFields.onChange(e);
              selectedTargetValue.current = (e as any).value;
            }}
          >
            {orderedFields.map((of) => {
              // don't show the current field in the list
              if (of.id === field.id) return null;
              return (
                <MultiselectField.Option value={of.id} key={of.id}>
                  {of.name}
                </MultiselectField.Option>
              );
            })}
          </MultiselectField>
        </FormRow>
      </section>
    </section>
  );
};

function getConditionValues(
  field: IFormBuilder.Field
): { label: string; value: IFormBuilder.FormConditionType }[] {
  const fieldType = field.meta.type;

  if (['checklist', 'radio'].includes(fieldType)) {
    return [EQUALS, IS_ANSWERED];
  }

  return [IS_ANSWERED];
}

export default ConditionItem;
