import { PrimaryButton, SecondaryButton } from '@weave/design-system';
import { FC, ChangeEvent, useRef } from 'react';

import { CustomIconButton } from 'shared/components';

import { containerStyle, fileInputStyle } from './image-upload-button.styles';

interface ImageUploadButtonProps {
  onChange: (files: FileList | null) => void;
  disabled?: boolean;
  buttonType?: 'icon' | 'primary' | 'secondary';
  icon?: FC;
  label: string;
  showLabelOnHover?: boolean;
}

export const ImageUploadButton: FC<ImageUploadButtonProps> = ({
  onChange,
  disabled,
  buttonType,
  icon: Icon,
  label,
  showLabelOnHover,
}) => {
  const fileInputElRef = useRef<HTMLInputElement>(null);

  function imageChangeHandler(e: ChangeEvent<HTMLInputElement>) {
    onChange(e.target.files);
    e.target.value = '';
  }

  function buttonClickHandler() {
    if (!fileInputElRef.current) {
      return;
    }

    fileInputElRef.current.click();
  }

  function getButton() {
    switch (buttonType) {
      case 'icon':
        return (
          <CustomIconButton
            disabled={disabled}
            roundedButtons
            label={label}
            showLabelOnHover={showLabelOnHover}
            onClick={buttonClickHandler}
          >
            {Icon && <Icon />}
          </CustomIconButton>
        );

      case 'secondary':
        return (
          <SecondaryButton
            type="button"
            disabled={disabled}
            onClick={buttonClickHandler}
            size="tiny"
          >
            {label}
          </SecondaryButton>
        );

      default:
        return (
          <PrimaryButton
            type="button"
            disabled={disabled}
            onClick={buttonClickHandler}
            size="tiny"
          >
            {label}
          </PrimaryButton>
        );
    }
  }

  return (
    <div css={containerStyle}>
      {getButton()}

      <input
        type="file"
        accept="image/png, image/jpeg, image/jpg"
        onChange={imageChangeHandler}
        disabled={disabled}
        css={fileInputStyle}
        ref={fileInputElRef}
      />
    </div>
  );
};
