import { css } from '@emotion/core';
import { theme } from '@weave/theme';

export const textStyle = css`
  max-width: 514px;
  margin-top: ${theme.spacing(1)};
`;

export const syncAppNameStyle = css`
  color: ${theme.colors.primary50};
  font-weight: 600;
`;

export const pmsNameStyle = css`
  font-size: ${theme.fontSize(14)};
`;

export const closeIconButtonStyle = css`
  position: absolute !important;
  right: 24px;
`;
