import { forwardRef } from 'react';
import Children from 'react-children-utilities';

import { InputProps } from 'shared/types';

import { containerStyle, inputStyle, valueContainerStyle } from './dropdown-input.styles';

type DropdownInputProps = InputProps<'dropdown'> & {
  as?: keyof JSX.IntrinsicElements;
  className?: string;
  valueToDisplay?: React.ReactNode;
};

const DropdownInput = forwardRef<HTMLInputElement, DropdownInputProps>(
  ({ className, as = 'p', valueToDisplay, placeholder, ...rest }, ref) => {
    const Component = as;

    return (
      <Component css={containerStyle} className={className}>
        <input
          {...rest}
          /**
           * Display the text shown in each option as selected value (not the option value)
           */
          value={Children.onlyText(valueToDisplay)}
          css={inputStyle}
          type="text"
          readOnly
          ref={ref}
        />

        <div css={valueContainerStyle}>{valueToDisplay || rest.value || placeholder}</div>
      </Component>
    );
  }
);

export default DropdownInput;
