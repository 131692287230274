import { css } from '@emotion/core';
import { theme } from '@weave/theme';

export const linkStyle = css`
  color: ${theme.colors.primary50};
  font-weight: 600;
  text-decoration: underline;

  :hover,
  :active {
    color: ${theme.colors.neutral80};
    text-decoration: underline;
  }
`;
