import { CustomAxios, getResponseData } from 'shared/helpers/axios';
import { getCurrentLocationId, getPastUnixTime } from 'shared/helpers/utils';
import {
  SubmissionLists,
  SubmissionSubLists,
  WritebackStatusCode,
  ContactType,
  WritebackNAReason,
  ApproveSubmissionPayload,
  RejectSubmissionPayload,
  ProviderSignaturePayload,
  SaveDefaultSignature,
  SubmissionReviewStatus,
} from 'shared/types';

import {
  NormalizedSubmissionDetailResponse,
  PendingFormDetails,
  SubmissionDetailsResponse,
} from './form-submissions.types';

export interface SubmissionMetadata {
  birthdate: string;
  email: string;
  firstName: string;
  formCreatedAt: string;
  formSubmittedAt: string;
  updatedAt: string;
  lastName: string;
  mobilePhone: string;
  submissionId: string;
  writebackStatusCode: WritebackStatusCode;
  formId?: string;
  formName?: string;
  packetId?: string;
  packetName?: string;
  isSolicited?: boolean;
  isRead?: boolean;
  isArchive?: boolean;
  archivedAt?: string;
  sourceType?: ContactType;
  reasonPhrase?: WritebackNAReason;
  reviewStatus?: SubmissionReviewStatus;
  expiryDate?: string;
}

export interface SubmissionsListResponse {
  data?: SubmissionMetadata[];
  success: boolean;
  meta: {
    page: number;
    nextPage: number | null;
  };
}

export type SubmissionListSortOptions = 'submitted_at' | 'created_at' | 'archived_at';
export type SubmissionListSortOrder = 'desc' | 'asc';

interface SubmissionListParams {
  page?: number;
  limit?: number;
  solicited?: boolean;
  submitted?: boolean;
  submitted_from?: number;
  companyIds: string[];
  submitted_till?: number;
  writebacks?: 'Manual' | 'NA' | 'NotCompleted';
  sync_status?: 'failed' | 'fulfilled' | 'submitted';
  search?: string;
  order?: SubmissionListSortOrder;
  sortBy?: SubmissionListSortOptions;
  is_archive?: boolean;
  provider_email?: string;
  review_status?: 'pending' | 'approved' | 'rejected' | 'reviewed';
}

interface FetchSubmissionListParams {
  hasProviderReviewPermission: boolean;
  type: SubmissionLists;
  pageParams: SubmissionListParams;
  subType?: SubmissionSubLists;
  isNonIntegrated: boolean;
}

async function fetchSubmissionList(params: SubmissionListParams) {
  const response = await CustomAxios.get<SubmissionsListResponse>('/submissions', {
    params,
  });

  return response.data;
}

export function fetchSubmissionListOfType({
  type,
  pageParams: overridingParams,
  subType,
  hasProviderReviewPermission,
  isNonIntegrated,
}: FetchSubmissionListParams) {
  switch (type) {
    case 'all-submitted': {
      const params: SubmissionListParams = {
        ...overridingParams,
        submitted: true,
        is_archive: false,
      };

      if (hasProviderReviewPermission) {
        params['review_status'] = 'pending';
      } else if (!isNonIntegrated) {
        params['writebacks'] = 'NotCompleted';
      }

      return fetchSubmissionList(params);
    }

    case 'needs-review': {
      const params: SubmissionListParams = {
        ...overridingParams,
        submitted: true,
        is_archive: false,
        review_status: 'pending',
      };
      return fetchSubmissionList(params);
    }

    case 'recent':
      return fetchSubmissionList({
        ...overridingParams,
        submitted: true,
        submitted_from: getPastUnixTime({ weeks: 1 }),
        is_archive: false,
        ...(!isNonIntegrated && { writebacks: 'NotCompleted' }),
      });

    case 'not-submitted':
      return fetchSubmissionList({
        ...overridingParams,
        submitted: false,
        sortBy: 'created_at',
        is_archive: false,
      });

    case 'all-submitted':
      return fetchSubmissionList({
        ...overridingParams,
        submitted: true,
        is_archive: false,
        review_status: 'pending',
      });

    case 'need-approval':
      switch (subType) {
        case 'manual':
          return fetchSubmissionList({
            ...overridingParams,
            submitted: true,
            writebacks: 'Manual',
            is_archive: false,
          });

        case 'not-synced':
          return fetchSubmissionList({
            ...overridingParams,
            submitted: true,
            sync_status: 'submitted',
            is_archive: false,
          });
      }

    case 'failed-sync':
      return fetchSubmissionList({
        ...overridingParams,
        submitted: true,
        sync_status: 'failed',
        is_archive: false,
      });

    case 'completed':
      switch (subType) {
        case 'with-writebacks':
          return fetchSubmissionList({
            ...overridingParams,
            submitted: true,
            sync_status: 'fulfilled',
            is_archive: false,
          });

        case 'without-writebacks':
          return fetchSubmissionList({
            ...overridingParams,
            submitted: true,
            writebacks: 'NA',
            is_archive: false,
          });
      }

    case 'reviewed':
      switch (subType) {
        case 'approved':
        case 'rejected':
          return fetchSubmissionList({
            ...overridingParams,
            submitted: true,
            is_archive: false,
            review_status: subType,
          });

        default:
          return fetchSubmissionList({
            ...overridingParams,
            submitted: true,
            is_archive: false,
            review_status: 'reviewed',
          });
      }

    case 'archived':
      return fetchSubmissionList({
        ...overridingParams,
        is_archive: true,
        sortBy: 'archived_at',
      });

    default:
      return fetchSubmissionList({
        ...overridingParams,
        submitted: true,
        is_archive: false,
      });
  }
}

export async function fetchSubmission(
  submissionId: string
): Promise<NormalizedSubmissionDetailResponse> {
  const response = await CustomAxios.get<SubmissionDetailsResponse>(
    `/submissions/${submissionId}`,
    { params: { company_id: getCurrentLocationId() } }
  );

  const submissionData = response.data;
  const normalizedSubmissionData: NormalizedSubmissionDetailResponse = {
    ...submissionData,
    data: [],
  };

  if (submissionData.data) {
    submissionData.data.forEach((formDetail) => {
      normalizedSubmissionData.data.push({
        ...formDetail,
        submissionData: formDetail.formSubmittedAt
          ? formDetail.submissionData
          : JSON.parse((formDetail as PendingFormDetails).submissionData.form.jsonData),
      });
    });
  }

  return normalizedSubmissionData;
}

interface SubmissionPDFPayload {
  submissionId: string;
  formId?: string;
}

interface SubmissionPDFResponse {
  file: string;
  message: string;
}

export async function fetchSubmissionPDF({ submissionId, formId }: SubmissionPDFPayload) {
  const companyId = getCurrentLocationId();

  if (!companyId) {
    return Promise.reject('Location ID not provided!');
  }

  const params: Record<string, string> = {
    submission_id: submissionId,
    company_id: companyId,
  };

  if (formId) {
    params['form_id'] = formId;
  }

  const response = await CustomAxios.get<SubmissionPDFResponse>('/pdf-auth', { params });
  const data = response.data;
  return data.file;
}

export interface UpdateArchiveStatusParams {
  submissionIds: string[];
  archive: boolean;
}

export async function updateArchiveStatus({
  submissionIds,
  archive,
}: UpdateArchiveStatusParams) {
  const { data } = await CustomAxios.patch('/submissions-archive-status', {
    submissionIds,
    isArchive: archive,
  });

  return data;
}

export const submitFormSubmissionReview = async (
  reviewData: ApproveSubmissionPayload | RejectSubmissionPayload
) => {
  try {
    const res = await CustomAxios.post('/provider-review', reviewData);
    return { success: true, data: res };
  } catch {
    return { success: false, data: null };
  }
};

export const saveDefaultSignature = async (signature: SaveDefaultSignature) => {
  return await CustomAxios.patch('/provider-esign', signature);
};

export const getDefaultSignature = async (provider_email: string) => {
  const res = await CustomAxios.get<ProviderSignaturePayload>('/provider-esign', {
    params: {
      provider_email,
    },
  }).then(getResponseData);
  return res;
};

interface UpdateProviderParams {
  submissionId: string;
  name: string;
  email: string;
}
export const updateSubmissionProvider = async ({
  email,
  name,
  submissionId,
}: UpdateProviderParams) => {
  return CustomAxios.patch('/provider-details', {
    submission_id: submissionId,
    provider_details: {
      email,
      name,
    },
  });
};
