import jwtDecode from 'jwt-decode';

import { WeaveToken, AuthStorage } from 'auth/auth.types';
import { handleLocalStorage } from 'auth/auth.helpers';

export function getWeaveToken() {
  const token = handleLocalStorage.get(AuthStorage.weave_token) as string;

  if (!token) {
    return null;
  }

  return jwtDecode(token) as WeaveToken;
}

export function isWeaveUser() {
  const weaveToken = getWeaveToken();

  if (!weaveToken) {
    return false;
  }

  return weaveToken.type === 'weave';
}
