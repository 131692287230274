import { BuilderFields, BuilderContext, IFormBuilder as FB } from 'shared/types';
import { genUID } from 'shared/helpers/utils';

import { scrollToTarget } from './form-components.utils';

export interface PrimaryFieldsIndexMapper {
  [key: string]: { categoryIndex: number; orderIndex: number };
}

interface FormStateReducer {
  state: FB.FormStructure;
  action: BuilderContext.FormStateReducerAction;
  setHighlightedArea: (sectionId: string | undefined) => void;
  setRecentlyAddedFieldId: (id: string | undefined) => void;
}

export function formStateReducer({
  state,
  action,
  setHighlightedArea,
  setRecentlyAddedFieldId,
}: FormStateReducer): FB.FormStructure {
  switch (action.type) {
    case BuilderContext.FormUpdateType.ADD_FIELD: {
      const fieldId = genUID();
      const sectionId = action.sectionId;
      const currentSection = state.sections[sectionId];

      const sectionFields = [...currentSection.fields];
      sectionFields.splice(action?.targetIndex, 0, fieldId);

      const meta = { ...action.data.meta };

      // isEmpty and isError are added in medical history fields after data fetching
      if (meta.hasOwnProperty('isEmpty')) {
        delete meta.isEmpty;
      }
      if (meta.hasOwnProperty('isError')) {
        delete meta.isError;
      }

      setRecentlyAddedFieldId(fieldId);

      // reset after 1 second
      setTimeout(() => {
        setRecentlyAddedFieldId(undefined);
      }, 1000);

      return {
        ...state,
        sections: {
          ...state.sections,
          [sectionId]: {
            ...state.sections[sectionId],
            fields: sectionFields,
          },
        },
        fields: {
          ...state.fields,
          [fieldId]: {
            ...action.data,
            id: fieldId,
            meta,
          },
        },
      };
    }

    case BuilderContext.FormUpdateType.DELETE_FIELD: {
      const {
        fieldId: fieldToBeDeleted,
        sectionId,
        targetIndex,
        targetToFieldMapper,
        conditionsMapper,
      } = action.data;

      const newSectionFieldsOrder = [...state.sections[sectionId].fields];
      newSectionFieldsOrder.splice(targetIndex, 1);

      const updatedFields = { ...state.fields };
      const conditionsIdToBeRemoved = updatedFields[fieldToBeDeleted].condition_ids || [];
      const conditions = [...(state.conditions || [])];

      delete updatedFields[fieldToBeDeleted];

      // remove conditions associated with the field and remove side effects
      if (conditionsIdToBeRemoved.length > 0) {
        const updatedConditions: FB.FormCondition[] = [];
        conditions.forEach((condition) => {
          if (conditionsIdToBeRemoved.includes(condition.id)) {
            // remove side effects
            (condition.actions || []).forEach((action) => {
              const targetFieldId = action.target;
              const targetExists = !!state.fields[targetFieldId]; // PRECAUTIONARY: target field might not exist in some edge case

              if (targetExists && targetFieldId) {
                const field = removeSideEffects(updatedFields[targetFieldId]);
                updatedFields[targetFieldId] = field;
              }
            });
          } else {
            updatedConditions.push(condition);
          }
        });

        state.conditions = updatedConditions;
      }

      // remove the condition ids from the field and delete condition
      if (targetToFieldMapper[fieldToBeDeleted]) {
        let conditions = [...(state.conditions || [])];

        // iterate through the fields that are targeted to the field to be deleted
        targetToFieldMapper[fieldToBeDeleted].forEach((fieldId) => {
          const field = updatedFields[fieldId];
          const conditionIds = field.condition_ids || [];
          const updatedConditions: Record<string, FB.FormCondition | null> = {}; // null is used to remove the condition, otherwise the condition is updated
          const updateConditionIds: string[] = [];

          conditionIds.forEach((conditionId) => {
            // remove the action that targets the field to be deleted
            const actions = conditionsMapper[conditionId].actions.filter(
              (action) => action.target !== fieldToBeDeleted
            );

            if (actions.length === 0) {
              // zero length means no action is required, so the condition is removed
              updatedConditions[conditionId] = null;
            } else {
              // update the condition if there are other actions targeted to other fields
              updatedConditions[conditionId] = {
                ...conditionsMapper[conditionId],
                actions,
              };
              updateConditionIds.push(conditionId);
            }
          });

          state.fields[fieldId].condition_ids = updateConditionIds;

          // update the actual conditions
          conditions = conditions.reduce((allConditions, condition) => {
            const c = updatedConditions[condition.id];

            // if null, the condition is removed
            if (c === null) {
              return allConditions;
            } else if (c) {
              // if not null, the condition is updated
              return [...allConditions, c];
            }

            // if not updated, the condition is not changed, so return the original condition
            return [...allConditions, conditionsMapper[condition.id]];
          }, [] as FB.FormCondition[]);
        });
        state.conditions = conditions;
      }

      return {
        ...state,
        fields: updatedFields,
        sections: {
          ...state.sections,
          [sectionId]: {
            ...state.sections[sectionId],
            fields: newSectionFieldsOrder,
          },
        },
      };
    }

    case BuilderContext.FormUpdateType.ADD_SECTION: {
      const newSectionId = genUID();

      setHighlightedArea(newSectionId);

      setTimeout(() => {
        scrollToTarget(newSectionId);
      }, 100);

      return {
        ...state,
        form: {
          ...state.form,
          sections: [...state.form.sections, newSectionId],
        },
        sections: {
          ...state.sections,
          [newSectionId]: {
            fields: [],
            id: newSectionId,
            title: action.data,
          },
        },
      };
    }

    case BuilderContext.FormUpdateType.UPDATE_SECTION: {
      return {
        ...state,
        sections: {
          ...state.sections,
          [action.sectionId]: {
            ...action.data,
          },
        },
      };
    }

    case BuilderContext.FormUpdateType.MOVE_FIELD_WITHIN_SECTIONS: {
      return {
        ...state,
        sections: {
          ...state.sections,
          [action.data.targetSectionId]: action.data.targetSection,
          [action.data.sourceSectionId]: action.data.sourceSection,
        },
      };
    }

    case BuilderContext.FormUpdateType.UPDATE_FIELD: {
      return {
        ...state,
        fields: {
          ...state.fields,
          [action.data.id]: action.data,
        },
      };
    }

    case BuilderContext.FormUpdateType.SET_FORM: {
      return {
        ...action.data,
      };
    }

    case BuilderContext.FormUpdateType.REORDER_SECTIONS: {
      return {
        ...state,
        form: {
          ...state.form,
          sections: action.data.sections,
        },
      };
    }

    case BuilderContext.FormUpdateType.UPDATE_FORM_NAME: {
      return {
        ...state,
        form: {
          ...state.form,
          name: action.data.name,
        },
      };
    }

    case BuilderContext.FormUpdateType.ADD_SECTION_TEMPLATE: {
      const newSectionId = genUID();

      setHighlightedArea(newSectionId);

      setTimeout(() => {
        scrollToTarget(newSectionId);
      }, 100);

      return {
        ...state,
        form: {
          ...state.form,
          sections: [...state.form.sections, newSectionId],
        },
        fields: {
          ...state.fields,
          ...action.data.fields, // new fields from template
        },
        sections: {
          ...state.sections,
          // new section template
          [newSectionId]: {
            id: newSectionId,
            fields: action.data.fieldsOrder,
            title: action.data.name,
            section_template: action.data.sectionTemplate,
          },
        },
        ...(action.data.condition && {
          conditions: state.conditions
            ? [...state.conditions, action.data.condition]
            : [action.data.condition],
        }),
      };
    }

    case BuilderContext.FormUpdateType.DELETE_SECTION: {
      const sectionOrder = [...state.form.sections];
      const sections = {
        ...state.sections,
      };
      const fields = { ...state.fields };
      // delete fields in the section before deleting a section
      // old form could have sections with no fields
      (sections[action.data.sectionId].fields || []).forEach((fieldId) => {
        delete fields[fieldId];
      });
      delete sections[action.data.sectionId]; //delete section data

      sectionOrder.splice(action.data.sectionIndex, 1); // delete section id from form

      return {
        ...state,
        form: {
          ...state.form,
          sections: sectionOrder,
        },
        fields,
        sections,
      };
    }

    case BuilderContext.FormUpdateType.SET_FORM_ID: {
      return {
        ...state,
        form: {
          ...state.form,
          id: action.data.formId,
        },
      };
    }

    case BuilderContext.FormUpdateType.COPY_SECTION: {
      const { newSectionId } = action.data;
      setHighlightedArea(newSectionId);

      setTimeout(() => {
        scrollToTarget(newSectionId);
      }, 100);

      return {
        ...state,
        form: {
          ...state.form,
          sections: [...state.form.sections, newSectionId],
        },
        fields: {
          ...state.fields,
          ...action.data.newFields,
        },
        sections: {
          ...state.sections,
          [newSectionId]: action.data.section,
        },
      };
    }

    case BuilderContext.FormUpdateType.COPY_FIELD: {
      const { insertIndex, field, fieldId, sectionId } = action.data;

      const fields = [...state.sections[sectionId].fields];

      fields.splice(insertIndex + 1, 0, fieldId);

      setHighlightedArea(fieldId);

      setTimeout(() => {
        scrollToTarget(fieldId);
      }, 100);

      return {
        ...state,
        sections: {
          ...state.sections,
          [sectionId]: {
            ...state.sections[sectionId],
            fields: fields,
          },
        },
        fields: {
          ...state.fields,
          [fieldId]: field,
        },
      };
    }

    case BuilderContext.FormUpdateType.ADD_CONDITION: {
      const { fieldId, targetFieldId } = action.data;
      const conditionId = genUID();
      const newState = { ...state };
      const actions: FB.FormAction[] = [];

      if (targetFieldId) {
        actions.push({
          operation: 'show',
          target: targetFieldId || '',
          type: 'fields',
        });
      }

      const condition: FB.FormCondition = {
        id: conditionId,
        operator: 'and',
        terms: [
          {
            check: 'answered',
            field: fieldId,
            value: '',
          },
        ],
        actions: actions,
      };

      const updatedConditions = [...(newState.conditions || []), condition];

      if (targetFieldId) {
        const targetedField = applySideEffects({
          currentOperation: 'show',
          targetField: newState.fields[targetFieldId],
        });
        newState.fields[targetFieldId] = targetedField;
      }

      return {
        ...newState,
        fields: {
          ...newState.fields,
          [fieldId]: {
            ...newState.fields[fieldId],
            condition_ids: [
              ...(newState.fields[fieldId].condition_ids || []),
              conditionId,
            ],
          },
        },
        conditions: updatedConditions,
      };
    }

    case BuilderContext.FormUpdateType.UPDATE_CONDITION: {
      const { index, ...condition } = action.data;

      const conditions = [...(state.conditions || [])];
      const newState = { ...state };

      const prevOperation = conditions[index].actions[0]?.operation ?? '';
      const currentOperation = condition.action[0]?.operation ?? '';

      const newActions = condition.action;
      const oldActions = conditions[index].actions;

      // find out the unique actions and use it find the actions that are added or deleted
      const uniq1 = newActions.filter(
        (action) => oldActions.findIndex((oa) => oa.target !== action.target) !== -1
      );
      const uniq2 = oldActions.filter(
        (action) => newActions.findIndex((na) => na.target !== action.target) !== -1
      );

      // TODO: this needs to be optimized
      const addedTargets = uniq1.filter(
        (action) => oldActions.findIndex((oa) => oa.target === action.target) === -1
      );
      const deletedTargets = uniq2.filter(
        (action) => newActions.findIndex((na) => na.target === action.target) === -1
      );

      conditions[index] = {
        ...conditions[index],
        actions: [...condition.action],
        id: condition.conditionId,
        terms: [condition.term],
      };

      // if the operation is changed, remove the side effects of the previous operation and apply the side effects of the current operation
      if (prevOperation !== currentOperation) {
        condition.action.forEach((action) => {
          const targetFieldId = action.target;
          const targetExists = !!state.fields[targetFieldId]; // PRECAUTIONARY: target field might not exist in some edge case

          if (targetFieldId && targetExists) {
            const targetedField = applySideEffects({
              currentOperation: action.operation,
              targetField: newState.fields[targetFieldId],
              prevOperation,
            });
            newState.fields[targetFieldId] = targetedField;
          }
        });
      } else {
        // if the operation is not changed, remove the side effects of the deleted targets and apply the side effects of the added targets
        addedTargets.forEach((action) => {
          const targetFieldId = action.target;
          const targetExists = !!state.fields[targetFieldId]; // PRECAUTIONARY: target field might not exist in some edge case
          if (targetFieldId && targetExists) {
            const targetedField = applySideEffects({
              currentOperation: action.operation,
              targetField: newState.fields[targetFieldId],
            });
            newState.fields[targetFieldId] = targetedField;
          }
        });

        deletedTargets.forEach((action) => {
          const targetFieldId = action.target;
          const targetExists = !!state.fields[targetFieldId]; // PRECAUTIONARY: target field might not exist in some edge case

          if (targetFieldId && targetExists) {
            const targetedField = removeSideEffects(newState.fields[targetFieldId]);
            newState.fields[targetFieldId] = targetedField;
          }
        });
      }

      return {
        ...newState,
        conditions: conditions,
      };
    }

    case BuilderContext.FormUpdateType.DELETE_CONDITION: {
      const { conditionId, fieldId } = action.data;
      const newState = { ...state };

      const conditions = [...(newState.conditions || [])];
      const conditionIndex = conditions.findIndex(
        (condition) => condition.id === conditionId
      );

      const conditionToBeRemoved = conditions.splice(conditionIndex, 1)[0];
      newState.fields[fieldId].condition_ids =
        newState.fields[fieldId].condition_ids?.filter((id) => id !== conditionId) || [];

      (conditionToBeRemoved.actions || []).forEach((action) => {
        const targetFieldId = action.target;
        const targetExists = !!state.fields[targetFieldId]; // PRECAUTIONARY: target field might not exist in some edge case

        // in some case it could be possible that a condition doesn't have any target field
        if (targetFieldId && targetExists) {
          const targetedField = removeSideEffects(newState.fields[targetFieldId]);

          newState.fields[targetFieldId] = targetedField;
        }
      });

      newState.conditions = conditions;

      return newState;
    }

    default:
      return state;
  }
}

export function primaryFieldsReducer(
  state: BuilderFields[],
  action: BuilderContext.PrimaryFieldsReducerAction,
  indexMapper: PrimaryFieldsIndexMapper
): BuilderFields[] {
  const newState = [...state];
  switch (action.type) {
    case BuilderContext.PrimaryFieldsUpdateType.ENABLE: {
      const field = indexMapper[action.fieldName];
      const fieldsOrderCopy = [...newState[field.categoryIndex].order];
      const fieldData = { ...fieldsOrderCopy[field.orderIndex], disabled: false };

      fieldsOrderCopy[field.orderIndex] = fieldData;

      newState[field.categoryIndex] = {
        ...newState[field.categoryIndex],
        order: fieldsOrderCopy,
      };
      return newState;
    }

    case BuilderContext.PrimaryFieldsUpdateType.DISABLE: {
      const field = indexMapper[action.fieldName];
      const fieldsOrderCopy = [...newState[field.categoryIndex].order];
      const fieldData = { ...fieldsOrderCopy[field.orderIndex], disabled: true };

      fieldsOrderCopy[field.orderIndex] = fieldData;

      newState[field.categoryIndex] = {
        ...newState[field.categoryIndex],
        order: fieldsOrderCopy,
      };
      return newState;
    }

    case BuilderContext.PrimaryFieldsUpdateType.SET_USED_FIELDS: {
      const fields = action.data.fields;
      fields.forEach((fieldName) => {
        const field = indexMapper[fieldName];
        const fieldsOrderCopy = [...newState[field.categoryIndex].order];
        const fieldData = { ...fieldsOrderCopy[field.orderIndex], disabled: true };
        fieldsOrderCopy[field.orderIndex] = fieldData;

        newState[field.categoryIndex] = {
          ...newState[field.categoryIndex],
          order: fieldsOrderCopy,
        };
      });

      return newState;
    }

    case BuilderContext.PrimaryFieldsUpdateType.BATCH_ENABLE: {
      const fields = action.data.fields;
      fields.forEach((fieldName) => {
        const field = indexMapper[fieldName];
        const fieldsOrderCopy = [...newState[field.categoryIndex].order];
        const fieldData = { ...fieldsOrderCopy[field.orderIndex], disabled: false };
        fieldsOrderCopy[field.orderIndex] = fieldData;

        newState[field.categoryIndex] = {
          ...newState[field.categoryIndex],
          order: fieldsOrderCopy,
        };
      });

      return newState;
    }

    default:
      return state;
  }
}

// side effects are removed when an action on a field is changed or targeted field is changed.
// this should remove the side effects of the previously targeted fields or change the side effects of the targeted fields
// e.g: if a field is targeted to be shown when a field is answered, and the targeted field is changed, the side effect should be removed.
// the side effect in above case is to hide the targeted field by default, which should be removed in this function.
interface UpdateConditionSideEffectsParams {
  targetField: FB.Field;
  currentOperation: FB.ConditionOperation;
  prevOperation?: FB.ConditionOperation;
}
function applySideEffects(params: UpdateConditionSideEffectsParams): FB.Field {
  const { targetField, currentOperation, prevOperation } = params;

  // not all side effects are to be removed
  // revert the side effects of the previous operation
  if (prevOperation) {
    if (prevOperation === 'show') {
      targetField.hidden = false;
    }
    if (prevOperation === 'enable') {
      targetField.disabled = false;
    }
  }

  // apply the side effects of the current operation
  if (currentOperation === 'show') {
    targetField.hidden = true;
  }

  if (currentOperation === 'enable') {
    targetField.disabled = true;
  }

  return targetField;
}

function removeSideEffects(targetField: FB.Field): FB.Field {
  if (targetField.hidden) {
    targetField.hidden = false;
  }
  if (targetField.disabled) {
    targetField.disabled = false;
  }

  return targetField;
}
