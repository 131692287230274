import { css } from '@emotion/core';
import { theme } from '@weave/theme';

export const mainContainerStyle = css`
  // display: flex;
  // flex-direction: column;
  flex-grow: 1;
  overflow: auto;
`;

export const innerContentContainerStyle = css`
  // transition: margin-left 0.5s;
  // flex-grow: 1;
  overflow: auto;
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const notMigratedTextContainerStyle = css`
  text-align: center;
  padding: ${theme.spacing(0, 2)};
`;
