import { FC } from 'react';

import { RadioField, useControlledField, Chip } from '@weave/design-system';

import { SyncAppTypes } from 'shared/types';
import { containerStyle, labelStyle } from './writeback-sites.styles';

interface WritebackSitesProps {
  sourceTenant: SyncAppTypes.ModifiedSourceTenant;
  preferredSourceTenantId: string;
  onSelectSourceTenant: (sourceTenantId: string) => void;
  pendoTrackingId?: string;
  sourceTenantIdsWithDuplicateRecords?: string[];
}

const WritebackSites: FC<WritebackSitesProps> = ({
  sourceTenant,
  preferredSourceTenantId,
  onSelectSourceTenant,
  pendoTrackingId,
  sourceTenantIdsWithDuplicateRecords = [],
}) => {
  const radioFieldProps = useControlledField({
    type: 'radio',
    value: preferredSourceTenantId,
    onChange: (sourceTenantId) => {
      onSelectSourceTenant(sourceTenantId);
    },
  });

  function getContentToDisplay() {
    if (!sourceTenant.sites || sourceTenant.sites.length === 0) {
      return null;
    }

    return (
      <RadioField {...radioFieldProps} name="writeback-sites" label="">
        {sourceTenant.sites.map(({ sourceTenantId, name }) => {
          const hasDuplicateRecords =
            sourceTenantIdsWithDuplicateRecords.includes(sourceTenantId);

          return (
            <RadioField.Radio
              value={sourceTenantId}
              key={sourceTenantId}
              trackingId={pendoTrackingId}
            >
              <div css={labelStyle}>
                {name}
                {hasDuplicateRecords && (
                  <Chip.Tag color="red">Duplicate Records</Chip.Tag>
                )}
              </div>
            </RadioField.Radio>
          );
        })}
      </RadioField>
    );
  }

  return <div css={containerStyle}>{getContentToDisplay()}</div>;
};

export default WritebackSites;
