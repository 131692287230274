import { css } from '@emotion/core';
import { theme } from '@weave/theme';

export const formViewerRootStyles = css`
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
  padding: ${theme.spacing(2, 2, 1, 2)};
  box-shadow: ${theme.shadows.heavy};
  max-width: 1000px;
`;

export const formControlStyles = css`
  display: flex;
  height: 65px;
  width: 100%;
  border-bottom: 1px solid ${theme.colors.neutral20};
`;

export const formViewerNameEditorWrapper = css`
  display: flex;
  /* flex: 1; */
  flex-grow: 10;
  min-width: 50%;
  align-items: center;

  & div:first-of-type {
    width: 100%;
  }
`;

export const formViewerNameEditor = css`
  padding-left: 0;
  height: 50px;
  border: none !important;
  box-shadow: none !important;

  &:focus {
    outline: none;
  }

  input {
    font-size: ${theme.font.size.h1};
    font-weight: ${theme.font.weight.bold};
    caret-color: ${theme.colors.neutral90};
  }
`;

export const formControlButtonStyles = css`
  display: flex;
  margin-left: auto;
  align-items: center;
  padding-right: 0;
  flex-grow: 1;
`;

export const formControlButtonWrapperStyle = css`
  width: auto !important;
  margin-left: auto;
`;

export function getFormViewerButtonStyle(isDisabled: boolean) {
  const styles = [
    css`
      white-space: nowrap;
    `,
  ];

  if (isDisabled) {
    styles.push(
      css`
        pointer-events: none;
      `
    );
  }

  return styles;
}

export function getFormSaveButtonStyle(isDisabled: boolean) {
  const styles = [
    css`
      white-space: nowrap;
      padding: ${theme.spacing(0.8, 2)};
    `,
  ];

  if (isDisabled) {
    styles.push(
      css`
        pointer-events: none;
      `
    );
  }

  return styles;
}

export const formViewerContent = css`
  padding-top: ${theme.spacing(2)};
  height: calc(100% - 65px);
  display: flex;
  flex-direction: column;

  .form-content {
    flex: 0 1 auto;
    overflow-y: auto;
  }
`;

export const previewFormModalStyle = css`
  height: 90vh;
`;
