import { useEffect, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import { useDebouncedValue, useFormField, useAlert } from '@weave/design-system';

import { NonEditableFormsResponse, NonEditableForm } from 'shared/types';
import { CustomAxios } from 'shared/helpers/axios';
import { updateReviewRequired as updateReviewRequiredAPI } from 'shared/helpers/axios/apis';
import { getCurrentLocationId } from 'shared/helpers/utils';
import { DEBOUNCE_INTERVAL, queryKeys } from 'shared/constants';

const EMPTY_FORMS: NonEditableForm[] = [];

export const useNonEditableForms = () => {
  const [filteredForms, setFilteredForms] = useState<NonEditableForm[]>([]);
  const searchFieldProps = useFormField({ type: 'text' });
  const queryClient = useQueryClient();
  const alert = useAlert();

  const debouncedSearchTerm = useDebouncedValue(
    searchFieldProps.value ?? '',
    DEBOUNCE_INTERVAL
  );

  const { data, isLoading, isSuccess } = useQuery(
    queryKeys.nonEditableForms,
    getNonEditableTemplates,
    {
      staleTime: 1000 * 60 * 10, // 10 minutes
      refetchOnMount: true,
      retry: false,
    }
  );

  const {
    mutateAsync: updateReviewRequiredForNonEditableForm,
    isLoading: isUpdatingReviewRequiredFlagForNonEditableForm,
  } = useMutation(updateReviewRequiredAPI, {
    onMutate: ({ token, reviewRequired }) => {
      const newTemplates = toggleReviewRequired(data?.data || [], token, reviewRequired);

      queryClient.setQueryData(queryKeys.nonEditableForms, (data: any) => {
        return {
          data: newTemplates,
        };
      });
    },
    onSuccess: (_, { reviewRequired }) => {
      if (reviewRequired) {
        alert.success('Successfully enabled Provider Review for this form');
      } else {
        alert.success('Successfully disabled Provider Review for this form');
      }
    },
    onError: (_, { token, reviewRequired }) => {
      const newTemplates = toggleReviewRequired(data?.data || [], token, !reviewRequired);

      queryClient.setQueryData(queryKeys.nonEditableForms, (data: any) => {
        return {
          data: newTemplates,
        };
      });
      alert.error('Failed to save changes.');
    },
  });

  useEffect(() => {
    if (isSuccess) {
      setFilteredForms(data?.data || []);
    }
  }, [isSuccess]);

  useEffect(() => {
    const filtered = (data?.data ?? []).filter((item) => {
      return item.name
        .toLocaleLowerCase()
        .includes(debouncedSearchTerm.toLocaleLowerCase());
    });
    setFilteredForms(filtered);
  }, [debouncedSearchTerm]);

  return {
    //need to be fixed along with search inside form-templates.component.tsx. Search should have a single source of truth.
    data: debouncedSearchTerm ? filteredForms : data?.data ?? EMPTY_FORMS, //if searched then return from filtered text, otherwise return actual data
    isLoading: isLoading,
    searchNonEditableForm: searchFieldProps,
    updateReviewRequiredForNonEditableForm,
    isUpdatingReviewRequiredFlagForNonEditableForm,
  };
};

export async function getNonEditableTemplates() {
  const apiResponse = await CustomAxios.get<NonEditableFormsResponse>(
    `/list-templates?company_id=${getCurrentLocationId()}`
  );
  return apiResponse.data;
}

function toggleReviewRequired(
  templates: NonEditableForm[],
  token: string,
  reviewRequired: boolean
) {
  let found = false;
  const newTemplates = [...templates];
  for (let form = 0; form < newTemplates.length; form++) {
    if (newTemplates[form].id === token) {
      newTemplates[form].reviewRequired = reviewRequired;
      found = true;
      break;
    }
  }

  return newTemplates;
}
