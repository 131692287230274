import { FC } from 'react';
import { noop, isFunction } from 'lodash';
import { SerializedStyles } from '@emotion/core';

import {
  cardContainerStyle,
  clickableCardStyle,
  cardContentContainerStyle,
} from './basic-card.style';

interface BasicCardProps {
  onClick?: () => void;
  isSelected?: boolean;
  activeStyle?: SerializedStyles;
  pendoTag?: string;
  testId?: string;
}

export const BasicCard: FC<BasicCardProps> = ({
  onClick = noop,
  children,
  isSelected,
  activeStyle,
  pendoTag,
  testId,
  ...otherProps
}) => (
  <div
    css={[
      cardContainerStyle,
      isFunction(onClick) && clickableCardStyle,
      isSelected && activeStyle,
    ]}
    onClick={onClick}
    {...otherProps}
    data-trackingid={pendoTag}
    {...(testId && { 'data-testid': testId })}
  >
    <div css={cardContentContainerStyle}>{children}</div>
  </div>
);
