import { useQuery } from 'react-query';

import { useLocationValidityContext } from 'context';
import { API_URL, queryKeys } from 'shared/constants';
import { CustomAxios } from 'shared/helpers/axios';
import { checkSettingTypeCapability, getCurrentLocationId } from 'shared/helpers/utils';
import { WritebackCapabilities, WritebackCapabilitiesV3 } from 'shared/types';

async function getWritebackCapabilities() {
  const response = await CustomAxios.get<WritebackCapabilitiesV3.Response>(
    `/writeback-capabilities?companyId=${getCurrentLocationId()}`,
    {
      baseURL: `${API_URL}/forms-digital/v3`,
    }
  );

  return response.data;
}

export interface UseWritebackCapabilitiesResults {
  writebackSourcesCapabilities: WritebackCapabilitiesV3.WritebackSource[];
  isLoadingWritebackSourcesCapabilities: boolean;
  isFetchingWritebackSourcesCapabilities: boolean;
  isFetchedWritebackSourcesCapabilities: boolean;
  hasWritebackCapableSources: boolean;
  isWritebackCapableSource: (sourceId: string) => boolean;
  getSourceCapabilities: (sourceId: string) => WritebackCapabilities;
}

export const useWritebackCapabilitiesV3 = (): UseWritebackCapabilitiesResults => {
  const { isValidating, isValidLocation } = useLocationValidityContext();

  const {
    data,
    isLoading: isLoadingWritebackSourcesCapabilities,
    isFetching: isFetchingWritebackSourcesCapabilities,
    isFetched: isFetchedWritebackSourcesCapabilities,
  } = useQuery(
    [queryKeys.writebackCapabilitiesV3, getCurrentLocationId()],
    getWritebackCapabilities,
    {
      enabled: !isValidating && isValidLocation,
    }
  );

  const writebackSourcesCapabilities = data?.sourceCapabilities ?? [];

  /**
   * Returns the writeback capabilities for a given sourceId.
   * NOTE: This takes into account the sourceId,
   * and not the source tenant ID.
   */
  function getSourceCapabilities(sourceId: string): WritebackCapabilities {
    const wbSource = writebackSourcesCapabilities.find(
      (source) => source.sourceId === sourceId
    );

    return {
      showMedicalConditionPrimaryFields: checkSettingTypeCapability(
        wbSource,
        'medical_condition_primary_fields'
      ),
      showWritebackSettings: {
        createPerson: checkSettingTypeCapability(wbSource, 'writeback_create_person'),
        updatePerson: checkSettingTypeCapability(wbSource, 'writeback_update_person'),
        uploadDocument: checkSettingTypeCapability(wbSource, 'writeback_upload_document'),
      },
      showMedicalConditionsTab: checkSettingTypeCapability(
        wbSource,
        'medical_condition_tab'
      ),
      showWritebackSubmissionsTab: checkSettingTypeCapability(
        wbSource,
        'writeback_submission_tabs'
      ),
    };
  }

  function isWritebackCapableSource(sourceId: string) {
    const index = writebackSourcesCapabilities.findIndex(
      (source) => source.sourceId === sourceId
    );

    return index !== -1;
  }

  function hasWritebackCapableSources() {
    return writebackSourcesCapabilities.length > 0;
  }

  return {
    writebackSourcesCapabilities,
    isFetchingWritebackSourcesCapabilities,
    isLoadingWritebackSourcesCapabilities,
    isFetchedWritebackSourcesCapabilities,
    hasWritebackCapableSources: hasWritebackCapableSources(),
    getSourceCapabilities,
    isWritebackCapableSource,
  };
};
