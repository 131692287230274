import {
  MutableRefObject,
  createContext,
  useContext,
  ReactElement,
  useRef,
  useCallback,
  useEffect,
  useMemo,
  FocusEvent,
} from 'react';
import { isFunction } from 'lodash';

import {
  useFlyoutState,
  useDebouncedBlur,
  FlyoutRefType,
  BasicFormFieldProps,
  useUid,
  ListboxProvider,
  KeyNames,
} from '@weave/design-system';

type FieldProps = Pick<
  BasicFormFieldProps<'dropdown'>,
  'id' | 'name' | 'onBlur' | 'onChange' | 'onFocus'
> & {
  value: string | string[];
};

export type ListboxTriggerProps<T extends HTMLElement> = {
  'aria-haspopup': string;
  'aria-expanded': boolean;
  'aria-labelledby': string;
  id: string;
  onBlur?: () => void;
  onClick: () => void;
  onFocus?: () => void;
  onKeyDown: (e: KeyboardEvent) => void;
  ref: FlyoutRefType<T>;
};

export type DropdownContextType = {
  active: boolean;
  debouncedBlur: () => void;
  debouncedFocus: () => void;
  id: string;
  labelId: string;
  menuRef: MutableRefObject<HTMLElement | null>;
  setActive: (active: boolean) => void;
  triggerProps: ListboxTriggerProps<HTMLInputElement>;
  onSearch?: (searchTerm: string) => void;
  loadedItems?: Record<string, string>;
  isLoadingItems?: boolean;
  onActive?: (isActive: boolean) => void;
  invalidItems?: string[];
};

const DropdownContext = createContext<DropdownContextType>({} as DropdownContextType);

export type DropdownChildren = ReactElement | Array<ReactElement | null>;

type DropdownProviderProps = FieldProps & {
  children?: DropdownChildren;
  onSearch?: (searchTerm: string) => void;
  loadedItems?: Record<string, string>;
  isLoadingItems?: boolean;
  onActive?: (isActive: boolean) => void;
  invalidItems?: string[];
};

export const DropdownProvider = <
  F extends HTMLElement = HTMLUListElement,
  T extends HTMLElement = HTMLInputElement
>({
  children,
  id,
  name,
  onBlur,
  onChange,
  onFocus,
  onSearch,
  value,
  loadedItems,
  isLoadingItems,
  onActive,
  invalidItems,
}: DropdownProviderProps) => {
  const containerRef = useRef<HTMLElement>(null);
  const onToggle = useCallback(({ active }) => {
    // the input will no longer be focused after opening the flyout
    // so make sure to update field's active state when clicking outside
    // @ts-ignore
    if (!active && isFunction(onBlur)) onBlur({ target: { name } });
  }, []);
  const { active, flyoutRef, triggerRef, setActive } = useFlyoutState<
    any,
    HTMLInputElement
  >({ onToggle, ignoreRefs: [containerRef] });

  // focus handling between trigger and menu
  // so we don't lose the menu or field active state until blurring field w/o
  // moving to menu, or menu w/o moving to field
  const { blur, focus } = useDebouncedBlur({
    onBlur: useCallback(() => {
      setActive(false);
      const payload: unknown = { target: { name } };
      // @ts-ignore
      onBlur(payload as FocusEvent);
    }, [name]),
    onFocus: useCallback(() => {
      const payload: unknown = { target: { name } };
      // @ts-ignore
      onFocus(payload as FocusEvent);
    }, [name]),
  });

  // return focus to trigger on esc and select (enter/click)
  const focusTrigger = () => {
    setTimeout(() => {
      triggerRef?.current?.focus();
    }, 0);
  };

  const onEscape = useCallback(() => {
    setActive(false);
    focusTrigger();
  }, []);

  const isMultiselect = Array.isArray(value);
  const onSelect = useCallback(
    (value: string | string[]) => {
      onChange({ name, value });
      if (!isMultiselect) {
        setActive(false);
        focusTrigger();
      }
    },
    [name, isMultiselect]
  );

  useEffect(() => {
    if (active) {
      setTimeout(() => {
        flyoutRef.current?.focus();
      }, 0);
    }
  }, [active]);

  const listboxId = useUid();
  const labelId = getLabelId(id);
  const triggerId = `${listboxId.current}-trigger`;

  const contextValue = useMemo(
    () => ({
      active,
      // expose debounced focus handling,
      // for cases with focusable elements inside the menu
      debouncedBlur: blur,
      debouncedFocus: focus,
      id: listboxId.current,
      labelId,
      // ref for dropdowns where menu is not the listbox (eg multiselect)
      menuRef: containerRef,
      setActive,
      triggerProps: {
        id: triggerId,
        'aria-expanded': active,
        'aria-haspopup': 'listbox',
        'aria-labelledby': `${labelId} ${triggerId}`,
        onBlur: blur,
        onFocus: focus,
        onClick: () => {
          setActive((visible) => !visible);
        },
        onKeyDown: (e: KeyboardEvent) => {
          switch (e.key) {
            case KeyNames.Enter:
            case KeyNames.Space:
            case KeyNames.Up:
            case KeyNames.Down:
              if (!active) setActive(true);
              break;
            default:
              break;
          }
        },
        ref: triggerRef,
      },
      onSearch: onSearch,
      loadedItems,
      isLoadingItems,
      onActive,
      invalidItems,
    }),
    [active, labelId, triggerId, blur, focus, loadedItems, isLoadingItems, invalidItems]
  );

  return (
    <DropdownContext.Provider value={contextValue}>
      <ListboxProvider
        active={active}
        id={listboxId.current}
        labelId={labelId}
        onBlur={blur}
        onFocus={focus}
        onEscape={onEscape}
        onSelect={onSelect}
        listboxRef={flyoutRef}
        value={value}
      >
        {children}
      </ListboxProvider>
    </DropdownContext.Provider>
  );
};

export function useDropdownContext(): DropdownContextType {
  const context = useContext(DropdownContext);
  if (typeof context === 'undefined') {
    throw new Error('useDropdownContext must be used inside a DropdownProvider');
  }
  return context;
}

function getLabelId(id: string) {
  return `${id}-label`;
}
