export const HELP_LINKS = {
  templateGallery: {
    termsOfUse: 'https://www.getweave.com/legal/digital-forms-terms/',
  },
  writebackSetup: {
    termsOfUse: 'https://www.getweave.com/legal/digital-forms-terms/',
    contactSupport:
      'https://www.weavehelp.com/hc/en-us/p/contact-us#:~:text=What%20Is%20the%20Weave%20Customer,to%20reach%20Weave%20Customer%20Support',
  },
  contactWeave: 'https://www.weavehelp.com/hc/en-us/p/contact-us',
  trainingCourses:
    'https://trainingcamp.weavehelp.com/page/all-courses#products-features_digital-forms',
  providerMapping: {
    userRoles: 'https://www.weavehelp.com/hc/en-us/articles/5487955674395',
    howToUse: 'https://www.weavehelp.com/hc/en-us/articles/13867688079131',
  },
};
