import { useState, useEffect, Dispatch, SetStateAction } from 'react';
import { useQuery } from 'react-query';

import { useAlert } from '@weave/design-system';

import { useLocationValidityContext } from 'context';
import { queryKeys } from 'shared/constants';
import { getCurrentLocationId } from 'shared/helpers/utils';
import { CustomAxios } from 'shared/helpers/axios';
import { WritebackAuth } from 'shared/types';

export interface UseWritebackAuthResults {
  writebackAuthorization: WritebackAuth;
  isLoadingWritebackAuthorization: boolean;
  isAuthStatusChecked: boolean;
  setWritebackAuthorization: Dispatch<SetStateAction<WritebackAuth>>;
}

export const useWritebackAuth = (): UseWritebackAuthResults => {
  const { isValidating, isValidLocation } = useLocationValidityContext();

  const [writebackAuthorization, setWritebackAuthorization] =
    useState<WritebackAuth>('Invalid');
  const [isAuthStatusChecked, setIsAuthStatusChecked] = useState(false);
  const alert = useAlert();

  const { data, isLoading } = useQuery(
    [queryKeys.writebackAuth, getCurrentLocationId()],
    fetchWritebackAuth,
    {
      onError: () => {
        alert.error('Failed to get Writeback authorization status!');
      },
      enabled: !isValidating && isValidLocation,
    }
  );

  useEffect(() => {
    if (data) {
      if (!isAuthStatusChecked) {
        setIsAuthStatusChecked(true);
      }

      setWritebackAuthorization(data.status);
    }
  }, [data]);

  return {
    writebackAuthorization,
    isLoadingWritebackAuthorization: isLoading,
    isAuthStatusChecked,
    setWritebackAuthorization,
  };
};

async function fetchWritebackAuth() {
  const companyId = getCurrentLocationId();

  if (!companyId) {
    return Promise.reject('Location ID not provided!');
  }

  const response = await CustomAxios.get<{ status: WritebackAuth }>(
    '/writeback/auth/status',
    { params: { companyId } }
  );

  return response.data;
}
