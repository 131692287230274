import { createContext, FC, useContext } from 'react';

import { UseValidateLocationResults, useValidateLocation } from './useValidateLocation';

interface LocationValidityProviderProps {
  disabled?: boolean;
}

interface LocationValidityProviderContext extends UseValidateLocationResults {}

const LocationValidityContext = createContext<
  LocationValidityProviderContext | undefined
>(undefined);

export const LocationValidityProvider: FC<LocationValidityProviderProps> = ({
  children,
  disabled = false,
}) => {
  const validationResults = useValidateLocation({ disabled });

  const contextValue: LocationValidityProviderContext = {
    ...validationResults,
  };

  return (
    <LocationValidityContext.Provider value={contextValue}>
      {children}
    </LocationValidityContext.Provider>
  );
};

export const useLocationValidityContext = (): LocationValidityProviderContext => {
  const context = useContext(LocationValidityContext);

  if (context === undefined) {
    throw new Error(
      'useLocationValidityContext must be used within a <LocationValidityProvider />.'
    );
  }

  return context;
};
