import { PlusIcon, TextButton } from '@weave/design-system';
import { useFormComponentsContext } from 'context';
import { FC } from 'react';
import { pendoTags } from 'shared/constants';

interface NewConditionButtonProps {
  fieldId: string;
}
const NewConditionButton: FC<NewConditionButtonProps> = ({ fieldId }) => {
  const { orderedFields, addNewCondition } = useFormComponentsContext();
  const onAddNewCondition = () => {
    const fieldTotalIndex = orderedFields.findIndex((f) => {
      if (f.id === fieldId) return true;

      return false;
    });
    const nextField = orderedFields[fieldTotalIndex + 1];

    addNewCondition({
      fieldId: fieldId,
      ...(nextField && { targetFieldId: nextField.id }),
    });
  };

  return (
    <TextButton
      className="add-condition"
      icon={PlusIcon}
      onClick={onAddNewCondition}
      trackingId={pendoTags.newFormEditor.conditionalLogic.addCondition}
    >
      Add Condition
    </TextButton>
  );
};

export default NewConditionButton;
