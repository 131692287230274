import { FC, useRef, useState, useEffect, useCallback } from 'react';

import {
  Table,
  TableColumnConfig,
  Text,
  BannerNotification,
  TableInstance,
  Chip,
} from '@weave/design-system';

import { useWritebackCapabilitiesContext } from 'context';
import { SyncAppTypes } from 'shared/types';
import { RelativeDate, SelectedWritebackDestination } from 'shared/components';
import { formatPMSName, writebackSettingsUtils, pendoTrack } from 'shared/helpers/utils';

import SyncStatus from './sync-status/sync-status.component';
import WritebackSites from './writeback-sites/writeback-sites.component';

import {
  lastSyncStyleOverride,
  getSourceNameStyle,
  defaultWarningMessageStyle,
} from './writeback-destinations.styles';

interface WritebackDestinationsProps {
  sourceTenants: SyncAppTypes.ModifiedSourceTenant[];
  preferredSourceTenantId: string;
  triggerCancelChange?: boolean;
  hasMultiTenantSource?: boolean;
  onSelectSourceTenant: (
    sourceTenantId: string,
    tableInstance: TableInstance<SyncAppTypes.ModifiedSourceTenant>
  ) => void;
  isDisabled?: boolean;
  warningMessage?: JSX.Element | string;
  pendoTrackingId?: string;
  shouldShowSelectedWritebackDestination?: boolean;
  sourceTenantIdsWithDuplicateRecords?: string[];
}

const WritebackDestinations: FC<WritebackDestinationsProps> = ({
  sourceTenants,
  preferredSourceTenantId,
  triggerCancelChange,
  hasMultiTenantSource = false,
  onSelectSourceTenant,
  isDisabled = false,
  warningMessage = "By selecting the respective PMS / EHR, I authorize Weave to create a new patient record if it doesn't exist in the selected system.",
  pendoTrackingId,
  shouldShowSelectedWritebackDestination = false,
  sourceTenantIdsWithDuplicateRecords = [],
}) => {
  const { getSourceTenantDetails } = useWritebackCapabilitiesContext();
  const shouldIgnoreRowSelectionRef = useRef(false);
  const shouldSelectParentRowRef = useRef(false);
  const tableInstanceRef =
    useRef<TableInstance<SyncAppTypes.ModifiedSourceTenant> | null>(null);
  const [preferredSourceId, setPreferredSourceId] = useState('');

  const tableColumnConfig: TableColumnConfig<SyncAppTypes.ModifiedSourceTenant>[] = [
    {
      id: 'pmsName',
      accessor: 'id',
      Header: 'PMS',
      width: 450,
      minWidth: 300,
      maxWidth: 580,
      cellRenderer: (sourceId: string) => {
        const sourceTenant = getSourceTenantDetails.byId(sourceId);

        if (!sourceTenant) {
          return '-';
        }

        const pmsName = formatPMSName(sourceTenant.pmsName);
        const hasDuplicateRecords = sourceTenantIdsWithDuplicateRecords.includes(
          sourceTenant.sourceTenantId
        );

        return (
          <Text
            css={getSourceNameStyle({
              isPreferredSource: sourceId === preferredSourceId,
            })}
          >
            <span>
              {sourceTenant.sourceName} | <span className="pms-name">{pmsName}</span>{' '}
              <span className="pms-version">{sourceTenant.pmVersion}</span>
            </span>
            {hasDuplicateRecords && <Chip.Tag color="red">Duplicate Records</Chip.Tag>}
          </Text>
        );
      },
    },
    {
      id: 'healthStatus',
      accessor: 'id',
      Header: 'Status',
      cellRenderer: (sourceId: string) => {
        const sourceTenant = getSourceTenantDetails.byId(sourceId);

        if (!sourceTenant) {
          return '-';
        }

        return (
          <SyncStatus
            status={sourceTenant.healthStatus}
            isSupported={sourceTenant.isSupported}
          />
        );
      },
    },
    {
      id: 'lastSync',
      accessor: 'lastSyncTime',
      Header: 'Last Sync',
      cellRenderer: (value: string) => {
        if (!value) {
          return '-';
        }

        return (
          <RelativeDate date={value} showDescriptiveLabel css={lastSyncStyleOverride} />
        );
      },
    },
  ];

  const markRowAsSelected = useCallback(
    (preferredSource: SyncAppTypes.ModifiedSourceTenant) => {
      const tableInstance = tableInstanceRef.current;

      if (tableInstance && preferredSource.sites && preferredSource.sites.length > 0) {
        const rowId = preferredSource.id;
        shouldSelectParentRowRef.current = true;
        tableInstance.toggleRowSelected(rowId, true);
      }
    },
    []
  );

  useEffect(() => {
    const preferredSource = getSourceTenantDetails.bySourceTenantId(
      preferredSourceTenantId
    );

    if (preferredSource) {
      markRowAsSelected(preferredSource);

      if (preferredSource.id !== preferredSourceId) {
        setPreferredSourceId(preferredSource.id);
      }
    }
  }, [preferredSourceTenantId]);

  // This is used to mark the parent row (if applicable) as selected when
  // the user cancels the source tenant change.
  useEffect(() => {
    if (!triggerCancelChange) {
      return;
    }

    const preferredSource = getSourceTenantDetails.bySourceTenantId(
      preferredSourceTenantId
    );

    if (preferredSource) {
      markRowAsSelected(preferredSource);
    }
  }, [triggerCancelChange]);

  /**
   * Returns the initial selection state for the table.
   */
  function getInitialSelectionState(): Record<string, boolean> | undefined {
    const index = sourceTenants.findIndex((sourceTenant) => {
      if (sourceTenant.sites && sourceTenant.sites.length > 0) {
        return sourceTenant.sites.some(
          (site) => site.sourceTenantId === preferredSourceTenantId
        );
      }

      return sourceTenant.sourceTenantId === preferredSourceTenantId;
    });

    if (index === -1) {
      return undefined;
    }

    const tableRowId = sourceTenants[index].id;

    const selection = {
      [tableRowId]: true,
    };

    return selection;
  }

  function sourceSelectionHandler(
    sourceTenantId: string | undefined,
    tableInstance: TableInstance<SyncAppTypes.ModifiedSourceTenant>
  ) {
    if (!sourceTenantId) {
      return;
    }

    onSelectSourceTenant(sourceTenantId, tableInstance);
  }

  return (
    <>
      <BannerNotification
        status="warn"
        message={warningMessage}
        css={defaultWarningMessageStyle}
      />

      {shouldShowSelectedWritebackDestination && (
        <SelectedWritebackDestination sourceTenantId={preferredSourceTenantId} />
      )}

      <Table
        colConfig={tableColumnConfig}
        data={sourceTenants}
        isSelectable
        rowSelectionConfig={{
          isSingleSelect: true,
          hideBulkSelection: true,
          onSelectionToggle: (selectedRows, data, selectedIds, tableInstance) => {
            tableInstanceRef.current = tableInstance;

            if (shouldSelectParentRowRef.current) {
              shouldSelectParentRowRef.current = false;
              return;
            }

            if (data.length === 0 || shouldIgnoreRowSelectionRef.current) {
              shouldIgnoreRowSelectionRef.current = false;
              return;
            }

            if (pendoTrackingId) {
              pendoTrack(pendoTrackingId);
            }

            const source = data[0];
            const sourceTenantId = source.sourceTenantId;
            const sourceSites = source.sites ?? [];

            // Expand the row if there are multiple sites available
            if (sourceSites.length > 0) {
              const rowId = source.id;
              const lastSelectedRowId = sourceTenants.find(
                (sourceTenant) => sourceTenant.sourceTenantId === preferredSourceTenantId
              )?.id;

              // Expands the row
              tableInstance.toggleRowExpanded([rowId], true);

              // Deselects all the rows in the table
              tableInstance.toggleRowSelected(rowId, false);

              if (lastSelectedRowId) {
                // This is done to prevent the table row selection trigger when
                // the previous table selection is restored.
                shouldIgnoreRowSelectionRef.current = true;
                tableInstance.toggleRowSelected(lastSelectedRowId, true);
              }
              return;
            }

            sourceSelectionHandler(sourceTenantId, tableInstance);
          },
          initialState: getInitialSelectionState(),
          isRowDisabled: (rowData) => {
            return !rowData.isSupported;
          },
        }}
        uniqueRowId={writebackSettingsUtils.getUniqueRowId}
        rowExpandingConfig={{
          isDisabled: (rowData, rowObject) => {
            return !rowData.isSupported || !rowData.sites || rowData.sites.length === 0;
          },
        }}
        expandableRowComponent={
          hasMultiTenantSource
            ? (sourceTenant, rowValues, tableInstance) => (
                <WritebackSites
                  sourceTenant={sourceTenant}
                  preferredSourceTenantId={preferredSourceTenantId}
                  onSelectSourceTenant={(selectedSourceTenantId) => {
                    tableInstanceRef.current = tableInstance;
                    sourceSelectionHandler(selectedSourceTenantId, tableInstance);
                  }}
                  pendoTrackingId={pendoTrackingId}
                />
              )
            : undefined
        }
      ></Table>
    </>
  );
};

export default WritebackDestinations;
