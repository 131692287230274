import { FC } from 'react';
import { SerializedStyles } from '@emotion/utils';

import { IconButton } from '@weave/design-system';

import { getButtonStyle } from './custom-icon-button.style';

interface CustomIconButtonProps {
  onClick?: () => void;
  color?: 'grey';
  iconStyles?: SerializedStyles | SerializedStyles[];
  pendoTag?: string;
  label?: string;
  showLabelOnHover?: boolean;
  disabled?: boolean;
  roundedButtons?: boolean;
}

export const CustomIconButton: FC<CustomIconButtonProps> = ({
  children,
  color,
  onClick,
  iconStyles,
  pendoTag,
  showLabelOnHover,
  label,
  disabled,
  roundedButtons,
}) => {
  const iconBtnStyle = getButtonStyle({ rounded: roundedButtons, color });

  return (
    <IconButton
      css={[!!iconStyles ? [iconStyles, iconBtnStyle] : iconBtnStyle]}
      onClick={onClick}
      trackingId={pendoTag}
      label={label || ''}
      showLabelOnHover={!!showLabelOnHover}
      disabled={disabled}
    >
      {children}
    </IconButton>
  );
};
