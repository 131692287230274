import { useEffect, useState } from 'react';
import { useDebouncedValue, useFormField } from '@weave/design-system';
import { BuilderFields, BuilderFieldsOrder, IFormBuilder as FB } from 'shared/types';

export function useComponentSearch(
  fieldsOrder: BuilderFields[],
  isPrimaryField?: boolean,
  primaryFields?: FB.IPrimaryFields
) {
  const [filteredFields, setFilteredFields] = useState(fieldsOrder);

  const searchProps = useFormField({ type: 'text' });

  const searchValue = useDebouncedValue(searchProps.value);

  useEffect(() => {
    //index is important for react-beatiful-dnd
    let indexCount = 0;
    const newFieldOrder: BuilderFields[] = JSON.parse(JSON.stringify(fieldsOrder));

    if (searchValue.trim() === '') {
      if (isPrimaryField && primaryFields) {
        const hideMedicalHistoryFields =
          !primaryFields.hasOwnProperty('disease') &&
          !primaryFields.hasOwnProperty('medications') &&
          !primaryFields.hasOwnProperty('allergies');

        if (hideMedicalHistoryFields) {
          newFieldOrder.splice(0, 1);
        }
      }

      newFieldOrder.forEach((category) => {
        category.order.forEach((field) => {
          field.index = indexCount++;
        });
      });

      setFilteredFields(newFieldOrder);
    } else {
      const filteredCategories: BuilderFields[] = [];
      newFieldOrder.forEach((category) => {
        const filteredOrder: BuilderFieldsOrder[] = [];
        category.order.forEach((field) => {
          if (field.field.toLowerCase().includes(searchValue.toLowerCase())) {
            filteredOrder.push({
              ...field,
              index: indexCount++,
            });
          }
        });
        if (filteredOrder.length > 0) {
          filteredCategories.push({ ...category, order: filteredOrder });
        }
      });
      setFilteredFields(filteredCategories);
    }
  }, [searchValue, fieldsOrder]);

  return { searchProps, filteredFields };
}
