import { CustomAxios } from 'shared/helpers/axios';
import { APP_BASE_URL } from 'shared/constants';
import { getCurrentLocationId } from 'shared/helpers/utils';

export enum DocumentType {
  Unknown = 'unknown',
  Form = 'form',
  Packet = 'packet',
}

export interface FormData {
  documentType: string;
  documentId: string;
}
export interface Templates {
  id: string;
  name: string;
  type: string;
  form: FormData | null;
  locale: string;
  personStrategy: string;
}

export interface AutoMessageType {
  slug: string;
}

export interface Rules {
  id: string;
  name: string;
  templates: Templates[];
  autoMessageType: AutoMessageType;
}

export interface AutoReminderRulesResponse {
  rules: Rules[];
}

export function fetchAutoReminderRules() {
  return CustomAxios.get<AutoReminderRulesResponse>('/messaging/auto-rules/v1/rules', {
    baseURL: APP_BASE_URL,
    params: {
      orgId: getCurrentLocationId(),
      skipFilterValidation: true,
    },
  });
}
