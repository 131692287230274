import { FC } from 'react';
import { css } from '@emotion/core';

import {
  Modal,
  ModalControlModalProps,
  SecondaryButton,
  Text,
} from '@weave/design-system';
import { theme } from '@weave/theme';

import { handleLocalStorage } from 'auth/auth.helpers';

interface PMSAuthReminderModalProps {
  modalProps: ModalControlModalProps;
  onClose: () => void;
}

const PMSAuthReminderModal: FC<PMSAuthReminderModalProps> = ({ modalProps, onClose }) => {
  function closeAuthErrorMessage() {
    onClose();
    handleLocalStorage.create('pmsAuthError', { lastShown: Date.now() });
  }

  return (
    <Modal
      {...modalProps}
      disableCloseOnOverlayClick={true}
      css={css`
        padding: ${theme.spacing(2)};
      `}
      maxWidth={500}
    >
      <Modal.Header
        css={css`
          text-align: left;
        `}
      >
        Unable to connect to your system
      </Modal.Header>
      <Modal.Body>
        <Text>
          Weave is unable to connect to your system of record (PMS/EHR). To reconnect,
          login to the Weave Desktop Client and click the{' '}
          <Text as="span" weight="bold">
            Sync
          </Text>{' '}
          button.
          {/* <TextLink to="#">Refer to this</TextLink> guide for more information. */}
        </Text>
      </Modal.Body>
      <SecondaryButton
        css={css`
          width: 150px;
          margin: 1rem auto 0;
        `}
        onClick={closeAuthErrorMessage}
        size="tiny"
      >
        Close
      </SecondaryButton>
    </Modal>
  );
};

export default PMSAuthReminderModal;
