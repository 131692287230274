import { SubmissionLists } from 'shared/types';

export const useShowReadUnreadStatus = (category: SubmissionLists): boolean => {
  const categoriesToInclude: SubmissionLists[] = [
    'all-submitted',
    'completed',
    'failed-sync',
    'need-approval',
    'recent',
  ];

  return categoriesToInclude.includes(category);
};
