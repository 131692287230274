import { FC } from 'react';
import { css } from '@emotion/core';

import { BackIcon } from '@weave/design-system';

const sortIconStyle = css`
  transform: rotate(-90deg);
  cursor: pointer;

  &.asc {
    transform: rotate(90deg);
  }
`;

interface CustomSortIconProps {
  sortOrder: 'asc' | 'desc';
}

export const CustomSortIcon: FC<CustomSortIconProps> = ({ sortOrder }) => (
  <BackIcon css={sortIconStyle} className={sortOrder} />
);
