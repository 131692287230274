import { CustomAxios } from 'shared/helpers/axios';
import { getCurrentLocationId } from 'shared/helpers/utils';
import { PaginationMetadata } from 'shared/types';

export interface FormTemplateData {
  dateTime: string;
  id: string;
  name: string;
  reviewRequired?: boolean;
}

export interface FormsListResponse {
  data: FormTemplateData[];
  pageMetadata: PaginationMetadata;
  message: string;
  isDataEmpty?: boolean;
}

interface RequestParams {
  page?: number;
  search?: string;
  limit?: number;
}

/**
 * API to fetch the list of form templates.
 */
export const fetchFormTemplates = async (params: RequestParams = {}) => {
  const { page = 1, search = '', limit = 20 } = params;
  const response = await CustomAxios.get<FormsListResponse>('/forms-internal', {
    params: {
      company_id: getCurrentLocationId(),
      page,
      limit,
      search,
    },
  });

  return response.data;
};

export interface IDeleteFormPayload {
  token: string;
  formName: string;
  isNonEditable: boolean;
}

export async function deleteFormTemplate({ token, formName }: IDeleteFormPayload) {
  return CustomAxios.delete(`/forms/${token}?company_id=${getCurrentLocationId()}`);
}

export interface ToggleReviewRequiredPayload {
  token: string;
  reviewRequired: boolean;
}

export async function updateReviewRequired({
  token,
  reviewRequired,
}: ToggleReviewRequiredPayload) {
  await CustomAxios.patch('forms/review-required', {
    reviewRequired,
    companyId: getCurrentLocationId(),
    formId: token,
  });
}
