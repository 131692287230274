import { FC, useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';

import {
  BackIcon,
  FlyoutMenuAction,
  Heading,
  IconButton,
  Modal,
  MoreIcon,
  NakedButton,
  PrimaryButton,
  SecondaryButton,
  SwitchField,
  SyncIcon,
  Text,
  useControlledField,
  useFlyoutDialog,
  useFlyoutMenu,
  useHoverLabel,
  useModalControl,
  useSlideFadeTransition,
} from '@weave/design-system';

import { decodeHtml } from 'shared/helpers/utils';
import { usePhoneMode, useSendForm } from 'shared/hooks';

import HeaderActionButton from './header-action-button/header-action-button.component';
import ReviewIcon from 'assets/icons/small/thumbs-up-and-down.svg';

import {
  headerStyle,
  moreButtonStyle,
  backLinkStyle,
  flexCenterStyle,
  flyoutMenuItemStyles,
  editableTitleStyle,
  iconButtonContainerStyle,
  sendButtonStyle,
  needsApprovalToggleStyle,
  reviewIconStyle,
  needReviewFlyoutStyle,
  reviewConfirmationModalStyle,
} from './header-actions.style';
import { defaultHoverFontStyle } from 'shared/styles';
import { pendoTags } from 'shared/constants';

interface HeaderActionsProps {
  title: string;
  reviewRequired?: boolean;
  backTo?: string;
  menuActions?: FlyoutMenuAction[];
  onTitleEdit?: (value: string) => void;
  titleEditable?: boolean;
  titlePlaceholder?: string;
  titleRef?: any;
  menuPendoTag?: string;
  token?: string;
  onReviewRequiredToggle?: (value: boolean) => void;
  isPacket?: boolean;
  isEaglesoftForm?: boolean;
  onSync?: () => void;
  isSyncing?: boolean;
}

interface SplitMenuActions {
  upFront: FlyoutMenuAction[];
  asDropdown: FlyoutMenuAction[];
}

const MENU_ACTION_CUTOFF = 3;

export const HeaderActions: FC<HeaderActionsProps> = ({
  backTo,
  menuActions,
  onTitleEdit,
  title,
  titleEditable,
  titlePlaceholder,
  titleRef,
  menuPendoTag,
  token,
  reviewRequired = false,
  onReviewRequiredToggle,
  isPacket = false,
  isEaglesoftForm = false,
  onSync,
  isSyncing = false,
}) => {
  const {
    active: flyoutDialogActive,
    FlyoutDialog,
    flyoutProps: flyoutDialogProps,
    triggerProps: flyoutDialogTriggerProps,
  } = useFlyoutDialog({});

  const {
    HoverLabel: SyncButtonHoverProps,
    labelProps: syncButtonLabelProps,
    triggerProps: syncButtonTriggerProps,
  } = useHoverLabel({
    placement: 'bottom',
  });

  const {
    modalProps: reviewConfirmationModalProps,
    triggerProps: reviewConfirmationTriggerProps,
  } = useModalControl();
  const { active, FlyoutMenu, flyoutProps, triggerProps } = useFlyoutMenu();
  const transitions = useSlideFadeTransition({ active, direction: 'up' });
  const isMobileMode = usePhoneMode();
  const { SendFormInfoModal, modalProps, openModal, closeModal, sendForm } =
    useSendForm();

  const [isReviewRequired, setReviewRequired] = useState(reviewRequired);

  useEffect(() => {
    setReviewRequired(reviewRequired);
  }, [reviewRequired]);

  const {
    HoverLabel: NeedApprovalHoverLabel,
    labelProps: needApprovalHoverProps,
    triggerProps: needApprovalHoverTriggerProps,
  } = useHoverLabel({
    placement: 'left',
  });

  const needsApprovalProps = useControlledField({
    type: 'switch',
    value: isReviewRequired,
    onChange: (value) => {
      if (!value) {
        reviewConfirmationTriggerProps.onClick();
      } else if (onReviewRequiredToggle) {
        onReviewRequiredToggle(value);
      }
    },
  });

  const splitMenuActions = getSpiltMenuActions();

  function getSpiltMenuActions(): SplitMenuActions {
    let upFront: FlyoutMenuAction[] = [];
    let asDropdown: FlyoutMenuAction[] = [];

    if (menuActions && menuActions.length > MENU_ACTION_CUTOFF) {
      upFront = menuActions.slice(0, MENU_ACTION_CUTOFF - 1);
      asDropdown = menuActions.slice(MENU_ACTION_CUTOFF - 1);
    }

    return { upFront, asDropdown };
  }

  function showDesktopActions() {
    if (!menuActions) {
      return null;
    }

    if (menuActions.length > MENU_ACTION_CUTOFF) {
      return (
        <>
          {splitMenuActions.upFront.map((menuAction) => (
            <HeaderActionButton key={menuAction.label} actionItem={menuAction} />
          ))}

          <IconButton
            label="More actions"
            {...triggerProps}
            trackingId={menuPendoTag}
            showLabelOnHover
          >
            <MoreIcon />
          </IconButton>

          {transitions.map(
            ({ item, key, props }) =>
              item && (
                <FlyoutMenu
                  key={key}
                  css={flyoutMenuItemStyles}
                  {...flyoutProps}
                  actions={splitMenuActions.asDropdown}
                  style={props}
                />
              )
          )}
        </>
      );
    }

    return menuActions.map((menuAction) => (
      <HeaderActionButton key={menuAction.label} actionItem={menuAction} />
    ));
  }

  function sendFormHandler() {
    if (!token) {
      return;
    }

    openModal();
  }

  function confirmSendFormHandler() {
    if (!token) {
      return;
    }

    sendForm(token);
  }

  const onConfirmDisableReview = () => {
    if (onReviewRequiredToggle) {
      onReviewRequiredToggle(false);
      reviewConfirmationModalProps.onClose();
    }
  };

  return (
    <header css={headerStyle}>
      <div css={flexCenterStyle}>
        {backTo && (
          <NavLink css={backLinkStyle} to={backTo}>
            <BackIcon />
          </NavLink>
        )}

        {titleEditable && typeof onTitleEdit === 'function' ? (
          <input
            css={editableTitleStyle}
            onChange={({ target }) => onTitleEdit(target.value)}
            placeholder={titlePlaceholder}
            type="text"
            value={title}
            ref={titleRef}
          />
        ) : (
          <Heading level={2} className="header-title">
            {decodeHtml(title)}
          </Heading>
        )}
      </div>

      {onReviewRequiredToggle && (
        <div css={needsApprovalToggleStyle} {...needApprovalHoverTriggerProps}>
          <IconButton
            label={
              isPacket
                ? 'Lets a practitioner review the packet after patient submits it'
                : 'Lets a practitioner review the form after patient submits it'
            }
            trackingId={
              isPacket
                ? pendoTags.providerReview.toggleReviewPacket
                : pendoTags.providerReview.toggleReviewForm
            }
            {...flyoutDialogTriggerProps}
          >
            <ReviewIcon css={reviewIconStyle(isReviewRequired)} />
          </IconButton>
          {flyoutDialogActive && (
            <FlyoutDialog {...flyoutDialogProps} css={needReviewFlyoutStyle}>
              <SwitchField
                css={needsApprovalToggleStyle}
                {...needsApprovalProps}
                name="need_approval"
                label="Needs Review"
                labelPlacement="left"
              />
            </FlyoutDialog>
          )}
        </div>
      )}

      <NeedApprovalHoverLabel {...needApprovalHoverProps} css={defaultHoverFontStyle}>
        {isPacket
          ? 'Specify whether submissions of this packet require additional review by provider or forms manager'
          : 'Specify whether submissions of this form require additional review by provider or forms manager'}
      </NeedApprovalHoverLabel>

      <div css={iconButtonContainerStyle}>
        {menuActions &&
          (isMobileMode ? (
            <>
              <NakedButton
                css={moreButtonStyle}
                {...triggerProps}
                trackingId={menuPendoTag}
              >
                <MoreIcon />
              </NakedButton>

              {transitions.map(
                ({ item, key, props }) =>
                  item && (
                    <FlyoutMenu
                      key={key}
                      css={flyoutMenuItemStyles}
                      {...flyoutProps}
                      actions={menuActions}
                      style={props}
                    />
                  )
              )}
            </>
          ) : (
            showDesktopActions()
          ))}
      </div>

      {isEaglesoftForm && (
        <IconButton
          label="Sync"
          showLabelAlways
          className={`sync-button ${isSyncing ? 'syncing' : ''}`}
          onClick={onSync}
          disabled={!!isSyncing}
          {...syncButtonTriggerProps}
          trackingId={pendoTags.eaglesoftMedicalHistorySyncButton}
        >
          <SyncIcon size={24} />
        </IconButton>
      )}

      <SyncButtonHoverProps {...syncButtonLabelProps} css={defaultHoverFontStyle}>
        Get the latest default medical history form from your PMS
      </SyncButtonHoverProps>

      {token && token !== 'new' && (
        <PrimaryButton css={sendButtonStyle} onClick={sendFormHandler} size="tiny">
          Send
        </PrimaryButton>
      )}

      <Modal
        {...reviewConfirmationModalProps}
        css={reviewConfirmationModalStyle}
        minWidth={600}
      >
        <Heading level={2}>Disable Review</Heading>
        <Text>
          Are you sure that you want to disable review for this{' '}
          {isPacket ? 'packet' : 'form'}?
        </Text>
        <ul>
          <li>
            This {isPacket ? 'packet' : 'form'} maybe already in use (sent or submitted).
          </li>
          <li>
            The assigned reviewer (provider or admin) will not be able to approve or
            reject this {isPacket ? 'packet' : 'form'}.
          </li>
        </ul>
        <Text>Do you want to continue?</Text>
        <div className="action-btn">
          <SecondaryButton
            onClick={() => reviewConfirmationModalProps.onClose()}
            size="tiny"
          >
            Cancel
          </SecondaryButton>
          <PrimaryButton onClick={onConfirmDisableReview} size="tiny">
            Yes, disable review
          </PrimaryButton>
        </div>
      </Modal>

      <SendFormInfoModal
        modalProps={modalProps}
        onConfirm={confirmSendFormHandler}
        onClose={closeModal}
      />
    </header>
  );
};

export default HeaderActions;
