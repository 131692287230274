function getPlatformDetails() {
  return window.platform;
}

export function getBrowserDetails() {
  const { browser, browserMajorVersion, browserVersion } = getPlatformDetails();

  return {
    browser,
    browserMajorVersion,
    browserVersion,
  };
}

export function getOSDetails() {
  const { os, osVersion } = getPlatformDetails();

  return {
    os,
    osVersion,
  };
}

interface IPAddressResponse {
  ip: string;
}

async function fetchPublicIPAddress() {
  try {
    const fetchResponse = await fetch('https://api.ipify.org/?format=json');
    const jsonData = await fetchResponse.json();
    return jsonData as IPAddressResponse;
  } catch {
    console.log('Failed to get the IP address');
    return { ip: '' };
  }
}

export class IPAddress {
  private static instance: IPAddress;
  private ipAddress = '';

  private constructor() {}

  public static getInstance() {
    if (!IPAddress.instance) {
      IPAddress.instance = new IPAddress();
    }

    return IPAddress.instance;
  }

  public async getIPAddress() {
    const ipAddress = IPAddress.getInstance().ipAddress;

    if (ipAddress) {
      return ipAddress;
    }

    const data = await fetchPublicIPAddress();
    IPAddress.getInstance().ipAddress = data.ip;
    return data.ip;
  }
}
