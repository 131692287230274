import { useHistory, useParams } from 'react-router';
import { useEffect, useState, FC } from 'react';

import {
  ButtonBar,
  ContentLoader,
  Modal,
  PrimaryButton,
  SecondaryButton,
  TextField,
  useDebouncedValue,
  useFormField,
  useHoverLabel,
  useModalControl,
  useAlert,
} from '@weave/design-system';

import { useFormComponentsContext } from 'context';
import { FormPreview } from 'shared/components';
import { getCurrentLocationId, containsSpecialCharacters } from 'shared/helpers/utils';
import { saveFormTemplate } from 'shared/helpers/axios/apis';
import { BuilderContext, IFormBuilder as FB } from 'shared/types';
import { b64toBlob } from 'shared/helpers/download';
import { FORM_EDITOR_BASE } from 'shared/constants/paths';
import { pendoTags } from 'shared/constants';
import { defaultHoverFontStyle } from 'shared/styles';

import {
  formViewerNameEditor,
  formViewerNameEditorWrapper,
  formControlButtonStyles,
  formControlStyles,
  formViewerRootStyles,
  formViewerContent,
  previewFormModalStyle,
  getFormViewerButtonStyle,
  getFormSaveButtonStyle,
  formControlButtonWrapperStyle,
} from './editor-view.styles';
import { SectionButtons } from '../section-buttons/section-buttons.component';
import { FormSection } from '../section/section.component';

import { generatePDF } from '../../form-editor.api';

const locationId = getCurrentLocationId();

export const EditorView: FC = () => {
  const { formState, updateFormState, setInvalidConditions } = useFormComponentsContext();
  const { formId } = useParams<{ formId: string | undefined }>();
  const history = useHistory();
  const { form } = formState;
  const alert = useAlert();
  const [loader, setLoader] = useState<{ show: boolean; message: string }>({
    show: false,
    message: '',
  });
  const { modalProps, openModal } = useModalControl();
  const [actionButtonStatus, setActionButtonStatus] = useState({
    disabled: true,
    errorMessage: '',
  });

  const [isSavingForm, setIsSavingForm] = useState(false);

  const formNameProps = useFormField(
    {
      type: 'text',
      value: form.name,
    },
    [form.name]
  );

  const {
    HoverLabel: PDFHoverLabel,
    labelProps: pdfLabelProps,
    triggerProps: pdfTriggerProps,
  } = useHoverLabel({
    placement: 'left',
  });
  const {
    HoverLabel: PreviewHoverLabel,
    labelProps: previewLabelProps,
    triggerProps: previewTriggerProps,
  } = useHoverLabel({
    placement: 'left',
  });
  const {
    HoverLabel: SaveFormHoverLabel,
    labelProps: saveFormLabelProps,
    triggerProps: saveFormTriggerProps,
  } = useHoverLabel({
    placement: 'left',
  });

  useEffect(() => {
    if (!form.name.trim()) {
      setActionButtonStatus({
        disabled: true,
        errorMessage: 'Please give the form a name',
      });
      return;
    }

    if (!Object.keys(formState.fields).length) {
      setActionButtonStatus({
        disabled: true,
        errorMessage: 'Please add some fields to the form',
      });
      return;
    }

    const isAnySectionEmpty = Object.keys(formState.sections).some((sectionId) => {
      // old form could have sections with no fields
      return (formState.sections[sectionId].fields?.length ?? 0) === 0;
    });

    if (isAnySectionEmpty) {
      setActionButtonStatus({
        disabled: true,
        errorMessage:
          'Section cannot be empty. Add at least one field or remove the section before saving the form',
      });

      return;
    }

    setActionButtonStatus({
      disabled: false,
      errorMessage: '',
    });
  }, [formState]);

  const debouncedFormNameValue = useDebouncedValue(formNameProps.value, 500);

  useEffect(() => {
    updateFormState({
      type: BuilderContext.FormUpdateType.UPDATE_FORM_NAME,
      data: {
        name: debouncedFormNameValue,
      },
    });
  }, [debouncedFormNameValue]);

  const requireFormBasics = () => {
    if (!form.name.trim()) {
      alert.error('Please give the form a name.');
      return false;
    }

    // Check for special characters
    if (containsSpecialCharacters(form.name)) {
      alert.error(
        "Form name contains prohibited special characters. Only these '-' ,'_', '(', ')' are allowed."
      );
      return false;
    }

    if (!Object.keys(formState.fields).length) {
      alert.error('Please add some fields to the form.');
      return false;
    }

    const isAnySectionEmpty = Object.keys(formState.sections).some((sectionId) => {
      return formState.sections[sectionId].fields.length === 0;
    });

    if (isAnySectionEmpty) {
      return false;
    }

    const invalidConditions: Record<string, boolean> = {};

    let areConditionsValid = true;
    (formState.conditions || []).forEach((condition) => {
      const areTermsValid = condition.terms.every((term) => {
        // value isn't required if check is 'answered'
        if (term.check === 'answered') return true;

        return term.value !== '';
      });

      const areActionsValid =
        condition.actions.length > 0 &&
        condition.actions.every((action) => {
          return action.target && action.operation;
        });

      if (!areTermsValid || !areActionsValid) {
        invalidConditions[condition.id] = true;
        areConditionsValid = false;
      }
    });

    if (!areConditionsValid) {
      alert.error('Please make sure all conditions are valid.');
      setInvalidConditions(invalidConditions);
      return false;
    }

    return true;
  };

  const getFormattedFields = (fields: Record<string, FB.Field>) => {
    const formattedFields = {};

    Object.keys(fields).forEach((key) => {
      let isFormatted = false;

      if (fields[key].hasOwnProperty('options')) {
        formattedFields[key] = {
          ...fields[key],
          options: JSON.stringify([...(fields[key] as FB.BaseOptionsField).options]),
        };

        isFormatted = true;
      }

      if (fields[key].meta.type === 'checkbox') {
        formattedFields[key] = {
          ...fields[key],
          value: JSON.stringify(fields[key].value), //converts boolean to string
        };

        isFormatted = true;
      }

      if (
        (!!fields[key].value &&
          (fields[key].value.hasOwnProperty('front') ||
            fields[key].value.hasOwnProperty('font_type'))) ||
        Array.isArray(fields[key].value)
      ) {
        if (formattedFields[key]) {
          formattedFields[key].value = JSON.stringify(fields[key].value);
        } else {
          formattedFields[key] = {
            ...fields[key],
            value: JSON.stringify(fields[key].value),
          };
        }
        isFormatted = true;
      }

      if (!isFormatted) {
        formattedFields[key] = {
          ...fields[key],
        };
      }
    });
    return formattedFields;
  };

  const onFormSave = async (onlySaveFrom: boolean = true): Promise<boolean> => {
    setIsSavingForm(true);
    if (requireFormBasics()) {
      // cleanState();
      // make sure we have a form id stored in the json data

      let templateData = {
        data: {
          ...formState,
          form: {
            ...formState.form,
            // ...(formId && { id: formId }),
            ...(!formState.form.company_id && { company_id: locationId }),
          },
          fields: getFormattedFields(formState.fields),
        },
        ...(formState.form.id && { form_id: formState.form.id }),
      };

      try {
        const saveResponse = await saveFormTemplate(templateData);

        if (saveResponse.success) {
          // if formId is absent then append form id to route
          onlySaveFrom && alert.success('Form saved successfully');

          if (formState.form.id === '') {
            updateFormState({
              type: BuilderContext.FormUpdateType.SET_FORM_ID,
              data: {
                formId: saveResponse.formId,
              },
            });

            history.replace(`${FORM_EDITOR_BASE}/${saveResponse.formId}`);
          }
          setIsSavingForm(false);
          return true;
        } else {
          onlySaveFrom && alert.error('Failed to save form');
        }
      } catch (error) {
        setIsSavingForm(false);
        onlySaveFrom && alert.error('Failed to save form');
      }
    }
    setIsSavingForm(false);
    return false;
  };

  const onGeneratePDF = async () => {
    setLoader({ message: 'Generating PDF', show: true });
    const formSaved = await onFormSave(false);
    if (formSaved && formId) {
      try {
        const generatedPDF = await generatePDF(formId);
        const blob = b64toBlob(generatedPDF.file, 'application/pdf');
        const fileURL = URL.createObjectURL(blob);
        const pdfWindow = window.open(fileURL, '_blank');

        if (pdfWindow) {
          const interval = setInterval(() => {
            if (pdfWindow.closed) {
              URL.revokeObjectURL(fileURL);
              clearInterval(interval);
            }
          }, 500);
        }
      } catch (error) {
        alert.error('Failed to generate PDF.');
      }
    }
    setLoader({ message: '', show: false });
  };

  const onPreviewForm = async () => {
    setLoader({ message: 'Generating Preview', show: true });
    const formSaved = await onFormSave(false);

    if (formSaved) {
      openModal();
    }

    setLoader({ message: '', show: false });
  };

  return (
    <div css={formViewerRootStyles}>
      <div css={formControlStyles}>
        <div css={formViewerNameEditorWrapper}>
          <TextField
            css={formViewerNameEditor}
            placeholder="Form Name"
            name="form_name"
            label=""
            {...formNameProps}
            autoComplete="off"
          />
        </div>

        <ButtonBar css={formControlButtonStyles}>
          <div {...pdfTriggerProps} css={formControlButtonWrapperStyle}>
            <SecondaryButton
              css={getFormViewerButtonStyle(actionButtonStatus.disabled)}
              onClick={onGeneratePDF}
              trackingId={pendoTags.newFormEditor.generatePDF}
              disabled={actionButtonStatus.disabled}
            >
              Generate PDF
            </SecondaryButton>
          </div>

          {actionButtonStatus.disabled && (
            <PDFHoverLabel {...pdfLabelProps} css={defaultHoverFontStyle}>
              {actionButtonStatus.errorMessage}
            </PDFHoverLabel>
          )}

          <div {...previewTriggerProps} css={formControlButtonWrapperStyle}>
            <SecondaryButton
              css={getFormViewerButtonStyle(actionButtonStatus.disabled)}
              onClick={onPreviewForm}
              trackingId={pendoTags.newFormEditor.previewForm}
              disabled={actionButtonStatus.disabled}
            >
              Preview
            </SecondaryButton>
          </div>

          {actionButtonStatus.disabled && (
            <PreviewHoverLabel {...previewLabelProps} css={defaultHoverFontStyle}>
              {actionButtonStatus.errorMessage}
            </PreviewHoverLabel>
          )}

          <div {...saveFormTriggerProps} css={formControlButtonWrapperStyle}>
            <PrimaryButton
              css={getFormSaveButtonStyle(actionButtonStatus.disabled || isSavingForm)}
              onClick={() => {
                onFormSave();
              }}
              trackingId={pendoTags.newFormEditor.saveForm}
              disabled={actionButtonStatus.disabled || isSavingForm}
            >
              Save Form
            </PrimaryButton>
          </div>

          {actionButtonStatus.disabled && (
            <SaveFormHoverLabel {...saveFormLabelProps} css={defaultHoverFontStyle}>
              {actionButtonStatus.errorMessage}
            </SaveFormHoverLabel>
          )}
        </ButtonBar>
      </div>

      <div css={formViewerContent}>
        <div id="form_view" className="form-content">
          {form.sections.map((sectionId, index) => (
            <FormSection
              key={sectionId}
              sectionId={sectionId}
              totalSections={form.sections.length}
              sectionIndex={index}
            />
          ))}
        </div>
        <SectionButtons />
      </div>

      <ContentLoader show={loader.show} message={loader.message} />

      <Modal {...modalProps} maxWidth={800} css={previewFormModalStyle}>
        <FormPreview token={formId as string} type="form" />
      </Modal>
    </div>
  );
};
