import { css } from '@emotion/core';
import { theme } from '@weave/theme';
import { NoteType } from './inline-alert.component';

export const getAlertStyle = (type: NoteType) => css`
  display: flex;
  gap: ${theme.spacing(1)};
  padding-left: ${theme.spacing(2)};
  padding-right: ${theme.spacing(2)};
  align-items: center;
  width: fit-content;
  border-radius: ${theme.borderRadius.medium};
  border: 1px solid;
  max-width: 700px;
  background-color: ${getBackgroundColor(type)};
  border-color: ${getBorderColor(type)};
  margin: ${theme.spacing(2, 0)};
`;

const getBackgroundColor = (type: NoteType) => {
  switch (type) {
    case 'error':
      return theme.colors.critical10;
    case 'success':
      return theme.colors.success10;
    case 'warning':
      return theme.colors.warning5;
    case 'info':
      return theme.colors.primary5;
  }
};

const getBorderColor = (type: NoteType) => {
  switch (type) {
    case 'error':
      return theme.colors.critical50;
    case 'success':
      return theme.colors.success50;
    case 'warning':
      return theme.colors.warning50;
    case 'info':
      return theme.colors.primary50;
  }
};
