import { FC, useState } from 'react';
import { NavLinkProps, useHistory, useLocation } from 'react-router-dom';

import { Tabs, useHoverLabel, Text } from '@weave/design-system';

import { defaultHoverFontStyle } from 'shared/styles';

import { tabStyle } from './custom-tab-nav.style';

export interface CustomTabNavLink extends NavLinkProps {
  label: string | JSX.Element;
  count?: number | string;
  abbreviatedCount?: number | string;
  helperText?: JSX.Element | string;
  pendoTag?: string;
}

interface CustomTabNavProps {
  navLinks: CustomTabNavLink[];
}

export const CustomTabNav: FC<CustomTabNavProps> = ({ navLinks }) => {
  const history = useHistory();
  const { pathname } = useLocation();
  const [initialTab] = useState(getInitialTabState());

  function getInitialTabState() {
    if (navLinks.length === 0) {
      return pathname;
    }

    const firstTabId = navLinks[0].to.toString();
    return pathname !== firstTabId ? pathname : firstTabId;
  }

  function tabChangeHandler(tabId: string) {
    history.push(tabId);
  }

  return (
    <Tabs initialTab={initialTab} onChange={tabChangeHandler}>
      <Tabs.Bar>
        {navLinks.map((navLink, index) => (
          <CustomTabNavContent navLink={navLink} key={index} />
        ))}
      </Tabs.Bar>
    </Tabs>
  );
};

interface CustomTabNavContentProps {
  navLink: CustomTabNavLink;
}

const CustomTabNavContent: FC<CustomTabNavContentProps> = ({ navLink }) => {
  const { to, label, helperText, count, abbreviatedCount, pendoTag } = navLink;
  const { labelProps, triggerProps, HoverLabel } = useHoverLabel({ placement: 'top' });

  function shouldShowCount() {
    return count !== undefined && count !== 0;
  }

  return (
    <Tabs.Tab data-trackingid={pendoTag} controls="" id={to.toString()} css={tabStyle}>
      <div {...triggerProps} className="tab-content">
        {shouldShowCount() && (
          <Text as="span" className="count">
            {abbreviatedCount || count}
          </Text>
        )}
        {label}
      </div>

      {helperText && (
        <HoverLabel {...labelProps} css={defaultHoverFontStyle}>
          {helperText}
        </HoverLabel>
      )}
    </Tabs.Tab>
  );
};
