import { format } from 'date-fns';

import { ModalControlResponse, useModalControl } from '@weave/design-system';

import { useCustomizationFlags } from 'shared/hooks';

import FreeTrial from './free-trial.component';

interface UseFreeTrialResults {
  FreeTrial: typeof FreeTrial;
  isInFreeTrial: () => boolean;
  freeTrialModalControls: ModalControlResponse;
  getFreeTrialEndDate: () => string;
}

export const useFreeTrial = (): UseFreeTrialResults => {
  const modalControls = useModalControl();
  const { isLoadingCustomizationFlags, getFormsCustomizationFlag } =
    useCustomizationFlags();

  const formsCustomizationFlag = getFormsCustomizationFlag();

  function isInFreeTrial() {
    if (isLoadingCustomizationFlags) {
      return false;
    }

    return formsCustomizationFlag?.isFreeTrial ?? false;
  }

  function getFreeTrialEndDate() {
    if (isLoadingCustomizationFlags) {
      return '';
    }

    const endDate = formsCustomizationFlag?.endDate ?? null;

    if (!endDate) {
      return '';
    }

    return format(new Date(endDate), 'MMMM do');
  }

  return {
    FreeTrial,
    isInFreeTrial,
    freeTrialModalControls: modalControls,
    getFreeTrialEndDate,
  };
};
