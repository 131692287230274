import { useMutation, useQueryClient, UseMutateAsyncFunction } from 'react-query';
import { AxiosResponse } from 'axios';

import { useAlert } from '@weave/design-system';

import { ProviderMapping } from 'shared/helpers/axios/apis';
import { queryKeys } from 'shared/constants';

export interface UseProviderMappingMutationsResults {
  bulkSaveProviderMapping: UseMutateAsyncFunction<
    {
      success: boolean;
    }[],
    unknown,
    BulkSaveMappingPayload,
    unknown
  >;
  isBulkSavingMapping: boolean;
}

export const useProviderMappingMutations = (): UseProviderMappingMutationsResults => {
  const alerts = useAlert();
  const queryClient = useQueryClient();

  const { mutateAsync: bulkSaveProviderMapping, isLoading: isBulkSavingMapping } =
    useMutation(bulkSaveMapping, {
      onSuccess: (response) => {
        for (const { success } of response) {
          if (!success) {
            alerts.error('Failed to save provider mapping!');
            return;
          }
        }

        alerts.success('Provider mapping saved successfully!');
        return invalidateMappedData();
      },
    });

  function invalidateMappedData() {
    return queryClient.invalidateQueries(queryKeys.providerMapping.mapping);
  }

  return {
    bulkSaveProviderMapping,
    isBulkSavingMapping,
  };
};

interface BulkSaveMappingPayload {
  mappingToUpdate: ProviderMapping.Types.ProviderMapping[];
  mappingToDelete: ProviderMapping.Types.ProviderMapping[];
}

function bulkSaveMapping({ mappingToUpdate, mappingToDelete }: BulkSaveMappingPayload) {
  const payloadToUpdate: ProviderMapping.Types.ProviderMappingPayload[] = [];
  const payloadToDelete: string[] = [];

  for (const { email, name, id, userId } of mappingToUpdate) {
    if (!email || !userId) {
      continue;
    }

    payloadToUpdate.push({
      name: name,
      userId: userId,
      email: email,
      id: id,
    });
  }

  for (const { id } of mappingToDelete) {
    if (!id) {
      continue;
    }

    payloadToDelete.push(id);
  }

  const promises: Array<Promise<{ success: boolean }>> = [];

  if (payloadToUpdate.length) {
    promises.push(ProviderMapping.API.bulkProviderMappingUpdate(payloadToUpdate));
  }

  if (payloadToDelete.length) {
    promises.push(ProviderMapping.API.bulkProviderMappingDelete(payloadToDelete));
  }

  return Promise.all(promises);
}
