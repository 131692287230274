import { FC } from 'react';
import { useHistory } from 'react-router-dom';

import { Text, IconButton, XIcon } from '@weave/design-system';

import { useWritebackSetupContext, useWritebackCapabilitiesContext } from 'context';
import { CustomModalButtons } from 'shared/components';
import { pendoTags } from 'shared/constants';
import { SETTINGS, FORM_SUBMISSION_CATEGORIES } from 'shared/constants/paths';
import { formatPMSName } from 'shared/helpers/utils';

import {
  textStyle,
  syncAppNameStyle,
  pmsNameStyle,
  closeIconButtonStyle,
} from './review.styles';

const ReviewScreen: FC = () => {
  const history = useHistory();
  const {
    selectedSourceTenantId,
    onFinishWritebackSetup,
    closeModal: onCloseModal,
    hasCustomUserAccess,
  } = useWritebackSetupContext();

  const { getSourceTenantDetails } = useWritebackCapabilitiesContext();

  const selectedSourceTenant =
    getSourceTenantDetails.bySourceTenantId(selectedSourceTenantId);

  const sourceName = selectedSourceTenant?.sourceName || '';
  const pmsName = selectedSourceTenant?.pmsName || '';

  async function goToWBSettingsHandler() {
    await onFinishWritebackSetup();
    history.push(SETTINGS.writebacks);
    onCloseModal();
  }

  async function goToDashboardHandler() {
    await onFinishWritebackSetup();
    history.push(FORM_SUBMISSION_CATEGORIES.allSubmitted);
    onCloseModal();
  }

  return (
    <>
      {hasCustomUserAccess && (
        <IconButton css={closeIconButtonStyle} onClick={onCloseModal} label="">
          <XIcon color="default" />
        </IconButton>
      )}

      <Text css={textStyle}>
        <b>Your form submissions will writeback (sync) automatically to</b>{' '}
        <span css={syncAppNameStyle}>{sourceName}</span>{' '}
        <span css={pmsNameStyle}>({formatPMSName(pmsName)})</span>. While you can change
        the writeback settings anytime, we highly recommend that you review them before
        you start using Digital Forms.
      </Text>

      <CustomModalButtons
        primaryButtonText="Go To Writeback Settings"
        onClickPrimaryButton={goToWBSettingsHandler}
        primaryButtonTrackingId={pendoTags.writebackAuthSetup.goToWBSettings}
        secondaryButtonText="Go to Dashboard"
        onClickSecondaryButton={goToDashboardHandler}
        secondaryButtonTrackingId={pendoTags.writebackAuthSetup.goToDashboard}
      />
    </>
  );
};

export default ReviewScreen;
