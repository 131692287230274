import { useQuery } from 'react-query';

import { queryKeys } from 'shared/constants';
import { ProviderMapping } from 'shared/helpers/axios/apis';

interface UseUsersProps {
  enabled?: boolean;
}

export interface UseUsersResults {
  users: ProviderMapping.Types.User[];
  isLoadingUsers: boolean;
}

const EMPTY_ARRAY: ProviderMapping.Types.User[] = [];

export const useUsers = (props: UseUsersProps = { enabled: true }): UseUsersResults => {
  const { data, isLoading } = useQuery(
    queryKeys.providerMapping.list,
    ProviderMapping.API.fetchUsers,
    {
      enabled: props.enabled,
      retry: 1,
    }
  );

  const users = data ?? EMPTY_ARRAY;

  return {
    users,
    isLoadingUsers: isLoading,
  };
};
