import { PhoneField, useFormField } from '@weave/design-system';
import { FC } from 'react';

interface BuilderPhoneProps {
  label: string;
  name: string;
}

export const BuilderPhoneField: FC<BuilderPhoneProps> = ({ label, name }) => {
  const fieldProps = useFormField({
    type: 'phone',
  });
  return <PhoneField name={name} label={label} {...fieldProps} />;
};
