import { FC, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import {
  Modal,
  ModalControlModalProps,
  IconButton,
  XIcon,
  Heading,
  Text,
  PlusIcon,
} from '@weave/design-system';

import { useWritebackCapabilitiesContext } from 'context';
import FormATemplateIcon from 'assets/svgs/template-gallery/from-a-template.svg';
import FormEaglesoft from 'assets/svgs/template-gallery/from-eaglesoft.svg';
import { WEAVE_TEMPLATES } from 'shared/constants/paths';
import { useLocationChildren } from 'shared/hooks';

import {
  modalStyle,
  headerStyle,
  closeButtonStyle,
  bodyStyle,
  headingStyle,
  buttonsContainerStyle,
  buttonContainerStyle,
  fromScratchButtonStyle,
  fromTemplateButtonStyle,
  textStyle,
} from './form-creation-ways-modal.styles';
import { EAGLESOFT_PMS } from 'shared/constants';

interface FormCreationWaysModalProps {
  modalProps: ModalControlModalProps;
  onClose: () => void;
  onSelectFormBuilder: () => void;
  onEaglesoftMultiImport: () => void;
}

const FormCreationWaysModal: FC<FormCreationWaysModalProps> = ({
  modalProps,
  onClose,
  onSelectFormBuilder,
  onEaglesoftMultiImport,
}) => {
  const [isEaglesoftLocation, setIsEaglesoftLocation] = useState(false);
  const history = useHistory();

  const { supportedSourceTenants } = useWritebackCapabilitiesContext();
  const { isParentLocation, isUnifyChildLocation } = useLocationChildren();

  useEffect(() => {
    if (supportedSourceTenants.length > 0 && !isUnifyChildLocation && !isParentLocation) {
      const eaglesoftLocations = supportedSourceTenants.filter((sourceTenant) => {
        return sourceTenant.pmsName.includes(EAGLESOFT_PMS);
      });

      // don't show the eaglesoft option if there are multiple eaglesoft locations
      if (eaglesoftLocations.length === 1) {
        setIsEaglesoftLocation(true);
      }
    }
  }, [supportedSourceTenants, isUnifyChildLocation, isParentLocation]);

  function selectFormBuilderHandler() {
    onClose();
    onSelectFormBuilder();
  }

  function selectEaglesoftMultiImportHandler() {
    onClose();
    onEaglesoftMultiImport();
  }

  function goToFormTemplatesHandler() {
    history.push(WEAVE_TEMPLATES.root);
  }

  return (
    <Modal {...modalProps} css={modalStyle} minWidth={isEaglesoftLocation ? 700 : 530}>
      <Modal.Header css={headerStyle}>
        <IconButton label="" onClick={onClose} css={closeButtonStyle}>
          <XIcon />
        </IconButton>
      </Modal.Header>

      <Modal.Body css={bodyStyle}>
        <Heading level={2} css={headingStyle}>
          Create New Form
        </Heading>

        <div css={buttonsContainerStyle}>
          <div css={buttonContainerStyle}>
            <button css={fromScratchButtonStyle} onClick={selectFormBuilderHandler}>
              <span>
                <PlusIcon color="white" />
              </span>
            </button>
            <Text css={textStyle}>From Scratch</Text>
          </div>

          <div css={buttonContainerStyle}>
            <button css={fromTemplateButtonStyle} onClick={goToFormTemplatesHandler}>
              <FormATemplateIcon />
            </button>
            <Text css={textStyle}>From a Template</Text>
          </div>

          {isEaglesoftLocation && (
            <div css={buttonContainerStyle}>
              <button
                css={fromTemplateButtonStyle}
                onClick={selectEaglesoftMultiImportHandler}
                data-trackingid="forms-eaglesoft-multi-import"
              >
                <FormEaglesoft />
              </button>
              <Text css={textStyle}>From Eaglesoft</Text>
            </div>
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default FormCreationWaysModal;
