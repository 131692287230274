import { SelectedItemIds } from './selection.types';

export function areAllItemsSelected(params: {
  loadedItemIds: Record<string, boolean>;
  selectedItems: SelectedItemIds;
}) {
  const { loadedItemIds, selectedItems } = params;

  for (const id in loadedItemIds) {
    if (!selectedItems[id]) {
      return false;
    }
  }

  return true;
}
