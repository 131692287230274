import { useFormsUpload, UseFormUploadResults } from './useFormUpload';
import { useDigitizationFee, UseDigitizationFeeResults } from './useDigitizationFee';
import FormDigitizationSetting from './forms-digitization-setting/form-digitization-setting.component';

interface UseFormsDigitizationResults {
  formsUploadProps: UseFormUploadResults;
  digitizationFeeProps: UseDigitizationFeeResults;
  FormDigitizationSetting: typeof FormDigitizationSetting;
}

export const useFormsDigitization = (): UseFormsDigitizationResults => {
  const formsUploadProps = useFormsUpload();
  const digitizationFeeProps = useDigitizationFee(formsUploadProps.canUploadForms);

  return {
    formsUploadProps,
    digitizationFeeProps,
    FormDigitizationSetting,
  };
};
