import { FC } from 'react';

import {
  Modal,
  ModalControlResponse,
  PrimaryButton,
  IconButton,
  XIcon,
  Text,
  TextLink,
} from '@weave/design-system';

import CrownIcon from 'assets/icons/small/crown.svg';
import { HELP_LINKS, APP_ENVIRONMENT } from 'shared/constants';

import {
  buttonStyle,
  modalStyle,
  closeModalButtonStyle,
  titleStyle,
  headerStyle,
  messageContainerStyle,
  subscriptionContainerStyle,
  manageSubscriptionButtonStyle,
} from './free-trial.styles';

interface FreeTrialProps {
  modalControls: ModalControlResponse;
  endDate: string;
}

const FreeTrial: FC<FreeTrialProps> = ({ modalControls, endDate }) => {
  function handleManageSubscription() {
    let baseUrl = 'https://app.getweave.com';

    if (APP_ENVIRONMENT === 'development') {
      baseUrl = 'https://app.weavedev.net/';
    }

    window.open(`${baseUrl}/portal/subscriptions`, '_blank', 'noopener');
  }

  return (
    <>
      <PrimaryButton css={buttonStyle} onClick={modalControls.openModal}>
        <CrownIcon /> Free Trial
      </PrimaryButton>

      <Modal {...modalControls.modalProps} maxWidth={500} css={modalStyle}>
        <Modal.Header css={headerStyle}>
          <Text as="span" css={titleStyle}>
            Your Digital Forms free trial ends <span className="date">{endDate}</span>
          </Text>

          <IconButton
            label=""
            css={closeModalButtonStyle}
            onClick={modalControls.closeModal}
          >
            <XIcon />
          </IconButton>
        </Modal.Header>

        <Modal.Body>
          <div css={messageContainerStyle}>
            <Text>
              Get the most out of your trial by configuring your settings and completing
              our Digital Forms training courses.
            </Text>

            <div className="links">
              <TextLink to={HELP_LINKS.trainingCourses} target="_blank">
                Training Courses
              </TextLink>
            </div>
          </div>

          <div css={subscriptionContainerStyle}>
            <Text className="text">
              Upgrade your plan to include your Digital Form subscription now
            </Text>

            <PrimaryButton
              size="tiny"
              css={manageSubscriptionButtonStyle}
              onClick={handleManageSubscription}
            >
              Manage Subscription
            </PrimaryButton>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default FreeTrial;
