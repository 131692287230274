import { WritebackSetupStep } from 'shared/types';

export const WRITEBACK_SETUP_STEPS = {
  multiPMSLocation: [
    WritebackSetupStep.WELCOME,
    WritebackSetupStep.SELECT_DESTINATION,
    WritebackSetupStep.REVIEW,
  ],
  singlePMSLocation: [WritebackSetupStep.WELCOME, WritebackSetupStep.REVIEW],
};
