import { TextField, useFormField } from '@weave/design-system';
import { FC } from 'react';

interface BuilderTextFieldProps {
  label: string;
  name: string;
}

export const BuilderTextField: FC<BuilderTextFieldProps> = ({ name }) => {
  const fieldProps = useFormField({
    type: 'text',
  });
  return <TextField name={name} label={''} {...fieldProps} />;
};
