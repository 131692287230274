import { CustomAxios } from 'shared/helpers/axios';
import { getCurrentLocationId } from 'shared/helpers/utils';

export interface ICreatePacket {
  forms_ids: string[];
  name: string;
  is_transient?: boolean;
}

interface ICreatePacketResponse {
  message: string;
  packetId: string;
}

export async function createPacket(payload: ICreatePacket) {
  return CustomAxios.post<ICreatePacketResponse>(
    `/companies/${getCurrentLocationId()}/packets`,
    payload
  );
}

export interface IUpdatePacket {
  forms_ids: string[];
  name: string;
  packet_id: string;
}

export async function updatePacket(payload: IUpdatePacket) {
  return CustomAxios.put(`/companies/${getCurrentLocationId()}/packets`, payload);
}

export interface IPacketReviewRequiredPayload {
  packetId: string;
  reviewRequired: boolean;
}

export async function updateReviewRequiredStatus({
  packetId,
  reviewRequired,
}: IPacketReviewRequiredPayload) {
  return CustomAxios.patch('/packets/review-required', {
    companyId: getCurrentLocationId(),
    packetId,
    review_required: reviewRequired,
  });
}
