import { FC } from 'react';

import { SwitchField } from '@weave/design-system';
import { BasicBoolFieldProps } from '@weave/design-system';

import { customSwitchStyle } from './custom-switch.style';

interface CustomSwitchFieldProps extends BasicBoolFieldProps<'switch'> {}

export const CustomSwitchField: FC<CustomSwitchFieldProps> = ({
  ...designSystemProps
}) => {
  const { value } = designSystemProps;

  return <SwitchField {...designSystemProps} css={value && customSwitchStyle} />;
};
