import { useMutation, useQueryClient, UseMutateAsyncFunction } from 'react-query';

import { useAlert } from '@weave/design-system';

import { queryKeys } from 'shared/constants';
import { WritebackAuth } from 'shared/types';
import { getCurrentLocationId } from 'shared/helpers/utils';
import { CustomAxios } from 'shared/helpers/axios';

export interface UseUpdateWritebackAuthResults {
  updateWritebackAuth: UseMutateAsyncFunction<
    any,
    unknown,
    UpdateWriteBackStatusPayload,
    unknown
  >;
  isUpdatingWritebackAuth: boolean;
}

export const useUpdateWritebackAuth = (): UseUpdateWritebackAuthResults => {
  const queryClient = useQueryClient();
  const alert = useAlert();

  const { mutateAsync, isLoading } = useMutation(updateWritebackAuthorization, {
    onSuccess: async (_, payload) => {
      await invalidateQueries();
      alert.success(
        `Writebacks are now ${
          payload.status === 'Authorised' ? 'authorized' : 'deauthorized'
        }!`
      );
    },
    onError: async (_, payload) => {
      await invalidateQueries();
      alert.error(
        `Failed to ${
          payload.status === 'Authorised' ? 'authorize' : 'deauthorize'
        } the Writebacks!`
      );
    },
  });

  function invalidateQueries() {
    return queryClient.invalidateQueries(queryKeys.writebackAuth);
  }

  return {
    updateWritebackAuth: mutateAsync,
    isUpdatingWritebackAuth: isLoading,
  };
};

interface UpdateWriteBackStatusPayload {
  status: WritebackAuth;
  previousStatus: WritebackAuth;
}

async function updateWritebackAuthorization(payload: UpdateWriteBackStatusPayload) {
  const companyId = getCurrentLocationId();

  if (!companyId) {
    return Promise.reject('Location ID not provided!');
  }

  return await CustomAxios.put('/writeback/auth/status', {
    company_id: companyId,
    ...payload,
  });
}
