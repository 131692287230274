import { useQuery, UseQueryResult } from 'react-query';

import { useAuth } from 'auth/auth-provider';
import { useWritebackCapabilitiesContext, useLocationValidityContext } from 'context';
import { queryKeys } from 'shared/constants';
import { fetchUnreadCount } from 'shared/helpers/axios/apis';
import { getCurrentLocationId, getPastUnixTime } from 'shared/helpers/utils';
import { UnreadCountResponse } from 'shared/types';

import { useUserACL } from '../use-user-ACL.hook';

export const useUnreadCount = (): UseQueryResult<UnreadCountResponse, any> => {
  const { isValidating, isValidLocation } = useLocationValidityContext();
  const currentLocationId = getCurrentLocationId();
  const QUERY_KEY = [queryKeys.submissionsUnreadCount, currentLocationId];
  const submitted_from = getPastUnixTime({ weeks: 1 });
  const { hasProviderReviewPermission } = useUserACL();
  const { user } = useAuth();
  const { hasPMSIntegration } = useWritebackCapabilitiesContext();

  return useQuery(
    QUERY_KEY,
    () =>
      fetchUnreadCount({
        submitted_from,
        show_unread_count: true,
        is_location_writeback_capable: hasPMSIntegration,
        ...(hasProviderReviewPermission && { provider_email: user?.email as string }),
      }),
    {
      retry: false,
      refetchOnWindowFocus: 'always',
      refetchOnMount: 'always',
      enabled: !!currentLocationId && !isValidating && isValidLocation,
    }
  );
};
