import { OktaAuth } from '@okta/okta-auth-js';
import { AxiosRequestConfig } from 'axios';
import { DecodedToken as DecodedAuthToken } from '@weave/auth';

export type OktaAuthProps = {
  oktaAuth: OktaAuth;
};

export type User = {
  email: string;
  name: string;
};
export type SignOutParams = (expired?: boolean) => Promise<void>;

export type AuthContextValue = {
  oktaAuth: OktaAuth;
  signOut: SignOutParams;
  user: User | undefined | null;
  authToken: DecodedAuthToken | undefined | null;
  handleAuthorization: (location: Location) => void;
  hasACL: (ACL: number) => boolean;
};

export enum AuthStorage {
  weave_token = 'weave_token',
  locations = 'locations',
  active_location = 'active_location',
  is_weave_user = 'is_weave_user',
  redirect_url = 'redirect_url',
}

export enum Scopes {
  openid = 'openid',
  roles = 'roles',
  email = 'email',
  profile = 'profile',
}

export type Location = {
  locationID: string;
  name: string;
  roles?: string[];
  slug?: string;
  active?: boolean;
  parent_id?: string;
  phone_tenant_id?: string;
};
export type LocationParamsRes = {
  Active: boolean;
  LocationID: string;
  Name: string;
  ParentID?: string;
  Slug: string;
};
export interface DecodedToken {
  aud: string;
  exp: number;
  iat: number;
  iss: string;
  jti: string;
  sub: string;
}

export interface DecodedAccessToken extends DecodedToken {
  cid: string;
  scp: Scopes;
  uid: string;
  roles: { locations: { name: string; roles: string[] }[] };
  Locations: string[];
  WeaveUserID?: string;
}
export interface DecodedIdToken extends DecodedToken {
  ver: number;
  auth_time: number;
  amr: string[];
  nonce: string[];
  idp: string;
  name: string;
  email: string;
  at_hash: string;
  preferred_username: string;
}

export type ACLS = {
  [key: string]: number[];
};

export type WeaveToken = {
  ACLS: ACLS;
  aud: string[];
  exp: number;
  expBuffer: number;
  iat: number;
  jti: string;
  type: string;
  user_id?: string;
  username?: string;
};

/* auth functions */
export type UserIsAuthenticatedParams = {
  weave_token: string | WeaveToken;
  signOut: SignOutParams;
  config?: AxiosRequestConfig;
};
