import { css } from '@emotion/core';
import { theme } from '@weave/theme';

export const modalStyle = css`
  padding-top: ${theme.spacing(1)};
`;

export const headerStyle = css`
  margin: 0 !important;
  padding-left: ${theme.spacing(2)} !important;
  padding-right: ${theme.spacing(2)} !important;
`;

export const closeButtonStyle = css`
  margin: 0;
  margin-left: auto;
`;

export const bodyStyle = css`
  padding-left: ${theme.spacing(4)} !important;
  padding-right: ${theme.spacing(4)} !important;
`;

export const headingStyle = css`
  margin: 0 !important;
  text-align: center !important;
`;

export const buttonsContainerStyle = css`
  display: flex;
  justify-content: center;
  gap: ${theme.spacing(3)};
  margin-top: ${theme.spacing(2)};
`;

export const buttonContainerStyle = css`
  display: flex;
  flex-direction: column;
`;

const buttonStyle = css`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 200px;
  height: 200px;
  border: none;
  border-radius: ${theme.borderRadius.medium};
  cursor: pointer;

  &:hover {
    box-shadow: 0px 4px 10px 0px #20232840;
  }
`;

export const fromScratchButtonStyle = [
  buttonStyle,
  css`
    background-color: ${theme.colors.primary5};

    span {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      background-color: ${theme.colors.primary40};
      width: 50px;
      height: 50px;
    }
  `,
];

export const fromTemplateButtonStyle = [
  buttonStyle,
  css`
    background-color: ${theme.colors.primary5};
  `,
];

export const textStyle = css`
  font-weight: 600;
  line-height: 20px;
  text-align: center;
  color: ${theme.colors.text.subdued};
`;
