import {
  useQuery,
  useMutation,
  useQueryClient,
  UseMutateAsyncFunction,
} from 'react-query';

import { useAlert } from '@weave/design-system';

import { CustomAxios } from 'shared/helpers/axios';
import {
  updateReviewRequired as updateReviewRequiredAPI,
  ToggleReviewRequiredPayload,
} from 'shared/helpers/axios/apis';
import { getCurrentLocationId } from 'shared/helpers/utils';
import { queryKeys } from 'shared/constants';

interface UseSimplifiedFormsListResults {
  simplifiedFormsList: SimplifiedForm[];
  isLoadingSimplifiedFormsList: boolean;
  updateReviewRequiredStatusOfSimplifiedForm: UseMutateAsyncFunction<
    void,
    unknown,
    ToggleReviewRequiredPayload,
    void
  >;
  isUpdatingReviewRequuiredFlagOfSimplifiedForm: boolean;
}

export const useSimplifiedFormsList = (): UseSimplifiedFormsListResults => {
  const alert = useAlert();
  const queryClient = useQueryClient();
  const QUERY_KEY = queryKeys.simplifiedFormsList;

  const { data, isLoading } = useQuery(QUERY_KEY, fetchSimplifiedFormsList);

  const simplifiedFormsList = data ?? [];

  const {
    mutateAsync: updateReviewRequiredStatusOfSimplifiedForm,
    isLoading: isUpdatingReviewRequuiredFlagOfSimplifiedForm,
  } = useMutation(updateReviewRequiredAPI, {
    onMutate: ({ token, reviewRequired }) => {
      const newList = _toggleReviewRequired({
        simplifiedFormsList: queryClient.getQueryData(QUERY_KEY) ?? [],
        token,
        reviewRequired,
      });

      queryClient.setQueryData(QUERY_KEY, (_: SimplifiedForm[] | undefined) => {
        return newList;
      });
    },
    onSuccess: (_, { reviewRequired }) => {
      if (reviewRequired) {
        alert.success('Successfully enabled Provider Review for this form');
      } else {
        alert.success('Successfully disabled Provider Review for this form');
      }
    },
    onError: (_, { token, reviewRequired }) => {
      const newList = _toggleReviewRequired({
        simplifiedFormsList: queryClient.getQueryData(QUERY_KEY) ?? [],
        token,
        reviewRequired,
      });

      queryClient.setQueryData(QUERY_KEY, (_: SimplifiedForm[] | undefined) => {
        return newList;
      });
    },
  });

  return {
    simplifiedFormsList,
    isLoadingSimplifiedFormsList: isLoading,
    updateReviewRequiredStatusOfSimplifiedForm,
    isUpdatingReviewRequuiredFlagOfSimplifiedForm,
  };
};

interface SimplifiedForm {
  id: string;
  name: string;
  lastSentAt: string;
  reviewRequired?: boolean;
}

interface SimplifiedFormsResponse {
  data: SimplifiedForm[];
  message: string;
}

async function fetchSimplifiedFormsList() {
  const { data } = await CustomAxios.get<SimplifiedFormsResponse>('/forms', {
    params: {
      company_id: getCurrentLocationId(),
    },
  });

  return data.data ?? [];
}

interface ToggleReviewRequiredData {
  simplifiedFormsList: SimplifiedForm[];
  token: string;
  reviewRequired: boolean;
}

function _toggleReviewRequired({
  simplifiedFormsList,
  token,
  reviewRequired,
}: ToggleReviewRequiredData) {
  let formsList = [...simplifiedFormsList];

  const index = formsList.findIndex((form) => form.id === token);

  if (index === -1) {
    return formsList;
  }

  formsList[index] = {
    ...formsList[index],
    reviewRequired,
  };

  return formsList;
}
