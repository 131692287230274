import { useQuery } from 'react-query';

import { queryKeys } from 'shared/constants';
import { searchLocation, SearchLocationData } from 'shared/helpers/axios/apis';

interface UseClonerLocationsProps {
  searchTerm?: string;
  enableFetch: boolean;
}

interface UseClonerLocationsResults {
  locations: SearchLocationData[];
  isLoading: boolean;
}

const INITIAL_DATA = [];

export const useClonerLocations = ({
  searchTerm = 'a',
  enableFetch = false,
}: UseClonerLocationsProps): UseClonerLocationsResults => {
  const { data, isLoading } = useQuery(
    [queryKeys.clonerLocations, searchTerm],
    () => searchLocation(searchTerm),
    { enabled: enableFetch }
  );

  return { locations: data || INITIAL_DATA, isLoading };
};
