import {
  createContext,
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useReducer,
  useRef,
  useState,
} from 'react';
import { DragDropContext, DropResult } from 'react-beautiful-dnd';
import { useParams } from 'react-router';

import { primaryFieldsOrder } from 'shared/constants';
import { BuilderContext, IFormBuilder as FB, BuilderFields } from 'shared/types';
import { genUID, getCurrentLocationId } from 'shared/helpers/utils';

import { useFormBuilder } from 'pages/form-builder/hooks';
import {
  EditorView,
  FormFields,
  SkeletonLoader,
} from 'pages/form-builder/contents/form-editor/components';

import {
  processFields,
  getInitialFormState,
  initialUsedTemplates,
} from './form-components.utils';
import {
  formStateReducer,
  PrimaryFieldsIndexMapper,
  primaryFieldsReducer,
} from './reducers';

interface FormComponentsProviderProps {
  isBuilderReady: boolean;
  sources: FB.BuilderSources | undefined;
  sectionTemplates: FB.ISectionTemplates | undefined;
}

interface MoveFieldWithinSections {
  sourceSectionId: string;
  sourceIndexId: number;
  targetSectionId: string;
  targetIndexId: number;
}

const FormComponentsContext = createContext<
  BuilderContext.IFormComponentsContext | undefined
>(undefined);

export const FormComponentsProvider: FC<FormComponentsProviderProps> = ({
  isBuilderReady,
  sectionTemplates,
  sources,
}) => {
  const { formId = '' } = useParams<{ formId: string }>();
  const pfIndexMapper = useRef<PrimaryFieldsIndexMapper>({});
  const [highlightedArea, setHighlightedArea] = useState<string | undefined>(undefined);
  const [invalidConditions, setInvalidConditions] = useState<Record<string, boolean>>({});

  // used to open modal for text area but can be used for something similar as well
  const [recentlyAddedFieldId, setRecentlyAddedFieldId] = useState<string | undefined>();

  const [pf, updatePrimaryField] = useReducer(
    (state: BuilderFields[], action: BuilderContext.PrimaryFieldsReducerAction) =>
      primaryFieldsReducer(state, action, pfIndexMapper.current),
    primaryFieldsOrder
  ); // pf-> Primary fields

  const [formState, updateFormState] = useReducer(
    (state: FB.FormStructure, action: BuilderContext.FormStateReducerAction) =>
      formStateReducer({ state, action, setHighlightedArea, setRecentlyAddedFieldId }),
    getInitialFormState()
  );
  const [usedTemplates, setUsedTemplates] = useState(initialUsedTemplates);

  const {
    data: templateData,
    isLoading: isTemplateDataLoading,
    isSuccess: isTemplateDataSuccess,
  } = useFormBuilder(formId);

  const orderedFields = useMemo(() => {
    const fieldNames: BuilderContext.OrderedFields[] = [];

    formState.form.sections.forEach((sectionId) => {
      formState.sections[sectionId].fields.forEach((id) => {
        fieldNames.push({
          id,
          name: formState.fields[id].label,
        });
      });
    });

    return fieldNames;
  }, [formState.fields, formState.sections]);

  // conditions mapper is used to get the condition object from the condition id
  // targetToFieldMapper is used to get the fields that are affected by a condition. id: [field1, field2]
  const [conditionsMapper, targetToFieldMapper] = useMemo(() => {
    const conditionsMapper: Record<string, FB.FormCondition> = {};
    const targetToFieldMapper: Record<string, string[]> = {};

    (formState.conditions || []).forEach((condition) => {
      conditionsMapper[condition.id] = condition;

      const conditionField = condition.terms[0].field;

      (condition.actions || []).forEach((action) => {
        if (!targetToFieldMapper[action.target]) {
          targetToFieldMapper[action.target] = [conditionField];
        } else {
          targetToFieldMapper[action.target].push(conditionField);
        }
      });
    });

    return [conditionsMapper, targetToFieldMapper];
  }, [formState.conditions]);

  useEffect(() => {
    const primaryFieldsMapper: PrimaryFieldsIndexMapper = {};
    primaryFieldsOrder.forEach((category, categoryIndex) => {
      category.order.forEach((field, orderIndex) => {
        primaryFieldsMapper[field.field] = {
          categoryIndex,
          orderIndex,
        };
      });
    });
    pfIndexMapper.current = primaryFieldsMapper;
  }, []);

  useEffect(() => {
    if (!isTemplateDataLoading && isTemplateDataSuccess && templateData) {
      const { fields, form, sections, conditions } = templateData.template;
      const { convertedFields, usedPrimaryFields } = processFields(fields);

      const currentLocationId = getCurrentLocationId();

      // company id and location id doesn't change in cloned form's JSON data
      if (form.company_id !== currentLocationId) {
        form.company_id = currentLocationId;
      }
      if (formId !== '' && form.id !== formId) {
        form.id = formId;
      }

      updateFormState({
        type: BuilderContext.FormUpdateType.SET_FORM,
        data: {
          form: form as FB.Form,
          fields: convertedFields,
          sections: sections,
          conditions: conditions ?? [],
        },
      });

      if (usedPrimaryFields.length > 0) {
        updatePrimaryField({
          type: BuilderContext.PrimaryFieldsUpdateType.SET_USED_FIELDS,
          data: {
            fields: usedPrimaryFields,
          },
        });
      }

      const usedAllergies = {};

      // find section templates and disable them to prevent the user from adding it again
      form.sections.forEach((sectionId) => {
        const { section_template } = sections[sectionId];
        if (section_template) {
          usedAllergies[section_template] = true;
        }
      });

      setUsedTemplates((prev) => {
        return {
          ...prev,
          ...usedAllergies,
        };
      });
    }
  }, [isTemplateDataLoading, isTemplateDataSuccess]);

  const updateUsedTemplates = useCallback(
    (templateName: keyof BuilderContext.IUsedTemplates, isUsed: boolean) => {
      setUsedTemplates((prev) => {
        return {
          ...prev,
          [templateName]: isUsed,
        };
      });
    },
    []
  );

  const addNewPrimaryField = (
    fieldName: string,
    targetSectionId: string,
    destinationIndex: number
  ) => {
    const field: FB.Field = {
      ...sources?.primaryFields[fieldName],
      required: sources?.primaryFields[fieldName].required ?? false,
    };

    updateFormState({
      type: BuilderContext.FormUpdateType.ADD_FIELD,
      data: field,
      sectionId: targetSectionId,
      targetIndex: destinationIndex,
    });

    updatePrimaryField({
      fieldName,
      type: BuilderContext.PrimaryFieldsUpdateType.DISABLE,
    });
  };

  const addSectionTemplate = (templateName: keyof FB.ISectionTemplates) => {
    if (sectionTemplates && sources) {
      let allergyCondition: FB.FormCondition | undefined = undefined;
      const templateData = {
        ...sectionTemplates[templateName],
      };
      const addedPrimaryFields: string[] = [];

      const fields: Record<string, FB.Field> = {};
      const fieldsOrder: string[] = [];

      // just for allergies section template
      if (templateName === 'allergies') {
        const fieldId = genUID();
        fieldsOrder.push(fieldId);
        const fieldBaseType: FB.Field = sources.elements['binaryDecision'];

        fields[fieldId] = {
          ...fieldBaseType,
          label: 'Do you have any allergies?',
          required: true,
          id: fieldId,
        };
      }

      templateData.fields.forEach((fieldItem) => {
        const fieldId = genUID();

        //add from primary fields
        if (typeof fieldItem === 'string') {
          const fieldType = fieldItem as string;

          const fieldBaseType: FB.Field = sources.primaryFields[fieldType];

          fields[fieldId] = {
            ...fieldBaseType,
            id: fieldId,
          };
          addedPrimaryFields.push(fieldItem);
        } else {
          //add from elements
          const fieldType = fieldItem.meta.sourceType as FB.FieldType;

          const fieldBaseType: FB.Field = sources.elements[fieldType];

          fields[fieldId] = {
            ...fieldBaseType,
            label: fieldItem.label,
            required: fieldItem.required ?? false,
            id: fieldId,
          };
        }

        fieldsOrder.push(fieldId);
      });

      // branching logic just for allergies
      if (templateName === 'allergies') {
        const mainAllergyQuestionId = fieldsOrder[0];
        const conditionId = genUID();

        allergyCondition = {
          id: conditionId,
          operator: 'and',
          terms: [
            {
              field: mainAllergyQuestionId,
              check: 'equals',
              value: 'Yes',
            },
          ],
          actions: [],
        };

        fieldsOrder.forEach((fieldId) => {
          if (fieldId !== mainAllergyQuestionId) {
            fields[fieldId].hidden = true;

            if (allergyCondition) {
              allergyCondition.actions.push({
                operation: 'show',
                target: fieldId,
                type: 'fields',
              });
            }
          }
        });

        fields[mainAllergyQuestionId].condition_ids = [conditionId];
      }

      updateFormState({
        type: BuilderContext.FormUpdateType.ADD_SECTION_TEMPLATE,
        data: {
          fields,
          fieldsOrder,
          name: templateData.title,
          sectionTemplate: templateName,
          ...(allergyCondition && { condition: allergyCondition }),
        },
      });

      updatePrimaryField({
        type: BuilderContext.PrimaryFieldsUpdateType.SET_USED_FIELDS,
        data: {
          fields: addedPrimaryFields,
        },
      });
    }
  };

  const addNewElement = (
    fieldName: FB.FieldType,
    targetSectionId: string,
    destinationIndex: number
  ) => {
    if (sources) {
      const field: FB.Field = {
        ...sources.elements[fieldName],
        label:
          sources.elements[fieldName].label ||
          sources.elements[fieldName].meta.displayName,
        required: sources.elements[fieldName].required ?? false,
        // if field has options then check if it already has some options
        // if not then add default options
        ...(sources.elements[fieldName].options && {
          options:
            sources.elements[fieldName].options.length > 0
              ? sources.elements[fieldName].options
              : [
                  { label: 'Option 1', value: 'Option 1' },
                  { label: 'Option 2', value: 'Option 2' },
                ],
        }),
      };

      updateFormState({
        type: BuilderContext.FormUpdateType.ADD_FIELD,
        data: field,
        sectionId: targetSectionId,
        targetIndex: destinationIndex,
      });
    }
  };

  const updateFieldOrder = (
    sectionId: string,
    targetIndex: number,
    currentIndex: number
  ) => {
    const newFieldOrder = [...formState.sections[sectionId].fields];
    const fieldToBeReordered = newFieldOrder.splice(currentIndex, 1);
    newFieldOrder.splice(targetIndex, 0, fieldToBeReordered[0]);

    const updatedSection: FB.Section = {
      ...formState.sections[sectionId],
      fields: newFieldOrder,
    };

    updateFormState({
      type: BuilderContext.FormUpdateType.UPDATE_SECTION,
      data: updatedSection,
      sectionId,
    });
  };

  const moveFieldWithinSections = ({
    sourceIndexId,
    sourceSectionId,
    targetIndexId,
    targetSectionId,
  }: MoveFieldWithinSections) => {
    const sourceSection = { ...formState.sections[sourceSectionId] };
    const targetSection = { ...formState.sections[targetSectionId] };

    const sourceSectionFields = [...sourceSection.fields];
    const targetSectionFields = [...targetSection.fields];

    const clonedFieldId = sourceSectionFields.splice(sourceIndexId, 1);
    targetSectionFields.splice(targetIndexId, 0, clonedFieldId[0]);

    sourceSection.fields = sourceSectionFields;
    targetSection.fields = targetSectionFields;

    updateFormState({
      type: BuilderContext.FormUpdateType.MOVE_FIELD_WITHIN_SECTIONS,
      data: {
        sourceSection,
        sourceSectionId,
        targetSection,
        targetSectionId,
      },
    });
  };

  const onDragEnd = useCallback(
    (result: DropResult) => {
      if (!result.destination) return;

      const { droppableId, index: destinationIndex } = result.destination;
      const [targetSectionId] = droppableId.split('__');
      const [fieldToBeAdded, dragType] = result.draggableId.split('__');
      const { droppableId: sourceDroppableId, index: sourceIndex } = result.source;
      const [sourceType] = sourceDroppableId.split('__');

      // even though the droppable is set to false for both primary fields and sections
      // the library sometimes behaves wierdly, so we need to check if the source type is 'primaryFields' | 'element'
      if (['primary-field', 'element'].includes(targetSectionId)) return;

      // new field is added
      if (['primary-field', 'element'].includes(sourceType)) {
        if (sourceType === 'element') {
          addNewElement(
            fieldToBeAdded as FB.FieldType,
            targetSectionId,
            destinationIndex
          );
        } else if (sourceType === 'primary-field') {
          addNewPrimaryField(fieldToBeAdded, targetSectionId, destinationIndex);
        }
      }
      // re-order inside the form
      else {
        // if a field is dragged
        if (dragType === 'field') {
          //  target sectionid and source section id are different
          // dragged from one section but dropped in other section
          if (targetSectionId !== sourceType) {
            moveFieldWithinSections({
              sourceSectionId: sourceType,
              sourceIndexId: sourceIndex,
              targetSectionId,
              targetIndexId: destinationIndex,
            });
          }
          // dragged and dropped within the same section
          else {
            updateFieldOrder(targetSectionId, destinationIndex, sourceIndex);
          }
        }
      }
    },
    [sources, formState]
  );

  const addNewCondition = (condition: BuilderContext.AddNewConditionArgs) => {
    updateFormState({
      type: BuilderContext.FormUpdateType.ADD_CONDITION,
      data: condition,
    });
  };

  const providerValue: BuilderContext.IFormComponentsContext = {
    isFormBuilderReady: isBuilderReady && isTemplateDataSuccess,
    primaryFieldsOrder: pf,
    primaryFieldsIndexMapper: pfIndexMapper.current,
    sectionTemplates: sectionTemplates,
    usedTemplates,
    formState,
    highlightedArea,
    recentlyAddedFieldId,
    orderedFields,
    conditionsMapper,
    targetToFieldMapper,
    invalidConditions,
    setInvalidConditions,
    addNewCondition,
    addSectionTemplate,
    updateFormState,
    updatePrimaryFields: updatePrimaryField,
    updateUsedTemplates,
    setHighlightedArea,
  };

  return (
    <FormComponentsContext.Provider value={providerValue}>
      <DragDropContext onDragEnd={onDragEnd}>
        {providerValue.isFormBuilderReady ? (
          <>
            <FormFields sources={sources} />
            <EditorView />
          </>
        ) : (
          <SkeletonLoader />
        )}
      </DragDropContext>
    </FormComponentsContext.Provider>
  );
};

export const useFormComponentsContext = (): BuilderContext.IFormComponentsContext => {
  const context = useContext(FormComponentsContext);

  if (context === undefined) {
    throw new Error(
      'useFormComponents must be used within a <FormComponentsProvider />.'
    );
  }

  return context;
};
