import { useQuery } from 'react-query';

import { queryKeys } from 'shared/constants';
import { fetchLocationChildren, ILocationChildren } from 'shared/helpers/axios/apis';
import { getActiveLocationData } from 'shared/helpers/utils';

interface IUseLocationChildrenResults {
  isParentLocation: boolean;
  isUnifyChildLocation: boolean;
  children: ILocationChildren[];
  isLoading: boolean;
  isSuccess: boolean;
}

export const useLocationChildren = (): IUseLocationChildrenResults => {
  const { data, isLoading, isSuccess } = useQuery(
    queryKeys.locationChildren,
    () => fetchLocationChildren(),
    {
      staleTime: 5 * 60 * 1000,
    }
  );

  function isParentLocation() {
    if (isLoading) {
      return true;
    }

    return (data && data.data.length > 0) || false;
  }

  function isUnifyChildLocation() {
    const activeLocation = getActiveLocationData();

    if (activeLocation && activeLocation.parent_id) {
      return true;
    }

    return false;
  }

  return {
    isParentLocation: isParentLocation(),
    isUnifyChildLocation: isUnifyChildLocation(),
    children: data?.data || [],
    isLoading,
    isSuccess,
  };
};
