import { FC } from 'react';

import { RippleSize, getRippleStyle } from './ripple.styles';

interface RippleProps {
  className?: string;
  size?: RippleSize;
}

export const Ripple: FC<RippleProps> = ({ size = 'normal', ...otherProps }) => {
  return (
    <div css={getRippleStyle({ size })} {...otherProps}>
      <div className="anim-ripple">
        <div></div>
        <div></div>
      </div>
    </div>
  );
};
