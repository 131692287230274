import { handleLocalStorage } from 'auth/auth.helpers';
import { AuthStorage, Location, WeaveToken } from 'auth/auth.types';
import jwtDecode from 'jwt-decode';
import { useEffect, useMemo, useState } from 'react';
import { FORMS_ACLs } from 'shared/types';

export const useUnifyCloner = (searchTerm = '') => {
  const activeLocation = handleLocalStorage.get(AuthStorage.active_location) as Location;
  const [accessibleLocations, setAccessibleLocations] = useState<Location[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  // Only show locations that the user has access to and is a forms manager of
  useEffect(() => {
    if (activeLocation?.parent_id) {
      const userLocations = handleLocalStorage.get(AuthStorage.locations) as Location[];
      const weaveToken = handleLocalStorage.get(AuthStorage.weave_token);

      if (userLocations && typeof weaveToken === 'string') {
        const weave_decoded_token: WeaveToken = jwtDecode(weaveToken);

        // get all locations with parent_id equal to activeLocation.parent_id
        const siblingLocations = userLocations
          .filter(
            (location) =>
              location.parent_id === activeLocation.parent_id &&
              weave_decoded_token.ACLS[location.locationID].includes(
                FORMS_ACLs.FORMS_CLONE_MANAGE
              )
          )
          .sort((a, b) => a.name.localeCompare(b.name));

        if (siblingLocations.length > 0) {
          setAccessibleLocations(siblingLocations);
        }
      }
    }
    setIsLoading(false);
  }, []);

  const locations = useMemo(() => {
    if (accessibleLocations.length === 0) {
      return [];
    }

    const searchKey = searchTerm.trim().toLowerCase();

    return accessibleLocations.filter((sibling) =>
      sibling.name.toLowerCase().includes(searchKey)
    );
  }, [searchTerm, accessibleLocations]);

  return {
    locations,
    isLoading,
    hasAccessToMultipleLocations: accessibleLocations.length > 1,
  };
};
