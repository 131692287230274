export type WritebackAuth = 'Authorised' | 'Deauthorised' | 'Invalid';
export type SettingsValue = 'Off' | 'Auto' | 'Manual';
export type WritebackSettingName = 'Create Person' | 'Update Person' | 'Upload Document';

export interface WritebackSettingResponse {
  settingId: string;
  settingType: string;
  settingValue: SettingsValue;
  settingName: WritebackSettingName;
  updatedAt: string;
  customName: string;
  customDescription: string;
  customNote: string;
}

export interface UpdateWritebackSettingPayload {
  settingId: string;
  settingValue: SettingsValue;
  settingName: string;
}

export type WritebackStatusCode =
  | 'Pending'
  | 'Fulfilled'
  | 'SUCCESS'
  | 'Failed'
  | 'Manual'
  | 'Submitted';

export enum ContactType {
  PMS,
  CSV,
  CUSTOM_CONTACT,
}

export type WritebackNAReason =
  | 'WRITEBACK_NA_CC'
  | 'WRITEBACK_NA_CSV'
  | 'WRITEBACK_INCAPABLE_SRC'
  | 'UNABLE_TO_SEARCH_PATIENT'
  | 'DUPLICATE_PATIENT'
  | 'MULTIPLE_PATIENT_FOUND';

export interface WritebackStatus {
  settingId: string;
  settingName: WritebackSettingName;
  settingStatus: WritebackStatusCode | 'Not Applicable';
  settingValue: SettingsValue;
}
