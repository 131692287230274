import { EmailField, useFormField } from '@weave/design-system';
import { FC } from 'react';

interface BuilderEmailFieldProps {
  name: string;
}

export const BuilderEmailField: FC<BuilderEmailFieldProps> = ({ name }) => {
  const fieldProps = useFormField({
    type: 'email',
  });
  return <EmailField name={name} label="" {...fieldProps} />;
};
