import { ModalControlResponse, useModalControl } from '@weave/design-system';

import FormCreationWaysModal from './form-creation-ways-modal.component';

interface UseFormCreationWaysResults extends ModalControlResponse {
  FormCreationWaysModal: typeof FormCreationWaysModal;
}

export const useFormCreationWays = (): UseFormCreationWaysResults => {
  const modalControls = useModalControl();

  return {
    ...modalControls,
    FormCreationWaysModal,
  };
};
