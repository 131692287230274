import jwtDecode from 'jwt-decode';
import { useQuery } from 'react-query';
import { AxiosRequestConfig } from 'axios';

import { useDebouncedValue } from '@weave/design-system';

import { axiosInstance, CustomAxios } from 'shared/helpers/axios';

import { authApi } from './auth.api';
import {
  ACLS,
  AuthStorage,
  DecodedAccessToken,
  DecodedIdToken,
  Location,
  LocationParamsRes,
  SignOutParams,
  UserIsAuthenticatedParams,
  WeaveToken,
} from './auth.types';

export const handleLocalStorage = {
  get: (key: string): string | object | null => {
    const data = localStorage.getItem(key);
    if (!data) return null;
    try {
      return JSON.parse(data!);
    } catch (e) {
      return data;
    }
  },
  create: (key: string, value: string | object) => {
    if (typeof value === 'object') {
      localStorage.setItem(key, JSON.stringify(value));
      return;
    }
    localStorage.setItem(key, value);
  },
  delete: (key: string | string[]) => {
    if (typeof key === 'string') {
      localStorage.removeItem(key);
      return;
    }
    key.forEach((k) => {
      localStorage.removeItem(k);
    });
  },
};

export const handleGetLocations = async (acls: ACLS): Promise<Location[] | undefined> => {
  var queryString = Object.keys(acls)
    .map((key) => `location_id=${key}`)
    .join('&');
  try {
    const locations = await authApi.GET.locationDetails(queryString);
    return locations.map((location: LocationParamsRes) => {
      return {
        active: location.Active,
        locationID: location.LocationID,
        name: location.Name,
        parent_id: location.ParentID,
        slug: location.Slug,
      };
    });
  } catch (err: any) {
    console.error(err.message || "Couldn't retrieve user locations.");
    return;
  }
};

function sortByName<T extends { name: string }>(collection: T[]): T[] {
  return collection.sort((a, b) =>
    a.name.toUpperCase() <= b.name.toUpperCase() ? -1 : 1
  );
}

export function useLocationSearch(searchValue: string, location?: Location) {
  const debouncedSearchValue = useDebouncedValue(searchValue, 300);
  const { data = [], isLoading } = useQuery(
    ['locations', debouncedSearchValue],
    async () => {
      if (!searchValue) return [];
      const locations: Location[] = await authApi.GET.locationQuery(searchValue);
      if (location) {
        const filterdLocations = locations.filter(
          (l) => l.locationID !== location.locationID
        );
        return filterdLocations;
      } else {
        return locations;
      }
    }
  );

  return { isLoading, locations: sortByName<Location>(data) };
}

export const tokenExpired = (
  token: string | DecodedAccessToken | WeaveToken
): boolean => {
  if (typeof token === 'string') {
    const decodedToken = jwtDecode(token) as DecodedAccessToken | DecodedIdToken;
    return decodedToken.exp * 1000 < new Date().getTime();
  } else {
    return token.exp * 1000 < new Date().getTime();
  }
};

export let authInterceptor: any;
let refreshLoading = false;
let refreshPromise = Promise.resolve('promise');

const handleTokenRefresh = async (): Promise<string | undefined> => {
  if (refreshLoading) {
    return refreshPromise;
  }

  refreshLoading = true;
  refreshPromise = authApi.POST.refreshWeaveToken().then((token: string) => {
    setTimeout(() => {
      refreshLoading = false;
    }, 5000);
    return token;
  });

  return refreshPromise;
};

/* Checking if the user has a valid session & refreshing weave token if we can. */
const userAuthenticated = async ({
  weave_token,
  signOut,
  config,
}: UserIsAuthenticatedParams): Promise<boolean | AxiosRequestConfig> => {
  /* Checking if Weave token is expired. If it is get new one. */
  if (tokenExpired(weave_token)) {
    return handleTokenRefresh()
      .then((fresh_token) => {
        if (fresh_token) {
          CustomAxios.setUpAuth(fresh_token);

          if (config && config.headers) {
            config.headers.Authorization = `Bearer ${fresh_token}`;
          }
        }

        return config || true;
      })
      .catch((err: any) => {
        console.error(
          err?.message || 'There was a problem refreshing token; please Sign back in. '
        );

        signOut();
        return config || false;
      });
  }

  return config || true;
};

export const setUpAuthInterceptor = (signOut: SignOutParams) => {
  authInterceptor = axiosInstance.interceptors.request.use(async (config) => {
    const weave_token = axiosInstance.__wvaTokens?.weave_token;

    const noCheckNeeded =
      config.url === '/portal/token/refresh' ||
      !weave_token ||
      config.url?.includes('featureflags');

    if (noCheckNeeded) {
      return config;
    }

    const isAuthenticated = (await userAuthenticated({
      weave_token,
      signOut,
      config,
    })) as AxiosRequestConfig;

    return isAuthenticated;
  });
};

export const handleAuthFromLocalStorage = async (signOut: SignOutParams) => {
  const weave_token = handleLocalStorage.get(AuthStorage.weave_token) as string;

  if (!weave_token) {
    return false;
  }

  /* If auth has been cleared out of the instance want to reset. */
  if (!CustomAxios.headerExists('Authorization')) {
    CustomAxios.resetAuth(weave_token);
    setUpAuthInterceptor(signOut);
  }

  const isAuthenticated = await userAuthenticated({
    weave_token,
    signOut,
  });

  return isAuthenticated;
};
