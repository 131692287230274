import { FC, FocusEvent, KeyboardEvent, useEffect, useState } from 'react';
import {
  CaretDownIcon,
  CaretUpIcon,
  CheckboxField,
  ConfirmationModal,
  CopyIcon,
  EditSimpleIcon,
  Heading,
  KeyNames,
  Modal,
  PrimaryButton,
  Text,
  TextField,
  TrashIcon,
  useAlert,
  useFormField,
  useHoverLabel,
  useModalControl,
} from '@weave/design-system';
import { Droppable } from 'react-beautiful-dnd';

import {
  BUILDER_AREA_HIGHLIGHT_TIME,
  LocalStorageKeys,
  pendoTags,
} from 'shared/constants';
import { BuilderContext, IFormBuilder } from 'shared/types';
import { useFormComponentsContext } from 'context';
import { scrollToTarget } from 'context/form-components/form-components.utils';

import { FormField } from '../field/field.component';
import {
  formSectionEmptyStyle,
  sectionHeaderStyle,
  formSectionStyle,
  sectionWrapperStyle,
} from './section.style';
import { genUID } from 'shared/helpers/utils';
import { defaultHoverFontStyle } from 'shared/styles';
import { handleLocalStorage } from 'auth/auth.helpers';
import { css } from '@emotion/core';

interface FormSectionProps {
  sectionId: string;
  totalSections: number;
  sectionIndex: number;
}

export const FormSection: FC<FormSectionProps> = ({
  sectionId,
  sectionIndex,
  totalSections,
}) => {
  const {
    formState,
    highlightedArea,
    updateFormState,
    setHighlightedArea,
    updateUsedTemplates,
    updatePrimaryFields,
  } = useFormComponentsContext();
  const { modalProps, triggerProps } = useModalControl();
  const dontAskAgainCopyConfirmation = useFormField({ type: 'checkbox', value: false });
  const {
    modalProps: duplicateSectionModalProps,
    triggerProps: duplicateSectionModalTriggerProps,
  } = useModalControl();
  const {
    HoverLabel,
    labelProps: hoverLabelProps,
    triggerProps: hoverLabelTriggerProps,
  } = useHoverLabel({
    placement: 'left',
  });
  const alert = useAlert();
  const { sections, fields, form } = formState;

  const [editMode, setEditMode] = useState(false);

  const sectionLabelInputName = `${sectionId}__edit`;

  const sectionNameProps = useFormField(
    {
      type: 'text',
      value: sections[sectionId].title,
      required: false,
    },
    [editMode]
  );

  // old form could have sections with no fields
  const totalFieldsInSection = sections[sectionId].fields?.length ?? 0;

  const onUpdateSectionName = () => {
    updateFormState({
      type: BuilderContext.FormUpdateType.UPDATE_SECTION,
      data: {
        ...sections[sectionId],
        title: sectionNameProps.value,
      },
      sectionId,
    });
    setEditMode(false);
  };

  const reorderSection = (currentIndex: number, targetIndex: number) => {
    const sectionOrder = [...form.sections];
    [sectionOrder[currentIndex], sectionOrder[targetIndex]] = [
      sectionOrder[targetIndex],
      sectionOrder[currentIndex],
    ];

    updateFormState({
      type: BuilderContext.FormUpdateType.REORDER_SECTIONS,
      data: {
        sections: sectionOrder,
      },
    });

    setTimeout(() => {
      scrollToTarget(sectionOrder[targetIndex]);
    }, 0);

    setHighlightedArea(sectionOrder[targetIndex]);
  };

  const onDeleteSection = () => {
    // check for primary fields to enable back if any
    const primaryFieldsToEnable: string[] = [];
    // old form could have sections with no fields
    (sections[sectionId].fields || []).forEach((fieldId) => {
      if (fields[fieldId].meta.dataKey) {
        primaryFieldsToEnable.push(fields[fieldId].meta.dataKey as string);
      }
    });

    updateFormState({
      type: BuilderContext.FormUpdateType.DELETE_SECTION,
      data: {
        sectionId,
        sectionIndex,
      },
    });

    // enable back primary fields if any
    if (primaryFieldsToEnable.length > 0) {
      updatePrimaryFields({
        type: BuilderContext.PrimaryFieldsUpdateType.BATCH_ENABLE,
        data: {
          fields: primaryFieldsToEnable,
        },
      });
    }

    // enable back section template if deleted section is a section template
    const { section_template } = sections[sectionId];
    if (section_template) {
      updateUsedTemplates(section_template, false);
    }
  };

  const detectEnterPress = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === KeyNames.Enter) {
      onUpdateSectionName();
    }
  };

  useEffect(() => {
    if (highlightedArea === sectionId) {
      setTimeout(() => {
        setHighlightedArea(undefined);
      }, BUILDER_AREA_HIGHLIGHT_TIME * 1000);
    }
  }, [highlightedArea]);

  const labelInputOnBlur = (e?: FocusEvent<Element, Element>) => {
    if (e) {
      // hacky: check if actionText button is clicked
      // this isn't handled in the design system
      if (e.relatedTarget && e.relatedTarget.innerHTML === 'Update') {
        return;
      } else {
        setEditMode(false);
      }
    } else {
      setEditMode(false);
    }
  };

  const onTriggerDuplicateSection = () => {
    const noCopyConfirmation = handleLocalStorage.get(
      LocalStorageKeys.noCopyConfirmation
    );
    if (noCopyConfirmation) {
      onCopySection();
    } else {
      duplicateSectionModalTriggerProps.onClick();
    }
  };

  const onCopySection = () => {
    if (dontAskAgainCopyConfirmation.value) {
      handleLocalStorage.create(LocalStorageKeys.noCopyConfirmation, 'true');
    }

    const newSection = { ...sections[sectionId] };
    const newSectionId = genUID();
    const newSectionFields: Record<string, IFormBuilder.Field> = {};

    newSection.id = newSectionId;
    newSection.title = `${newSection.title || 'Untitled'} (copy)`;
    newSection.fields = [];

    sections[sectionId].fields.forEach((fieldId) => {
      const newField: IFormBuilder.Field = { ...fields[fieldId] };

      // don't copy primary fields
      if (!newField.meta.dataKey) {
        const newFieldId = genUID();
        newField.id = newFieldId;
        // conditions should not be copied. If we decide to copy them in future, we need to create new conditions and assign a new condition id here
        newField.condition_ids = [];

        newSectionFields[newFieldId] = newField;
        newSection.fields.push(newFieldId);
      }
    });

    if (newSection.fields.length === 0) {
      alert.info('There are no fields to copy in this section.');
    } else {
      updateFormState({
        data: {
          newFields: newSectionFields,
          newSectionId: newSectionId,
          section: newSection,
        },
        type: BuilderContext.FormUpdateType.COPY_SECTION,
      });
      alert.success('Section cloned successfully');
    }
    duplicateSectionModalProps.onClose();
  };

  return (
    <section
      id={sectionId}
      css={sectionWrapperStyle(sectionId === highlightedArea)}
      className="section-wrapper"
      key={sectionId}
    >
      <header css={sectionHeaderStyle}>
        {!editMode ? (
          <div className="edit-section-name" onClick={() => setEditMode(true)}>
            <Heading className="secion-name" level={2}>
              {sections[sectionId].title || 'Untitled'}
            </Heading>
            <EditSimpleIcon className="edit-icon" size={18} color="light" />
          </div>
        ) : (
          <div className="edit-section-wrapper">
            <TextField
              autoFocus
              className="edit-section-input"
              {...sectionNameProps}
              name={sectionLabelInputName}
              label=""
              onBlur={labelInputOnBlur}
              onKeyDown={detectEnterPress}
              data-trackingid={pendoTags.newFormEditor.sectionLabelUpdate}
              placeholder="Section Name"
            />
            <PrimaryButton
              onClick={onUpdateSectionName}
              className="update-section-name-btn"
              size="tiny"
            >
              Update
            </PrimaryButton>
          </div>
        )}

        <div className="section-actions">
          <span {...hoverLabelTriggerProps}>
            <CopyIcon
              className="duplicate-section-icon"
              size={20}
              color="light"
              onClick={onTriggerDuplicateSection}
            ></CopyIcon>
            <HoverLabel css={defaultHoverFontStyle} {...hoverLabelProps}>
              Clone Form Section
            </HoverLabel>
          </span>

          <TrashIcon
            className="delete-section-icon"
            color="light"
            size={20}
            {...triggerProps}
          />
          <div className="reorder-icons-wrapper">
            {sectionIndex !== 0 && (
              <CaretUpIcon
                onClick={() => reorderSection(sectionIndex, sectionIndex - 1)}
              />
            )}
            {sectionIndex !== totalSections - 1 && (
              <CaretDownIcon
                onClick={() => reorderSection(sectionIndex, sectionIndex + 1)}
              />
            )}
          </div>
        </div>
      </header>
      <Droppable droppableId={`${sectionId}__section`}>
        {(provided) => (
          <div
            css={formSectionStyle}
            {...provided.droppableProps}
            ref={provided.innerRef}
          >
            {totalFieldsInSection === 0 ? (
              <div css={formSectionEmptyStyle}>
                <Text size="medium" textAlign="center">
                  Drag and drop a primary field or element here
                </Text>
              </div>
            ) : (
              <>
                {sections[sectionId].fields.map((fieldId, index) => (
                  <FormField
                    field={fields[fieldId]}
                    key={fieldId}
                    index={index}
                    sectionId={sectionId}
                    totalFields={totalFieldsInSection}
                  />
                ))}
              </>
            )}
          </div>
        )}
      </Droppable>
      <ConfirmationModal
        {...modalProps}
        title="Delete Section"
        message="Are you sure you want to delete this section? This can't be undone."
        onConfirm={onDeleteSection}
        confirmLabel="Delete"
        destructive={true}
      />
      <Modal {...duplicateSectionModalProps}>
        <Modal.Header textAlign="left">Clone Section</Modal.Header>
        <Modal.Body>
          <Text
            css={css`
              margin-top: 0;
            `}
          >
            Are you sure you want to clone this section? Primary fields present in this
            section will not be cloned.
          </Text>
          <CheckboxField
            {...dontAskAgainCopyConfirmation}
            name="dontAskAgain"
            label="Don't ask me again"
          />
        </Modal.Body>
        <Modal.Actions
          onSecondaryClick={duplicateSectionModalProps.onClose}
          onPrimaryClick={onCopySection}
          primaryLabel={'Duplicate'}
          secondaryLabel={'Cancel'}
        />
      </Modal>
    </section>
  );
};
