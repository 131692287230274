import { css } from '@emotion/core';
import { theme } from '@weave/theme';

import { blackButtonHoverStyle } from 'shared/styles';

const commonButtonStyle = css`
  align-items: center;
  border-radius: ${theme.spacing(2)};
  display: flex;
  height: ${theme.spacing(5)};
  justify-content: center;
  width: ${theme.spacing(5)};
`;

const normalIconBtnStyle = [
  css`
    color: ${theme.colors.white} !important;
    background-color: ${theme.colors.neutral70} !important;
    padding: ${theme.spacing(0.8, 1)} !important;
    border-radius: ${theme.borderRadius.medium};
  `,
  blackButtonHoverStyle,
  commonButtonStyle,
];

const greyIconBtnStyle = [
  css`
    color: ${theme.colors.white};
    background-color: ${theme.colors.neutral50} !important;
    padding: ${theme.spacing(0.8, 1)} !important;
    border-radius: ${theme.borderRadius.medium};
    border: 2px solid ${theme.colors.neutral50} !important;
    transition: 0.5s;

    :hover {
      color: ${theme.colors.neutral50};
      background-color: ${theme.colors.white} !important;
    }
  `,
  commonButtonStyle,
];

const roundedButtonStyle = css`
  border-radius: ${theme.borderRadius.full};
`;

export type ButtonColors = 'grey';

interface ButtonStyleConfig {
  rounded?: boolean;
  color?: ButtonColors;
}

export function getButtonStyle({ rounded, color }: ButtonStyleConfig = {}) {
  switch (color) {
    case 'grey':
      return [...greyIconBtnStyle, rounded && roundedButtonStyle];

    default:
      return [...normalIconBtnStyle, rounded && roundedButtonStyle];
  }
}
