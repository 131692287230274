import { FC, useEffect, useState } from 'react';
import { css } from '@emotion/core';

import { CenteredSpinningLoader } from 'shared/components';
import { FORMS_EXPERIENCE_BASE_URL } from 'shared/constants';
import { getCurrentLocationId } from 'shared/helpers/utils';

interface FormPreviewProps {
  token: string;
  type: 'form' | 'packet' | 'template';
  hideFooterLinks?: boolean;
  disableReviewMode?: boolean;
  isReloading?: boolean;
}

export const FormPreview: FC<FormPreviewProps> = ({
  token,
  type,
  hideFooterLinks,
  disableReviewMode,
  isReloading,
}) => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (isReloading) {
      setIsLoading(true);
    }
  }, [isReloading]);

  let url = `${FORMS_EXPERIENCE_BASE_URL}/form-preview?${type}Id=${token}&companyId=${getCurrentLocationId()}`;

  if (hideFooterLinks) {
    url += '&hideFooterLinks=true';
  }

  if (disableReviewMode) {
    url += '&disableReviewMode=true';
  }

  if (isReloading) {
    return <CenteredSpinningLoader />;
  }

  return (
    <>
      {isLoading && <CenteredSpinningLoader />}

      <iframe
        css={css`
          width: 100%;
          height: 100%;
          border: none;
          overflow: auto;
          display: flex;
          flex-direction: column;
          flex: 1;
        `}
        src={url}
        onLoad={() => setIsLoading(false)}
      />
    </>
  );
};
