import { tracking } from '@weave/tracking';

export function pendoTrack(name: string, data?: any) {
  if (tracking.pendo.isReady()) {
    return tracking.pendo.track(name, data);
  }

  setTimeout(function () {
    pendoTrack(name, data);
  }, 500);
}
