import { getCurrentLocationId } from 'shared/helpers/utils';
import { MediaManagerResponse } from 'shared/types';
import { API_URL } from 'shared/constants';

import { CustomAxios } from '../axios-helpers';

interface UploadMediaConfig {
  signal?: AbortSignal;
}

export async function uploadMedia(
  file: File,
  config: UploadMediaConfig = {}
): Promise<MediaManagerResponse> {
  const { signal } = config;

  const payload = new FormData();
  payload.append('file', file);
  payload.append('file_type', file.type);
  payload.append('company_id', getCurrentLocationId());

  try {
    const { data } = await CustomAxios.post('/upload-media', payload, {
      headers: { 'Content-Type': 'multipart/form-data' },
      signal,
    });

    return {
      success: true,
      fileId: data.data.data.id,
    };
  } catch (err) {
    console.log('Error uploading the file: ', err);
    return {
      success: false,
      fileId: null,
      message: 'Failed to upload the file!',
      error: err,
    };
  }
}

export async function deleteMedia(mediaId: string): Promise<MediaManagerResponse> {
  try {
    await CustomAxios.delete(`/portal/v1/media/forms-app/${mediaId}`, {
      baseURL: API_URL,
    });

    return {
      success: true,
      fileId: mediaId,
    };
  } catch (err) {
    console.log('Error deleting the file: ', err);
    return {
      success: false,
      fileId: mediaId,
      message: 'Failed to delete the file!',
      error: err,
    };
  }
}
