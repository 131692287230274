import { FC, useState } from 'react';
import { Tabs } from '@weave/design-system';
import { IFormBuilder as FB } from 'shared/types';
import { FormElements } from './form-elements.component';
import { formFieldsRootStyles, tabStyles, tabBarStyle } from './form-fields.style';
import { PrimaryFields } from './primary-fields.component';

interface FormFieldsProps {
  sources: FB.BuilderSources | undefined;
}

export const FormFields: FC<FormFieldsProps> = ({ sources }) => {
  const [activeTab, setActiveTab] = useState('primary-fields');

  return (
    <div css={formFieldsRootStyles}>
      <Tabs initialTab={activeTab} onChange={setActiveTab}>
        <Tabs.Bar css={tabBarStyle}>
          <Tabs.Tab id="primary-fields" controls="builder-pf">
            Primary Fields
          </Tabs.Tab>
          <Tabs.Tab id="elements" controls="builder-elements">
            Elements
          </Tabs.Tab>
        </Tabs.Bar>
        <Tabs.Panel id="builder-pf" controller="primary-fields" css={tabStyles}>
          {sources && <PrimaryFields fields={sources.primaryFields} />}
        </Tabs.Panel>
        <Tabs.Panel id="builder-elements" controller="elements" css={tabStyles}>
          {sources && <FormElements fields={sources.elements} />}
        </Tabs.Panel>
      </Tabs>
    </div>
  );
};
