import { css } from '@emotion/core';
import { theme } from '@weave/theme';

export const alignSelfEnd = css`
  align-self: flex-end;
`;

export const alignSelfStart = css`
  align-self: flex-start;
`;

export const mb0 = css`
  margin-bottom: 0px;
`;

export const mb1 = css`
  margin-bottom: ${theme.spacing(1)};
`;

export const mt0 = css`
  margin-top: ${theme.spacing(0)};
`;

export const mt1 = css`
  margin-top: ${theme.spacing(1)};
`;

export const ml1 = css`
  margin-left: ${theme.spacing(1)};
`;

export const mr1 = css`
  margin-right: ${theme.spacing(1)};
`;