import { FC } from 'react';

import {
  ModalControlModalProps,
  Modal,
  Text,
  IconButton,
  XIcon,
  PrimaryButton,
  SecondaryButton,
} from '@weave/design-system';

import {
  modalStyle,
  headerStyle,
  bodyStyle,
  footerStyle,
} from './send-form-info-modal.styles';

interface SendFormInfoModalProps {
  modalProps: ModalControlModalProps;
  onClose: () => void;
  onConfirm: () => void;
}

const SendFormInfoModal: FC<SendFormInfoModalProps> = ({
  modalProps,
  onClose,
  onConfirm,
}) => {
  function confirmHandler() {
    onConfirm();
    onClose();
  }

  return (
    <Modal {...modalProps} css={modalStyle} minWidth={600}>
      <Modal.Header css={headerStyle}>
        Send this form...
        <IconButton label="" onClick={onClose}>
          <XIcon />
        </IconButton>
      </Modal.Header>

      <Modal.Body css={bodyStyle}>
        <Text>
          This will ask you to <b>select a patient</b> (form recipient) in Weave desktop
          client.
        </Text>

        <Text>
          If you have access to more than one location, make sure that you are logged in
          to the same location in Weave desktop client as well.
        </Text>
      </Modal.Body>

      <footer css={footerStyle}>
        <PrimaryButton onClick={confirmHandler} size="tiny">
          Send
        </PrimaryButton>
        <SecondaryButton onClick={onClose} size="tiny">
          Cancel
        </SecondaryButton>
      </footer>
    </Modal>
  );
};

export default SendFormInfoModal;
