import { FC } from 'react';

import { Modal, ModalControlModalProps } from '@weave/design-system';

import { useWritebackCapabilitiesContext } from 'context';
import { CenteredSpinningLoader } from 'shared/components';

import SinglePMSNotification from './single-pms-notification/single-pms-notification.component';
import MultiPMSNotification from './multi-pms-notification/multi-pms-notification.component';

import { getModalStyles, bodyStyle } from './pms-change-notification-modal.styles';

interface PMSChangeNotificationModalProps {
  modalProps: ModalControlModalProps;
  onCloseModal: () => void;
}

const PMSChangeNotificationModal: FC<PMSChangeNotificationModalProps> = ({
  modalProps,
  onCloseModal,
}) => {
  const {
    hasMultipleSupportedSourceTenants,
    isUpdatingPreferredSyncApp,
    hasMultiTenantSource,
  } = useWritebackCapabilitiesContext();

  const isMulti = hasMultipleSupportedSourceTenants() || hasMultiTenantSource;

  function getContentView() {
    if (isUpdatingPreferredSyncApp && !isMulti) {
      return <CenteredSpinningLoader />;
    }

    return isMulti ? (
      <MultiPMSNotification onCloseModal={onCloseModal} />
    ) : (
      <SinglePMSNotification onCloseModal={onCloseModal} />
    );
  }

  return (
    <Modal
      {...modalProps}
      css={getModalStyles({ isMultiPMSNotification: isMulti })}
      disableCloseOnEscape
      disableCloseOnOverlayClick
    >
      <Modal.Body css={bodyStyle}>{getContentView()}</Modal.Body>
    </Modal>
  );
};

export default PMSChangeNotificationModal;
