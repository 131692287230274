import { ModalControlModalProps, useModalControl } from '@weave/design-system';

import FormClonerModal from './form-cloner-modal/form-cloner-modal.component';

interface UseFormClonerModalProps {
  onClose?: () => void;
}

interface UseFormClonerModalResults {
  FormClonerModal: typeof FormClonerModal;
  modalProps: ModalControlModalProps;
  openModal: () => void;
  closeModal: () => void;
}

export const useFormClonerModal = (
  props?: UseFormClonerModalProps
): UseFormClonerModalResults => {
  const { onClose } = props || {};
  const { modalProps, openModal, closeModal } = useModalControl({ onClose });

  return {
    FormClonerModal,
    modalProps,
    openModal,
    closeModal,
  };
};
