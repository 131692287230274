import { APP_BASE_URL } from 'shared/constants';
import {
  getCurrentLocationId,
  processLocationValidationStatus,
} from 'shared/helpers/utils';

import { CustomAxios } from '../axios-helpers';

export interface SearchLocationData {
  active: boolean;
  locationID: string;
  name: string;
  parent_id: string | null;
  phone_tenant_id: string | null;
  slug: string;
  type: 'Regular' | 'Dev' | 'Demo';
}

interface SearchLocationResponse {
  data: SearchLocationData[];
}

export async function searchLocation(searchTerm: string) {
  const LOCATION_SEARCH_URL = '/portal/locations';
  const { data } = await CustomAxios.get<SearchLocationResponse>(
    `${LOCATION_SEARCH_URL}/${searchTerm}`,
    {
      baseURL: APP_BASE_URL,
    }
  );

  return data.data || [];
}

export async function validateLocation(locationId = getCurrentLocationId()) {
  try {
    const response = await CustomAxios.post('/onboard-company', {
      company_id: locationId,
    });
    return response.status;
  } catch (e: any) {
    return e.status as number;
  }
}

export async function validateLocations(locations: string[]) {
  const promises: Promise<boolean>[] = [];

  for (const location of locations) {
    const promise = validateLocation(location).then(
      (status) => processLocationValidationStatus(status).isValidLocation
    );

    promises.push(promise);
  }

  const result = await Promise.all(promises);
  const hasInvalidLocation = result.some((value) => value === false);
  const invalidLocations: string[] = [];

  if (hasInvalidLocation) {
    for (const index in result) {
      if (!result[index]) {
        invalidLocations.push(locations[index]);
      }
    }
  }

  return {
    result,
    hasInvalidLocation,
    invalidLocations,
  };
}
