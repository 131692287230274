import { css } from '@emotion/core';
import { theme } from '@weave/theme';

export const cardContainerStyle = css`
  border: 1px solid ${theme.colors.neutral5};
`;

export const clickableCardStyle = css`
  cursor: pointer;
`;

export const cardContentContainerStyle = css`
  padding: ${theme.spacing(2)};
  position: relative;
  display: flex;
  width: 100%;
`;
