import { css } from '@emotion/core';
import { theme } from '@weave/theme';

const containerStyle = css`
  width: fit-content;
  max-width: 150px;
  height: 26px;
  background: white;
  border: 1px solid ${theme.colors.neutral20};
  border-radius: ${theme.borderRadius.small};
  display: flex;
  align-items: center;
  margin: 0;
  padding: ${theme.spacing(0.5, 0.75, 0.5, 1)};
  z-index: ${theme.zIndex.low};
`;

interface ContainerStyleConfig {
  isInvalid?: boolean;
}

export function getContainerStyle({ isInvalid }: ContainerStyleConfig = {}) {
  if (isInvalid) {
    return [
      containerStyle,
      css`
        border-color: ${theme.colors.status.critical};
        color: ${theme.colors.status.critical};
      `,
    ];
  }

  return containerStyle;
}

export const textStyle = css`
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const buttonStyle = css`
  color: ${theme.colors.neutral10};
  line-height: 0;
  margin-left: ${theme.spacing(1)};
  position: relative;
  transition: color 250ms ease-out;

  ::before {
    content: '';
    width: 24px;
    height: 24px;
    background: transparent;
    border: 2px solid transparent;
    border-radius: 50%;
    display: block;
    transition: border-color 250ms ease-out;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  :focus {
    color: ${theme.colors.neutral20};
    outline: none;

    ::before {
      border-color: ${theme.colors.primary10};
    }
  }

  :hover {
    color: ${theme.colors.neutral20};
  }
`;
