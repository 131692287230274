import { getCurrentLocationId, genUID } from 'shared/helpers/utils';
import { FormDataFields, IFormBuilder as FB } from 'shared/types';

interface IProcessFields {
  convertedFields: Record<string, FB.Field>;
  usedPrimaryFields: string[];
}

function recursiveParsing(data: string) {
  const parsed = JSON.parse(data);
  if (typeof parsed === 'string') {
    return recursiveParsing(parsed);
  }

  return parsed;
}

export function getInitialFormState() {
  const initialSectionId: string = genUID();

  const initialSection: FB.Section = {
    fields: [],
    id: initialSectionId,
    title: '',
  };

  const initialFormState: FB.FormStructure = {
    sections: {
      [initialSectionId]: initialSection,
    },
    fields: {},
    form: {
      company_id: getCurrentLocationId(),
      id: '',
      name: '',
      sections: [initialSectionId],
    },
  };

  return initialFormState;
}

export function processFields(fields: Record<string, FormDataFields>): IProcessFields {
  const convertedFields: any = {};
  const usedPrimaryFields: string[] = [];

  for (const fieldId in fields) {
    const field: any = fields[fieldId];

    if (field.hasOwnProperty('name')) {
      delete field.name;
    }

    convertedFields[fieldId] = { ...field };

    if (typeof (field as FB.BaseOptionsField).options === 'string') {
      try {
        let options: FB.Option[] = JSON.parse(field.options || '[]');

        if (typeof options === 'string') {
          // some forms have options stringified more than once
          // e.g: '"\\"[{\\\\\\"label\\\\\\":\\\\\\"\\\\\\",\\\\\\"value\\\\\\":\\\\\\"\\\\\\"}]\\""'
          // so we need to parse it recursively
          options = recursiveParsing(options);
        }

        convertedFields[fieldId] = {
          ...convertedFields[fieldId],
          options,
        };
      } catch (error) {
        if (field.options === 'states') {
          convertedFields[fieldId] = {
            ...convertedFields[fieldId],
            options: [],
            meta: {
              dataGroup: 'person',
              dataKey: 'state',
              displayName: 'State',
              optionSet: 'states',
              optionsDisplay: 'US States',
              type: 'select',
            },
          };
        }
      }
    }

    // parse stringified value.
    // If value is stringified then the value parses else it will remain the same
    try {
      convertedFields[fieldId].value = JSON.parse(convertedFields[fieldId].value);
    } catch (error) {}

    if (field.meta.dataKey) {
      usedPrimaryFields.push(field.meta.dataKey);
    }
  }

  return {
    convertedFields,
    usedPrimaryFields,
  };
}

export function scrollToTarget(targetId: string) {
  const formView = document.getElementById('form_view');
  const target = document.getElementById(targetId);
  if (formView && target) {
    formView.scrollTo({
      top: target.offsetTop,
      behavior: 'smooth',
    });
  }
}

export const initialUsedTemplates = {
  allergies: false,
  covid: false,
  femaleMedicalConditions: false,
  medicalConditions: false,
  patient: false,
  primaryInsurance: false,
  secondaryInsurance: false,
};
