import { css } from '@emotion/core';
import { theme } from '@weave/theme';

import { MAX_READABLE_WIDTH } from 'shared/constants';

export const headingStyle = css`
  margin-top: 0;
  display: flex;
  justify-content: space-between;
`;

export const containerStyle = css`
  margin-bottom: ${theme.spacing(4)};
  overflow: auto;
`;

export const textStyle = css`
  max-width: ${MAX_READABLE_WIDTH}px;
`;
