import { FC, Fragment } from 'react';
import { AlertIcon, SearchField, Text } from '@weave/design-system';
import { elementsOrder, pendoTags } from 'shared/constants';
import { IFormBuilder as FB } from 'shared/types';
import { FormFieldItem } from '../form-field-item/form-field-item.component';
import {
  categoryNameStyle,
  fieldItemsStyle,
  panelDescriptionStyle,
  panelDescriptionTextStyle,
  tabsContentStyle,
} from './form-fields.style';
import { useComponentSearch } from './useComponentSearch';
import { Droppable } from 'react-beautiful-dnd';

interface FormElementsProps {
  fields: FB.IElements;
}

export const FormElements: FC<FormElementsProps> = ({ fields }) => {
  const { filteredFields, searchProps } = useComponentSearch(elementsOrder);

  return (
    <Droppable droppableId="element" isDropDisabled={true}>
      {(provided) => (
        <div css={tabsContentStyle} {...provided.droppableProps} ref={provided.innerRef}>
          <SearchField
            name="elements-search"
            {...searchProps}
            autoComplete={'off'}
            data-trackingid={pendoTags.newFormEditor.searchFields}
          />
          <div css={panelDescriptionStyle(true)}>
            <Text
              css={panelDescriptionTextStyle}
              className="element-panel-desc"
              size={'small'}
              color="light"
            >
              <AlertIcon size={16} className="alert-icon" />
              These fields do not writeback to your system.
            </Text>
          </div>
          <ul css={fieldItemsStyle}>
            {filteredFields.map((category) => {
              return (
                <Fragment key={category.label}>
                  <Text css={categoryNameStyle} color="light">
                    {category.label}
                  </Text>
                  {category.order.map((fieldItem) => {
                    if (!fields[fieldItem.field]) {
                      return null;
                    }
                    return (
                      <FormFieldItem
                        key={fieldItem.field}
                        {...fieldItem}
                        isPrimaryField={false}
                        field={fields[fieldItem.field]}
                        fieldName={fieldItem.field}
                      />
                    );
                  })}
                </Fragment>
              );
            })}
          </ul>
        </div>
      )}
    </Droppable>
  );
};
