import { useReducer } from 'react';

import { validateLocations as validateLocationsAPI } from 'shared/helpers/axios/apis';

import { ReducerState, reducer } from './reducer/locations-validation.reducer';

interface UseLocationsValidationResults extends ReducerState {
  validateLocations: (locationIds: string[]) => ReturnType<typeof validateLocationsAPI>;
  resetInvalidLocations: () => void;
}

const initialState: ReducerState = {
  invalidLocations: [],
  isValidating: false,
};

export const useLocationsValidation = (): UseLocationsValidationResults => {
  const [state, dispatch] = useReducer(reducer, initialState);

  async function validateLocations(locationIds: string[]) {
    dispatch({ type: 'start-validation' });
    const validationResult = await validateLocationsAPI(locationIds);

    if (validationResult.hasInvalidLocation) {
      dispatch({
        type: 'set-invalid-locations',
        payload: validationResult.invalidLocations,
      });
    }

    dispatch({ type: 'finish-validation' });
    return validationResult;
  }

  function resetInvalidLocations() {
    dispatch({ type: 'reset-invalid-locations' });
  }

  return {
    ...state,
    validateLocations,
    resetInvalidLocations,
  };
};
