import { FC } from 'react';

import {
  Modal,
  ModalControlModalProps,
  Heading,
  Text,
  TextLink,
  XIcon,
  IconButton,
} from '@weave/design-system';

import { useAuth } from 'auth/auth-provider';
import { CustomModalButtons } from 'shared/components';
import { pendoTags, HELP_LINKS } from 'shared/constants';

import { headingContainerStyle } from '../writeback-setup-modal.styles';
import { headingStyle, listStyle } from './skip-modal.styles';

interface SkipWritebackSetupModalProps {
  modalProps: ModalControlModalProps;
  onClose: () => void;
}

const SkipWritebackSetupModal: FC<SkipWritebackSetupModalProps> = ({
  modalProps,
  onClose,
}) => {
  const { signOut } = useAuth();

  return (
    <Modal
      {...modalProps}
      disableCloseOnEscape={true}
      disableCloseOnOverlayClick={true}
      minWidth={700}
    >
      <Modal.Body>
        <div css={headingContainerStyle}>
          <Heading level={2} css={headingStyle}>
            Skip selecting default writeback destination
          </Heading>

          <IconButton
            onClick={onClose}
            label=""
            trackingId={pendoTags.writebackAuthSetup.continueSourceSelection}
          >
            <XIcon color="default" />
          </IconButton>
        </div>

        <Text>Are you sure you want to skip this initial setup?</Text>

        <ul css={listStyle}>
          <li>
            Before you start using Digital Forms, you must specify where (PMS) you want to
            save (sync or writeback) your collected patient information.
          </li>
          <li>
            Since your office has access to two or more compatible systems of record, you
            must select one of them as your default where all form submissions will be
            saved.
          </li>
          <li>
            You may override this default setting anytime if you choose to writeback
            manually.
          </li>
          <li>
            If you are not the deciding authority, you may ask your office manager or
            system administrator to complete this initial setup.
          </li>
        </ul>

        <Text>
          <TextLink
            to={HELP_LINKS.writebackSetup.contactSupport}
            target="_blank"
            trackingId={pendoTags.writebackAuthSetup.contactSupport}
          >
            Contact support
          </TextLink>{' '}
          if you need more help with this initial setup.
        </Text>

        <CustomModalButtons
          primaryButtonText="Yes, Logout"
          onClickPrimaryButton={signOut}
          primaryButtonTrackingId={pendoTags.writebackAuthSetup.skipSetup}
          secondaryButtonText="Go Back"
          onClickSecondaryButton={onClose}
          secondaryButtonTrackingId={pendoTags.writebackAuthSetup.continueSourceSelection}
        />
      </Modal.Body>
    </Modal>
  );
};

export default SkipWritebackSetupModal;
