import { css } from '@emotion/core';
import { theme } from '@weave/theme';

export const containerStyle = css`
  border: 1px dashed ${theme.colors.status.critical};
  border-radius: ${theme.borderRadius.medium};
  padding: ${theme.spacing(2)};
  margin-top: ${theme.spacing(2)};
  color: ${theme.colors.status.critical};
`;

export const textStyle = css`
  margin: 0;
  margin-bottom: ${theme.spacing(1)};
`;

export const listStyle = css`
  margin: 0;

  li {
    &:not(:last-of-type) {
      margin-bottom: ${theme.spacing(0.5)};
    }
  }
`;
