import { css } from '@emotion/core';
import { theme } from '@weave/theme';

import {
  PHONE_MODE_MAX_WIDTH,
  TABLET_MODE_MAX_WIDTH,
  defaultSideBarWidth,
} from 'shared/constants';

export const containerStyle = css`
  display: flex;
  align-items: center;
  background-color: ${theme.colors.neutral10};

  @media (max-width: ${TABLET_MODE_MAX_WIDTH}px) {
    padding-right: ${theme.spacing(2)};
  }

  @media (max-width: ${PHONE_MODE_MAX_WIDTH}px) {
    padding-left: ${theme.spacing(2)};
  }
`;

export const brandContainerNonLocationStyle = css`
  background-color: white;
`;

export const brandContainerLocationStyle = css`
  background-color: transparent;
`;

export const brandContainerStyle = css`
  display: flex;
  align-items: center;
  height: 100%;
  padding: ${theme.spacing(1, 0, 1, 3)};
  width: ${defaultSideBarWidth}px;
  border-bottom: 1px solid ${theme.colors.neutral5};

  @media (max-width: ${TABLET_MODE_MAX_WIDTH}px) {
    width: 110px;
    border-bottom: none;
  }

  @media (max-width: ${PHONE_MODE_MAX_WIDTH}px) {
    background: transparent;
    width: auto;
    margin-right: ${theme.spacing(1)};
    padding: ${theme.spacing(1, 0)};
  }
`;

export const weaveLogoWithTextStyle = css`
  display: flex;
  align-items: center;
  width: 100%;

  .logo {
    width: 121px;
    height: 38px;
  }

  .tag {
    margin: ${theme.spacing(0, 0, 0, 2)};
    font-size: 0.75rem;
  }

  @media screen and (max-width: ${TABLET_MODE_MAX_WIDTH}px) {
    .logo {
      width: 32px;
      height: 32px;
    }

    .tag {
      margin: ${theme.spacing(0, 0, 0, 1)};
    }
  }
`;

export const userStyle = css`
  flex: 1;
  display: flex;
  justify-content: space-between;
  padding: ${theme.spacing(1, 2)};

  span {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 200px;

    ul {
      width: 230px;
    }
  }

  @media (max-width: ${PHONE_MODE_MAX_WIDTH}px) {
    padding: ${theme.spacing(1, 0)};

    span {
      justify-content: flex-end;
    }
  }
`;

export const userImageContainerStyle = css`
  border-radius: 50%;
  background-color: ${theme.colors.neutral20};
  width: 56px;
  height: 56px;
`;

export const defaultIconStyle = css`
  width: inherit;
  height: inherit;
  min-width: inherit !important;
`;

export const userTextContainerStyle = css`
  padding-left: ${theme.spacing(2)};

  @media (max-width: ${TABLET_MODE_MAX_WIDTH}px) {
    padding-left: ${theme.spacing(1)};
  }
`;

export const userNameStyle = css`
  font-weight: 700;
  font-size: ${theme.font.size.h2};
  color: ${theme.colors.neutral80};
  line-height: 1;

  > span {
    margin-right: ${theme.spacing(1)};
  }

  @media (max-width: ${TABLET_MODE_MAX_WIDTH}px) {
    > span {
      display: none;
    }
  }
`;

export const caretIconStyle = css`
  color: ${theme.colors.neutral30};
`;

export const officeNameStyle = css`
  color: ${theme.colors.neutral50};
  line-height: 1;

  @media (max-width: ${TABLET_MODE_MAX_WIDTH}px) {
    display: none;
  }
`;

export const nameContainer = css`
  display: flex;
  justify-content: center;
  align-items: center;

  h6 {
    font-size: 0.75rem;
    line-height: 1.375;
    color: rgb(141, 145, 155);
    max-width: 120px;
    white-space: nowrap;
    overflow: hidden;
    text-align: left;
    text-overflow: ellipsis;
    margin: 0;
    font-weight: bold;
    width: 100%;
  }
`;

export const nameWrap = css`
  margin-right: ${theme.spacing(1)};
  > p {
    margin: 0;
    font-size: 1rem;
    line-height: 1.375;
    color: rgb(49, 53, 61);
    font-weight: 800;
    text-align: left;
    max-width: 120px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
  }

  svg {
    margin-left: ${theme.spacing(1)};
  }
`;

export const flyoutMenuItemStyles = css`
  width: 185px;
  box-shadow: ${theme.shadows.heavy};
  top: 102%;
  right: 0;
  background-color: #eceff3;
  z-index: ${theme.zIndex.high};
`;

export const userProfileStyles = css`
  position: relative;
  cursor: pointer;
`;

export const productName = css`
  margin: ${theme.spacing(0.5)};
  line-height: 2rem;
  cursor: pointer;
`;

export const productVersionStyle = css`
  display: inline !important;
`;

export const personAndNotificationBadgeContainer = css`
  display: flex;
  align-items: center;
`;
