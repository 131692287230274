import { FC, useMemo, Children } from 'react';

import { ListboxChildren } from '@weave/design-system';

import { useDropdownContext } from 'shared/providers';
import { InputProps } from 'shared/types';

import DropdownInput from '../dropdown-input/dropdown-input.component';
import MultiSelectSelection from '../multi-select-selction/multi-select-selection.component';

type MultiSelectInputProps = InputProps<'multiselect'> & {
  options: ListboxChildren;
};

const MultiSelectInput: FC<MultiSelectInputProps> = ({ options, value, ...rest }) => {
  const { triggerProps, loadedItems, invalidItems } = useDropdownContext();

  const optionsMap = useMemo(() => {
    const map: Record<string, any> = {};

    for (const option of Children.toArray(options)) {
      const opt = option as any;
      map[opt.props.value] = opt.props.children;
    }

    return map;
  }, [options]);

  function getDisplayContent() {
    return value.map((val) => {
      let isInvalid = false;

      if (invalidItems && invalidItems.indexOf(val) !== -1) {
        isInvalid = true;
      }

      return (
        <MultiSelectSelection
          key={`selection-${val}`}
          selectionCount={value.length}
          onRemove={() => {
            rest.onChange({ name: rest.name, value: val });
            setTimeout(() => {
              triggerProps.ref.current?.focus();
            }, 0);
          }}
          isInvalid={isInvalid}
        >
          {loadedItems ? loadedItems[val] : optionsMap[val]}
        </MultiSelectSelection>
      );
    });
  }

  return (
    <DropdownInput
      as="div"
      valueToDisplay={getDisplayContent()}
      value=""
      {...rest}
      {...triggerProps}
    />
  );
};

export default MultiSelectInput;
