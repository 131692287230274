type WindowWithEnv = Window &
  typeof globalThis & {
    env?: { [key: string]: string };
  };

const win = window as WindowWithEnv;

// coalesce returns the first non-empty value
function coalesce(properties: any, errorMessage: any) {
  const notEmptyProperties = properties.filter((property: any) => property !== undefined);
  if (notEmptyProperties.length === 0) {
    throw new Error(errorMessage);
  }
  return notEmptyProperties[0];
}

// the env object doesn't get loaded for tests so initialize it here
// if it's empty
if (!win.env) {
  win.env = {};
}

export const APP_BASE_URL = coalesce(
  [win.env.REACT_APP_BASE_URL, process.env.REACT_APP_BASE_URL],
  'Failed to resolve dynamic env REACT_APP_BASE_URL.'
);

export const FORMS_EXPERIENCE_BASE_URL = coalesce(
  [win.env.REACT_APP_FORM_EXPERIENCE_URL, process.env.REACT_APP_FORM_EXPERIENCE_URL],
  'Failed to resolve dynamic env REACT_APP_FORM_EXPERIENCE_URL.'
);

export const API_URL = coalesce(
  [win.env.REACT_APP_API_URL, process.env.REACT_APP_API_URL],
  'Failed to resolve dynamic env REACT_APP_API_URL.'
);

export const APP_ENVIRONMENT = coalesce(
  [win.env.REACT_APP_APP_ENVIRONMENT, process.env.REACT_APP_APP_ENVIRONMENT],
  'Failed to resolve dynamic env REACT_APP_APP_ENVIRONMENT.'
);

export const REACT_APP_NOTIFICATION_SOCKET_URL = coalesce(
  [
    win.env.REACT_APP_NOTIFICATION_SOCKET_URL,
    process.env.REACT_APP_NOTIFICATION_SOCKET_URL,
  ],
  'Failed to resolve dynamic env REACT_APP_NOTIFICATION_SOCKET_URL'
);

export const REACT_APP_OKTA_CLIENT_ID = coalesce(
    [
        win.env.REACT_APP_OKTA_CLIENT_ID,
        process.env.REACT_APP_OKTA_CLIENT_ID,
    ],
    'Failed to resolve dynamic env REACT_APP_OKTA_CLIENT_ID'
);

export const REACT_APP_SIGN_IN_METHOD = coalesce(
    [
        win.env.REACT_APP_SIGN_IN_METHOD,
        process.env.REACT_APP_SIGN_IN_METHOD,
    ],
    'Failed to resolve dynamic env REACT_APP_SIGN_IN_METHOD'
);

export const REACT_APP_OIDC_ISSUER = coalesce(
    [
        win.env.REACT_APP_OIDC_ISSUER,
        process.env.REACT_APP_OIDC_ISSUER,
    ],
    'Failed to resolve dynamic env REACT_APP_OIDC_ISSUER'
);

export const REACT_APP_OIDC_CLIENT_ID = coalesce(
    [
        win.env.REACT_APP_OIDC_CLIENT_ID,
        process.env.REACT_APP_OIDC_CLIENT_ID,
    ],
    'Failed to resolve dynamic env REACT_APP_OIDC_CLIENT_ID'
);
