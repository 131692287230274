import { useQuery } from 'react-query';

import { authApi } from 'auth/auth.api';
import { getCurrentLocationId, getUserEmail, isWeaveUser } from 'shared/helpers/utils';
import { queryKeys } from 'shared/constants';

interface UseCustomAccessUserResults {
  isCustomAccessUser: boolean;
  isLoading: boolean;
}

export const useCustomAccessUser = (featureName: string): UseCustomAccessUserResults => {
  const { data, isLoading } = useQuery(
    [queryKeys.customAccessUser, featureName],
    () => checkIfCustomAccessUser(featureName),
    {
      enabled: isWeaveUser(),
    }
  );

  return {
    isCustomAccessUser: data || false,
    isLoading,
  };
};

async function checkIfCustomAccessUser(featureName: string) {
  const email = getUserEmail();

  if (!email) {
    return false;
  }

  return authApi.POST.isCustomAccessUser({
    email,
    locationID: getCurrentLocationId(),
    featureName,
  });
}
