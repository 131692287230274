import { BuilderFields } from 'shared/types';

export const primaryFieldsOrder: BuilderFields[] = [
  {
    label: 'Medical History',
    order: [
      {
        field: 'allergies',
        syncable: true,
        desc: 'Populates the list of allergies you have selected in patient medical history settings',
        index: 0,
      },
      {
        field: 'disease',
        syncable: true,
        desc: 'Populates the list of medical conditions you have selected in patient medical history settings',
        index: 1,
      },
      {
        field: 'medications',
        syncable: true,
        desc: 'Populates the list of medications you have selected in patient medical history settings',
        index: 2,
      },
    ],
  },
  {
    label: 'Personal Information: Identification',
    order: [
      {
        field: 'firstName',
        syncable: true,
        index: 3,
      },
      {
        field: 'middleName',
        syncable: true,
        index: 4,
      },
      {
        field: 'lastName',
        syncable: true,
        index: 5,
      },
      {
        field: 'preferredName',
        syncable: true,
        index: 6,
      },
      {
        field: 'birthdate',
        syncable: true,
        index: 7,
      },
      {
        field: 'gender',
        syncable: true,
        index: 8,
      },
      {
        field: 'maritalStatus',
        syncable: true,
        index: 9,
      },
      {
        field: 'socialSecurityNumber',
        syncable: false,
        index: 10,
      },
      {
        field: 'driversLicenseNumber',
        syncable: true,
        index: 11,
      },
    ],
  },
  {
    label: 'Contact Information',
    order: [
      {
        field: 'mobilePhone',
        syncable: true,
        index: 12,
      },
      {
        field: 'homePhone',
        syncable: true,
        index: 13,
      },
      {
        field: 'workPhone',
        syncable: true,
        index: 14,
      },
      {
        field: 'email',
        syncable: true,
        index: 15,
      },
    ],
  },
  {
    label: 'Residential Address',
    order: [
      {
        field: 'address1',
        syncable: true,
        index: 16,
      },
      {
        field: 'address2',
        syncable: true,
        index: 17,
      },
      {
        field: 'city',
        syncable: true,
        index: 18,
      },
      {
        field: 'state',
        syncable: true,
        index: 19,
      },
      {
        field: 'postalCode',
        syncable: true,
        index: 20,
      },
    ],
  },
  {
    label: 'Insurance Information',
    order: [
      {
        field: 'primaryInsuranceId',
        syncable: false,
        index: 21,
      },
      {
        field: 'primaryInsuranceAddress1',
        syncable: false,
        index: 22,
      },
      {
        field: 'primaryInsuranceAddress2',
        syncable: false,
        index: 23,
      },
      {
        field: 'primaryInsuranceCity',
        syncable: false,
        index: 24,
      },
      {
        field: 'primaryInsuranceName',
        syncable: false,
        index: 25,
      },
      {
        field: 'primaryInsurancePostalCode',
        syncable: false,
        index: 26,
      },
      {
        field: 'primaryInsuranceRelationship',
        syncable: false,
        index: 27,
      },
      {
        field: 'primaryInsuranceSSN',
        syncable: false,
        index: 28,
      },
      {
        field: 'primaryInsuranceSpouseName',
        syncable: false,
        index: 29,
      },
      {
        field: 'primaryInsuranceSpouseSSN',
        syncable: false,
        index: 30,
      },
      {
        field: 'primaryInsuranceState',
        syncable: false,
        index: 31,
      },
      {
        field: 'primaryPolicyHolderName',
        syncable: false,
        index: 32,
      },
      {
        field: 'primaryGroupNumber',
        syncable: false,
        index: 33,
      },
      {
        field: 'primaryRxNumber',
        syncable: false,
        index: 34,
      },
      {
        field: 'secondaryInsuranceId',
        syncable: false,
        index: 35,
      },
      {
        field: 'secondaryInsuranceAddress1',
        syncable: false,
        index: 36,
      },
      {
        field: 'secondaryInsuranceAddress2',
        syncable: false,
        index: 37,
      },
      {
        field: 'secondaryInsuranceCity',
        syncable: false,
        index: 38,
      },
      {
        field: 'secondaryInsuranceName',
        syncable: false,
        index: 39,
      },
      {
        field: 'secondaryInsurancePostalCode',
        syncable: false,
        index: 40,
      },
      {
        field: 'secondaryInsuranceRelationship',
        syncable: false,
        index: 41,
      },
      {
        field: 'secondaryInsuranceSSN',
        syncable: false,
        index: 42,
      },
      {
        field: 'secondaryInsuranceSpouseName',
        syncable: false,
        index: 43,
      },
      {
        field: 'secondaryInsuranceSpouseSSN',
        syncable: false,
        index: 44,
      },
      {
        field: 'secondaryInsuranceState',
        syncable: false,
        index: 45,
      },
      {
        field: 'secondaryPolicyHolderName',
        syncable: false,
        index: 46,
      },
      {
        field: 'secondaryGroupNumber',
        syncable: false,
        index: 47,
      },
      {
        field: 'secondaryRxNumber',
        syncable: false,
        index: 48,
      },
    ],
  },
];

export const elementsOrder: BuilderFields[] = [
  {
    label: 'Medical History',
    order: [
      {
        field: 'staticAllergies',
        index: 0,
      },
      {
        field: 'medicalConditions',
        index: 1,
      },
      {
        field: 'femaleOnlyConditions',
        index: 2,
      },
    ],
  },
  {
    label: 'Generic Form Fields',
    order: [
      {
        field: 'eSign',
        index: 6,
      },
      {
        field: 'cardCapture',
        index: 3,
      },
      {
        field: 'checklist',
        index: 4,
      },
      {
        field: 'checkbox',
        index: 5,
      },
      {
        field: 'date',
        index: 6,
      },
      {
        field: 'signature',
        index: 7,
      },
      {
        field: 'select',
        index: 8,
      },
      {
        field: 'email',
        index: 9,
      },
      {
        field: 'richText',
        index: 10,
      },
      {
        field: 'textarea',
        index: 11,
      },
      {
        field: 'text',
        index: 12,
      },
    ],
  },
  {
    label: 'Survey Answers',
    order: [
      {
        field: 'radio',
        index: 13,
      },
      {
        field: 'binaryDecision',
        index: 14,
      },
    ],
  },
  {
    label: 'Contact Information',
    order: [
      {
        field: 'phone',
        index: 15,
      },
      {
        field: 'postalCode',
        index: 16,
      },
      {
        field: 'referralSource',
        index: 17,
      },
      {
        field: 'ssn',
        index: 18,
      },
    ],
  },
];
