import { FC, FocusEvent, KeyboardEvent, useState } from 'react';
import {
  CheckboxField,
  EditSimpleIcon,
  KeyNames,
  TextField,
  useFormField,
} from '@weave/design-system';
import { css } from '@emotion/core';
import { BuilderContext, IFormBuilder as FB } from 'shared/types';
import { useFormComponentsContext } from 'context';

interface BuilderCheckboxProps {
  field: FB.Checkbox;
}

const checboxWrapperStyle = css`
  display: flex;
`;

export const BuilderCheckbox: FC<BuilderCheckboxProps> = ({ field }) => {
  const { updateFormState } = useFormComponentsContext();
  const [isEditing, setIsEditing] = useState(false);
  const fieldProps = useFormField({
    type: 'checkbox',
  });
  const textFieldProps = useFormField(
    {
      type: 'text',
      value: field.label,
    },
    [field.label]
  );

  const onLabelUpdate = () => {
    updateFormState({
      type: BuilderContext.FormUpdateType.UPDATE_FIELD,
      data: {
        ...field,
        label: textFieldProps.value,
      },
    });
    setIsEditing(false);
  };

  const detectEnterPress = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === KeyNames.Enter) {
      onLabelUpdate();
    }
  };

  const onCheckboxInputTextBlur = (e?: FocusEvent<Element, Element>) => {
    if (e) {
      // hacky: check if actionText button is clicked
      // this isn't handled in the design system
      if (e.relatedTarget && e.relatedTarget.innerHTML === 'Update') {
        return;
      } else {
        setIsEditing(false);
      }
    } else {
      setIsEditing(false);
    }
  };

  return (
    <>
      {!isEditing ? (
        <div css={checboxWrapperStyle}>
          <CheckboxField name={field.id} label={field.label} {...fieldProps} />
          {/* icon class is being used in field.style.ts  */}
          <EditSimpleIcon
            className="edit-checkbox-icon"
            size={18}
            color="light"
            onClick={() => setIsEditing(true)}
          />
        </div>
      ) : (
        <TextField
          autoFocus
          {...textFieldProps}
          name={field.id}
          label=""
          actionText="Update"
          onActionClick={onLabelUpdate}
          onBlur={onCheckboxInputTextBlur}
          onKeyDown={detectEnterPress}
        />
      )}
    </>
  );
};
