import { lazy } from 'react';
import { RouteProps } from 'react-router-dom';

import * as paths from 'shared/constants/paths';

export const publicRoutes: RouteProps[] = [
  {
    path: paths.SIGN_IN,
    component: lazy(() => import('./sign-in/sign-in.component')),
  },
  {
    path: '/',
    exact: true,
    component: lazy(() => import('./sign-in/sign-in.component')),
  },
  {
    path: paths.LOCATION,
    component: lazy(() => import('../pages/select-location/location-container')),
  },
  {
    path: paths.FRONT_CHANNEL_LOGOUT,
    component: lazy(() => import('../pages/logout/logout.component')),
  },
];

const SUBMISSIONS_ROUTE: RouteProps = {
  path: paths.FORM_SUBMISSION_ROOT,
  component: lazy(() => import('./form-submissions/form-submissions.page')),
};

const KIOSK_ROUTE: RouteProps = {
  path: paths.KIOSK_MODE.root,
  component: lazy(() => import('./kiosk-mode/kiosk-mode.page')),
};

interface IPrivateRoutesConfig {
  isUnifyParentLocation?: boolean;
  hasProviderReviewPermission: boolean;
}

export function getPrivateRoutes(
  { isUnifyParentLocation, hasProviderReviewPermission }: IPrivateRoutesConfig = {
    hasProviderReviewPermission: false,
  }
) {
  if (hasProviderReviewPermission) {
    const practitionerRoutes: RouteProps[] = [SUBMISSIONS_ROUTE, KIOSK_ROUTE];

    return practitionerRoutes;
  }

  const routes: RouteProps[] = [
    SUBMISSIONS_ROUTE,
    {
      path: paths.SETTINGS.root,
      component: lazy(() => import('./settings/settings.page')),
    },
    {
      path: paths.TRAINING_VIDEOS.root,
      component: lazy(() => import('./training-videos/training-help.page')),
    },
    {
      path: paths.FORM_BUILDER.root,
      component: lazy(() => import('./form-builder/form-builder.page')),
    },
    {
      path: paths.FORM_TEMPLATES_BASE,
      component: lazy(() => import('./form-builder/form-builder.page')),
    },
    {
      path: paths.FORM_PACKETS_BASE,
      component: lazy(() => import('./form-builder/form-builder.page')),
    },
    {
      path: paths.LEGAL_DOCUMENTS_BASE,
      component: lazy(() => import('./form-builder/form-builder.page')),
    },
    {
      path: paths.WEAVE_TEMPLATES.template,
      component: lazy(() => import('./form-templates/form-templates.page')),
    },
  ];

  if (!isUnifyParentLocation) {
    routes.push(KIOSK_ROUTE);
  }

  return routes;
}
