import { FC } from 'react';
import { Link } from 'react-router-dom';

import { Text } from '@weave/design-system';

import { useWritebackCapabilitiesContext } from 'context';
import { CustomModalButtons } from 'shared/components';
import { linkStyle } from 'shared/styles';
import { pendoTags } from 'shared/constants';
import { WRITEBACKS } from 'shared/constants/paths';

import { textStyle } from './single-pms-notification.styles';

interface SinglePMSNotificationProps {
  onCloseModal: () => void;
}

const SinglePMSNotification: FC<SinglePMSNotificationProps> = ({ onCloseModal }) => {
  const { getPreferredSourceTenantDetails } = useWritebackCapabilitiesContext();

  const preferredSourceTenantDetails = getPreferredSourceTenantDetails(true);
  const sourceName = preferredSourceTenantDetails?.sourceName ?? '';
  const pmsName = preferredSourceTenantDetails?.pmsName ?? '';

  return (
    <>
      <Text css={textStyle}>
        We noticed that your system of record was changed recently. Please note that your
        form submissions will now writeback to <b>{sourceName}</b> [{pmsName}]. For your
        convenience, we have restored your previous{' '}
        <Link
          to={WRITEBACKS.setting}
          css={linkStyle}
          onClick={onCloseModal}
          data-trackingid={pendoTags.preferredSourceCorrection.navigateToWBSettings}
        >
          writeback settings
        </Link>
        . There's no action required from you.
      </Text>

      <CustomModalButtons
        primaryButtonText="Dimiss"
        onClickPrimaryButton={onCloseModal}
        primaryButtonTrackingId={
          pendoTags.preferredSourceCorrection.updatePreferredSinglePMS
        }
      />
    </>
  );
};

export default SinglePMSNotification;
