export const BADGE_LABELS = {
  NOT_SYNCED: 'Not Synced',
  SYNCED: 'Synced',
  NEEDS_APPROVAL: 'Needs Manual Sync',
  FAILED_SYNC: 'Failed Sync',
  NOT_SUBMITTED: 'Not Submitted',
  WRITEBACK_DISABLED: 'Writeback disabled',
  NOT_APPLICABLE: 'Not Applicable',
  SUBMITTED: 'Submitted',
  NOT_COMPLETED: 'Not Completed',
  ARCHIVED: 'Archived',
  NOT_SYNCABLE_CONTACT: 'Submitted',
  REVIEW_APPROVED: 'Approved',
  REVIEW_REJECTED: 'Rejected',
  NEEDS_REVIEW: 'Needs Review',
  EXPIRED: 'Expired',
};

export const BADGE_LONG_LABELS = {
  NOT_SYNCED: 'Writesback Automatically',
  SYNCED: 'Writeback was successful',
  NEEDS_APPROVAL: 'Manual sync required',
  FAILED_SYNC: 'Writeback failed. Retry Sync.',
  NOT_SUBMITTED: 'Not yet submitted by the patient',
  WRITEBACK_DISABLED: 'Writeback is disabled in settings',
  NOT_APPLICABLE: 'Writeback is not applicable',
  SUBMITTED: 'Submission obtained from patient',
  NOT_COMPLETED: 'You have to Mark as Complete',
  ARCHIVED: 'Submission has been archived',
  NOT_SYNCABLE_CONTACT: 'Submitted but does not sync',
};
