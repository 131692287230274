import { FC, Fragment, useEffect, useState } from 'react';
import {
  DataSyncIcon,
  SearchField,
  Text,
  TextLink,
  XIconSmall,
} from '@weave/design-system';
import { IFormBuilder as FB } from 'shared/types';
import { useFormComponentsContext } from 'context';
import {
  categoryNameStyle,
  fieldItemsStyle,
  panelDescriptionStyle,
  panelDescriptionTextStyle,
  panelDescSyncIconStyle,
  tabsContentStyle,
} from './form-fields.style';
import { FormFieldItem } from '../form-field-item/form-field-item.component';
import { useComponentSearch } from './useComponentSearch';
import { Droppable } from 'react-beautiful-dnd';
import { pendoTags } from 'shared/constants';
import { handleLocalStorage } from 'auth/auth.helpers';

interface PrimaryFieldsProps {
  fields: FB.IPrimaryFields;
}

const primaryFieldDescCollaped = 'pf_desc_view';

export const PrimaryFields: FC<PrimaryFieldsProps> = ({ fields }) => {
  const [descPanelCollapsed, toggleDescPanel] = useState(true);
  const { primaryFieldsOrder } = useFormComponentsContext();

  useEffect(() => {
    const desc_collapsed_status = handleLocalStorage.get(primaryFieldDescCollaped);
    toggleDescPanel(desc_collapsed_status === 'collaped');
  }, []);

  const { filteredFields, searchProps } = useComponentSearch(
    primaryFieldsOrder,
    true,
    fields
  );

  const hideMedicalHistoryFields =
    !fields.hasOwnProperty('disease') &&
    !fields.hasOwnProperty('medications') &&
    !fields.hasOwnProperty('allergies');

  const onTogglePanelDesc = () => {
    handleLocalStorage.create(
      primaryFieldDescCollaped,
      !descPanelCollapsed ? 'collaped' : 'expanded'
    );
    toggleDescPanel((prev) => !prev);
  };

  return (
    <Droppable droppableId="primary-field" isDropDisabled={true}>
      {(provided) => (
        <div css={tabsContentStyle} {...provided.droppableProps} ref={provided.innerRef}>
          <SearchField
            name="pf-search"
            {...searchProps}
            autoComplete={'off'}
            data-trackingid={pendoTags.newFormEditor.searchFields}
          />
          <div
            css={panelDescriptionStyle(!descPanelCollapsed)}
            className={'expanded-view'}
          >
            {descPanelCollapsed ? (
              <>
                <div>
                  <Text css={panelDescriptionTextStyle} size={'small'}>
                    Primary Fields are pre-built data fields.
                  </Text>
                  <Text css={panelDescriptionTextStyle} size={'small'}>
                    <DataSyncIcon
                      css={panelDescSyncIconStyle}
                      className="writeable-icon"
                      size={18}
                    />{' '}
                    indicates fields that writeback to your system of record, if{' '}
                    <TextLink href="/settings/writebacks">setup</TextLink> correctly.
                  </Text>
                  <Text css={panelDescriptionTextStyle} size={'small'}>
                    <DataSyncIcon css={panelDescSyncIconStyle} color="light" size={18} />
                    indicates fields that do not writeback at present.
                  </Text>
                </div>
                <XIconSmall
                  className="close-icon"
                  color="light"
                  onClick={onTogglePanelDesc}
                />
              </>
            ) : (
              <Text
                css={panelDescriptionTextStyle}
                className="collapsed-desc"
                size={'small'}
                color="light"
                onClick={onTogglePanelDesc}
              >
                <DataSyncIcon
                  css={panelDescSyncIconStyle}
                  className="writeable-icon"
                  size={16}
                />
                These fields writeback to your system of record
              </Text>
            )}
          </div>
          <ul css={fieldItemsStyle}>
            {filteredFields.map((category) => {
              if (category.label === 'Medical History' && hideMedicalHistoryFields) {
                return null;
              }
              return (
                <Fragment key={category.label}>
                  <Text css={categoryNameStyle} color="light">
                    {category.label}
                  </Text>
                  {category.order.map((fieldItem) => {
                    return (
                      <FormFieldItem
                        key={fieldItem.field}
                        {...fieldItem}
                        isPrimaryField={true}
                        field={fields[fieldItem.field]}
                        fieldName={fieldItem.field}
                        isUsed={fieldItem.disabled ?? false}
                      />
                    );
                  })}
                </Fragment>
              );
            })}
          </ul>
        </div>
      )}
    </Droppable>
  );
};
