export const pendoTags = {
  activitiesPage: 'forms-client-pg-activities',
  builderPage: 'forms-client-pg-builder',
  trainingPage: 'forms-client-pg-training',
  kioskPage: {
    _: 'forms-client-pg-kiosk',
    customiseOrder: {
      start: 'forms-kiosk-customise-kiosk-form',
      sort: 'forms-kiosk-customise-kiosk-form-sort',
      save: 'forms-kiosk-customise-kiosk-form-save',
      togglePin: 'forms-kiosk-customise-kiosk-form-toggle-pin',
      toggleVisibility: 'forms-kiosk-customise-kiosk-form-toggle-visibility',
    },
    downloadKioskQr: 'forms-kiosk-download-kiosk-qr',
    staffCheckIn: {
      start: 'forms-kiosk-staff-check-in-start',
      appointmentDate: {
        selected: 'forms-kiosk-appointment-date-selected',
        revisited: 'forms-kiosk-appointment-date-revisited',
        skippped: 'forms-kiosk-appointment-date-skipped',
      },
      recipient: {
        selected: 'forms-kiosk-recipient-selected',
        revisited: 'forms-kiosk-recipient-revisited',
      },
      form: {
        selected: 'forms-kiosk-form-or-packet-selected',
      },
      attachment: {
        markedAsSignatureRequired: 'forms-kiosk-attachment-marked-as-signature-required',
        markedAsSignatureNotRequired:
          'forms-kiosk-attachment-marked-as-signature-not-required',
        removed: 'forms-kiosk-attachment-removed',
        skipped: 'forms-kiosk-attachment-skipped',
        next: 'forms-kiosk-attachment-added-to-form',
      },
      kioskOpened: 'forms-kiosk-solicited-link-opened',
    },
    patronCheckIn: {
      start: 'forms-kiosk-patron-check-in-start',
      kioskOpened: 'forms-client-btn-launchkiosk',
    },
  },
  settingsPage: 'forms-client-pg-settings',
  profileButton: 'forms-client-btn-profile',
  companyChangeButton: 'forms-client-btn-changecompany',
  inProgressButton: 'forms-client-btn-allinprog',
  recentSubmitButton: 'forms-client-btn-recentsubmit',
  needsReview: 'forms-client-btn-needsreview',
  pendingButton: 'forms-client-btn-pending',
  approvalNeededButton: 'forms-client-btn-approvalneeded',
  failedSyncButton: 'forms-client-btn-failedsync',
  reviewedSubmissions: {
    reviewed: 'forms-btn-reviewed',
    approved: 'forms-btn-reviewed-approved',
    rejected: 'forms-btn-reviewed-rejected',
  },
  completed: {
    category: 'forms-client-btn-completed',
    tabs: {
      withWritebacks: 'forms-client-btn-completed-withwb',
      withoutWritebacks: 'forms-client-btn-completed-wowb',
    },
  },
  eaglesoftMedicalHistorySyncButton: 'forms-client-btn-custom-med-history-form',
  viewFormData: 'forms-client-btn-viewformdata',
  nextForm: 'forms-client-btn-viewright',
  previousForm: 'forms-client-btn-viewleft',
  viewFormPreview: 'forms-client-btn-viewform',
  viewAttachmentsPreview: 'forms-client-btn-viewattach',
  attachmentDownload: 'forms-client-btn-viewdownload',
  formApproveButton: 'forms-client-btn-viewapprove',
  modalApproveButton: 'forms-client-btn-modalsubmitwb',
  modalCancelButton: 'forms-client-btn-modalcancel',
  modalViewFormButton: 'forms-client-btn-modalform',
  modalViewAttachmentsButton: 'forms-client-btn-modalattach',
  modalDownloadButton: 'forms-client-btn-modaldownload',
  modalPrintButton: 'forms-client-btn-modalprint',
  modalEditButton: 'forms-client-btn-modaledit',
  modalExitButton: 'forms-client-btn-modalexit',
  formNavigator: 'forms-client-btn-form-navigator',
  searchbarField: 'forms-client-btn-searchbar',
  searchFilterButton: 'forms-client-btn-searchfilter',
  templatesButton: 'forms-client-btn-templates',
  packetsButton: 'forms-client-btn-packets',
  legalButton: 'forms-client-btn-legaldocs',
  templateSearch: 'forms-client-btn-templatesearchbar',
  templateSearchFilter: 'forms-client-btn-templatesearchfilter',
  viewTemplate: 'forms-client-btn-viewtemplate',
  templateMenuButton: 'forms-client-btn-templatemenu',
  templateCopyButton: 'forms-client-btn-templatecopy',
  templateEditButton: 'forms-client-btn-templateedit',
  templateDeleteButton: 'forms-client-btn-templatedelete',
  viewPacket: 'forms-client-btn-viewpacket',
  packetSearch: 'forms-client-btn-packetsearch',
  packetRearrange: 'forms-client-btn-rearrange',
  packetViewFormButton: 'forms-client-btn-viewincludedform',
  packetRemoveFormButton: 'forms-client-btn-remove',
  savePacketButton: 'forms-client-btn-savepacket',
  addPacketButton: 'forms-client-btn-newpacket',
  packetMenuButton: 'forms-client-btn-packetmenu',
  packetNameEditButton: 'forms-client-btn-editpacketname',
  packetCopyButton: 'forms-client-btn-copypacket',
  packetDeleteButton: 'forms-client-btn-deletepacket',
  brandingButton: 'forms-client-btn-branding',
  writebacksButton: 'forms-client-btn-wbsettings',
  medicalButton: 'forms-client-btn-medical',
  patientCreationSetting: 'forms-client-btn-patientcreation',
  patientUpdateSetting: 'forms-client-btn-patientupdate',
  pdfUploadSetting: 'forms-client-btn-PDF',
  packetPDFDownloadButton: 'forms-client-btn-packetPDF',
  markAsUnread: 'forms-client-btn-markasunread-web',
  writebackOnOff: 'forms-client-btn-onoff',
  writebackAuto: 'forms-client-btn-automatic',
  writebackManual: 'forms-client-btn-manual',
  writebackRetry: 'forms-client-btn-retrywb',
  changeWritebackSyncApp: 'forms-client-btn-changePMSforsubmission',
  medicalHistory: {
    syncButton: 'forms-client-btn-medhistorysync',
    optionToggle: 'forms-client-btn-medhistorytoggle',
    eagleSoft: 'forms-client-btn-template-ESmedhistory',
  },
  manualWriteback: {
    needApproval: {
      setToSyncManually: 'forms-client-btn-needapproval-manual',
      setToNotSyncAtAll: 'forms-client-btn-needapproval-nosync',
    },
    syncAll: 'forms-client-btn-manualwb-all',
    syncSelected: 'forms-client-btn-manualwb-selected',
  },
  archive: {
    category: 'forms-client-btn-archived',
    action: {
      bulkArchive: 'forms-client-btn-archiveCTA',
      bulkUnarchive: 'forms-client-btn-unarchiveCTA',
      singleArchive: 'forms-client-btn-menu-archiveCTA',
      singleUnarchive: 'forms-client-btn-menu-unarchiveCTA',
    },
  },
  sortOrder: 'forms-client-btn-sort-order',
  reminderModals: {
    writebackAuthorization: {
      settingsPageLink: 'forms-client-btn-dialog-authwb-UI',
      weaveHelpLink: 'forms-client-btn-dialog-authwb-help',
    },
  },
  settings: {
    writebacks: {
      authorize: 'forms-client-tab-authorizewb',
      deauthorize: 'forms-client-tab-deauthorizewb',
      confirmAuthorize: 'forms-client-btn-modalconfirmauthorize',
      confirmDeauthorize: 'forms-client-btn-modalconfirmdeauthorize',
      changeDefaultPMS: 'forms-client-btn-changedefaultsyncapp',
      confirmDefaultPMS: 'forms-client-btn-confirmdefaultsyncapp',
      cancelDefaultPMSChange: 'forms-client-btn-canceldefaultsyncappchange',
      saveUnifySettings: 'forms-client-btn-saveunifysettings',
      toggleWritebackDestinationAccordion:
        'forms-client-btn-togglewbdestinationaccordion',
    },
    providerMapping: {
      save: 'forms-client-btn-saveprovidermapping',
      changeMapping: 'forms-client-btn-changeprovidermapping',
      getHelp: 'forms-client-btn-getprovidermappinghelp',
    },
  },
  newFormEditor: {
    editorPage: 'form-builder-page',
    previewForm: 'form-builder-preview',
    generatePDF: 'form-builder-generate-pdf',
    saveForm: 'form-builder-save-form',
    newSection: 'form-builder-new-section',
    reorderField: 'form-builder-reorder-field',
    searchFields: 'form-builder-search-fields',
    fieldLabelUpdate: 'form-builder-field-label-update',
    sectionLabelUpdate: 'form-builder-section-label-update',
    conditionalLogic: {
      addCondition: 'form-builder-add-condition',
      showField: 'form-builder-condition-show-field',
      hideField: 'form-builder-condition-hide-field',
      requireField: 'form-builder-condition-require-field',
      optionalField: 'form-builder-condition-optional-field',
    },
    sectionTemplate: {
      allergies: 'form-builder-allergies-template',
      covid: 'form-builder-covid-template',
      femaleMedicalConditions: 'form-builder-female-medical-condition-template',
      medicalConditions: 'form-builder-medical-condition-template',
      patient: 'form-builder-personal-information-template',
      primaryInsurance: 'form-builder-primary-insurance-template',
      secondaryInsurance: 'form-builder-secondary-insurance-template',
    },
  },
  beta: {
    unifyOptOut: 'form-client-btn-unify-beta-optout',
  },
  templateGallery: {
    navLink: 'template-gallery-nav',
    termsAndConditions: {
      accepted: 'template-gallery-terms-and-conditions-accepted',
      declined: 'template-gallery-terms-and-conditions-declined',
    },
    views: {
      gallery: 'template-gallery-gallery-view',
      list: 'template-gallery-list-view',
    },
    copyTemplate: 'template-gallery-copy-template',
    previewButton: 'template-gallery-preview-btn',
    preview: {
      desktop: 'template-gallery-desktop-preview',
      mobile: 'template-gallery-mobile-preview',
    },
    markAs: {
      favourite: 'template-gallery-favourite-the-template',
      unfavourite: 'template-gallery-unfavourite-the-template',
    },
    sort: {
      aToZ: 'template-gallery-sort-a-z',
      zToA: 'template-gallery-sort-z-a',
      oldToNew: 'template-gallery-sort-old-to-new',
      newToOld: 'template-gallery-sort-new-to-old',
      mostPopular: 'template-gallery-sort-by-most-popular',
    },
    search: 'template-gallery-search',
    usageTerms: {
      accept: 'template-gallery-usage-terms-accept',
      decline: 'template-gallery-usage-terms-decline',
    },
  },
  providerReview: {
    toggleReviewForm: 'forms-toggle-provider-review',
    toggleReviewPacket: 'packet-toggle-provider-review',
    modal: {
      approve: 'forms-provider-review-approve',
      reject: 'forms-provider-review-reject',
    },
    signature: {
      saveDefaultSignature: 'forms-provider-review-save-default-signature',
      wetSignature: 'forms-provider-review-wet-signature',
      image: 'forms-provider-review-signature-image',
      typed: 'forms-provider-review-signature-typed',
    },
    approveNotes: {
      approveBtn: 'forms-provider-review-approve-notes-approve',
      continueToSync: 'forms-provider-review-approve-notes-continue-to-sync',
    },
    rejectSubmission: 'forms-provider-review-reject-submission',
    writebackModal: {
      sync: 'forms-provider-review-writeback-modal-sync',
      approve: 'forms-provider-review-writeback-modal-approve',
    },
    changeProvider: 'forms-provider-review-change-provider',
  },
  writebackWidget: {
    open: 'forms-wb-widget-open',
    close: 'forms-wb-widget-close',
    backdropClose: 'forms-wb-widget-close-using-backdrop',
    approve: 'forms-wb-widget-approve',
    showRejectUI: 'forms-wb-widget-show-reject-ui',
    rejectConfirm: 'forms-wb-widget-reject-confirm',
    cancelReject: 'forms-wb-widget-cancel-reject',
    retrySync: 'forms-wb-widget-retry-sync',
    sync: 'forms-wb-widget-sync',
  },
  writebackAuthSetup: {
    termsOfUse: 'forms-wb-setup-terms-of-use',
    getStarted: 'forms-wb-setup-get-started',
    cancelSourceSelection: 'forms-wb-setup-cancel-source-selection',
    selectAnotherSource: 'forms-wb-setup-select-another-source',
    reviewSourceSelection: 'forms-wb-setup-review-source-selection',
    goToDashboard: 'forms-wb-setup-go-to-dashboard',
    goToWBSettings: 'forms-wb-setup-go-to-wb-settings',
    skipSetup: 'forms-wb-setup-skip',
    contactSupport: 'forms-wb-setup-contact-support',
    continueSourceSelection: 'forms-wb-setup-continue-source-selection',
  },
  preferredSourceCorrection: {
    cancelNotSure: 'forms-wb-source-correction-not-sure',
    updatePreferredMultiPMS: 'forms-wb-source-correction-save-for-multi-pms',
    updatePreferredSinglePMS: 'forms-wb-source-correction-save-for-single-pms',
    navigateToWBSettings: 'forms-wb-source-correction-navigate-to-settings',
  },
};
