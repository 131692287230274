import { render } from 'react-dom';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';

import { App } from './app';

import {
  APP_BASE_URL,
  APP_ENVIRONMENT,
  REACT_APP_OIDC_CLIENT_ID,
  REACT_APP_OIDC_ISSUER,
  REACT_APP_OKTA_CLIENT_ID, REACT_APP_SIGN_IN_METHOD
} from 'shared/constants';
import {authnClient} from "@weave/auth";

if (APP_ENVIRONMENT !== 'development') {
  Sentry.init({
    dsn: 'https://732e1b3943fb4a6d9d8e32c41c112df9@sentry.gke1-west3.wsf-prod-1.wstack.net/45',
    integrations: [new BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.05,
    debug: false,
    environment: APP_ENVIRONMENT,
    attachStacktrace: true,
  });
}

authnClient.setConfig({
  oktaIssuer: 'https://auth.weaveconnect.com/oauth2/default',
  oktaClientID: REACT_APP_OKTA_CLIENT_ID as string,
  oktaRedirectUri: `${window.location.origin}/form-submissions`,
  oidcIssuer: REACT_APP_OIDC_ISSUER,
  oidcClientID: REACT_APP_OIDC_CLIENT_ID,
  oidcRedirectUri: `${window.location.origin}/form-submissions`,
  backendAPI: APP_BASE_URL,
  appSource: REACT_APP_SIGN_IN_METHOD === 'oidc' ? REACT_APP_OIDC_CLIENT_ID : 'forms',
});

render(<App />, document.getElementById('root'));
