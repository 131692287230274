import { css } from '@emotion/core';
import { theme } from '@weave/theme';

import { TABLET_MODE_MAX_WIDTH } from 'shared/constants';

interface ModalStylesConfig {
  isMultiPMSNotification: boolean;
}

export function getModalStyles({ isMultiPMSNotification }: ModalStylesConfig) {
  return css`
    padding: ${theme.spacing(3, 2)};

    @media only screen and (min-width: 448px) {
      width: 100% !important;
      max-width: ${isMultiPMSNotification
        ? `${TABLET_MODE_MAX_WIDTH}px`
        : '630px'} !important;
    }
  `;
}

export const bodyStyle = css`
  display: flex;
  flex-direction: column;
`;
