import { FC } from 'react';

import {
  successBadgeStyle,
  successBadgeHoverStyle,
  partialSuccessBadgeStyle,
  partialSuccessBadgeHoverStyle,
  disabledBadgeStyle,
  disabledBadgeHoverStyle,
  disabledTwoBadgeStyle,
  disableTwoBadgeHoverStyle,
  warningOrangeBadgeStyle,
  warningOrangeBadgeHoverStyle,
  warningEggplantBadgeStyle,
  warningEggplantBadgeHoverStyle,
  infoBadgeStyle,
  infoBadgeHoverStyle,
  errorBadgeStyle,
  errorBadgeHoverStyle,
  idleBadgeStyle,
  idleBadgeHoverStyle,
  archivedBadgeStyle,
  archivedBadgeHoverStyle,
  softErrorBadgeStyle,
} from './badge.style';

interface BadgeProps {
  type:
    | 'success'
    | 'error'
    | 'soft-error'
    | 'warning-orange'
    | 'warning-eggplant'
    | 'info'
    | 'disabled'
    | 'disabled-two'
    | 'idle'
    | 'partial-success'
    | 'archived';
  alternateMessage?: string;
  showAlternateMessage?: boolean;
}

export const Badge: FC<BadgeProps> = ({
  type,
  alternateMessage,
  showAlternateMessage,
  children,
  ...otherProps
}) => {
  let style = disabledBadgeStyle;

  switch (type) {
    case 'warning-orange':
      style = showAlternateMessage
        ? warningOrangeBadgeHoverStyle
        : warningOrangeBadgeStyle;
      break;

    case 'warning-eggplant':
      style = showAlternateMessage
        ? warningEggplantBadgeHoverStyle
        : warningEggplantBadgeStyle;
      break;

    case 'success':
      style = showAlternateMessage ? successBadgeHoverStyle : successBadgeStyle;
      break;

    case 'partial-success':
      style = showAlternateMessage
        ? partialSuccessBadgeHoverStyle
        : partialSuccessBadgeStyle;
      break;

    case 'info':
      style = showAlternateMessage ? infoBadgeHoverStyle : infoBadgeStyle;
      break;

    case 'error':
      style = showAlternateMessage ? errorBadgeHoverStyle : errorBadgeStyle;
      break;

    case 'idle':
      style = showAlternateMessage ? idleBadgeHoverStyle : idleBadgeStyle;
      break;

    case 'archived':
      style = showAlternateMessage ? archivedBadgeHoverStyle : archivedBadgeStyle;
      break;

    case 'disabled':
      style = showAlternateMessage ? disabledBadgeHoverStyle : disabledBadgeStyle;
      break;

    case 'disabled-two':
      style = showAlternateMessage ? disableTwoBadgeHoverStyle : disabledTwoBadgeStyle;
      break;

    case 'soft-error':
      style = softErrorBadgeStyle;
  }

  return (
    <span css={style} {...otherProps}>
      {alternateMessage && showAlternateMessage ? alternateMessage : children}
    </span>
  );
};
