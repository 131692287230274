import { useState } from 'react';
import { useHistory } from 'react-router';

import { DropdownField, Text, useControlledField } from '@weave/design-system';

import { FORM_SUBMISSION_CATEGORY_SLUGS } from 'shared/constants/paths';
import { SubNav } from 'shared/types';

import {
  dropdownNavStyles,
  dropdownOptionStyle,
  dropdownOptionTextStyle,
  dropdownItemTitleStyle,
  nestedNavLinkStyle,
} from './dropdown-nav.style';

interface DropdownNavProps {
  subNavs: SubNav[];
  defaultValue?: string;
  onChange?: (route: SubNav) => void;
}

export const DropdownNav = ({ subNavs, defaultValue, onChange }: DropdownNavProps) => {
  const history = useHistory();
  const [currentNav, setCurrentNav] = useState(defaultValue || subNavs[0].title);

  const dropdownProps = useControlledField({
    type: 'dropdown',
    value: currentNav,
    onChange: (value: string) => {
      setCurrentNav(value);
      let route: SubNav | undefined = undefined;

      for (const subNav of subNavs) {
        const { title, nestedLinks } = subNav;

        if (title === value) {
          route = subNav;
          break;
        }

        if (nestedLinks) {
          for (const nestedLink of nestedLinks) {
            if (nestedLink.title === value) {
              route = nestedLink;
              break;
            }
          }
        }
      }

      if (route) {
        history.push(route.to);

        if (onChange) {
          onChange(route);
        }
      }
    },
  });

  function getDropdownFieldOptionsView(navLinks: SubNav[]) {
    return navLinks.map(
      ({
        title,
        to,
        showCount,
        count,
        unreadCount,
        abbreviatedCount,
        abbreviatedUnreadCount,
        nestedLinks,
        id,
        hidden,
      }) => {
        if (hidden) {
          return null;
        }

        let countToShow = count;
        let abbreviatedCountToShow = abbreviatedCount;

        if (id === FORM_SUBMISSION_CATEGORY_SLUGS.allSubmitted) {
          countToShow = unreadCount;
          abbreviatedCountToShow = abbreviatedUnreadCount;
        }

        const mainOption = (
          <DropdownField.Option css={dropdownOptionStyle} value={title} key={to}>
            <span css={dropdownOptionTextStyle}>
              {countToShow !== undefined && countToShow !== 0 && !!showCount && (
                <Text className="count" as="span">
                  {abbreviatedCountToShow}
                </Text>
              )}

              <Text as="span" css={dropdownItemTitleStyle}>
                {title}
              </Text>
            </span>
          </DropdownField.Option>
        );

        if (nestedLinks) {
          const nestedNavLinks = (
            <DropdownField.OptionGroup label="" key={`options-group-${to}`}>
              {nestedLinks.map(({ title: nestedNavLinkTitle, to: nestedNavLinkTo }) => (
                <DropdownField.Option
                  value={nestedNavLinkTitle}
                  key={nestedNavLinkTo}
                  css={dropdownOptionStyle}
                >
                  <span css={nestedNavLinkStyle}>
                    <Text as="span" css={dropdownItemTitleStyle}>
                      {nestedNavLinkTitle}
                    </Text>
                  </span>
                </DropdownField.Option>
              ))}
            </DropdownField.OptionGroup>
          );

          return [mainOption, nestedNavLinks];
        }

        return mainOption;
      }
    );
  }

  return (
    <DropdownField
      {...dropdownProps}
      label=""
      name="Dropdown Nav"
      css={dropdownNavStyles}
    >
      {getDropdownFieldOptionsView(subNavs)}
    </DropdownField>
  );
};
