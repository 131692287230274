import { getCurrentLocationId } from 'shared/helpers/utils';
import { UnreadCountResponse, SubmissionStatsResponse } from 'shared/types';
import { CustomAxios } from '../axios-helpers';

interface SubmissionStatsParams {
  submitted_from: number;
  dashboard_label?: number;
  is_location_writeback_capable?: boolean;
  show_unread_count?: boolean;
  provider_email?: string;
}

// This function fetches the total and unread count based on the params passed to the API
export async function fetchUnreadCount(params: SubmissionStatsParams) {
  const companyId = getCurrentLocationId();

  if (!companyId) {
    return Promise.reject('Location ID not provided!');
  }

  const response = await CustomAxios.get<UnreadCountResponse>(
    `/company/${companyId}/submission/stats`,
    { params }
  );

  return response.data;
}

export async function fetchCategorySubmissionStats(params: SubmissionStatsParams) {
  const companyId = getCurrentLocationId();

  if (!companyId) {
    return Promise.reject('Location ID not provided!');
  }

  const response = await CustomAxios.get<SubmissionStatsResponse>(
    `/company/${companyId}/submission/category/stats`,
    { params }
  );

  return response.data;
}
