import { FC, useState } from 'react';

import {
  PrimaryButton,
  SecondaryButton,
  SpinningLoader,
  useHoverLabel,
} from '@weave/design-system';

import { defaultHoverFontStyle } from 'shared/styles';

import { buttonsContainerStyle, buttonStyle } from './custom-modal-buttons.styles';

interface CustomModalButtonsProps {
  primaryButtonText: string;
  onClickPrimaryButton: () => void | Promise<any>;
  primaryButtonTrackingId?: string;
  disablePrimaryButton?: boolean;
  primaryButtonTooltipText?: string;
  hidePrimaryButtonTooltip?: boolean;
  secondaryButtonText?: string;
  onClickSecondaryButton?: () => void | Promise<any>;
  secondaryButtonTrackingId?: string;
  disableSecondaryButton?: boolean;
}

export const CustomModalButtons: FC<CustomModalButtonsProps> = ({
  primaryButtonText,
  onClickPrimaryButton,
  primaryButtonTrackingId,
  disablePrimaryButton,
  primaryButtonTooltipText,
  hidePrimaryButtonTooltip,
  secondaryButtonText,
  onClickSecondaryButton,
  secondaryButtonTrackingId,
  disableSecondaryButton,
  ...otherProps
}) => {
  const [isPrimaryActionInProgress, setIsPrimaryActionInProgress] = useState(false);
  const [isSecondaryActionInProgress, setIsSecondaryActionInProgress] = useState(false);

  const {
    HoverLabel: PrimaryButtonHoverLabel,
    labelProps: primaryButtonHoverLabelProps,
    triggerProps: primaryButtonTriggerProps,
  } = useHoverLabel();

  const spinner = (
    <span>
      <SpinningLoader size="small" />
    </span>
  );

  async function primaryButtonClickHandler() {
    setIsPrimaryActionInProgress(true);
    await onClickPrimaryButton();
    setIsPrimaryActionInProgress(false);
  }

  async function secondaryButtonClickHandler() {
    if (!onClickSecondaryButton) {
      return;
    }

    setIsSecondaryActionInProgress(true);
    await onClickSecondaryButton();
    setIsSecondaryActionInProgress(false);
  }

  function shouldShowPrimaryButtonTooltip() {
    return (
      primaryButtonTooltipText &&
      !hidePrimaryButtonTooltip &&
      !isPrimaryActionInProgress &&
      !isSecondaryActionInProgress
    );
  }

  return (
    <div css={buttonsContainerStyle} {...otherProps}>
      {isPrimaryActionInProgress ? (
        spinner
      ) : (
        <>
          <span {...primaryButtonTriggerProps}>
            <PrimaryButton
              css={buttonStyle}
              onClick={primaryButtonClickHandler}
              trackingId={primaryButtonTrackingId}
              disabled={isSecondaryActionInProgress || disablePrimaryButton}
              size="tiny"
            >
              {primaryButtonText}
            </PrimaryButton>
          </span>

          {shouldShowPrimaryButtonTooltip() && (
            <PrimaryButtonHoverLabel
              {...primaryButtonHoverLabelProps}
              css={defaultHoverFontStyle}
            >
              {primaryButtonTooltipText}
            </PrimaryButtonHoverLabel>
          )}
        </>
      )}

      {onClickSecondaryButton &&
        (isSecondaryActionInProgress ? (
          spinner
        ) : (
          <SecondaryButton
            css={buttonStyle}
            onClick={secondaryButtonClickHandler}
            trackingId={secondaryButtonTrackingId}
            disabled={isPrimaryActionInProgress || disableSecondaryButton}
            size="tiny"
          >
            {secondaryButtonText}
          </SecondaryButton>
        ))}
    </div>
  );
};
