import { useEffect } from 'react';
import { differenceInHours } from 'date-fns';

import { useModalControl, ModalControlResponse } from '@weave/design-system';

import { useLocationValidityContext } from 'context';
import { handleLocalStorage } from 'auth/auth.helpers';
import { CustomAxios, getResponseData } from 'shared/helpers/axios';
import { headerKeys, APP_BASE_URL } from 'shared/constants';

import PMSAuthReminderModal from './pms-auth-reminder-modal.component';

interface UsePMSAuthReminderResults extends ModalControlResponse {
  PMSAuthReminderModal: typeof PMSAuthReminderModal;
}

export const usePMSAuthReminder = (): UsePMSAuthReminderResults => {
  const hasLocationHeader = CustomAxios.headerExists(headerKeys.locationId);
  const modalControls = useModalControl();
  const { isValidLocation, isValidating } = useLocationValidityContext();

  useEffect(() => {
    if (isValidating || !isValidLocation) {
      return;
    }

    if (hasLocationHeader) {
      checkPMSAuth();
    }
  }, [hasLocationHeader, isValidating, isValidLocation]);

  async function checkPMSAuth() {
    const apiEndpoint = 'desktop/v1/syncapp/tool/check/';

    try {
      const { Required } = await CustomAxios.get(apiEndpoint, {
        baseURL: APP_BASE_URL,
      }).then(getResponseData);

      if (Required && !isShowninLast24Hours()) {
        modalControls.openModal();
      }
    } catch (error) {}
  }

  return {
    PMSAuthReminderModal,
    ...modalControls,
  };
};

function isShowninLast24Hours() {
  const lastErrorShownTime: any = handleLocalStorage.get('pmsAuthError');

  if (!lastErrorShownTime) {
    return false;
  }

  return differenceInHours(Date.now(), lastErrorShownTime.lastShown) < 24;
}
