import { Fragment, useMemo, FC } from 'react';
import { NavLink } from 'react-router-dom';

import { CaretRightIcon, useHoverLabel } from '@weave/design-system';

import { SideNavContext } from 'context';
import {
  useTabletMode,
  usePhoneMode,
  useLocationChildren,
  useUserACL,
  useFreeTrial,
} from 'shared/hooks';
import { NewBadge, Ripple } from 'shared/components';

import {
  caretRightIconStyle,
  sideNavLinkStyle,
  sideNavLinkIconStyle,
  sideNavLinkTextStyle,
  navLinkHoverLabelStyle,
  rippleStyle,
  freeTrialContainerStyle,
} from './side-nav-links.style';
import { getSideNavLinks } from './side-nav-links';
import { CustomNavLink } from '../side-nav-link.type';

type CustomNavLinkProps = Omit<
  CustomNavLink,
  'smallIcon' | 'mediumIcon' | 'largeIcon'
> & {
  onClick?: () => void;
  trackingId?: string;
  icon?: JSX.Element;
};

const CustomNavLink: FC<CustomNavLinkProps> = ({
  to,
  onClick,
  trackingId,
  icon,
  text,
  newFeature,
  hoverLabel,
  showRipple,
}) => {
  const { HoverLabel, labelProps, triggerProps } = useHoverLabel();

  return (
    <>
      <NavLink
        to={to}
        css={sideNavLinkStyle}
        onClick={onClick}
        data-trackingid={trackingId}
        {...triggerProps}
      >
        <div css={sideNavLinkIconStyle}>{icon}</div>
        <div css={sideNavLinkTextStyle}>{text}</div>
        {newFeature && <NewBadge>{newFeature}</NewBadge>}
        {showRipple && (
          <Ripple size="tiny" css={rippleStyle} className="side-nav-ripple" />
        )}
        <CaretRightIcon css={caretRightIconStyle} color={'light'} />
      </NavLink>

      {hoverLabel && (
        <HoverLabel {...labelProps} css={navLinkHoverLabelStyle}>
          {hoverLabel}
        </HoverLabel>
      )}
    </>
  );
};

export const SideNavLinks: FC = () => {
  const isTabletMode = useTabletMode();
  const isPhoneMode = usePhoneMode();

  const { isParentLocation } = useLocationChildren();
  const { hasProviderReviewPermission } = useUserACL();

  const { FreeTrial, freeTrialModalControls, isInFreeTrial, getFreeTrialEndDate } =
    useFreeTrial();

  const sideNavLinks = useMemo(() => {
    return getSideNavLinks({
      isUnifyParentLocation: isParentLocation,
      hasProviderReviewPermission,
    });
  }, [isParentLocation, hasProviderReviewPermission]);

  return (
    <Fragment>
      {isInFreeTrial() && (
        <div css={freeTrialContainerStyle}>
          <FreeTrial
            modalControls={freeTrialModalControls}
            endDate={getFreeTrialEndDate()}
          />
        </div>
      )}

      {sideNavLinks.map((sideNavLink) => {
        const {
          smallIcon,
          mediumIcon,
          largeIcon,
          text,
          to,
          externalLink,
          pendoTag,
          newFeature,
          hoverLabel,
          showRipple,
        } = sideNavLink;

        let icon = smallIcon;

        if (isTabletMode) {
          icon = largeIcon;
        }

        if (isPhoneMode) {
          icon = mediumIcon;
        }

        if (externalLink) {
          return (
            <a key={to} href={to} css={sideNavLinkStyle}>
              <div css={sideNavLinkIconStyle}>{icon}</div>
              <div css={sideNavLinkTextStyle}>{text}</div>
            </a>
          );
        }

        return (
          <SideNavContext.Consumer key={to}>
            {({ toggleSideNav }) => {
              return (
                <CustomNavLink
                  text={text}
                  icon={icon}
                  onClick={toggleSideNav}
                  trackingId={pendoTag}
                  to={to}
                  hoverLabel={hoverLabel}
                  newFeature={newFeature}
                  showRipple={showRipple}
                ></CustomNavLink>
              );
            }}
          </SideNavContext.Consumer>
        );
      })}
    </Fragment>
  );
};
