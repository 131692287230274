import { FC } from 'react';
import { IFormBuilder as FB } from 'shared/types';
import {
  BuilderCheckbox,
  BuilderCardCapture,
  BuilderPhoneField,
  BuilderTextField,
  BuilderRichText,
  BuilderSSNField,
  BuilderDateField,
  BuilderEmailField,
  BuilderTextareaField,
  BuilderPostalCode,
  BuilderInlineListEditor,
} from '../builder-input-components';
import { BuilderDigitalSignatureField } from '../builder-input-components/digital-signature.component';

interface FieldRendererProps {
  field: FB.Field;
}

export const FieldRenderer: FC<FieldRendererProps> = ({ field }) => {
  switch (field.meta.type) {
    case 'cardCapture':
      return <BuilderCardCapture />;
    case 'checkbox':
      return <BuilderCheckbox field={field as FB.Checkbox} />;
    case 'checklist':
    case 'optionswitch':
    case 'radio':
    case 'select':
    case 'searchable-listbox':
      return <BuilderInlineListEditor field={field as FB.BaseOptionsField} />;

    case 'date':
      return <BuilderDateField />;
    case 'email':
      return <BuilderEmailField name={field.label} />;
    case 'phone':
      return <BuilderPhoneField label={field.label} name={field.label} />;
    case 'postalCode':
      return <BuilderPostalCode />;

    case 'richText':
      return <BuilderRichText field={field as FB.BaseField} />;
    case 'ssn':
      return <BuilderSSNField />;
    case 'signature':
      return <BuilderDigitalSignatureField field={field as FB.Signature} />;
    case 'text':
      return <BuilderTextField label={field.label} name={field.label} />;
    case 'textarea':
      return <BuilderTextareaField name={field.label} />;
    default:
      return null;
  }
};
