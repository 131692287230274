import { FC, useState } from 'react';

import {
  ModalControlModalProps,
  Modal,
  Text,
  ContentLoader,
  PrimaryButton,
  SecondaryButton,
} from '@weave/design-system';

import { pendoTags } from 'shared/constants';

import {
  modalConatinerStyle,
  footerStyle,
  primaryButtonStyle,
} from './source-tenant-setting-confirmation-modal.styles';

interface SourceTenantSettingConfirmationModalProps {
  modalProps: ModalControlModalProps;
  onCancel: () => void;
  onClose: () => void;
  onConfirm: () => Promise<any>;
}

const SourceTenantSettingConfirmationModal: FC<
  SourceTenantSettingConfirmationModalProps
> = ({ modalProps, onClose, onConfirm, onCancel }) => {
  const [showLoader, setShowLoader] = useState(false);

  async function confirmationHandler() {
    setShowLoader(true);
    await onConfirm();
    setShowLoader(false);
    onClose();
  }

  return (
    <Modal
      {...modalProps}
      css={modalConatinerStyle}
      disableCloseOnEscape
      disableCloseOnOverlayClick
      minWidth={500}
    >
      <Modal.Header textAlign="left">Update Default Writeback Destination</Modal.Header>

      <Modal.Body>
        <Text textAlign="left">
          Do you wish to change the default destination for writebacks? With this
          configuration, all future submissions will sync with the chosen system of
          record.
        </Text>
      </Modal.Body>

      <footer css={footerStyle}>
        <PrimaryButton
          onClick={confirmationHandler}
          css={primaryButtonStyle}
          trackingId={pendoTags.settings.writebacks.confirmDefaultPMS}
        >
          Change Destination
        </PrimaryButton>

        <SecondaryButton
          onClick={onCancel}
          trackingId={pendoTags.settings.writebacks.cancelDefaultPMSChange}
        >
          Cancel
        </SecondaryButton>
      </footer>

      <ContentLoader show={showLoader} />
    </Modal>
  );
};

export default SourceTenantSettingConfirmationModal;
