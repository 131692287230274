import { IconButton, XIcon } from '@weave/design-system';

import { SideNavContext } from 'context';
import { usePhoneMode } from 'shared/hooks';

import { SideNavLinks } from './components/side-nav-links/side-nav-links.component';

import {
  sideNavContainerStyle,
  mainNavMenuStyle,
  showPhoneSideNavStyle,
  crossIconStyle,
  crossIconContainerStyle,
} from './side-nav.style';

export const SideNav = () => {
  const isPhoneMode = usePhoneMode();
  return (
    <SideNavContext.Consumer>
      {({ showMobileSideNav, toggleSideNav }) => {
        const mainNavMenuStyles = showMobileSideNav
          ? [mainNavMenuStyle, showPhoneSideNavStyle]
          : [mainNavMenuStyle];
        return (
          <aside css={sideNavContainerStyle}>
            <div css={mainNavMenuStyles}>
              {isPhoneMode && (
                <div css={crossIconContainerStyle}>
                  <IconButton label="" css={crossIconStyle} onClick={toggleSideNav}>
                    <XIcon />
                  </IconButton>
                </div>
              )}
              <SideNavLinks />
            </div>
          </aside>
        );
      }}
    </SideNavContext.Consumer>
  );
};
