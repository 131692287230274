import { css } from '@emotion/core';
import { theme } from '@weave/theme';

export const weaveFormsFlyoutMenuStyle = css`
  box-shadow: ${theme.shadows.heavy};
  right: 1.5rem;
  max-width: 220px;
  z-index: ${theme.zIndex.highest};
  border-radius: ${theme.borderRadius.medium};

  li {
    background: ${theme.colors.neutral80};
    color: ${theme.colors.white};
    font-size: ${theme.fontSize(14)};

    :first-of-type {
      border-top-left-radius: ${theme.borderRadius.medium};
      border-top-right-radius: ${theme.borderRadius.medium};
    }

    :last-of-type {
      border-bottom-left-radius: ${theme.borderRadius.medium};
      border-bottom-right-radius: ${theme.borderRadius.medium};
    }

    :not(:last-of-type) {
      border-bottom: 1px solid ${theme.colors.neutral70};
    }

    :hover,
    :focus {
      background: ${theme.colors.neutral20};
      color: ${theme.colors.neutral80};
    }
  }
`;
