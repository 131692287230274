import { FC } from 'react';

import { AlertInvertIconSmall, Text } from '@weave/design-system';

import { containerStyle, iconStyle, textStyle } from './form-cloner-summary.styles';

interface FormClonerSummaryProps {
  itemCount: number;
  locationCount: number;
}

const FormClonerSummary: FC<FormClonerSummaryProps> = ({ itemCount, locationCount }) => {
  function getSummaryText() {
    const part1 =
      itemCount > 1 ? (
        <>
          <b>{itemCount}</b> forms
        </>
      ) : (
        'this form'
      );
    const part2 =
      locationCount > 1 ? (
        <>
          <b>{locationCount}</b> different locations
        </>
      ) : (
        'a location'
      );

    return (
      <>
        You are cloning {part1} to {part2}. You cannot undo this action.
      </>
    );
  }

  if (itemCount === 0 || locationCount === 0) {
    return null;
  }

  return (
    <section css={containerStyle}>
      <AlertInvertIconSmall css={iconStyle} />
      <Text css={textStyle}>{getSummaryText()}</Text>
    </section>
  );
};

export default FormClonerSummary;
