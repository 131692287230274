import { useQuery } from 'react-query';

import { queryKeys, API_URL } from 'shared/constants';
import { CustomAxios } from 'shared/helpers/axios';
import { getCurrentLocationId } from 'shared/helpers/utils';

interface UseCustomizationFlagsResults {
  customizationFlags: CustomizationFlag[];
  isLoadingCustomizationFlags: boolean;
  getFormsCustomizationFlag: () => CustomizationFlag | undefined;
}

export const useCustomizationFlags = (): UseCustomizationFlagsResults => {
  const { data, isLoading } = useQuery(
    queryKeys.customizationFlags,
    getCustomizationsFlags
  );

  const customizationFlags = data?.features || [];

  function getFormsCustomizationFlag() {
    return customizationFlags.find((feature) => feature.featureEnum === 'FORMS');
  }

  return {
    customizationFlags,
    isLoadingCustomizationFlags: isLoading,
    getFormsCustomizationFlag,
  };
};

interface CustomizationFlag {
  description: string;
  endDate: string; // yyyy-mm-dd
  featureEnum: string;
  id: string;
  isFreeTrial: boolean;
  name: string;
  startDate: string;
  state: string;
}

interface CustomizationFlagsResponse {
  features: CustomizationFlag[];
}

async function getCustomizationsFlags() {
  const companyId = getCurrentLocationId();

  if (!companyId) {
    return Promise.reject('Location ID not provided!');
  }

  const response = await CustomAxios.get<CustomizationFlagsResponse>(
    `/platform-location-feature/v1/location/${companyId}/features`,
    {
      baseURL: API_URL,
    }
  );

  return response.data;
}
