import { FC, useEffect, useRef, useState } from 'react';
import { useQueryClient } from 'react-query';

import {
  Alert,
  Chip,
  ContentLoader,
  Heading,
  Modal,
  ModalControlModalProps,
  Table,
  Text,
  TextLink,
  useAlert,
} from '@weave/design-system';

import { useWritebackCapabilitiesContext } from 'context';
import { useNonEditableForms } from 'shared/hooks';
import { CustomAxios } from 'shared/helpers/axios';
import { getCurrentLocationId } from 'shared/helpers/utils';
import { HELP_LINKS, queryKeys } from 'shared/constants';

import { useImportEaglesoftForm } from './import-es-form';
import { chipStyle, modalActions, modalStyle } from './multi-import-moda.style';

interface EaglesoftMultiImportModalProps {
  modalProps: ModalControlModalProps;
}

enum ImportStatus {
  ReadyToImport = 'Ready to import',
  Imported = 'Imported',
  Unavailable = 'Unavailable',
}

const importStatusVariantMapper = {
  [ImportStatus.ReadyToImport]: 'success',
  [ImportStatus.Imported]: 'primary',
  [ImportStatus.Unavailable]: 'neutral',
};

interface TableData {
  formName: string;
  status: ImportStatus;
  formPmsId: string;
}

export const EaglesoftMultiImport: FC<EaglesoftMultiImportModalProps> = ({
  modalProps,
}) => {
  const { esForms, errorLoadingEsForms, isLoadingAllEsForms, fetchEaglesoftForms } =
    useImportEaglesoftForm();
  const [showImportingLoader, setShowImportingLoader] = useState(false);
  const pmsIdIndexMapper = useRef<{ [key: string]: number }>({});
  const [tableData, setTableData] = useState<TableData[]>([]);
  const [integrationName, setIntegrationName] = useState<string | undefined>();
  const selectedForms = useRef<TableData[]>([]);

  const queryClient = useQueryClient();
  const alert = useAlert();
  const { supportedSourceTenants, preferredSourceTenantId, getSourceTenantDetails } =
    useWritebackCapabilitiesContext();
  const { data: importedForms, isLoading: isLoadingImportedForms } =
    useNonEditableForms();

  const resetState = () => {
    selectedForms.current = [];
    setShowImportingLoader(false);
  };

  useEffect(() => {
    if (!modalProps.show) {
      resetState();
    } else {
      fetchEaglesoftForms();
    }
  }, [modalProps.show]);

  useEffect(() => {
    if (supportedSourceTenants.length > 0 && preferredSourceTenantId) {
      const integration = getSourceTenantDetails.bySourceTenantId(
        preferredSourceTenantId,
        true
      );

      if (integration) {
        setIntegrationName(integration.sourceName);
      }
    }
  }, [supportedSourceTenants, preferredSourceTenantId]);

  useEffect(() => {
    if (isLoadingAllEsForms || isLoadingImportedForms) {
      return;
    }

    if (esForms.length > 0) {
      const importedFormsNames = importedForms.map((f) => f.formPmsId);
      const newTableData = esForms.map((f, index) => {
        pmsIdIndexMapper.current[f.form.formPmsId as string] = index;
        if (importedFormsNames.includes(f.form.formPmsId)) {
          return {
            formName: f.form.name,
            status: ImportStatus.Imported,
            formPmsId: f.form.formPmsId as string,
          };
        } else {
          return {
            formName: f.form.name,
            status: ImportStatus.ReadyToImport,
            formPmsId: f.form.formPmsId as string,
          };
        }
      });
      setTableData(newTableData);
    }
  }, [esForms, isLoadingAllEsForms, isLoadingImportedForms, importedForms]);

  useEffect(() => {
    if (errorLoadingEsForms && modalProps.show) {
      alert.error('Error loading Eaglesoft forms');
      modalProps.onClose();
    }
  }, [errorLoadingEsForms]);

  const importForms = async () => {
    if (selectedForms.current.length === 0) {
      alert.error('Please select at least one form to import');
      return;
    }

    setShowImportingLoader(true);

    const formsToBeImported = selectedForms.current.map((f) => {
      return esForms[pmsIdIndexMapper.current[f.formPmsId]];
    });

    try {
      const response = await CustomAxios.post('es-medical-history-forms', {
        forms: formsToBeImported,
        company_id: getCurrentLocationId(),
      });

      if (response.status === 200) {
        queryClient.invalidateQueries(queryKeys.nonEditableForms);
        alert.success('Selected forms imported successfully');
        modalProps.onClose();
      }
    } catch (error) {
      alert.error('Error importing forms');
    } finally {
      setShowImportingLoader(false);
    }
  };

  return (
    <Modal
      {...modalProps}
      css={modalStyle}
      disableCloseOnEscape
      disableCloseOnOverlayClick
      minWidth={780}
    >
      <div className="header">
        <Heading level={3}>Import from Eaglesoft</Heading>
        <Text color="light" weight="bold" className="sub-heading">
          {integrationName}
        </Text>
      </div>

      <Modal.Body className="modal-body">
        <Alert type="info" className="info-box">
          <div>
            <Text className="info-text" size="medium">
              Some of the forms might not appear due to an error.
            </Text>
            <Text className="info-text" size="medium">
              <TextLink
                href={HELP_LINKS.contactWeave}
                target="_blank"
                style={{ display: 'inline-block' }}
              >
                Contact Weave
              </TextLink>{' '}
              if you want to import them into your forms list.
            </Text>
          </div>
        </Alert>
        <ContentLoader show={showImportingLoader} message="Importing" />
        <Table
          isSelectable
          hasGlobalSearch
          hasFilterColumns
          isPaginated
          isLoading={isLoadingAllEsForms || isLoadingImportedForms}
          clientPaginationConfig={{
            defaultRowsPerPage: 5,
            rowsPerPageLabel: 'Forms Per Page',
          }}
          globalSearchConfig={{
            position: 'left',
          }}
          rowSelectionConfig={{
            hideBulkSelection: true,
            onSelectionToggle: (e) => {
              selectedForms.current = e.map((r) => r.original);
            },
            isRowDisabled: (r) =>
              r.status === ImportStatus.Unavailable || r.status === ImportStatus.Imported,
          }}
          colConfig={[
            {
              Header: 'Form',
              accessor: 'formName',
              id: 'formame',
              sortType: 'string',
            },
            {
              Header: 'Status',
              accessor: 'status',
              id: 'status',
              sortType: 'string',
              width: 150,
              cellRenderer: (cellProps) => {
                return (
                  <Chip css={chipStyle} variant={importStatusVariantMapper[cellProps]}>
                    {cellProps}
                  </Chip>
                );
              },
            },
          ]}
          data={tableData}
        />
        <Modal.Actions
          css={modalActions}
          secondaryLabel="Cancel"
          primaryLabel="Import"
          onPrimaryClick={importForms}
          onSecondaryClick={modalProps.onClose}
          primaryTrackingId="forms-eaglesoft-multi-import-btn"
        />
      </Modal.Body>
    </Modal>
  );
};
