import { useQueryClient, useMutation, UseMutateAsyncFunction } from 'react-query';

import { useAlert } from '@weave/design-system';

import { queryKeys, APP_BASE_URL } from 'shared/constants';
import { CustomAxios } from 'shared/helpers/axios';

interface UseWritebackRetryResults {
  retryWriteback: UseMutateAsyncFunction<
    RetryWritebackResponse,
    unknown,
    RetryWritebackPayload,
    unknown
  >;
  isRetryingWriteback: boolean;
}

const ERROR_MESSAGE = 'Writeback retry failed! ';

export const useWritebackRetry = (): UseWritebackRetryResults => {
  const queryClient = useQueryClient();
  const alert = useAlert();

  const { mutateAsync, isLoading } = useMutation(retryWriteback, {
    onSuccess: (res, payload) => {
      refetchSubmission(payload);

      if (!res.success) {
        alert.error(getWBErrorMessage(res.data?.message, '|'));
        return;
      }

      alert.success('Writeback retry was successful!');
    },
    onError: (_, payload) => {
      refetchSubmission(payload);
      alert.error(ERROR_MESSAGE);
    },
  });

  function refetchSubmission(payload: RetryWritebackPayload) {
    queryClient.invalidateQueries(queryKeys.formSubmissions);
    queryClient.invalidateQueries([queryKeys.formSubmission, payload.submissionId]);
  }

  return {
    retryWriteback: mutateAsync,
    isRetryingWriteback: isLoading,
  };
};

export type RetryWritebackMode = 'Manual' | 'Retry';

export interface RetryWritebackPayload {
  settingIds: string[];
  submissionId: string;
  mode: RetryWritebackMode;
  sourceTenantId?: string;
}

type RetryWritebackResponse =
  | {
      success: true;
      data: any;
    }
  | { success: false; data: any };

async function retryWriteback({
  settingIds,
  submissionId,
  mode,
  sourceTenantId,
}: RetryWritebackPayload): Promise<RetryWritebackResponse> {
  const payload = {
    wb_setting_ids: settingIds,
    submission_id: submissionId,
    mode,
  };

  if (sourceTenantId) {
    payload['source_tenant_id'] = sourceTenantId;
  }

  try {
    const { data } = await CustomAxios.put(
      `${APP_BASE_URL}/forms-writeback/sync`,
      payload
    );
    return { success: true, data };
  } catch (err: any) {
    return { success: false, data: err };
  }
}

const getWBErrorMessage = (str: string, delimiter: string): string => {
  const index = str.indexOf(delimiter);
  if (index !== -1) {
    return ERROR_MESSAGE + str.slice(0, index);
  }
  return ERROR_MESSAGE;
};
