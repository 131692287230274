import { handleLocalStorage } from 'auth/auth.helpers';
import { AuthStorage, Location } from 'auth/auth.types';
import { getDecodedWeaveToken } from '@weave/auth';

export function getActiveLocationData() {
  return handleLocalStorage.get(AuthStorage.active_location) as Location;
}

export function getCurrentLocationId() {
  const activeLocation = getActiveLocationData();
  return activeLocation ? activeLocation.locationID : '';
}

export function getCurrentLocationName() {
  const activeLocation = getActiveLocationData();
  return activeLocation ? activeLocation.name : '';
}

export function getUserEmail() {
  const decodedWeaveToken = getDecodedWeaveToken();
  return decodedWeaveToken ? decodedWeaveToken.username : '';
}

export function processLocationValidationStatus(status: number | undefined) {
  return {
    isValidLocation: status === 200,
    isUnauthorisedLocation: status === 401,
    hasUnknownError: status === undefined || status > 400,
    hasFieldError: status === 400,
  };
}
