export function formatPMSName(pmsName: string) {
  let name = pmsName;

  // Remove the leading "-" from the name
  while (name.charAt(0) === '-') {
    name = name.substring(1);
  }

  return name;
}
