import { useInfiniteQuery, useMutation, useQueryClient } from 'react-query';
import { useHistory } from 'react-router-dom';

import { useFormField, useDebouncedValue } from '@weave/design-system';
import { useAlert } from '@weave/design-system';

import { queryKeys, DEBOUNCE_INTERVAL } from 'shared/constants';
import { FORM_TEMPLATES_BASE } from 'shared/constants/paths';
import {
  fetchFormTemplates,
  FormsListResponse,
  deleteFormTemplate as deleteAPI,
  updateReviewRequired as updateReviewRequiredAPI,
} from 'shared/helpers/axios/apis';

function toggleReviewRequired(pages, token, reviewRequired) {
  const newPages = [...pages];
  for (let page = 0; page < newPages.length; page++) {
    let found = false;
    for (let form = 0; form < newPages[page].data.length; form++) {
      if (newPages[page].data[form].id === token) {
        newPages[page].data[form].reviewRequired = reviewRequired;
        found = true;
        break;
      }
    }
    if (found) break;
  }

  return newPages;
}

export const useFormTemplates = () => {
  const searchFieldProps = useFormField({ type: 'text' });
  const debouncedSearchTerm = useDebouncedValue(
    searchFieldProps.value,
    DEBOUNCE_INTERVAL
  );
  const queryClient = useQueryClient();
  const alert = useAlert();
  const history = useHistory();

  const QUERY_KEY = [queryKeys.formTemplates, debouncedSearchTerm];

  const { data, refetch, hasNextPage, ...infiniteQueryProps } = useInfiniteQuery(
    QUERY_KEY,
    ({ pageParam = { search: debouncedSearchTerm } }) => fetchFormTemplates(pageParam),
    {
      staleTime: 1000,
      refetchOnMount: true,
      getNextPageParam: (lastPage, pages) => {
        if (!lastPage || lastPage.isDataEmpty) {
          return undefined;
        }

        const currentPageNumber =
          typeof lastPage.pageMetadata.page === 'string'
            ? parseInt(lastPage.pageMetadata.page, 10)
            : lastPage.pageMetadata.page;

        const lastPageNumber =
          typeof lastPage.pageMetadata.lastPage === 'string'
            ? parseInt(lastPage.pageMetadata.lastPage, 10)
            : lastPage.pageMetadata.lastPage;

        return currentPageNumber < lastPageNumber
          ? {
              page: currentPageNumber + 1,
              search: debouncedSearchTerm,
            }
          : undefined;
      },
    }
  );

  const {
    mutate: deleteFormTemplate,
    isLoading: isDeleting,
    isSuccess: isDeletingSuccess,
    isError: isDeletingError,
  } = useMutation(deleteAPI, {
    onSuccess: (_, { token: deletedFormId, isNonEditable }) => {
      let isLastEntryOnLastPage = false;

      // Remove the deleted form from React query cache.
      let newPagesArray =
        data?.pages.map((page, pageIndex) => {
          page.data = page.data.filter((form, formIndexInPage) => {
            const formLocated = form.id === deletedFormId;

            if (formLocated) {
              if (pageIndex === data.pages.length - 1 && page.data.length === 1) {
                isLastEntryOnLastPage = true;
              }
            }

            return form.id !== deletedFormId;
          });

          return page;
        }) ?? [];

      if (isLastEntryOnLastPage) {
        // Remove the last page array.
        newPagesArray = newPagesArray.filter(
          (page, index) => index !== newPagesArray.length - 1
        );
      }

      // Update the React Query cache to see instant removal
      // of the entry from list.
      queryClient.setQueryData(QUERY_KEY, (data: any) => {
        return {
          pages: newPagesArray,
          pageParams: data.pageParams,
        };
      });

      // Invalidate the query cache to trigger a refetch.
      if (isNonEditable) {
        queryClient.invalidateQueries(queryKeys.nonEditableForms);
      }
      else {
        queryClient.invalidateQueries(queryKeys.formTemplates);
      }
     
      alert.success('Form template deleted successfully!');

      // Adding a small timeout to avoid unmounted React state update warning.
      // for the confirmation modal.
      setTimeout(() => {
        history.replace(FORM_TEMPLATES_BASE);
      }, 300);
    },
    onError: () => {
      alert.error('Failed to delete the form template!');
    },
  });

  const { mutate: updateReviewRequired } = useMutation(updateReviewRequiredAPI, {
    onMutate: ({ token, reviewRequired }) => {
      const newPages = toggleReviewRequired(data?.pages || [], token, reviewRequired);

      queryClient.setQueryData(QUERY_KEY, (data: any) => {
        return {
          pages: newPages,
          pageParams: data.pageParams,
        };
      });
    },
    onSuccess: (_, { reviewRequired }) => {
      if (reviewRequired) {
        alert.success('Successfully enabled Provider Review for this form');
      } else {
        alert.success('Successfully disabled Provider Review for this form');
      }
    },
    onError: (_, { token, reviewRequired }) => {
      const newPages = toggleReviewRequired(data?.pages || [], token, !reviewRequired);

      queryClient.setQueryData(QUERY_KEY, (data: any) => {
        return {
          pages: newPages,
          pageParams: data.pageParams,
        };
      });
      alert.error('Failed to save changes.');
    },
  });

  return {
    data: (data?.pages || []) as FormsListResponse[],
    ...infiniteQueryProps,
    hasNextPage: hasNextPage || false,
    searchFieldProps,
    deleteFormTemplate,
    updateReviewRequired,
    isDeleting,
    isDeletingSuccess,
    isDeletingError,
  };
};
