import { AlertIcon, DataSyncIcon, Text, useHoverLabel } from '@weave/design-system';
import { FC } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { NewBadge } from 'shared/components';
import { defaultHoverFontStyle } from 'shared/styles';
import { IFormBuilder as FB } from 'shared/types';
import {
  formFieldItemContentStyles,
  formFieldItemStyles,
  newFieldBadgeStyle,
  syncIconStyles,
} from './form-field-item.style';

const fieldHelper = {
  eSign: (
    <Text>
      Now your form recipients can quickly <b>draw, type or upload a signature</b> to
      electronically sign your form.
    </Text>
  ),
};

interface FormFieldItemProps {
  isPrimaryField: boolean;
  index: number;
  fieldName: string;
  syncable?: boolean;
  desc?: string;
  isUsed?: boolean;
  field:
    | FB.BaseField
    | FB.BaseOptionsField
    | FB.CardCapture
    | FB.Checklist
    | FB.ValidationField
    | FB.Email
    | FB.PostalCode
    | FB.Signature;
}

interface FieldErrorProps {
  isEmpty: boolean;
  isError: boolean;
}

const FieldError: FC<FieldErrorProps> = ({ isEmpty, isError }) => {
  const { HoverLabel, labelProps, triggerProps } = useHoverLabel({
    placement: 'top',
  });

  return (
    <span {...triggerProps}>
      <AlertIcon className="medical-history-error" color="error" size={16} />
      <HoverLabel {...labelProps} css={defaultHoverFontStyle}>
        {isError &&
          'We are unable to retrieve medical condition option sets from your system of record'}
        {isEmpty && 'You have not configured medical condition options sets'}
      </HoverLabel>
    </span>
  );
};

export const FormFieldItem: FC<FormFieldItemProps> = ({
  isPrimaryField,
  index,
  desc,
  syncable,
  field,
  fieldName,
  isUsed,
}) => {
  // isEmpty and isError is only available for medical history fields
  const { isEmpty = false, isError = false } = field.meta;
  const { HoverLabel, labelProps, triggerProps } = useHoverLabel({
    placement: 'right',
  });

  return (
    <Draggable
      draggableId={isPrimaryField ? `${fieldName}__pf` : fieldName}
      index={index}
      isDragDisabled={(isUsed || isEmpty || isError) ?? false}
    >
      {(provided, snapshot) => (
        <>
          <li
            {...provided.dragHandleProps}
            {...provided.draggableProps}
            ref={provided.innerRef}
          >
            <div
              css={formFieldItemStyles(
                snapshot.isDragging,
                (isUsed || isEmpty || isError) ?? false
              )}
              {...(isUsed && { ...triggerProps })}
              {...(snapshot.isDragging && { className: 'is-dragging' })}
            >
              {isPrimaryField && (
                <DataSyncIcon
                  className="field-sync-icon"
                  size={16}
                  css={syncIconStyles(!!syncable)}
                />
              )}
              <div css={formFieldItemContentStyles}>
                <Text className="field-name" weight="semibold" size="medium">
                  {field.meta.displayName}{' '}
                  {(isError || isEmpty) && (
                    <FieldError isError={isError} isEmpty={isEmpty} />
                  )}
                </Text>
                {desc && (
                  <Text className="field-desc" size="medium" color="light">
                    {desc}
                  </Text>
                )}
              </div>
              {fieldHelper[field.meta.type] && !snapshot.isDragging && (
                <NewBadge css={newFieldBadgeStyle} placement="right">
                  {fieldHelper[field.meta.type]}
                </NewBadge>
              )}
            </div>
            <HoverLabel {...labelProps} css={defaultHoverFontStyle}>
              Already added to this form
            </HoverLabel>
          </li>
          {snapshot.isDragging && (
            <li css={formFieldItemStyles(snapshot.isDragging, isUsed ?? false)}>
              {isPrimaryField && (
                <DataSyncIcon
                  size={16}
                  className="field-sync-icon"
                  css={syncIconStyles(!!syncable)}
                />
              )}
              <span css={formFieldItemContentStyles}>
                <Text className="field-name" weight="semibold" size="medium">
                  {field.meta.displayName}
                </Text>
                {desc && (
                  <Text className="field-desc" size="medium" color="light">
                    {desc}
                  </Text>
                )}
              </span>
              {fieldHelper[field.meta.type] && !snapshot.isDragging && (
                <NewBadge css={newFieldBadgeStyle} placement="right">
                  {fieldHelper[field.meta.type]}
                </NewBadge>
              )}
            </li>
          )}
        </>
      )}
    </Draggable>
  );
};
