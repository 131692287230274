import { FC } from 'react';

import { HamburgerMenuIcon, PlusIcon } from '@weave/design-system';

import { SideNavContext } from 'context';
import { CustomIconButton } from '..';
import {
  childrenStyle,
  hamburgerIconStyle,
  mobileNavbarStripIconsStyle,
  plusIconStyle,
} from './mobile-nav-strip.style';

export const MobileNavStrip: FC = ({ children }) => {
  return (
    <SideNavContext.Consumer>
      {({ toggleSideNav }) => {
        return (
          <div css={mobileNavbarStripIconsStyle}>
            <HamburgerMenuIcon css={hamburgerIconStyle} onClick={toggleSideNav} />
            <div css={childrenStyle}>{children}</div>
            <div css={plusIconStyle}>
              <CustomIconButton>
                <PlusIcon />
              </CustomIconButton>
            </div>
          </div>
        );
      }}
    </SideNavContext.Consumer>
  );
};
