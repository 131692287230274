import { css } from '@emotion/core';
import { theme } from '@weave/theme';

export const labelContainerStyle = css`
  font-size: 0.8rem;
  font-weight: 300;
  line-height: 1;
`;

export const labelInnerContainerStyle = css`
  color: inherit;
  display: flex;
  align-items: flex-end;
  gap: ${theme.spacing(1)};
`;
