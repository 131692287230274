import { useModalControl } from '@weave/design-system';
import { EaglesoftMultiImport } from './multi-import-modal.component';

export const useEaglesoftMultiImports = () => {
  const modalControls = useModalControl();

  return {
    ...modalControls,
    EaglesoftMultiImport,
  };
};
