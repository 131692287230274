import { BuilderContext } from './form-components.types';

export namespace IFormBuilder {
  export type FieldType =
    | 'cardCapture'
    | 'medicalConditions'
    | 'femaleOnlyConditions'
    | 'staticAllergies'
    | 'checklist'
    | 'checkbox'
    | 'date'
    | 'signature'
    | 'select'
    | 'eSign'
    | 'email'
    | 'richText'
    | 'textarea'
    | 'text'
    | 'radio'
    | 'binaryDecision'
    | 'phone'
    | 'postalCode'
    | 'referralSource'
    | 'ssn'
    | 'optionswitch'
    | 'searchable-listbox';

  export interface Meta {
    displayName: string;
    type: FieldType;
    sourceType?: FieldType;
    dataGroup?: string;
    dataKey?: string;
    optionSet?: string;
    optionsDisplay?: string;
    isEmpty?: boolean;
    isError?: boolean;
  }

  export interface BaseField<T = string> {
    id: string;
    label: string;
    meta: Meta;
    required: boolean;
    value: T;
    hidden: boolean;
    disabled: boolean;
    condition_ids?: string[];
  }

  export interface Option {
    label: string;
    value: string;
  }

  export interface BaseOptionsField<T = string> extends BaseField<T> {
    options: Option[];
  }

  export interface CardCaptureValue {
    back: string;
    front: string;
  }

  export interface CardCapture extends BaseField<CardCaptureValue> {}

  export interface Checklist extends BaseOptionsField<string[]> {}
  export interface Checkbox extends BaseField<boolean> {}

  export interface ValidationField extends BaseField {
    inputmode: string;
    mask: string;
    pattern: string;
    validation: string;
    placeholder?: string;
  }

  export interface Email extends BaseField {
    validation: string;
  }

  export interface PostalCode extends BaseField {
    inputmode: string;
    max_length: number;
  }

  export interface Signature extends BaseField {
    agrees_value: boolean;
    confirmationLabel: string;
    placeholder: string;
    validation: string;
  }

  export interface ElectronicSignatureValue {
    type: 'image' | 'text' | 'wet' | '';
    data: string;
    font_type: string;
    timestamp: string;
  }

  export interface ElectronicSignature extends BaseField<ElectronicSignatureValue> {}

  export interface IPrimaryFields {
    address1: BaseField;
    address2: BaseField;
    allergies: BaseOptionsField;
    birthdate: BaseField;
    city: BaseField;
    disease: BaseOptionsField;
    driversLicenseNumber: BaseField;
    email: BaseField;
    firstName: BaseField;
    gender: BaseOptionsField;
    homePhone: BaseField;
    lastName: BaseField;
    maritalStatus: BaseOptionsField;
    medications: BaseOptionsField;
    middleName: BaseField;
    mobilePhone: BaseField;
    postalCode: BaseField;
    preferredName: BaseField;
    primaryGroupNumber: BaseField;
    primaryInsuranceAddress1: BaseField;
    primaryInsuranceAddress2: BaseField;
    primaryInsuranceCity: BaseField;
    primaryInsuranceId: BaseField;
    primaryInsuranceName: BaseField;
    primaryInsurancePostalCode: BaseField;
    primaryInsuranceRelationship: BaseField;
    primaryInsuranceSSN: BaseField;
    primaryInsuranceSpouseName: BaseField;
    primaryInsuranceSpouseSSN: BaseField;
    primaryInsuranceState: BaseOptionsField;
    primaryPolicyHolderName: BaseField;
    primaryRxNumber: BaseField;
    secondaryGroupNumber: BaseField;
    secondaryInsuranceAddress1: BaseField;
    secondaryInsuranceAddress2: BaseField;
    secondaryInsuranceCity: BaseField;
    secondaryInsuranceId: BaseField;
    secondaryInsuranceName: BaseField;
    secondaryInsurancePostalCode: BaseField;
    secondaryInsuranceRelationship: BaseField;
    secondaryInsuranceSSN: BaseField;
    secondaryInsuranceSpouseName: BaseField;
    secondaryInsuranceSpouseSSN: BaseField;
    secondaryInsuranceState: BaseOptionsField;
    secondaryPolicyHolderName: BaseField;
    secondaryRxNumber: BaseField;
    socialSecurityNumber: BaseField;
    state: BaseOptionsField;
    workPhone: BaseField;
  }

  export interface IElements {
    baseField: BaseField;
    binaryDecision: BaseOptionsField;
    cardCapture: CardCapture;
    checkbox: Checkbox;
    checklist: BaseOptionsField;
    date: ValidationField;
    email: Email;
    femaleOnlyConditions: BaseOptionsField;
    medicalConditions: BaseOptionsField;
    phone: ValidationField;
    postalCode: PostalCode;
    radio: BaseOptionsField;
    richText: BaseField;
    referralSource: BaseOptionsField;
    select: BaseOptionsField;
    signature: Signature;
    ssn: ValidationField;
    staticAllergies: BaseOptionsField;
    text: BaseField;
    textarea: BaseField;
  }

  export interface ISectionTemplate {
    fields: Field[];
    title: string;
  }

  export interface ISectionTemplates {
    allergies: ISectionTemplate;
    covid: ISectionTemplate;
    femaleMedicalConditions: ISectionTemplate;
    medicalConditions: ISectionTemplate;
    patient: ISectionTemplate;
    primaryInsurance: ISectionTemplate;
    secondaryInsurance: ISectionTemplate;
  }

  export interface BuilderSources {
    elements: IElements;
    primaryFields: IPrimaryFields;
  }

  export type FormConditionType =
    | 'equals'
    | 'notEquals'
    | 'greaterThan'
    | 'lessThan'
    | 'greaterThanOrEqual'
    | 'lessThanOrEqual'
    | 'contains'
    | 'notContains'
    | 'answered';

  export interface FormTerm {
    field: string;
    check: FormConditionType;
    value: string;
  }

  export type ConditionOperation =
    | 'show'
    | 'hide'
    | 'enable'
    | 'disable'
    | 'require'
    | 'optional'
    | 'skip';

  export interface FormAction {
    operation: ConditionOperation;
    target: string;
    type: 'sections' | 'fields' | 'form';
  }

  export interface FormCondition {
    id: string;
    operator: 'and' | 'or';
    terms: FormTerm[];
    actions: FormAction[];
  }

  export interface Form {
    company_id: string;
    id: string;
    name: string;
    sections: string[];
    formPmsId?: string;
  }

  export type Field =
    | BaseField
    | BaseOptionsField
    | CardCapture
    | Checklist
    | Checkbox
    | ValidationField
    | Email
    | PostalCode
    | Signature;

  export interface Section {
    id: string;
    title: string;
    fields: string[];
    section_template?: keyof BuilderContext.IUsedTemplates;
  }

  export interface FormStructure {
    form: Form;
    sections: Record<string, Section>;
    fields: Record<string, Field>;
    conditions?: FormCondition[];
  }

  export enum FormVisibility {
    GLOBAL = 'Global',
    TRANSIENT = 'Transient',
    UNKNOWN = 'UnknownVisibility',
  }

  export interface PDFField {
    hidden: boolean;
    id: string;
    label: string;
    meta: {
      type: 'pdf';
      displayName: 'PDF Attachment';
    };
    value: string; // Attachment ID
    eSignRequired: boolean;
  }

  export interface TransientAttachmentsForm {
    fields: Record<string, PDFField>;
    form: {
      company_id: string;
      id: string;
      name: string;
      sections: string[];
      visibility: FormVisibility.TRANSIENT;
    };
    sections: Record<
      string,
      {
        fields: string[];
        id: string;
        title: string;
      }
    >;
  }
}
