import {
  CheckIcon,
  ErrorBadgeIcon,
  InfoBadgeIcon,
  Text,
  WarningBadgeIcon,
} from '@weave/design-system';
import { FC } from 'react';
import { getAlertStyle } from './inline-alert.style';

export type NoteType = 'info' | 'warning' | 'error' | 'success';

interface InlineAlertProps {
  type: NoteType;
  note: string;
}

const getIcon = (type: NoteType) => {
  switch (type) {
    case 'info':
      return <InfoBadgeIcon color="primary" />;
    case 'warning':
      return <WarningBadgeIcon color="warn" />;
    case 'error':
      return <ErrorBadgeIcon color="error" />;
    case 'success':
      return <CheckIcon color="success" />;
  }
};

export const InlineAlert: FC<InlineAlertProps> = ({ note, type }) => {
  return (
    <div css={getAlertStyle(type)}>
      {getIcon(type)}
      <Text>{note}</Text>
    </div>
  );
};
