import { FC, useEffect } from 'react';

import { Modal, ModalControlModalProps } from '@weave/design-system';

import { useWritebackSetupContext, useWritebackCapabilitiesContext } from 'context';
import { WritebackSetupStep } from 'shared/types';
import { useUserACL } from 'shared/hooks';
import { isWeaveUser } from 'shared/helpers/utils';

import WelcomeScreen from './setup-screens/welcome/welcome.component';
import WritebackDestinationScreen from './setup-screens/writeback-destination/writeback-destination.component';
import ReviewScreen from './setup-screens/review/review.component';

import { modalBodyStyle } from './writeback-setup-modal.styles';

interface WritebackSetupModalProps {
  modalProps: ModalControlModalProps;
  onOpenModal: () => void;
}

const WritebackSetupModal: FC<WritebackSetupModalProps> = ({
  modalProps,
  onOpenModal,
}) => {
  const {
    writebackAuthorization,
    isLoadingWritebackAuthorization,
    isAuthStatusChecked,
    hasPMSIntegration,
    hasMultipleSupportedSourceTenants,
    hasMultiTenantSource,
  } = useWritebackCapabilitiesContext();

  const { currentStep, isStepInitialised, hasCustomUserAccess } =
    useWritebackSetupContext();
  const { hasProviderReviewPermission } = useUserACL();

  useEffect(() => {
    if (
      hasPMSIntegration &&
      !isLoadingWritebackAuthorization &&
      isAuthStatusChecked &&
      writebackAuthorization === 'Invalid' &&
      isStepInitialised &&
      (hasCustomUserAccess || !isWeaveUser()) &&
      !hasProviderReviewPermission
    ) {
      onOpenModal();
    }
  }, [
    hasPMSIntegration,
    writebackAuthorization,
    isStepInitialised,
    hasCustomUserAccess,
    hasProviderReviewPermission,
    isLoadingWritebackAuthorization,
  ]);

  function getCurrentScreenView() {
    switch (currentStep) {
      case WritebackSetupStep.WELCOME:
        return <WelcomeScreen />;

      case WritebackSetupStep.SELECT_DESTINATION:
        return <WritebackDestinationScreen />;

      case WritebackSetupStep.REVIEW:
        return <ReviewScreen />;

      default:
        return null;
    }
  }

  function getModalMinWidth() {
    switch (currentStep) {
      case WritebackSetupStep.REVIEW:
        return 630;

      case WritebackSetupStep.SELECT_DESTINATION: {
        if (hasMultipleSupportedSourceTenants() || hasMultiTenantSource) {
          return 1080;
        }

        return 700;
      }

      default:
        return 700;
    }
  }

  return (
    <Modal
      {...modalProps}
      disableCloseOnEscape={!hasCustomUserAccess}
      disableCloseOnOverlayClick={!hasCustomUserAccess}
      minWidth={getModalMinWidth()}
    >
      <Modal.Body css={modalBodyStyle}>{getCurrentScreenView()}</Modal.Body>
    </Modal>
  );
};

export default WritebackSetupModal;
