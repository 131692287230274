import { FC } from 'react';

import { Chip } from '@weave/design-system';
import { SyncAppTypes } from 'shared/types';

interface SyncStatusProps {
  status: SyncAppTypes.HealthStatus;
  isSupported?: boolean;
}

const SyncStatus: FC<SyncStatusProps> = ({ status, isSupported = true }) => {
  if (!isSupported) {
    return <Chip variant="disabled">Not Supported</Chip>;
  }

  if (status === 'Healthy') {
    return <Chip variant="success">Sync</Chip>;
  }

  if (status === 'Unhealthy') {
    return <Chip variant="critical">Not Syncing</Chip>;
  }

  return null;
};

export default SyncStatus;
