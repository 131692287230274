import { Suspense, useEffect, useMemo, FC } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import jwtDecode from 'jwt-decode';

import { tracking } from '@weave/tracking';
import { PrimaryButton, Text, TextLink } from '@weave/design-system';

import { AuthStorage, WeaveToken } from 'auth/auth.types';
import {
  useWritebackCapabilitiesContext,
  useWritebackSetupContext,
  useLocationValidityContext,
} from 'context';
import { ROOT, FORM_SUBMISSION_ROOT } from 'shared/constants/paths';
import { CenteredSpinningLoader } from 'shared/components';
import { getCurrentLocationId, IPAddress } from 'shared/helpers/utils';
import {
  usePMSAuthReminder,
  useLocationChildren,
  useUserACL,
  usePMSChangeNotification,
} from 'shared/hooks';
import { CustomAxios } from 'shared/helpers/axios';
import { headerKeys } from 'shared/constants';

import { getPrivateRoutes } from 'pages/routes';

import {
  mainContainerStyle,
  innerContentContainerStyle,
  notMigratedTextContainerStyle,
} from './main-content.style';

const superUserString = 'weave';

export const MainContent: FC = () => {
  const mainContainerStyles = [mainContainerStyle];

  const { isValidating, isUnauthorisedLocation, hasFieldError, hasUnknownError } =
    useLocationValidityContext();

  const {
    isLoadingPreferredSourceTenant,
    isLoadingWritebackSourcesCapabilities,
    isLoadingSourceTenants,
  } = useWritebackCapabilitiesContext();

  const {
    WritebackSetupModal,
    modalProps: writebackSetupModalProps,
    openModal: openWritebackSetupModal,
  } = useWritebackSetupContext();

  const {
    modalProps: pmsChangeNotificationModalProps,
    PMSChangeNotificationModal,
    closeModal: closePMSChangeNotificationModal,
  } = usePMSChangeNotification();

  const {
    PMSAuthReminderModal,
    modalProps: pmsAuthReminderModalProps,
    closeModal: closePMSAuthReminderModal,
  } = usePMSAuthReminder();

  const { isParentLocation } = useLocationChildren();
  const { hasProviderReviewPermission } = useUserACL();

  const privateRoutes = useMemo(() => {
    return getPrivateRoutes({
      isUnifyParentLocation: isParentLocation,
      hasProviderReviewPermission: hasProviderReviewPermission,
    });
  }, [isParentLocation]);

  const contactSupportContent = (
    <PrimaryButton style={{ padding: 0 }} size="tiny">
      <TextLink
        href="https://www.weavehelp.com/hc/en-us/p/contact-us"
        target="_blank"
        style={{ color: 'inherit', padding: '8px 48px', display: 'inline-block' }}
      >
        Contact Support
      </TextLink>
    </PrimaryButton>
  );

  useEffect(() => {
    const weaveToken = window.localStorage.getItem(AuthStorage.weave_token);
    const decodedToken: WeaveToken = jwtDecode(weaveToken!);
    const locationId = getCurrentLocationId();

    if (decodedToken.user_id && decodedToken.type && locationId) {
      tracking.pendo.initialize({
        visitorApp: 'forms-portal-ui',
        accountId: decodedToken.type === superUserString ? 'weave' : locationId,
        visitorId: decodedToken.user_id,
        visitorRole: decodedToken.type,
      });
    }
  }, []);

  useEffect(() => {
    IPAddress.getInstance()
      .getIPAddress()
      .then((ipAddress) => {
        CustomAxios.setHeader(headerKeys.ipAddress, ipAddress);
      });
  }, []);

  function showRoutes() {
    if (
      isValidating ||
      isLoadingPreferredSourceTenant ||
      isLoadingSourceTenants ||
      isLoadingWritebackSourcesCapabilities
    ) {
      return <CenteredSpinningLoader />;
    }

    if (isUnauthorisedLocation) {
      return (
        <div css={notMigratedTextContainerStyle}>
          <Text>
            Hello, looks like you are not enrolled for the new and improved version of
            Forms.
          </Text>

          <Text>
            <TextLink
              href="https://www.weavehelp.com/hc/en-us/articles/4614809517851"
              target="_blank"
            >
              See what's new
            </TextLink>{' '}
            in Forms v2.0
          </Text>

          {contactSupportContent}
        </div>
      );
    }

    if (hasFieldError) {
      return (
        <div css={notMigratedTextContainerStyle}>
          <Text>Error occurred while validating your location.</Text>
          {contactSupportContent}
        </div>
      );
    }

    if (hasUnknownError) {
      return (
        <div css={notMigratedTextContainerStyle}>
          <Text>Sorry, something went wrong! We couldn't validate your location!</Text>
          {contactSupportContent}
        </div>
      );
    }

    return (
      <Suspense fallback={<CenteredSpinningLoader />}>
        {
          <Switch>
            {privateRoutes.map((route) => (
              <Route key={route.path as string} {...route} />
            ))}
            <Redirect from={ROOT} to={FORM_SUBMISSION_ROOT} />
          </Switch>
        }
      </Suspense>
    );
  }

  return (
    <main css={mainContainerStyles}>
      <div css={innerContentContainerStyle}>{showRoutes()}</div>

      <PMSAuthReminderModal
        modalProps={pmsAuthReminderModalProps}
        onClose={closePMSAuthReminderModal}
      />

      <PMSChangeNotificationModal
        modalProps={pmsChangeNotificationModalProps}
        onCloseModal={closePMSChangeNotificationModal}
      />

      <WritebackSetupModal
        modalProps={writebackSetupModalProps}
        onOpenModal={openWritebackSetupModal}
      />
    </main>
  );
};
