import {
  WritebackCapabilitySettingType,
  WritebackCapabilitiesResponse,
} from 'shared/types';

export function checkSettingTypeCapability(
  data: WritebackCapabilitiesResponse | undefined,
  settingType: WritebackCapabilitySettingType
) {
  if (!data) {
    return false;
  }

  const capability = data.capabilities.find((c) => c.settingType === settingType);

  if (!capability || !capability.isCapable) {
    return false;
  }

  return true;
}
