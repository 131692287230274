import { FC } from 'react';
import { css } from '@emotion/core';

import { ArchiveIcon } from '@weave/design-system';

const invertedStyle = css`
  transform: rotate(180deg);
`;

export const InvertedArchiveIcon: FC = (props) => {
  return <ArchiveIcon css={invertedStyle} {...props} />;
};
