import {
  UseMutateFunction,
  useMutation,
  useQueryClient,
  UseMutateAsyncFunction,
} from 'react-query';

import { useAlert } from '@weave/design-system';

import { queryKeys } from 'shared/constants';
import { Packet } from 'shared/types';
import {
  IUpdatePacket,
  updatePacket as updatePacketAPI,
  updateReviewRequiredStatus as updateReviewRequiredStatusAPI,
  IPacketReviewRequiredPayload,
} from 'shared/helpers/axios/apis';

interface IUseUpdatePacketResults {
  isUpdateError: boolean;
  isUpdateSuccess: boolean;
  isUpdating: boolean;
  updatePacket: UseMutateFunction<any, unknown, IUpdatePacket, unknown>;
  updateReset: () => void;
  updateReviewRequiredStatusForPacket: UseMutateAsyncFunction<
    any,
    unknown,
    IPacketReviewRequiredPayload,
    unknown
  >;
  isUpdatingReviewRequiredFlagForPacket: boolean;
}

export function useUpdatePacket(): IUseUpdatePacketResults {
  const queryClient = useQueryClient();
  const alerts = useAlert();

  const {
    mutate: updatePacket,
    reset: updateReset,
    isLoading,
    isSuccess,
    isError,
  } = useMutation(updatePacketAPI, {
    onSuccess: () => {
      queryClient.invalidateQueries(queryKeys.formPackets);
    },
    onError: () => {
      queryClient.invalidateQueries(queryKeys.formPackets);
    },
  });

  const {
    mutateAsync: updateReviewRequiredStatus,
    isLoading: isUpdatingReviewRequiredFlagForPacket,
  } = useMutation(updateReviewRequiredStatusAPI, {
    onMutate: ({ packetId }) => {
      queryClient.setQueryData(queryKeys.formPackets, (data: any) => {
        const packets = (data?.packets || []) as Packet.Data[];

        return {
          message: data?.message || '',
          packets: toggleReviewStatus(packets, packetId),
        };
      });
    },
    onSuccess: (_, { reviewRequired }) => {
      queryClient.invalidateQueries(queryKeys.formPackets);

      if (reviewRequired) {
        alerts.success('Successfully enabled Provider Review for this packet');
      } else {
        alerts.success('Successfully disabled Provider Review for this packet');
      }
    },
    onError: (_, { packetId }) => {
      queryClient.setQueryData(queryKeys.formPackets, (data: any) => {
        const packets = (data?.packets || []) as Packet.Data[];

        return {
          message: data?.message || '',
          packets: toggleReviewStatus(packets, packetId),
        };
      });

      queryClient.invalidateQueries(queryKeys.formPackets);
      alerts.error('Failed to save changes.');
    },
  });

  return {
    isUpdateError: isError,
    isUpdateSuccess: isSuccess,
    isUpdating: isLoading,
    updatePacket,
    updateReset,
    updateReviewRequiredStatusForPacket: updateReviewRequiredStatus,
    isUpdatingReviewRequiredFlagForPacket,
  };
}

function toggleReviewStatus(packets: Packet.Data[], packetId: string) {
  const newPackets = [...packets];
  const index = newPackets.findIndex((packet) => packet.id === packetId);

  if (index !== -1) {
    newPackets[index] = {
      ...newPackets[index],
      reviewRequired: !newPackets[index].reviewRequired,
    };
  }

  return newPackets;
}
