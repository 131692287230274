import { FC } from 'react';

import {
  Heading,
  Text,
  TextLink,
  IconButton,
  XIcon,
  PrimaryButton,
} from '@weave/design-system';

import WelcomeImage from 'assets/svgs/writeback-setup/welcome-screen.svg';
import { useAuth } from 'auth/auth-provider';
import { CenteredSpinningLoader } from 'shared/components';
import { HELP_LINKS, pendoTags } from 'shared/constants';
import { useWritebackSetupContext, useWritebackCapabilitiesContext } from 'context';

import { headingContainerStyle } from '../../writeback-setup-modal.styles';
import { headingStyle, textStyle } from './welcome.styles';

const WelcomeScreen: FC = () => {
  const { user } = useAuth();
  const { hasMultipleSupportedSourceTenants } = useWritebackCapabilitiesContext();
  const {
    goToNextStep,
    hasCustomUserAccess,
    closeModal: onCloseModal,
  } = useWritebackSetupContext();

  const _hasMultipleSupportedSourceTenants = hasMultipleSupportedSourceTenants();

  function getDescriptionTextContent() {
    const commonContent = (
      <>
        Welcome to <b>Weave Digital Forms</b> -- the most efficient way to collect and
        sync patient information reliably.{' '}
      </>
    );

    if (_hasMultipleSupportedSourceTenants) {
      return (
        <>
          {commonContent}
          Before you start using Digital Forms, you must specify where you want to save
          your collected patient information. We'll guide you through this initial setup.
        </>
      );
    }

    return (
      <>
        {commonContent}
        For your convenience, your form submissions are set to writeback automatically.
        You can change these settings anytime according to your office's needs.
      </>
    );
  }

  if (!user) {
    return <CenteredSpinningLoader />;
  }

  return (
    <>
      <div css={headingContainerStyle}>
        <Heading level={2} css={headingStyle}>
          Hello, {user.name}!
        </Heading>

        {hasCustomUserAccess && (
          <IconButton onClick={onCloseModal} label="">
            <XIcon color="default" />
          </IconButton>
        )}
      </div>

      <WelcomeImage />

      <Text css={textStyle}>{getDescriptionTextContent()}</Text>

      {_hasMultipleSupportedSourceTenants && (
        <Text css={textStyle}>
          <b>Note:</b> If you are not the deciding authority, you may ask your office
          manager or system administrator to complete this initial setup.
        </Text>
      )}

      <Text css={textStyle}>
        By continuing, you agree to Weave Digital Forms{' '}
        <TextLink
          to={HELP_LINKS.writebackSetup.termsOfUse}
          target="_blank"
          trackingId={pendoTags.writebackAuthSetup.termsOfUse}
        >
          terms of use
        </TextLink>
        .
      </Text>

      <PrimaryButton
        onClick={goToNextStep}
        trackingId={pendoTags.writebackAuthSetup.getStarted}
        size="tiny"
      >
        Get Started
      </PrimaryButton>
    </>
  );
};

export default WelcomeScreen;
