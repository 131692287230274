import { FC } from 'react';

import { FlyoutMenuAction, IconButton } from '@weave/design-system';

import { actionItemsStyles } from './header-action-button.styles';

interface HeaderActionButtonProps {
  actionItem: FlyoutMenuAction;
}

const HeaderActionButton: FC<HeaderActionButtonProps> = ({ actionItem }) => {
  const { Icon, onClick, trackingId, label } = actionItem;

  return (
    <IconButton
      onClick={onClick}
      label={label}
      trackingId={trackingId}
      showLabelOnHover
      data-testid={label}
    >
      {Icon && <Icon css={actionItemsStyles} />}
    </IconButton>
  );
};

export default HeaderActionButton;
