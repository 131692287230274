import { css } from '@emotion/core';
import { theme } from '@weave/theme';

export const formFieldItemStyles = (isDragging: boolean, isUsed: boolean) => css`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: ${theme.spacing(2)};
  border-bottom: 1px dashed ${theme.colors.neutral20};

  // https://github.com/atlassian/react-beautiful-dnd/issues/2361
  ${!isDragging && 'transform: translate(0px, 0px) !important;'}
  ${isUsed &&
  `
    background-color: ${theme.colors.neutral10};
  `}

  .field-sync-icon {
    margin-right: ${theme.spacing(1)};
  }

  &.is-dragging {
    transform: rotate(5deg);
    border-bottom: 0px;
    background-color: ${theme.colors.neutral10};
  }

  :hover {
    ${!isUsed &&
    `
      cursor: grab;
      background-color: ${theme.colors.neutral10};
      color: ${theme.colors.primary50} !important;

      .field-name {
        color: ${theme.colors.primary50};
      }
    `}
    ${isUsed && `cursor: not-allowed;`}
  }
`;

export const formFieldItemContentStyles = css`
  display: flex;
  flex-direction: column;
  justify-content: center;

  .field-name {
    display: flex;
    align-items: center;
    margin: ${theme.spacing(0)};
  }
  .field-desc {
    margin: ${theme.spacing(0)};
  }
  .medical-history-error {
    cursor: pointer;
    margin-left: ${theme.spacing(1)};
  }

  .field-name,
  .field-desc {
    -webkit-user-select: none; //safari
    user-select: none;
  }
`;

export const newFieldBadgeStyle = css`
  margin-left: auto;
`;

export const syncIconStyles = (syncable: boolean) => {
  return css`
    color: ${syncable ? theme.colors.primary50 : theme.colors.neutral30};
  `;
};
