import { FC, Fragment, MouseEvent, useState } from 'react';
import { NavLink } from 'react-router-dom';

import { Text, useHoverLabel } from '@weave/design-system';

import { NewBadge, Dot } from 'shared/components';
import { SubNav } from 'shared/types';
import { defaultHoverFontStyle } from 'shared/styles';
import {
  FORM_SUBMISSION_CATEGORY_SLUGS,
  FORM_SUBMISSION_CATEGORIES,
} from 'shared/constants/paths';
import { useUserACL } from 'shared/hooks';

import RightCaretAlternate from 'assets/icons/small/right-caret-alternate.svg';
import DownCaretAlternate from 'assets/icons/small/down-caret-alternate.svg';

import {
  subNavMenuStyle,
  subNavLinkCountStyle,
  subNavTitleStyle,
  newBadgeStyleOverride,
} from './sub-nav-menu.style';
import { NestedNavMenu } from './nested-nav.component';

interface SubNavMenuProps {
  navLinks: SubNav[];
  onChange?: (nav: SubNav) => void;
  onHover?: (navId: string | null) => void;
}

export const SubNavMenu: FC<SubNavMenuProps> = ({
  onChange,
  navLinks,
  onHover,
  ...otherProps
}) => {
  const { hasProviderReviewPermission } = useUserACL();
  const [collapsedNavs, setCollapsedNavs] = useState<Record<string, boolean>>({
    [FORM_SUBMISSION_CATEGORIES.reviewed.root]: hasProviderReviewPermission
      ? false
      : true,
  });
  const { HoverLabel, labelProps, triggerProps } = useHoverLabel();

  function clickHandler(index: number, subNavIndex?: number) {
    if (navLinks[index]?.nestedLinks?.length) {
      setCollapsedNavs((prev) => ({
        ...prev,
        [navLinks[index].to]: false,
      }));
    }

    if (onChange) {
      onChange(navLinks[index]);
    }
  }

  function mouseOverHandler(navId: string | undefined) {
    if (!onHover || !navId) {
      return;
    }

    onHover(navId);
  }

  function mouseOutHandler() {
    if (!onHover) {
      return;
    }

    onHover(null);
  }

  function toggleNestedNav(e: MouseEvent, to: string) {
    e.stopPropagation();
    e.preventDefault();
    setCollapsedNavs((prev) => ({
      ...prev,
      [to]: !prev[to],
    }));
  }

  return (
    <div css={subNavMenuStyle} {...otherProps}>
      {navLinks.map(
        (
          {
            to,
            title,
            count,
            abbreviatedCount,
            unreadCount,
            abbreviatedUnreadCount,
            pendoTag,
            hidden,
            id,
            newFeatureHelper,
            icon,
            nestedLinks,
            showCount,
            boldOnCount,
            showUnreadIndicator,
          },
          index
        ) => {
          let countToShow = count;
          let abbreviatedCountToShow = abbreviatedCount;

          if (id === FORM_SUBMISSION_CATEGORY_SLUGS.allSubmitted) {
            countToShow = unreadCount;
            abbreviatedCountToShow = abbreviatedUnreadCount;
          }

          return (
            <Fragment key={to}>
              {!hidden ? (
                <>
                  <NavLink
                    to={to}
                    onClick={() => clickHandler(index)}
                    data-trackingid={pendoTag}
                    onMouseOver={() => mouseOverHandler(id)}
                    onMouseOut={mouseOutHandler}
                  >
                    {icon && (
                      <span className="sub-nav-icon">
                        {icon}{' '}
                        {!!nestedLinks?.length && (
                          <>
                            {!collapsedNavs[to] ? (
                              <DownCaretAlternate
                                onClick={(e) => toggleNestedNav(e as MouseEvent, to)}
                              />
                            ) : (
                              <RightCaretAlternate
                                onClick={(e) => toggleNestedNav(e as MouseEvent, to)}
                              />
                            )}{' '}
                          </>
                        )}
                      </span>
                    )}

                    <Text
                      size="large"
                      weight={!!(count && boldOnCount) ? 'semibold' : 'regular'}
                      textAlign="left"
                      css={subNavTitleStyle}
                      className="sub-nav-text"
                    >
                      {title}
                    </Text>

                    {newFeatureHelper && (
                      <NewBadge placement="right" css={newBadgeStyleOverride}>
                        {newFeatureHelper}
                      </NewBadge>
                    )}

                    {countToShow !== undefined && countToShow != 0 && !!showCount && (
                      <>
                        <Text
                          size="medium"
                          color="white"
                          weight={'regular'}
                          css={subNavLinkCountStyle}
                          className={
                            countToShow === 0 ? 'sub-nav-count--zero' : 'sub-nav-count'
                          }
                          {...triggerProps}
                        >
                          {abbreviatedCountToShow || countToShow}
                        </Text>

                        <HoverLabel {...labelProps} css={defaultHoverFontStyle}>
                          Unread submissions
                        </HoverLabel>
                      </>
                    )}

                    {showUnreadIndicator &&
                      unreadCount !== undefined &&
                      unreadCount !== 0 && <Dot css={{ marginLeft: 'auto' }} />}
                  </NavLink>
                  {!collapsedNavs[to] && !!nestedLinks?.length && (
                    <NestedNavMenu
                      nestedLinks={nestedLinks}
                      onHover={mouseOverHandler}
                      onHoverOut={mouseOutHandler}
                      onChange={(subIndex) => clickHandler(index, subIndex)}
                    />
                  )}
                </>
              ) : null}
            </Fragment>
          );
        }
      )}
    </div>
  );
};
