import { useModalControl, ModalControlResponse } from '@weave/design-system';

import WritebackSetupModal from '../../shared/hooks/reminders/writeback-setup/writeback-setup-modal.component';

export interface UseWritebackSetupResults extends ModalControlResponse {
  WritebackSetupModal: typeof WritebackSetupModal;
}

export const useWritebackSetup = (): UseWritebackSetupResults => {
  const modalControls = useModalControl();

  return {
    ...modalControls,
    WritebackSetupModal,
  };
};
