import { FocusEvent, KeyboardEvent, useState } from 'react';

import {
  DropdownField,
  KeyNames,
  DataSyncIcon,
  Text,
  TextField,
  useControlledField,
  PrimaryButton,
} from '@weave/design-system';

import { useFormComponentsContext } from 'context';
import { BuilderContext, IFormBuilder as FB } from 'shared/types';
import { pendoTags } from 'shared/constants';

import {
  quickSectionsStyle,
  inplaceActionButtonWrapperStyle,
  newSectionInputWrapperStyle,
  newSectionInputStyle,
  sectionTemplateDropdownStyle,
  sectionTemplateDropdownOptionStyle,
  sectionTemplateWrapperStyle,
  syncIconStyle,
  newSectionTemplateStyle,
} from './section-buttons.style';

export const SectionButtons = () => {
  const { updateFormState, addSectionTemplate } = useFormComponentsContext();
  const [newSectionName, setNewSectionName] = useState('');

  const newSectionProps = useControlledField({
    type: 'text',
    value: newSectionName,
    onChange: (value: string) => {
      setNewSectionName(value);
    },
  });

  const sectionTemplatesProps = useControlledField({
    type: 'dropdown',
    value: '',
    onChange: (selectedTemplate) => {
      addSectionTemplate(selectedTemplate);
      updateUsedTemplates(selectedTemplate, true);
    },
  });

  const { sectionTemplates, usedTemplates, updateUsedTemplates } =
    useFormComponentsContext();

  const onAddNewSection = () => {
    updateFormState({
      type: BuilderContext.FormUpdateType.ADD_SECTION,
      data: newSectionProps.value,
    });
    setNewSectionName('');
  };

  const onNewSectionInputBlur = (e?: FocusEvent<Element, Element>) => {
    if (e && e.relatedTarget) {
      // if focus is on "Add" button then add section
      if ((e.relatedTarget as HTMLButtonElement).name === 'add-section-btn') {
        onAddNewSection();
        return;
      }
    }
  };

  const onSectionInputKeyDetect = (e: KeyboardEvent<HTMLInputElement>): void => {
    if (e.key === KeyNames.Enter && newSectionProps.value.trim() !== '') {
      onAddNewSection();
    }
  };

  function getSectionTemplateOptions(sectionTemplates: FB.ISectionTemplates | undefined) {
    if (!sectionTemplates) {
      return null;
    }

    const options: Array<JSX.Element> = [];

    for (const template in sectionTemplates) {
      options.push(
        <DropdownField.Option
          value={template}
          key={template}
          disabled={usedTemplates[template]}
          trackingId={pendoTags.newFormEditor.sectionTemplate[template]}
          css={sectionTemplateDropdownOptionStyle}
        >
          <div className="section-template-item">
            <DataSyncIcon size={16} css={syncIconStyle(template === 'patient')} />

            <Text as="span">
              {(sectionTemplates as FB.ISectionTemplates)[template].title}
            </Text>

            <Text
              as="span"
              size="small"
              color={template === 'patient' ? 'success' : 'error'}
            >
              {template === 'patient' ? 'Writebacks' : `Doesn't writeback`}
            </Text>
          </div>
        </DropdownField.Option>
      );
    }

    return options;
  }

  return (
    <div css={quickSectionsStyle}>
      <div css={inplaceActionButtonWrapperStyle}>
        <div css={newSectionInputWrapperStyle}>
          <div css={newSectionInputStyle}>
            <TextField
              css={newSectionTemplateStyle}
              name="new_section"
              label=""
              placeholder="Enter a section name"
              {...newSectionProps}
              autoComplete="off"
              onBlur={onNewSectionInputBlur}
              onKeyDown={onSectionInputKeyDetect}
            />
          </div>
          <PrimaryButton
            name="add-section-btn"
            disabled={newSectionProps.value.trim() === ''}
            onClick={onAddNewSection}
            trackingId={pendoTags.newFormEditor.newSection}
            size="tiny"
          >
            Add
          </PrimaryButton>
        </div>
      </div>

      <div css={inplaceActionButtonWrapperStyle}>
        <div css={sectionTemplateWrapperStyle}>
          <DropdownField
            name="section_template"
            label=""
            {...sectionTemplatesProps}
            placeholder={'Select a section to add'}
            css={sectionTemplateDropdownStyle}
          >
            {getSectionTemplateOptions(sectionTemplates)}
          </DropdownField>
        </div>
      </div>
    </div>
  );
};
