import { useSubmissionStats } from './useSubmissionStats.hook';

export const useHasAnySubmission = () => {
  const { count: inboxCount } = useSubmissionStats({ dashboardLabel: 1 });
  const { count: completedCount } = useSubmissionStats({ dashboardLabel: 11 });
  const { count: sentCount } = useSubmissionStats({ dashboardLabel: 14 });
  const { count: archivedCount } = useSubmissionStats({ dashboardLabel: 15 });

  return inboxCount > 0 || sentCount > 0 || completedCount > 0 || archivedCount > 0;
};
