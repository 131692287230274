import { css } from '@emotion/core';
import { theme } from '@weave/theme';

export const dropdownNavStyles = css`
  background: ${theme.colors.primary50};
  border-radius: ${theme.borderRadius.medium};
  border: none;

  .count {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${theme.colors.white};
    border-radius: ${theme.borderRadius.small};
    padding: ${theme.spacing(0, 1)};
    margin-right: ${theme.spacing(1)};
    font-weight: ${theme.font.weight.regular};
  }

  p {
    .count {
      color: ${theme.colors.primary50} !important;
    }
  }

  > * {
    font-weight: ${theme.font.weight.bold};
    color: ${theme.colors.white} !important;
  }
`;

export const dropdownOptionTextStyle = css`
  display: flex;
`;

export const dropdownOptionStyle = css`
  .count {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${theme.colors.primary50};
    border-radius: ${theme.borderRadius.small};
    padding: ${theme.spacing(0, 1)};
    margin-right: ${theme.spacing(1)};
    font-weight: ${theme.font.weight.regular};
    color: ${theme.colors.white};
  }
  &[aria-selected='true'] {
    svg {
      color: ${theme.colors.primary50};
    }
  }
`;

export const dropdownItemTitleStyle = css`
  margin: 0;
  font-weight: ${theme.font.weight.semibold} !important;
  color: inherit !important;
`;

export const nestedNavLinkStyle = css`
  border-left: 1px solid ${theme.colors.neutral20};
  padding-left: ${theme.spacing(2)};
`;
