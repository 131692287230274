import { FC } from 'react';
import { css } from '@emotion/core';
import { DatePickerField, useFormField } from '@weave/design-system';

export const BuilderDateField: FC<{}> = () => {
  const fieldProps = useFormField({
    type: 'date',
  });
  return (
    <DatePickerField
      css={css`
        max-width: 500px;
      `}
      name="date-picker"
      label=""
      {...fieldProps}
    />
  );
};
