import { css } from '@emotion/core';
import { theme } from '@weave/theme';

export const headerStyle = css`
  margin: 0;
  margin-bottom: ${theme.spacing(1)};
`;

export const textStyle = css`
  max-width: 562px;
`;

interface OverrideSyncAppStyleConfig {
  isMultiPMS: boolean;
}

export function getSyncAppStyleOverride({ isMultiPMS }: OverrideSyncAppStyleConfig) {
  if (isMultiPMS) {
    return css`
      margin-bottom: ${theme.spacing(3)};
    `;
  }

  return css`
    max-width: 562px;
  `;
}
