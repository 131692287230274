import React, {
  useEffect,
  createContext,
  useMemo,
  useContext,
  useCallback,
  useState,
} from 'react';
import { useHistory, useLocation } from 'react-router';
import jwtDecode from 'jwt-decode';
import { useQueryClient } from 'react-query';
import { setUser as setSentryUser } from '@sentry/react';

import { useAlert } from '@weave/design-system';
import {
  authnClient,
  ClientTokens,
  DecodedToken,
  getDecodedWeaveToken,
  getLastVisitedPage,
  getWeaveToken,
  setLastVisitedPage,
  PortalUser,
} from '@weave/auth';

import { axiosInstance, CustomAxios } from 'shared/helpers/axios';
import { FORM_SUBMISSION_ROOT } from 'shared/constants/paths';
import { REACT_APP_SIGN_IN_METHOD } from 'shared/constants';

import {
  OktaAuthProps,
  AuthContextValue,
  AuthStorage,
  Location,
  User,
  WeaveToken,
} from './auth.types';
import { authApi } from './auth.api';
import {
  authInterceptor,
  handleAuthFromLocalStorage,
  handleGetLocations,
  handleLocalStorage,
  setUpAuthInterceptor,
  tokenExpired,
} from './auth.helpers';

const AuthContext = createContext<AuthContextValue>({} as AuthContextValue);

export const AuthProvider: React.FC<OktaAuthProps> = ({ oktaAuth, children }) => {
  const [user, setUser] = useState<User | null>();
  const [authToken, setAuthToken] = useState<DecodedToken | null>();
  const alerts = useAlert();
  const history = useHistory();
  const { pathname } = useLocation();
  const queryClient = useQueryClient();

  const signOut = async (expired?: boolean) => {
    const isWeaveUser = handleLocalStorage.get(AuthStorage.is_weave_user);

    // authnClient going forward manages these local storage items but since the keys don't match we need to clear the old ones as well
    handleLocalStorage.delete([
      AuthStorage.weave_token,
      AuthStorage.redirect_url,
      AuthStorage.locations,
      AuthStorage.is_weave_user,
      AuthStorage.active_location,
    ]);

    if (isWeaveUser) handleLocalStorage.delete(AuthStorage.active_location);

    /* clear headers */
    CustomAxios.clearAuth();
    axiosInstance.interceptors.request.eject(authInterceptor);

    /* Clear memory storage */
    setUser(null);
    setAuthToken(null);

    // Clear react query cache
    queryClient.removeQueries();

    if (!expired) {
      authnClient.changeAuthMethod(REACT_APP_SIGN_IN_METHOD);
      await authnClient.signOut();
    } else {
      alerts.info('Session Expired please sign back in.');
    }

    history.push('/sign-in');
  };

  const hasACL = (ACL: number): boolean => {
    const weaveToken = handleLocalStorage.get(AuthStorage.weave_token);
    const location = handleLocalStorage.get(AuthStorage.active_location);

    if (typeof weaveToken === 'string' && location) {
      const weave_decoded_token: WeaveToken = jwtDecode(weaveToken);

      // check if token type is weave location
      if (weave_decoded_token.type === 'weave') {
        return weave_decoded_token.ACLS['weave'].includes(ACL);
      }

      return weave_decoded_token.ACLS[(location as Location).locationID].includes(ACL);
    }

    return false;
  };

  const handleAuthorization = async (location: Location) => {
    const originalPath = getLastVisitedPage();
    const redirect = handleLocalStorage.get(AuthStorage.redirect_url);

    handleLocalStorage.create(AuthStorage.active_location, location);
    CustomAxios.setLocationHeader(location.locationID);

    // Clear react query cache
    queryClient.removeQueries();

    if (originalPath && originalPath !== '/') {
      history.push(originalPath);
      oktaAuth.removeOriginalUri();
    } else {
      if (!redirect && pathname === '/location') {
        history.push(FORM_SUBMISSION_ROOT);
        return;
      }
      history.push(redirect || pathname);
    }
    handleLocalStorage.delete(AuthStorage.redirect_url);
  };

  const handleAuthentication = async (clientTokens: ClientTokens) => {
    try {
      if (clientTokens) {
        const weave_decoded_token = getDecodedWeaveToken();
        const weave_token: string | undefined = getWeaveToken();

        const active_location = handleLocalStorage.get(
          AuthStorage.active_location
        ) as Location;

        /* Initial Setup */
        CustomAxios.setUpAuth(weave_token!, weave_decoded_token);
        const email = weave_decoded_token?.username || '';
        let userInfo: PortalUser | undefined;

        try {
          userInfo = await authnClient.fetchUser();
        } catch (error) {
          console.error('Error fetching user info');
        }

        /* 1. Is Weave user send them to "/location" to select location */
        if (weave_decoded_token?.type === 'weave') {
          if (active_location) {
            handleLocalStorage.delete(AuthStorage.active_location);
          }

          handleLocalStorage.create(AuthStorage.is_weave_user, 'true');
          history.push('/location');
        } else {
          const locations = await handleGetLocations(weave_decoded_token!.ACLS);

          if (locations) {
            handleLocalStorage.create(AuthStorage.locations, locations);

            const isCustomAccessUser = await authApi.POST.isCustomAccessUser({
              email,
              locationID: locations[0].locationID,
            });

            if (isCustomAccessUser) {
              /**
               * 2. Not a Weave user, but a contractor.
               * Send them to "/location" to select the location.
               */
              handleAuthorization(locations[0]);
              handleLocalStorage.create(AuthStorage.is_weave_user, 'true');
              history.push('/location');
            } else if (locations.length === 1) {
              /* 3. Not a Weave user and only has one location */
              handleAuthorization(locations[0]);
            } else {
              /* 4. Not a Weave user and has multiple locations */
              if (active_location) {
                const hasAccessToActiveLocation = locations.find((location) =>
                  location.locationID === active_location.locationID ? true : false
                );

                if (!hasAccessToActiveLocation) {
                  handleLocalStorage.delete(AuthStorage.active_location);
                  history.push('/location');
                } else {
                  handleAuthorization(active_location);
                }
              } else {
                history.push('/location');
              }
            }
          } else {
            throw new Error("Couldn't retrieve user locations.");
          }
        }

        setUpAuthInterceptor(signOut);
        setAuthToken(weave_decoded_token);
        let name = '';
        if (!!userInfo) {
          name = `${userInfo.firstName} ${userInfo.lastName}`;
        }
        setUser({
          name: name,
          email: email,
        });
        setSentryUser({
          name: name,
          email: email,
          location: active_location?.locationID || '',
        });
      } else {
        throw new Error('Token not received in api call.');
      }
    } catch (err: any) {
      alerts.error(err?.message || 'Failed to retrieve tokens.');
    }
  };

  const handleOktaAuthFlow = useCallback(async () => {
    if (!oktaAuth) return;

    /* Check if we are already signed in or needs to refresh token */
    const userAuthenticated = await handleAuthFromLocalStorage(signOut);

    if (userAuthenticated) {
      const decodedWeaveToken = getDecodedWeaveToken();
      const location = handleLocalStorage.get(AuthStorage.active_location) as Location;
      const redirect = handleLocalStorage.get(AuthStorage.redirect_url) as string;
      let userInfo: PortalUser | undefined;

      const weave_token: string | undefined = getWeaveToken();

      try {
        if (weave_token && tokenExpired(weave_token)) {
          const newWeaveToken = await authnClient.refreshWeaveToken();
          CustomAxios.setUpAuth(newWeaveToken);
        }

        userInfo = await authnClient.fetchUser();
      } catch (error) {
        console.error('Failed to fetch user info.');
      }

      if (decodedWeaveToken) {
        const name =
          `${!!userInfo?.firstName ? userInfo.firstName + ' ' : ''}${
            userInfo?.lastName
          }` || '';
        const email = userInfo?.username || '';
        setUser({
          name: name,
          email: email,
        });
        setAuthToken(decodedWeaveToken);
        setSentryUser({
          name: name,
          email: email,
          location: location?.locationID || '',
        });
      }

      if (!location) {
        history.push('/location');
      } else {
        CustomAxios.setLocationHeader(location.locationID);
        history.push(redirect || pathname);
      }

      return;
    }

    const code = new URLSearchParams(history.location.search).get('code');
    if (!code) {
      history.push('/sign-in');
      return;
    }

    const clientTokens = await authnClient.handleCallback();
    handleAuthentication(clientTokens);
  }, [oktaAuth]);

  useEffect(() => {
    if (
      history.location.pathname !== '/sign-in' &&
      history.location.pathname !== '/login/callback' &&
      history.location.pathname !== '/' &&
      history.location.pathname !== '/location'
    ) {
      authnClient.changeAuthMethod(REACT_APP_SIGN_IN_METHOD);
      const { pathname, search } = history.location;
      setLastVisitedPage(`${pathname}${search}`);
    }
    handleOktaAuthFlow();
  }, [handleOktaAuthFlow]);

  const value: AuthContextValue = useMemo(
    () => ({
      user,
      oktaAuth,
      authToken,
      signOut,
      handleAuthorization,
      hasACL,
    }),
    [oktaAuth, authToken, user]
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => useContext(AuthContext);
