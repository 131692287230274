import { css } from '@emotion/core';
import { theme } from '@weave/theme';

interface IContainerStyleConfig {
  removePadding?: boolean;
}

export function getContainerStyle({ removePadding }: IContainerStyleConfig) {
  const styles = css`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: ${theme.spacing(4, 0)};
    flex: 1;
  `;

  if (removePadding) {
    return [
      styles,
      css`
        padding: 0;
      `,
    ];
  }

  return styles;
}
