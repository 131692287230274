import { css } from '@emotion/core';
import { theme } from '@weave/theme';

import { TABLET_MODE_MAX_WIDTH, PHONE_MODE_MAX_WIDTH } from 'shared/constants';
import { defaultHoverFontStyle } from 'shared/styles';

export const sideNavLinkStyle = css`
  display: flex;
  align-items: center;
  color: ${theme.colors.text.default};
  text-decoration: none;
  padding: ${theme.spacing(1.5, 2.5)};
  font-weight: ${theme.font.weight.regular};
  border-left: ${theme.spacing(0.5)} solid transparent;
  border-bottom: 1px solid ${theme.colors.neutral10};
  line-height: 1;

  :hover,
  :active,
  &.active {
    border-left-color: ${theme.colors.primary50};
    text-decoration: none;
    color: inherit;
  }

  &.active {
    font-weight: ${theme.font.weight.semibold};

    svg {
      stroke-width: 1.5;
    }

    .side-nav-ripple {
      display: none;
    }
  }

  @media (max-width: ${TABLET_MODE_MAX_WIDTH}px) {
    border-bottom: none;
    justify-content: center;
    padding: ${theme.spacing(2, 2.5)};
  }

  @media (max-width: ${PHONE_MODE_MAX_WIDTH}px) {
    justify-content: flex-start;
    padding: ${theme.spacing(1.5, 2)};
    border-bottom: 1px solid ${theme.colors.neutral10};
  }
`;

export const sideNavLinkIconStyle = css`
  display: flex;
  margin-right: ${theme.spacing(1)};
  color: ${theme.colors.black};

  @media (max-width: ${TABLET_MODE_MAX_WIDTH}px) {
    margin-right: 0;
  }
`;

export const sideNavLinkTextStyle = css`
  @media (max-width: ${TABLET_MODE_MAX_WIDTH}px) {
    display: none;
  }

  @media (max-width: ${PHONE_MODE_MAX_WIDTH}px) {
    display: block;
    margin-left: ${theme.spacing(3)};
  }
`;

export const rippleStyle = css`
  margin-left: ${theme.spacing(0.5)};
`;

export const caretRightIconStyle = css`
  display: none;

  @media (max-width: ${PHONE_MODE_MAX_WIDTH}px) {
    display: block;
    margin-left: auto;
  }
`;

export const navLinkHoverLabelStyle = [
  defaultHoverFontStyle,
  css`
    max-width: 195px !important;
  `,
];

export const freeTrialContainerStyle = css`
  padding: ${theme.spacing(2, 2.5)};

  @media screen and (max-width: ${TABLET_MODE_MAX_WIDTH}px) {
    display: none;
  }
`;
