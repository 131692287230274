import { Children, useEffect } from 'react';

import {
  AnimatedMenu,
  useListboxContext,
  useListboxSearch,
  StyledListboxList,
  Text,
  useDebouncedValue,
} from '@weave/design-system';

import { useDropdownContext } from 'shared/providers';

import {
  noResultStyle,
  getDropdownMenuStyle,
  searchFieldStyle,
  listBoxStyle,
} from './multi-select-menu.styles';

interface EmptyStateType {
  value: string;
  isLoading?: boolean;
}

export const EmptyState = ({ value, isLoading }: EmptyStateType) => (
  <Text as="li" css={noResultStyle}>
    {isLoading ? (
      'Searching...'
    ) : (
      <>
        No results found for <b>"{value}"</b>.
      </>
    )}
  </Text>
);

const MultiSelectMenu = ({
  active,
  children,
  direction,
  name = '',
  placeholder = 'Search',
  ...rest
}) => {
  const { debouncedBlur, debouncedFocus, menuRef, onSearch, isLoadingItems, onActive } =
    useDropdownContext();

  const { listboxProps } = useListboxContext();
  const { searchFieldProps, SearchField } = useListboxSearch(children);
  const debouncedSearchTerm = useDebouncedValue(searchFieldProps.value);
  const searchFieldName = `ms-search-${name}`;

  useEffect(() => {
    if (active) {
      // make sure to reset field value between menu toggles
      searchFieldProps.onChange({ name: searchFieldName, value: '' });
    }

    if (onActive) {
      onActive(active);
    }
  }, [active]);

  useEffect(() => {
    if (onSearch) {
      onSearch(debouncedSearchTerm);
    }
  }, [debouncedSearchTerm]);

  return (
    // @ts-ignore some issue with HTMLAttributes on FlyoutProps
    <AnimatedMenu
      as="div"
      css={getDropdownMenuStyle({ isActive: active })}
      {...rest}
      ref={menuRef}
    >
      <SearchField
        {...searchFieldProps}
        onBlur={() => {
          debouncedBlur();
          searchFieldProps.onBlur();
        }}
        onFocus={() => {
          debouncedFocus();
          searchFieldProps.onFocus();
        }}
        label=""
        placeholder={placeholder}
        name={searchFieldName}
        css={searchFieldStyle}
        tabIndex={active ? undefined : -1}
      />

      <StyledListboxList css={listBoxStyle} {...listboxProps} tabIndex={active ? 0 : -1}>
        {Children.toArray(children).length ? (
          children
        ) : (
          <EmptyState value={searchFieldProps.value} isLoading={isLoadingItems} />
        )}
      </StyledListboxList>
    </AnimatedMenu>
  );
};

export default MultiSelectMenu;
