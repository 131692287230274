import { css } from '@emotion/core';
import { theme } from '@weave/theme';

export const headingStyle = css`
  line-height: 38px;
  margin: 0;
  margin-bottom: ${theme.spacing(2)};
  font-weight: ${theme.font.weight.regular};
`;

export const textStyle = css`
  max-width: 640px;
`;
