export enum ReminderType {
  Unspecified = 'UNSPECIFIED',
  AppointmentReminder = 'APPOINTMENT_REMINDER',
  AppointmentFollowup = 'APPOINTMENT_FOLLOWUP',
  BirthdayReminder = 'BIRTHDAY_MESSAGE',
  RecallReminder = 'RECALL_REMINDER',
  ReviewReminder = 'REVIEW_REQUEST',
  SaveTheDateReminder = 'SAVE_THE_DATE',
  MissedCall = 'MISSED_CALL',
  OrderReady = 'ORDER_READY',
}

export interface ActiveReminder {
  id: string;
  name: string;
}

export interface AutoReminderCheckResult {
  success: boolean;
  hasActiveReminders: boolean;
  appointmentReminders: ActiveReminder[];
  appointmentFollowup: ActiveReminder[];
  birthdays: ActiveReminder[];
  recall: ActiveReminder[];
  reviews: ActiveReminder[];
  saveTheDates: ActiveReminder[];
  missedCalls: ActiveReminder[];
  orderReady: ActiveReminder[];
  unspecified: ActiveReminder[];
}
