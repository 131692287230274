import { css } from '@emotion/core';
import { theme } from '@weave/theme';

export const modalStyle = css`
  .header {
    font-size: ${theme.fontSize(20)};
    margin-bottom: 0;
    padding-left: ${theme.spacing(3)};
    padding-right: ${theme.spacing(3)};

    h3 {
      margin: 0;
    }

    .sub-heading {
      margin: 0;
    }
  }

  .info-box {
    width: max-content;
    margin-bottom: ${theme.spacing(2)};
  }

  .info-text {
    margin: 0;
  }

  .modal-body {
    padding: ${theme.spacing(1, 0)};
  }

  .loader-wrapper {
    height: 200px;
  }
`;

export const chipStyle = css`
  max-width: 150px !important;
`;

export const modalActions = css`
  justify-content: flex-end;
  padding-right: 0 !important;

  button {
    padding-left: ${theme.spacing(2)};
    padding-right: ${theme.spacing(2)};
    width: fit-content !important;
  }
`;
