import { useQuery } from 'react-query';

import { queryKeys } from 'shared/constants';
import { Packet } from 'shared/types';
import { getCurrentLocationId } from 'shared/helpers/utils';
import { CustomAxios, getResponseData } from 'shared/helpers/axios';

interface UseGetPackets {
  data: Packet.Data[];
  isLoading: boolean;
}

export const useGetPackets = (): UseGetPackets => {
  const { data, isLoading } = useQuery(queryKeys.formPackets, getPackets, {
    refetchOnMount: true,
    staleTime: 1000,
    retry: false,
  });

  return {
    data: data?.packets || [],
    isLoading,
  };
};

interface PacketListResponse {
  message: string;
  packets: Packet.Data[];
}

async function getPackets() {
  const response = await CustomAxios.get<PacketListResponse>(
    `companies/${getCurrentLocationId()}/packets`
  );

  return getResponseData(response) as PacketListResponse;
}
