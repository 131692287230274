import { css } from '@emotion/core';
import { theme } from '@weave/theme';

export const conditionsWrapperStyles = css`
  padding: ${theme.spacing(2)};

  .new-condition {
    display: flex;
    justify-content: end;
    margin-top: ${theme.spacing(1)};
  }
`;

export const conditionsPanelItemStyles = (hasError: boolean) => css`
  ${hasError
    ? `border: 2px solid ${theme.colors.critical50};`
    : `border: 1px solid ${theme.colors.neutral20};`}

  border-radius: ${theme.borderRadius.small};
  padding: ${theme.spacing(2)};
  margin-bottom: ${theme.spacing(2)};

  header {
    display: flex;
    align-items: center;

    .condition-name {
      margin: 0;
    }

    .delete-condition {
      margin-left: auto;
    }
  }
`;
