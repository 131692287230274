import { css } from '@emotion/core';
import { theme, getBaseFontSize } from '@weave/theme';

const BASE = getBaseFontSize();

export const noResultStyle = css`
  margin: ${theme.spacing(2)};
`;

const dropdownMenuStyle = css`
  display: flex;
  flex-direction: column;
  max-height: ${288 / BASE}rem;
  width: 100%;
  background: white;
  box-shadow: ${theme.shadows.heavy};
  bottom: -1px;
  left: 0;
  overflow: auto;
  transform: translateY(100%);
  z-index: ${theme.zIndex.dropdown};

  :focus {
    outline: none;
  }
`;

interface DropdownMenuStyleConfig {
  isActive: boolean;
}

export function getDropdownMenuStyle({ isActive }: DropdownMenuStyleConfig) {
  if (!isActive) {
    return [
      dropdownMenuStyle,
      css`
        pointer-events: none;
      `,
    ];
  }

  return dropdownMenuStyle;
}

export const searchFieldStyle = css`
  margin: ${theme.spacing(1, 2, 0)};
`;

export const listBoxStyle = css`
  margin: ${theme.spacing(1)};
`;
