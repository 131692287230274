import { FC, useEffect, useState } from 'react';

import ReactQuill from 'react-quill';
import { sanitize } from 'dompurify';

import { EditSimpleIcon, PrimaryButton, SecondaryButton } from '@weave/design-system';

import { useFormComponentsContext } from 'context';
import { BuilderContext, IFormBuilder as FB } from 'shared/types';
import 'react-quill/dist/quill.snow.css';
import { css } from '@emotion/core';
import { theme } from '@weave/theme';

const toolbarOptions = [
  ['bold', 'italic', 'underline', 'strike'], // toggled buttons
  [{ header: [1, 2, 3, 4, 5, 6, false] }],
  ['blockquote'],

  [{ list: 'ordered' }, { list: 'bullet' }],
  [{ indent: '-1' }, { indent: '+1' }], // outdent/indent

  [{ color: [] }, { background: [] }], // dropdown with defaults from theme
  [{ font: [] }],
  [{ align: [] }],
];

interface BuilderRichTextProps {
  field: FB.BaseField;
}

const modalStyles = (showModal) => css`
  display: ${showModal ? 'block' : 'none'};
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);

  @keyframes animatebottom {
    0% {
      bottom: -300px;
      opacity: 0;
    }

    50% {
      opacity: 050;
    }

    100% {
      bottom: 0;
      opacity: 1;
    }
  }

  .modal-content {
    display: flex;
    flex-direction: column;
    margin: 100px auto 0;
    background-color: ${theme.colors.white};
    padding: 20px;
    width: 70vw;
    height: 80vh;

    position: relative;
    animation: animatebottom 0.4s;
  }

  .rich-text-wrapper {
    height: calc(100% - 80px);
  }

  .rich-text-editor {
    height: 100%;

    .ql-container {
      height: 90%;
    }
  }

  .rich-text-actions {
    display: flex;
    gap: 20px;
    justify-content: end;
  }
`;

const fieldWrapperStyle = css`
  position: relative;

  .edit-rich-text {
    position: absolute;
    right: 76px;
    top: -42px;
    cursor: pointer;
    visibility: hidden;
  }
`;

export const BuilderRichText: FC<BuilderRichTextProps> = ({ field }) => {
  const { updateFormState, recentlyAddedFieldId } = useFormComponentsContext();
  const [richTextValue, setRichTextValue] = useState(field.value);
  const [showModal, setShowModal] = useState<boolean>(false);

  useEffect(() => {
    if (field.id === recentlyAddedFieldId) {
      setShowModal(true);
    }
  }, []);

  const updateField = () => {
    updateFormState({
      type: BuilderContext.FormUpdateType.UPDATE_FIELD,
      data: {
        ...field,
        value: richTextValue,
      },
    });
    setShowModal(false);
  };

  const purifiedRichText = sanitize(field.value, { USE_PROFILES: { html: true } });

  const onModalCancel = () => {
    setRichTextValue(field.value);
    setShowModal(false);
  };

  return (
    // ReactQuill has some issue when using it with design system modal
    // So, using custom modal
    <div css={fieldWrapperStyle}>
      <div css={modalStyles(showModal)}>
        <div className="modal-content">
          <div className="rich-text-wrapper">
            <ReactQuill
              className="rich-text-editor"
              id={field.id}
              theme="snow"
              placeholder="Compose a custom styled text..."
              value={richTextValue}
              onChange={setRichTextValue}
              modules={{ toolbar: toolbarOptions }}
            />
          </div>
          <div className="rich-text-actions">
            <SecondaryButton onClick={onModalCancel} size="tiny">
              Cancel
            </SecondaryButton>
            <PrimaryButton onClick={updateField} size="tiny">
              Update
            </PrimaryButton>
          </div>
        </div>
      </div>

      <EditSimpleIcon
        className="edit-rich-text"
        size={20}
        color="light"
        onClick={() => setShowModal(true)}
      />
      <div
        className="ql-editor"
        dangerouslySetInnerHTML={{ __html: purifiedRichText }}
      ></div>
    </div>
  );
};
