import { CustomAxios } from 'shared/helpers/axios';
import { API_URL } from 'shared/constants';
import { getCurrentLocationId } from 'shared/helpers/utils';
import { FORMS_ACLs } from 'shared/types';

import {
  UsersListResponse,
  ProviderMappingResponse,
  ProviderMappingPayload,
} from './types';

export async function fetchUsers() {
  const { data } = await CustomAxios.get<UsersListResponse>('/portal/v1/users', {
    baseURL: API_URL,
  });

  let usersWithProviderReviewRole = (data.data ?? []).filter((user) => {
    for (const location of user.Locations) {
      for (const role of location.Roles) {
        if (role.Permissions?.includes(FORMS_ACLs.FORMS_PROVIDER_REVIEW)) {
          return true;
        }
      }
    }

    return false;
  });

  return usersWithProviderReviewRole;
}

const PROVIDER_MAPPING_ENDPOINT = '/providers/weave-user-mapping';
const PROVIDER_MAPPING_ENDPOINT_V2 = `${API_URL}/forms-digital/v2/providers/weave-user-mapping`;

export async function fetchProviderMapping() {
  const { data } = await CustomAxios.get<ProviderMappingResponse>(
    PROVIDER_MAPPING_ENDPOINT,
    {
      params: {
        companyId: getCurrentLocationId(),
      },
    }
  );

  return data.providerMappings ?? [];
}

export async function bulkProviderMappingUpdate(payload: ProviderMappingPayload[]) {
  try {
    await CustomAxios.post<void>(PROVIDER_MAPPING_ENDPOINT_V2, {
      companyId: getCurrentLocationId(),
      providerMappings: payload,
    });

    return { success: true };
  } catch (error) {
    console.error('Error updating provider mapping', error);
    return { success: false };
  }
}

export async function bulkProviderMappingDelete(mappingIdsToRemove: string[]) {
  try {
    let url = `${PROVIDER_MAPPING_ENDPOINT_V2}?companyId=${getCurrentLocationId()}`;

    for (const id of mappingIdsToRemove) {
      url += `&ids=${id}`;
    }

    await CustomAxios.delete<void>(url);

    return { success: true };
  } catch (error) {
    console.error('Error deleting provider mapping', error);
    return { success: false };
  }
}
