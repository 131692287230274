import { CustomAxios } from 'shared/helpers/axios';
import { getCurrentLocationId } from 'shared/helpers/utils';
import { SyncAppTypes } from 'shared/types';

interface SourceTenantsResponse {
  sources?: SyncAppTypes.SourceTenant[];
}

export async function fetchAvailableSourceTenants() {
  const locationId = getCurrentLocationId();

  if (!locationId) {
    return Promise.reject('Location ID not provided');
  }

  const { data } = await CustomAxios.get<SourceTenantsResponse>(
    '/locations/source-tenants',
    {
      params: { company_id: locationId },
    }
  );

  return data.sources ?? [];
}

interface PreferredSourceTenantResponse {
  sourceTenantId?: string;
}

export async function fetchPreferredSourceTenant() {
  const locationId = getCurrentLocationId();

  if (!locationId) {
    return Promise.reject('Location ID not provided');
  }

  const { data } = await CustomAxios.get<PreferredSourceTenantResponse>(
    '/preferred-source-tenant',
    {
      params: { company_id: locationId },
    }
  );

  return data;
}

export interface UpdatePreferredSourceTenantPayload {
  sourceTenantId: string;
}

export async function updatePreferredSourceTenant({
  sourceTenantId,
}: UpdatePreferredSourceTenantPayload) {
  const locationId = getCurrentLocationId();

  if (!locationId) {
    return Promise.reject('Location ID not provided');
  }

  const response = await CustomAxios.patch('/preferred-source-tenant', {
    company_id: locationId,
    source_tenant_id: sourceTenantId,
  });

  return response;
}
