import {
  QueryObserverResult,
  RefetchOptions,
  RefetchQueryFilters,
  useQuery,
} from 'react-query';

import { useWritebackCapabilitiesContext } from 'context';
import { queryKeys } from 'shared/constants';
import { CustomAxios, getResponseData } from 'shared/helpers/axios';
import { getCurrentLocationId } from 'shared/helpers/utils';
import { IFormBuilder } from 'shared/types';

interface UseImportEaglesoftFormResults {
  esForms: IFormBuilder.FormStructure[];
  isLoadingAllEsForms: boolean;
  errorLoadingEsForms: boolean;
  fetchEaglesoftForms: <TPageData>(
    options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined
  ) => Promise<QueryObserverResult<EaglesoftMultiImportResponse, unknown>>;
}

const EMPTY_FORMS: IFormBuilder.FormStructure[] = [];

export const useImportEaglesoftForm = (): UseImportEaglesoftFormResults => {
  const { preferredSourceTenantId } = useWritebackCapabilitiesContext();

  const { data, isLoading, isError, refetch } = useQuery(
    queryKeys.eagleSoft.multiImport,
    () => getEaglesoftMultiImport(preferredSourceTenantId),
    {
      enabled: false,
    }
  );

  return {
    esForms: data?.forms || EMPTY_FORMS,
    isLoadingAllEsForms: isLoading,
    errorLoadingEsForms: isError,
    fetchEaglesoftForms: refetch,
  };
};

interface EaglesoftMultiImportResponse {
  forms: IFormBuilder.FormStructure[];
}

function getEaglesoftMultiImport(
  sourceTenantId: string
): Promise<EaglesoftMultiImportResponse> {
  return CustomAxios.get<EaglesoftMultiImportResponse>('es-medical-history-forms', {
    params: {
      company_id: getCurrentLocationId(),
      source_tenant_id: sourceTenantId,
    },
  }).then<EaglesoftMultiImportResponse>(getResponseData);
}
