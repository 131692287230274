import { useQuery } from 'react-query';

import { getFormTemplate } from 'shared/helpers/axios/apis/form-builder.api';

export function useFormBuilder(templateId: string | null) {
  return useQuery(
    ['form-template', templateId],
    () => (templateId ? getFormTemplate(templateId) : undefined),
    { staleTime: 0, cacheTime: 0 }
  );
}
