import { CheckboxField, useFormField } from '@weave/design-system';
import { FC } from 'react';
import { IFormBuilder as FB } from 'shared/types';

interface BuilderDigitalSignatureFieldProps {
  field: FB.Signature;
}

export const BuilderDigitalSignatureField: FC<BuilderDigitalSignatureFieldProps> = ({
  field,
}) => {
  const fieldProps = useFormField({
    type: 'checkbox',
  });
  return (
    <CheckboxField name={field.id} label={field.confirmationLabel} {...fieldProps} />
  );
};
