import { FC } from 'react';

import { Text, NakedButton, XIconSmall, useHoverLabel } from '@weave/design-system';

import { defaultHoverFontStyle } from 'shared/styles';

import {
  getContainerStyle,
  textStyle,
  buttonStyle,
} from './multi-select-selection.styles';

interface MultiSelectSelectionProps {
  onRemove: () => void;
  selectionCount: number;
  isInvalid?: boolean;
}

const MultiSelectSelection: FC<MultiSelectSelectionProps> = ({
  children,
  onRemove,
  isInvalid,
}) => {
  const { HoverLabel, labelProps, triggerProps } = useHoverLabel();

  return (
    <Text size="medium" color="light" css={getContainerStyle({ isInvalid })}>
      <span css={textStyle} {...triggerProps}>
        {children}
      </span>

      <HoverLabel {...labelProps} css={defaultHoverFontStyle}>
        {children}
      </HoverLabel>

      <NakedButton onClick={onRemove} css={buttonStyle}>
        <XIconSmall />
      </NakedButton>
    </Text>
  );
};

export default MultiSelectSelection;
