import { css } from '@emotion/core';
import { theme } from '@weave/theme';
import { BUILDER_AREA_HIGHLIGHT_TIME } from 'shared/constants';

export const sectionWrapperStyle = (hightlight: boolean) => css`
  @keyframes highlight-section {
    0% {
      box-shadow: 0 0 10px ${theme.colors.primary50};
    }
    50% {
      box-shadow: 0 0 5px ${theme.colors.primary50};
    }
    100% {
      box-shadow: none;
    }
  }

  &.section-wrapper {
    margin-left: ${theme.spacing(1)};
    margin-right: ${theme.spacing(1)};
    ${hightlight && `animation: highlight-section ${BUILDER_AREA_HIGHLIGHT_TIME}s ease`};

    &:not(:last-of-type) {
      margin-bottom: ${theme.spacing(5)};
    }
  }
`;

export const formSectionStyle = css`
  width: 100%;
`;

export const sectionHeaderStyle = css`
  display: flex;
  align-items: center;

  .edit-section-wrapper {
    width: 70%;
    display: flex;
    margin-bottom: ${theme.spacing(2)};

    .edit-section-input {
      width: 400px;
    }

    .update-section-name-btn {
      margin-left: ${theme.spacing(1)};
      padding-left: ${theme.spacing(1)};
      padding-right: ${theme.spacing(1)};
    }
  }

  .secion-name {
    font-weight: ${theme.font.weight.semibold};
    display: inline;
  }

  .edit-section-name {
    display: inline-block;
    align-items: center;
    cursor: text;

    :hover {
      .edit-icon {
        visibility: visible;
      }
    }
  }

  .delete-section-icon,
  .duplicate-section-icon {
    display: none;
  }

  .edit-icon {
    visibility: hidden;
    margin-left: ${theme.spacing(1)};
    position: relative;
    top: ${theme.spacing(0.25)};
  }
  .section-actions {
    margin-left: auto;
    display: flex;
    align-items: center;
    gap: ${theme.spacing(2)};

    svg {
      cursor: pointer;
    }
  }

  .reorder-icons-wrapper {
    display: flex;
    flex-direction: column;
  }

  :hover {
    .delete-section-icon,
    .duplicate-section-icon {
      display: block;
    }
  }
`;

export const formSectionEmptyStyle = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: ${theme.colors.neutral10};
  min-height: 350px;
`;
