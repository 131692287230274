import { css } from '@emotion/core';
import { theme } from '@weave/theme';

export const containerStyle = css`
  display: flex;
  align-items: center;
  gap: ${theme.spacing(1)};
  margin-bottom: ${theme.spacing(2)};
  padding: ${theme.spacing(0, 2)};
  border: 1px solid ${theme.colors.neutral20};
  border-radius: ${theme.borderRadius.small};
`;

export const iconStyle = css`
  color: ${theme.colors.primary50};
`;

export const textStyle = css`
  font-size: ${theme.fontSize(14)};
`;
