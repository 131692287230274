import { css } from '@emotion/core';
import { theme } from '@weave/theme';

export const newBadgeContainerStyle = css`
  margin-left: ${theme.spacing(2)};
  cursor: help;
`;

export const newBadgeHoverLabelStyle = css`
  > * {
    color: ${theme.colors.white};
    margin: 0;
    font-weight: 400;
    font-size: ${theme.fontSize(14)};
    max-width: 195px;

    .bold {
      font-weight: 700;
    }
  }
`;

export const badgeStyleOverride = css`
  border-color: ${theme.colors.primary70};
  line-height: 1;
  text-transform: uppercase;
  font-size: ${theme.fontSize(8)};
  font-weight: 600;
  padding: 3px 3.5px 1.75px;
  display: block;
`;
