export type SubmissionReviewStatus =
  | 'Pending'
  | 'Approved'
  | 'Rejected'
  | 'Not Applicable';

export interface UnreadCountResponse {
  allSubmitted?: number;
  approved?: number;
  archived?: number;
  completed?: number;
  failedSync?: number;
  inbox?: number;
  needsApproval?: number;
  notSubmitted?: number;
  pending?: number;
  recentlySubmitted?: number;
  recentNotSynced?: number;
  rejected?: number;
  reviewed?: number;
  submitted?: number;
  synced?: number;
}

export interface SubmissionCount {
  count?: number;
  dashboard_label: string;
}

export interface SubmissionStatsResponse {
  result: SubmissionCount[];
}

export enum SubmissionMode {
  SUBMISSION_MODE_UNKNOWN, // Unknown mode
  SOLICITED_AUTOREMINDER, // Submissions received through auto reminders flow
  SOLICITED_DESKTOPCLIENT, // Submissions received through desktop client link generation flow
  SOLICITED_INCHAIRKIOSK, // Submissions received through in-chair Kiosk mode
  UNSOLICITED_PUBLICLINKS, // Submissions received through public links
  UNSOLICITED_PATIENTKIOSK, // Submissions received through Kiosk mode for patients
}
