import { css } from '@emotion/core';
import { theme } from '@weave/theme';

export const containerStyle = css`
  padding-top: ${theme.spacing(2)};
`;

export const headerStyle = css`
  h2 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: ${theme.spacing(1)};
  }
`;

const bodyStyle = css`
  display: flex;
  flex-direction: column;
  padding-top: ${theme.spacing(1)};
  position: relative;
`;

interface BodyStyleConfig {
  isDropdownActive: boolean;
}

export function getBodyStyle({ isDropdownActive }: BodyStyleConfig) {
  if (!isDropdownActive) {
    return [
      bodyStyle,
      css`
        overflow: hidden !important;
      `,
    ];
  }

  return [
    bodyStyle,
    css`
      min-height: 400px;
    `,
  ];
}

const innerContainerStyle = css`
  margin-top: auto;
  padding-top: ${theme.spacing(1)};
`;

interface InnerContainerStyleConfig {
  isDropdownActive: boolean;
}

export function getInnerContainerStyle({ isDropdownActive }: InnerContainerStyleConfig) {
  if (!isDropdownActive) {
    return innerContainerStyle;
  }

  return [
    innerContainerStyle,
    css`
      margin-top: 320px;
    `,
  ];
}

export const buttonContainerStyle = css`
  display: flex;
  flex-direction: row-reverse;
  gap: ${theme.spacing(2)};
  margin: ${theme.spacing(0, 3)};
  margin-top: ${theme.spacing(2)};
`;
