export const ROOT = '/';

const FORMS_ROOT = '/forms';
export const FORMS = {
  root: FORMS_ROOT,
  publicSubmissions: `${FORMS_ROOT}/public-submissions`,
  templates: `${FORMS_ROOT}/templates`,
  packets: `${FORMS_ROOT}/packets`,
};
export const FRONT_CHANNEL_LOGOUT = '/front-channel-logout';
export const SIGN_IN = '/sign-in';
export const LOCATION = '/location';

export const FORM_SUBMISSION_ROOT = '/form-submissions';
const FORM_SUBMISSION_NEED_APPROVAL_CATEGORY_SLUG = 'need-approval';
const FORM_SUBMISSION_COMPLETED_CATEGORY_SLUG = 'completed';
const FORM_SUBMISSION_REVIEWED_CATEGORY_SLUG = 'reviewed';
export const FORM_SUBMISSION_CATEGORY_SLUGS = {
  allSubmitted: 'all-submitted',
  recentlySubmitted: 'recent',
  notSubmitted: 'not-submitted',
  needsReview: 'needs-review',
  needApproval: {
    root: FORM_SUBMISSION_NEED_APPROVAL_CATEGORY_SLUG,
    manual: `${FORM_SUBMISSION_NEED_APPROVAL_CATEGORY_SLUG}/manual`,
    notSynced: `${FORM_SUBMISSION_NEED_APPROVAL_CATEGORY_SLUG}/not-synced`,
  },
  failedSync: 'failed-sync',
  reviewed: {
    root: `${FORM_SUBMISSION_REVIEWED_CATEGORY_SLUG}/all`,
    approved: `${FORM_SUBMISSION_REVIEWED_CATEGORY_SLUG}/approved`,
    rejected: `${FORM_SUBMISSION_REVIEWED_CATEGORY_SLUG}/rejected`,
  },
  completed: {
    root: FORM_SUBMISSION_COMPLETED_CATEGORY_SLUG,
    withWritebacks: `${FORM_SUBMISSION_COMPLETED_CATEGORY_SLUG}/with-writebacks`,
    withoutWritebacks: `${FORM_SUBMISSION_COMPLETED_CATEGORY_SLUG}/without-writebacks`,
  },
  archived: 'archived',
};
export const FORM_SUBMISSION_CATEGORIES = {
  root: FORM_SUBMISSION_ROOT,
  allSubmitted: `${FORM_SUBMISSION_ROOT}/${FORM_SUBMISSION_CATEGORY_SLUGS.allSubmitted}`,
  recentlySubmitted: `${FORM_SUBMISSION_ROOT}/${FORM_SUBMISSION_CATEGORY_SLUGS.recentlySubmitted}`,
  notSubmitted: `${FORM_SUBMISSION_ROOT}/${FORM_SUBMISSION_CATEGORY_SLUGS.notSubmitted}`,
  needsReview: `${FORM_SUBMISSION_ROOT}/${FORM_SUBMISSION_CATEGORY_SLUGS.needsReview}`,
  needApproval: {
    root: `${FORM_SUBMISSION_ROOT}/${FORM_SUBMISSION_CATEGORY_SLUGS.needApproval.root}`,
    manual: `${FORM_SUBMISSION_ROOT}/${FORM_SUBMISSION_CATEGORY_SLUGS.needApproval.manual}`,
    notSynced: `${FORM_SUBMISSION_ROOT}/${FORM_SUBMISSION_CATEGORY_SLUGS.needApproval.notSynced}`,
  },
  failedSync: `${FORM_SUBMISSION_ROOT}/${FORM_SUBMISSION_CATEGORY_SLUGS.failedSync}`,
  reviewed: {
    root: `${FORM_SUBMISSION_ROOT}/${FORM_SUBMISSION_CATEGORY_SLUGS.reviewed.root}`,
    approved: `${FORM_SUBMISSION_ROOT}/${FORM_SUBMISSION_CATEGORY_SLUGS.reviewed.approved}`,
    rejected: `${FORM_SUBMISSION_ROOT}/${FORM_SUBMISSION_CATEGORY_SLUGS.reviewed.rejected}`,
  },
  completed: {
    root: `${FORM_SUBMISSION_ROOT}/${FORM_SUBMISSION_CATEGORY_SLUGS.completed.root}`,
    withWritebacks: `${FORM_SUBMISSION_ROOT}/${FORM_SUBMISSION_CATEGORY_SLUGS.completed.withWritebacks}`,
    withoutWritebacks: `${FORM_SUBMISSION_ROOT}/${FORM_SUBMISSION_CATEGORY_SLUGS.completed.withoutWritebacks}`,
  },
  archived: `${FORM_SUBMISSION_ROOT}/${FORM_SUBMISSION_CATEGORY_SLUGS.archived}`,
};
export const FORM_SUBMISSION_CATEGORIES_WITH_SUBMISSION_ID = {
  allSubmitted: `${FORM_SUBMISSION_CATEGORIES.allSubmitted}/:submissionId?`,
  recentlySubmitted: `${FORM_SUBMISSION_CATEGORIES.recentlySubmitted}/:submissionId?`,
  notSubmitted: `${FORM_SUBMISSION_CATEGORIES.notSubmitted}/:submissionId?`,
  needsReview: `${FORM_SUBMISSION_CATEGORIES.needsReview}/:submissionId?`,
  needApproval: {
    root: FORM_SUBMISSION_CATEGORIES.needApproval.root,
    manual: `${FORM_SUBMISSION_CATEGORIES.needApproval.manual}/:submissionId?`,
    notSynced: `${FORM_SUBMISSION_CATEGORIES.needApproval.notSynced}/:submissionId?`,
  },
  failedSync: `${FORM_SUBMISSION_CATEGORIES.failedSync}/:submissionId?`,
  reviewed: {
    root: `${FORM_SUBMISSION_CATEGORIES.reviewed.root}/:submissionId?`,
    approved: `${FORM_SUBMISSION_CATEGORIES.reviewed.approved}/:submissionId?`,
    rejected: `${FORM_SUBMISSION_CATEGORIES.reviewed.rejected}/:submissionId?`,
  },
  completed: {
    root: FORM_SUBMISSION_CATEGORIES.completed.root,
    withWritebacks: `${FORM_SUBMISSION_CATEGORIES.completed.withWritebacks}/:submissionId?`,
    withoutWritebacks: `${FORM_SUBMISSION_CATEGORIES.completed.withoutWritebacks}/:submissionId?`,
  },
  archived: `${FORM_SUBMISSION_CATEGORIES.archived}/:submissionId?`,
};

const KIOSK_MODE_ROOT = '/kiosk';
export const KIOSK_MODE = {
  root: KIOSK_MODE_ROOT,
  welcome: `${KIOSK_MODE_ROOT}/welcome`,
  officeCheckIn: `${KIOSK_MODE_ROOT}/office-check-in`,
  patronCheckIn: `${KIOSK_MODE_ROOT}/patron-check-in`,
  kioskOrder: `${KIOSK_MODE_ROOT}/kiosk-order`,
};

export const LEGAL_DOCUMENTATION = {
  root: '/legal-documentation',
};

const SETTINGS_ROOT = '/settings';
export const SETTINGS = {
  root: `${SETTINGS_ROOT}`,
  brandingSetup: `${SETTINGS_ROOT}/branding-setup`,
  writebacks: `${SETTINGS_ROOT}/writebacks`,
  medicalConditions: `${SETTINGS_ROOT}/medical-history`,
  providerMapping: `${SETTINGS_ROOT}/provider-mapping`,
};

export const MEDICAL_CONDITONS = {
  root: `${SETTINGS.medicalConditions}`,
  slug: `${SETTINGS.medicalConditions}/:id`,
};

export const WRITEBACKS = {
  root: `${SETTINGS.writebacks}`,
  setting: `${SETTINGS.writebacks}/:id`,
};

export const TRAINING_VIDEOS = {
  root: '/training-videos',
};

export const FORM_BUILDER_BASE = '/form-builder';
export const FORM_TEMPLATES_BASE = `${FORM_BUILDER_BASE}/templates`;
export const FORM_PACKETS_BASE = `${FORM_BUILDER_BASE}/packets`;
export const FORM_EDITOR_BASE = `${FORM_BUILDER_BASE}/editor`;
export const LEGAL_DOCUMENTS_BASE = `${FORM_BUILDER_BASE}/legal-documents`;

export const FORM_BUILDER = {
  root: `${FORM_BUILDER_BASE}`,
  templates: `${FORM_TEMPLATES_BASE}/:token?`,
  packets: `${FORM_PACKETS_BASE}/:token?`,
  privacyPolicy: `${LEGAL_DOCUMENTS_BASE}/privacy-policy`,
  termsAndConditions: `${LEGAL_DOCUMENTS_BASE}/terms-and-conditions`,
};

const WEAVE_TEMPLATES_BASE = '/form-templates';

export const WEAVE_TEMPLATES = {
  root: WEAVE_TEMPLATES_BASE,
  template: `${WEAVE_TEMPLATES_BASE}/:token?`,
};
