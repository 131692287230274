import { css } from '@emotion/core';
import { theme } from '@weave/theme';
import { BUILDER_AREA_HIGHLIGHT_TIME } from 'shared/constants';
import { IFormBuilder as FB } from 'shared/types';

export const fieldStyle = css`
  border: 1px solid transparent;
  margin: ${theme.spacing(2)};
  padding: ${theme.spacing(2)};
  border-radius: ${theme.borderRadius.small};
  cursor: default !important;

  @keyframes highlight-section {
    0% {
      box-shadow: 0 0 10px ${theme.colors.primary50};
    }
    50% {
      box-shadow: 0 0 5px ${theme.colors.primary50};
    }
    100% {
      box-shadow: none;
    }
  }

  &.highlighted {
    animation: highlight-section ${BUILDER_AREA_HIGHLIGHT_TIME}s ease;
  }

  .reorder-icon {
    display: none;
    margin-right: ${theme.spacing(1)};
  }

  /* this class is used in checkbox.component.tsx */
  .edit-checkbox-icon {
    visibility: hidden;
    margin-left: ${theme.spacing(1)};
  }

  :hover {
    border-color: ${theme.colors.neutral20};
    box-shadow: ${theme.shadows.light};

    .field-item-actions,
    .edit-checkbox-icon,
    .edit-rich-text {
      visibility: visible;
      cursor: pointer;
    }

    .reorder-icon {
      display: block;
    }
  }
`;

export const fieldRendererWrapper = (fieldType: FB.FieldType) => css`
  max-width: ${fieldType === 'richText' ? '100%' : '500px'};
`;

export const fieldActionsStyle = css`
  display: flex;
  margin-top: ${theme.spacing(2)};
  align-items: center;

  .add-condition {
    margin-left: auto;
  }
`;

export const syncIconStyles = (isSyncable: boolean) => css`
  margin-right: ${theme.spacing(1)};

  ${isSyncable && `color: ${theme.colors.primary50}`}
  ${!isSyncable && `color: ${theme.colors.neutral30}`}
`;

export const headerStyles = (isEditing: boolean) => {
  return css`
    display: flex;
    align-items: center;
    border-bottom: 1px solid ${theme.colors.neutral10};
    margin-bottom: ${theme.spacing(2)};
    ${isEditing && `padding-bottom: ${theme.spacing(1)}`}

    .field-name-text {
      display: inline-block;
      align-items: center;
      cursor: text;

      :hover {
        .edit-field-icon {
          visibility: visible;
        }
      }
    }

    .field-name {
      font-size: ${theme.fontSize(18)};
      margin: ${theme.spacing(1, 1, 1, 0)};
      font-weight: ${theme.font.weight.semibold};
      display: inline;
    }

    .edit-field-icon {
      position: relative;
      top: ${theme.spacing(0.25)};
      visibility: hidden;
      cursor: pointer;
    }

    .field-item-actions {
      display: flex;
      gap: ${theme.spacing(2)};
      margin-left: auto;
      visibility: hidden;
    }

    .field-name-edit {
      width: 50%;
    }
  `;
};
