import { useQuery } from 'react-query';
import { API_URL, queryKeys } from 'shared/constants';
import { CustomAxios } from 'shared/helpers/axios';
import { getCurrentLocationId } from 'shared/helpers/utils';

const getAppointmentDetails = (appointmentId: string) => {
  return CustomAxios.get(`${API_URL}/v1/list/reminder`, {
    params: {
      filter: `appointmentid~${appointmentId}`,
    },
  });
};

export const useAppointment = (appointmentId: string) => {
  return useQuery(
    [queryKeys.appointments.appointment, appointmentId],
    () => getAppointmentDetails(appointmentId),
    {
      staleTime: Infinity,
    }
  );
};
