import { CustomAxios } from '../axios';
import { DownloadPayload, createDownloadLink } from './';

export const base64toImage = (base64: string) => {
  return `data:image/png;base64,${base64}`;
};

export function saveImage(data: DownloadPayload) {
  const { base64Data } = data;
  return createDownloadLink({ ...data, base64Data: base64toImage(base64Data) });
}

export async function fetchAttachmentData(
  id: string,
  companyId: string,
  submissionId?: string
) {
  let queryURL = `media-auth/${id}?company_id=${companyId}`;
  let isSubmissionAttachment = false;

  if (submissionId) {
    queryURL += `&submission_id=${submissionId}`;
    isSubmissionAttachment = true;
  }
  queryURL += `&submission_attachment=${isSubmissionAttachment}`;

  const response = await CustomAxios.get<{ data: string }>(queryURL);
  return response.data.data;
}

export function saveCanvasImage(canvasId: string, filename: string) {
  const qrCodeImage = document.getElementById(canvasId) as HTMLCanvasElement;
  if (qrCodeImage) {
    try {
      const dataURL = qrCodeImage.toDataURL('image/png');
      const link = document.createElement('a');
      link.download = filename;
      link.href = dataURL;
      link.click();
      return true;
    } catch (error) {
      return false;
    }
  } else {
    return false;
  }
}
