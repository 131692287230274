import { useEffect } from 'react';

import { ModalControlResponse, useModalControl } from '@weave/design-system';

import { useWritebackCapabilitiesContext, useWritebackSetupContext } from 'context';
import { CUSTOM_USER_ACCESS } from 'shared/constants';
import { isWeaveUser } from 'shared/helpers/utils';
import { useCustomAccessUser } from 'shared/hooks';

import PMSChangeNotificationModal from './pms-change-notification-modal/pms-change-notification-modal.component';

interface UsePMSChangeNotificationResults extends ModalControlResponse {
  PMSChangeNotificationModal: typeof PMSChangeNotificationModal;
}

export const usePMSChangeNotification = (): UsePMSChangeNotificationResults => {
  const { isWritebackSetupInProgress } = useWritebackSetupContext();

  const {
    isLoadingPreferredSourceTenant,
    isFetchedPreferredSourceTenant,
    isLoadingWritebackSourcesCapabilities,
    isFetchedWritebackSourcesCapabilities,
    isLoadingSourceTenants,
    isFetchedSourceTenants,
    getSourceTenantValidity,
    isWritebackCapableSource,
    preferredSourceTenantId,
    updatePreferredSourceTenant,
    hasMultiTenantSource,
    hasMultipleSupportedSourceTenants,
    supportedSourceTenants,
    isAuthStatusChecked,
    writebackAuthorization,
    hasPMSIntegration,
  } = useWritebackCapabilitiesContext();

  const { isCustomAccessUser, isLoading: isCheckingCustomUserAccess } =
    useCustomAccessUser(CUSTOM_USER_ACCESS.sourceIdCorrection);

  const modalControls = useModalControl();

  useEffect(() => {
    if (
      isLoadingPreferredSourceTenant ||
      isLoadingWritebackSourcesCapabilities ||
      isLoadingSourceTenants ||
      isWritebackSetupInProgress ||
      !isAuthStatusChecked ||
      isCheckingCustomUserAccess ||
      (isWeaveUser() && !isCustomAccessUser) ||
      !hasPMSIntegration ||
      !isFetchedWritebackSourcesCapabilities ||
      !isFetchedPreferredSourceTenant ||
      !isFetchedSourceTenants
    ) {
      return;
    }

    if (
      isAuthStatusChecked &&
      (writebackAuthorization === 'Invalid' || writebackAuthorization === 'Deauthorised')
    ) {
      return;
    }

    const timeout = setTimeout(() => {
      const sourceTenantValidity = getSourceTenantValidity(preferredSourceTenantId, true);

      const sourceId = sourceTenantValidity.sourceId ?? '';

      if (!sourceTenantValidity.isValidSource || !isWritebackCapableSource(sourceId)) {
        // Update the preferred sync app for single PMS locations.
        if (!hasMultipleSupportedSourceTenants() && !hasMultiTenantSource) {
          if (supportedSourceTenants.length === 0) {
            return;
          }

          const newPreferredSourceTenant = supportedSourceTenants[0];

          if (newPreferredSourceTenant.sourceTenantId) {
            updatePreferredSourceTenant({
              sourceTenantId: newPreferredSourceTenant.sourceTenantId,
            });
          }
        }

        modalControls.openModal();
      }
    }, 300);

    return () => {
      clearTimeout(timeout);
    };
  }, [
    isLoadingPreferredSourceTenant,
    isLoadingWritebackSourcesCapabilities,
    isLoadingSourceTenants,
    preferredSourceTenantId,
    hasMultipleSupportedSourceTenants,
    supportedSourceTenants,
    isAuthStatusChecked,
    writebackAuthorization,
    isCheckingCustomUserAccess,
    isCustomAccessUser,
    isWritebackSetupInProgress,
    hasMultiTenantSource,
    isFetchedWritebackSourcesCapabilities,
    isFetchedPreferredSourceTenant,
    isFetchedSourceTenants,
  ]);

  return {
    ...modalControls,
    PMSChangeNotificationModal,
  };
};
